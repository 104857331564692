import './styles.scss'

import { Loader } from '@/app/base/components'
import {
  ShipmentSearchBar,
  ShipmentsTable,
} from '@/app/features/shipments/components'
import EmptyResult from '@/app/features/shipments/components/EmptyResult'
import { ShipmentsContext } from '@/contexts'
import { useTranslation } from '@/hooks'
import { SmartSearchContext } from '@/store/smartSearch'
import * as React from 'react'
import { useContext, useEffect } from 'react'

import { ShipmentsTableProvider } from './providers'

const Shipments: React.FC = () => {
  const { setSearchMode, searchMode, fetching } = useContext(ShipmentsContext)

  const {
    searchResult,
    isFetching,
    activeChips,
    isScrolling,
    fetchFilteredShipments,
  } = useContext(SmartSearchContext)

  useEffect(() => {
    if (searchResult.length === 0 || searchMode) {
      setSearchMode(false)
    }
    fetchFilteredShipments()
  }, [])

  const [t] = useTranslation('ALL_SHIPMENTS')

  return (
    <div id="shipments" className="container-fluid">
      <h1 className="h2 mt-4 text-left title bold">{t('ALL_SHIPMENTS')}</h1>
      <div className="wrapper">
        <ShipmentSearchBar />
        {!isScrolling ? (
          <Loader
            loading={fetching || isFetching}
            caption={t('FETCHING_SHIPMENTS')}
          />
        ) : null}
        {!!!searchResult.length && !!activeChips.length ? (
          <EmptyResult />
        ) : !!searchResult.length ? (
          <div className="flex-wrapper">
            <ShipmentsTableProvider>
              <ShipmentsTable
                shipments={searchResult}
                isFetching={fetching || isFetching}
                isSearchMode={!!activeChips.length && !isScrolling}
              />
            </ShipmentsTableProvider>
          </div>
        ) : (
          <EmptyResult />
        )}
      </div>
    </div>
  )
}

export default Shipments
