import { fromBuffer } from 'file-type'
import get from 'lodash/get'

export const getMimeTypeFromBase64String: (
  base64Str: string
) => Promise<string | undefined> = async (base64Str: string) => {
  const result = await fromBuffer(Buffer.from(base64Str, 'base64'))

  if (result) {
    return get(result, 'mime')
  }
}
