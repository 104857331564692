import './style.scss'

import { Tooltip } from '@/app/base/components'
import Trunkrs from '@/app/base/components/icons/Trunkrs'
import { AppContext, AuthContext } from '@/contexts'
import { useTranslation } from '@/hooks'
import { IRoute } from '@/models'
import { clsx } from '@/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { defaults, first, isArray } from 'lodash'
import React, { memo, useCallback, useContext, useMemo } from 'react'
import { NavLink } from 'react-router-dom'

import { ReactComponent as ToggleIconCollapsed } from '../../img/sidebar-menu/sidemenu-toggle-collapsed.svg'
import { ReactComponent as ToggleIcon } from '../../img/sidebar-menu/sidemenu-toggle.svg'

interface ISidebarProps {
  routes: Array<IRoute>
  children?: React.ReactNode
}

const Sidebar = ({ routes, children }: ISidebarProps) => {
  const { sidebarCollapsed, setSidebarCollapsed } = useContext(AppContext)

  const { authenticated } = useContext(AuthContext)

  const [t] = useTranslation('SIDEBAR')
  const routesWithLabel = useMemo(() => routes.filter(route => route.label), [])

  const toggleSidebar = useCallback(
    () => setSidebarCollapsed(!sidebarCollapsed),
    [sidebarCollapsed]
  )

  const normalizePath = useCallback(
    (path: string | string[]) =>
      isArray(path) ? (first(path) as string) : path,
    []
  )

  return (
    <>
      <nav className={clsx('sidebar', sidebarCollapsed && 'collapsed')}>
        {authenticated && (
          <div
            id="toggle-sidebar-collapse"
            className={clsx(sidebarCollapsed && 'collapsed')}
            onClick={toggleSidebar}
          >
            {sidebarCollapsed ? <ToggleIconCollapsed /> : <ToggleIcon />}
          </div>
        )}
        <div>
          <nav className="brand">
            <div />
          </nav>
          <ul className="nav flex-column">
            {useMemo(
              () =>
                routesWithLabel.map(
                  ({ exact, path, icon, label, disabled }, index) => (
                    <li className="nav-item" key={`nav-link-${index}`}>
                      <NavLink
                        className={clsx('nav-link menu-link', { disabled })}
                        exact={exact}
                        to={normalizePath(path)}
                      >
                        {sidebarCollapsed ? (
                          <Tooltip
                            text={t(defaults(label, ''))}
                            className="menu-tooltip"
                            eventTriggers={['hover']}
                          >
                            {icon}
                          </Tooltip>
                        ) : (
                          icon
                        )}
                        <span className="menu-text">
                          {t(defaults(label, ''))}
                        </span>
                      </NavLink>
                    </li>
                  )
                ),
              [routesWithLabel, sidebarCollapsed]
            )}
          </ul>
        </div>
        <footer>{children}</footer>
      </nav>
    </>
  )
}

export default memo(Sidebar)
