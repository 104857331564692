import API from '@/api'
import { FETCH_STATUS } from '@/constants'
import { useResponseErrorHandler } from '@/hooks'
import { ITour } from '@/models'
import { arrayReplace } from '@/utils'
import { useState } from 'react'

import { ITourState } from './state'

export const useTourActions = (initialState: ITourState): ITourState => {
  const [tours, setTours] = useState(initialState.tours)

  const addTour = (tour: ITour) =>
    setTours(currentTours => [...currentTours, tour])

  const updateTour = (tour: ITour) => {
    setTours(currentTours => {
      const currentTour = currentTours.find(({ id }) => id === tour.id)
      return arrayReplace(
        currentTours,
        { id: tour.id },
        {
          ...currentTour,
          ...tour,
        }
      )
    })
  }

  const fetchTour = async (id: number) => {
    updateTour({ id, status: FETCH_STATUS.FETCHING } as ITour)

    const [err, tour] = await useResponseErrorHandler(
      API.Tour().fetchDetailedTourOverview(id)
    )

    if (err) {
      updateTour({ id, status: FETCH_STATUS.FAILED } as ITour)
      return null
    }

    updateTour({ id, status: FETCH_STATUS.SUCCESSFUL } as ITour)
    return tour
  }

  return {
    tours,
    addTour,
    updateTour,
    fetchTour,
  }
}
