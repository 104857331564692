import { canBeSet } from '@/app/features/tourRecovery/helpers'
import { SHIPMENT_STATE } from '@/constants'
import { ShipmentsContext } from '@/contexts'
import { RouterContext } from '@/contexts'
import { useCallback, useContext, useEffect, useMemo, useReducer } from 'react'
import { ReasonCode, Tour } from 'Trunkrs-SDK/dist/models'
import { SHIPMENT_STATES } from 'Trunkrs-SDK/dist/models/delivery/ShipmentLog'

import reducer from './reducer'
import { ITourRecoveryState } from './state'
import { IRouteParams, TOUR_RECOVERY_ACTIONS } from './types'

export const useTourRecovery = (
  initialState: ITourRecoveryState
): ITourRecoveryState => {
  const { getParams } = useContext(RouterContext)
  const { tourId } = getParams<IRouteParams>()

  const { getReasonCodesByShipmentState } = useContext(ShipmentsContext)
  const [state, dispatch] = useReducer(reducer, initialState)

  const fetchInitialData = useCallback(async () => {
    const [tour, reasonCodes] = await Promise.all([
      Tour.fetchTourOverview(tourId),
      getReasonCodesByShipmentState(SHIPMENT_STATES.SHIPMENT_NOT_DELIVERED),
    ])

    dispatch({
      type: TOUR_RECOVERY_ACTIONS.SET_INITIAL_STATE,
      payload: {
        collations: tour.collations,
        driver: tour.driver,
        reasonCodes,
      },
    })
  }, [tourId, dispatch])

  useEffect(() => {
    fetchInitialData()
  }, [fetchInitialData])

  const clearSelection = useCallback(
    () => dispatch({ type: TOUR_RECOVERY_ACTIONS.CLEAR_SELECTION }),
    [dispatch]
  )

  const handleApplyPreview = useCallback(
    (state: SHIPMENT_STATE, time: string, reasonCode?: ReasonCode) =>
      dispatch({
        type: TOUR_RECOVERY_ACTIONS.APPLY_PREVIEW,
        payload: {
          selectedShipmentState: state,
          selectedTime: time,
          selectedReasonCode: reasonCode,
        },
      }),
    [dispatch]
  )

  const handleModifyRow = useCallback(
    (
      rowIndex: number,
      selectedShipmentState?: SHIPMENT_STATE,
      selectedTime?: string,
      selectedReasonCode?: ReasonCode
    ) => {
      dispatch({
        type: TOUR_RECOVERY_ACTIONS.MODIFY_ROW,
        payload: {
          rowIndex,
          selectedReasonCode,
          selectedShipmentState,
          selectedTime,
        },
      })
    },
    [dispatch]
  )

  const handleResetRow = useCallback(
    (rowIndex: number) =>
      dispatch({ type: TOUR_RECOVERY_ACTIONS.RESET_ROW, rowIndex }),
    [dispatch]
  )

  const handleApplyToRowsBelow = useCallback(
    (rowIndex: number) => {
      dispatch({
        type: TOUR_RECOVERY_ACTIONS.APPLY_TO_ROWS_BELOW,
        payload: {
          rowIndex,
        },
      })
    },
    [dispatch]
  )

  const handleToggleRow = useCallback(
    (rowIndex: number) =>
      dispatch({ type: TOUR_RECOVERY_ACTIONS.TOGGLE_ROW, rowIndex }),
    [dispatch]
  )

  const handleSetTime = useCallback(
    (rowIndex: number, time: string) => {
      dispatch({
        type: TOUR_RECOVERY_ACTIONS.SET_ROW_TIME,
        payload: {
          rowIndex,
          time,
        },
      })
    },
    [dispatch]
  )
  const handleSetReasonCode = useCallback(
    (rowIndex: number, reasonCode: ReasonCode) =>
      dispatch({
        type: TOUR_RECOVERY_ACTIONS.SET_ROW_REASON_CODE,
        payload: {
          rowIndex,
          reasonCode,
        },
      }),
    [dispatch]
  )

  const handleSetShipmentState = useCallback(
    (rowIndex: number, state: SHIPMENT_STATE) => {
      dispatch({
        type: TOUR_RECOVERY_ACTIONS.SET_ROW_STATE,
        payload: {
          rowIndex,
          state,
        },
      })
    },
    [dispatch]
  )

  const handleToggleAllRows = useCallback(
    () => dispatch({ type: TOUR_RECOVERY_ACTIONS.TOGGLE_ALL_ROWS }),
    [dispatch]
  )

  const selectableRows = useMemo(
    () => state.rows.filter(row => canBeSet(row.original)),
    [state.rows]
  )

  const selectedRows = useMemo(
    () => selectableRows.filter(row => row.isSelected),
    [selectableRows]
  )

  const isAllRowsSelected = useMemo(
    () => selectableRows.length === selectedRows.length,
    [selectableRows]
  )

  const modifiedRows = useMemo(
    () => state.rows.filter(row => canBeSet(row.original) && row.modified),
    [state.rows]
  )

  const { driver, reasonCodes, rows } = state
  return {
    tourId,
    isAllRowsSelected,
    selectedRows,
    modifiedRows,
    driver,
    reasonCodes,
    rows,
    fetchInitialData,
    handleToggleRow,
    handleToggleAllRows,
    handleApplyPreview,
    handleModifyRow,
    handleResetRow,
    handleApplyToRowsBelow,
    clearSelection,
    handleSetReasonCode,
    handleSetShipmentState,
    handleSetTime,
  }
}

export default useTourRecovery
