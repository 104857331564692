import Button from '@/app/base/components/.v2/Button'
import { useTranslation } from '@/hooks/useTranslation'
import { SmartSearchContext } from '@/store/smartSearch'
import React, { useCallback, useContext, useState } from 'react'

import styles from './styles.module.scss'

export const TextSearch: React.FC = () => {
  const { handleAddChip, activeChips, selectedFilter } = useContext(
    SmartSearchContext
  )

  const [t] = useTranslation('SMART_SEARCH')

  const [value, setValue] = useState('')

  const handleChange = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => setValue(ev.target.value),
    []
  )

  const handleSearch = useCallback(
    event => {
      event.preventDefault()
      if (!!value) {
        handleAddChip({
          id: activeChips.length + 1,
          key: String(selectedFilter),
          value,
        })
      }
    },
    [activeChips, selectedFilter, value, handleAddChip]
  )

  return (
    <form className={styles.root} onSubmit={handleSearch}>
      <input
        autoFocus
        onChange={handleChange}
        value={value}
        type="text"
        className={styles.input}
      />
      <Button type="submit" variant="primary">
        {t('SEARCH')}
      </Button>
    </form>
  )
}

export default TextSearch
