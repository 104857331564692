import './styles.scss'

import { Messenger } from '@/app/features/driverMessages/components/'
import { AppContext, DriverContext } from '@/contexts'
import { useTranslation } from '@/hooks'
import React, { memo, useContext, useEffect } from 'react'

const DriverMessages = () => {
  const [t] = useTranslation(['DRIVER_MESSAGES'])
  const { setLoading } = useContext(AppContext)
  const { allDrivers, fetchAllDrivers } = useContext(DriverContext)

  useEffect(() => {
    setLoading(true)
    fetchAllDrivers().finally(() => {
      setLoading(false)
    })
  }, [])

  return (
    <div id="driver-messages" className="container-fluid">
      <h1 className="h2 mt-4 text-left title">{t('DRIVER_MESSAGES')}</h1>
      <div className="wrapper">
        <Messenger />
      </div>
    </div>
  )
}

export default memo(DriverMessages)
