import * as React from 'react'
import { RouterContext } from '@/contexts'
import { useRouterActions } from '@/store/router/actions'

export default ({ children }: { children: React.ReactNode }) => {
  const state = useRouterActions()
  return (
    <RouterContext.Provider value={state}>{children}</RouterContext.Provider>
  )
}
