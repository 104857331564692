import { DriverContext, SubcoContext, TourContext } from '@/contexts'
import { ITour } from '@/models'
import * as React from 'react'
import { useContext } from 'react'

import QuickTour from './QuickTour'

const QuickTourContainer: React.FunctionComponent = () => {
  const { tours } = useContext(TourContext)
  const { activeSubcoId } = useContext(SubcoContext)
  const { activeDriverId } = useContext(DriverContext)

  const getTour = React.useCallback(
    ({ driverId, subCoId }: ITour) =>
      activeDriverId ? driverId === activeDriverId : subCoId === activeSubcoId,
    [activeDriverId, activeSubcoId]
  )

  const filteredTours = activeSubcoId
    ? tours.filter((tour: ITour) => getTour(tour))
    : []

  const showTours = React.useMemo(
    () =>
      filteredTours.map((tour: ITour, index: number) => (
        <QuickTour key={tour.id} tour={tour} legend={!index} />
      )),
    [tours, activeSubcoId, activeDriverId]
  )

  return <div className="tours">{showTours}</div>
}

export default QuickTourContainer
