import moment from 'moment'

export const getNextBusinessDay = (date = new Date()) => {
  const targetDayOfTheWeek = moment(date)
    .add(1, 'd')
    .day()
  return moment(date).add(
    targetDayOfTheWeek === 6 ? 3 : targetDayOfTheWeek === 0 ? 2 : 1,
    'd'
  )
}
