import React, { useCallback, useRef } from 'react'

export const ShipmentsTableContext = React.createContext({} as {
  getSelectedShipments: () => number[]
  setSelectedShipments: (selectedShipments: number[]) => void
  toggleSelectedShipments: (shipmentId: number) => void
})
ShipmentsTableContext.displayName = 'ShipmentsTableContext'

/*Provides optimum way to prevent all rows from re-rendering upon single row selection*/
export const ShipmentsTableProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const selectedShipmentsRef = useRef<number[]>([])

  const toggleSelectedShipments = useCallback((shipmentId: number) => {
    const selectedShipments = selectedShipmentsRef.current
    const isSelected = selectedShipments.includes(shipmentId)
    if (isSelected) {
      selectedShipmentsRef.current = selectedShipments.filter(
        (id: number) => id !== shipmentId
      )
    } else {
      selectedShipmentsRef.current = [...selectedShipments, shipmentId]
    }
  }, [])

  const setSelectedShipments = useCallback((selectedShipments: number[]) => {
    selectedShipmentsRef.current = selectedShipments
  }, [])

  const getSelectedShipments = useCallback(() => selectedShipmentsRef.current, [
    selectedShipmentsRef.current,
  ])

  return (
    <ShipmentsTableContext.Provider
      value={{
        getSelectedShipments,
        setSelectedShipments,
        toggleSelectedShipments,
      }}
    >
      {children}
    </ShipmentsTableContext.Provider>
  )
}
