import { ENVIRONMENT } from '@/constants'
import localforage from 'localforage'
import { TrunkrsSDK } from 'Trunkrs-SDK/dist'

const getMode = () => {
  switch (ENVIRONMENT) {
    case 'production':
      return 'PRODUCTION' as any
    case 'development':
      return 'DEVELOPMENT' as any
    case 'qa':
      return 'QA' as any
    default:
      return 'DEVELOPMENT' as any
  }
}

const OPTIONS = {
  cache: false,
  cacheInvalidateAfter: 15,
  maxRetryAttempts: 2,
  mode: getMode(),
  store: localforage,
}

const API = new TrunkrsSDK(OPTIONS)
export default API
