import { useTextArea } from '@/app/base/components/.lib/hooks'
import clsx from 'clsx'
import React, { memo } from 'react'

import styles from './styles.module.scss'

export default memo(
  ({
    className,
    value,
    maxLength,
    onChange,
    ...props
  }: React.HTMLAttributes<HTMLTextAreaElement | HTMLDivElement> & {
    maxLength?: number
    value?: string
  }) => {
    const { ref, handleInput, handleKeyDown } = useTextArea(
      value,
      onChange,
      maxLength
    )

    return (
      <div className={styles['text-area-container']}>
        <div
          role="textbox"
          contentEditable
          ref={ref}
          onInput={handleInput}
          onKeyDown={handleKeyDown}
          className={clsx(styles['text-area'], className)}
          {...props}
        />
      </div>
    )
  }
)
