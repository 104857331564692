import { ListItem, Paper } from '@material-ui/core'
import Card from '@material-ui/core/Card'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Popper from '@material-ui/core/Popper'
import CloseIcon from '@material-ui/icons/Close'
import SearchIcon from '@material-ui/icons/Search'
import clsx from 'clsx'
import snakeCase from 'lodash/snakeCase'
import ChipInput from 'material-ui-chip-input'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { useTranslation } from '.././../../../../hooks'
import { SmartSearchContext } from '../../../../../store/smartSearch/'
import SearchFilter from './components/Filter'
import SearchChip from './components/SearchChip'
import { filterArrayEnabled, filterList } from './helpers/constants'
import { SearchableItemKey } from './helpers/interfaces'
import { modifiers } from './modifiers'
import styles from './styles.module.scss'

export const SmartSearch: React.FC = () => {
  const [showPopper, setShowPopper] = useState(false)
  const anchorRef = useRef<HTMLDivElement>(null)
  const selectStatesRef = useRef<HTMLDivElement>(null)
  const {
    activeChips,
    selectedFilter,
    setSelectedFilter,
    clearState,
  } = useContext(SmartSearchContext)

  const [filter, setFilters] = useState<
    {
      label: string
      key: SearchableItemKey
      hidden: boolean
    }[]
  >([])
  const [title, setTitle] = useState<string | null>('SEARCH_BY')
  const [t] = useTranslation(['SHIPMENT_STATES', 'SMART_SEARCH'])

  const renderChip = useCallback(
    ({ value }) => <SearchChip value={value} />,
    []
  )
  const setActiveFilter = useCallback(
    (key: string) => () => {
      setSelectedFilter(key)

      switch (key) {
        case SearchableItemKey.CURRENT_DELIVERY_DATE:
        case SearchableItemKey.CREATION_DATE:
          setTitle('SELECT_DATES')
          break
        case SearchableItemKey.CURRENT_SHIPMENT_STATE:
          setTitle('SELECT_STATUS')
          break
        default:
          setTitle('CONTAINING')
      }
    },
    [setSelectedFilter, setTitle]
  )

  const handleShowPopper = useCallback(() => {
    if (anchorRef) {
      setShowPopper(true)
    }
  }, [anchorRef, setShowPopper])

  const handleHidePopper = useCallback(() => {
    if (anchorRef) {
      setShowPopper(false)
      setTitle('SEARCH_BY')
      clearState()
    }
  }, [anchorRef, clearState, setShowPopper, setTitle])

  const isSelectStatusActive = useMemo(
    () => selectedFilter === SearchableItemKey.CURRENT_SHIPMENT_STATE,
    [selectedFilter]
  )

  const getPlaceHolder = useMemo(() => {
    if (selectedFilter) {
      return `${t(snakeCase(selectedFilter).toUpperCase())}...`
    }

    if (activeChips.length >= 1) {
      return t('ADD_MORE_FILTERS')
    }
    return t('SEARCH_SHIPMENTS')
  }, [selectedFilter, activeChips])

  useEffect(() => {
    const filtersWithLabel = filterList.map(fi => ({
      label: t(snakeCase(fi).toUpperCase()),
      key: fi,
      hidden: activeChips.some(
        a => a.key === fi && !filterArrayEnabled.includes(a.key)
      ),
    }))
    setFilters(filtersWithLabel)
  }, [activeChips, setFilters])

  useEffect(() => {
    if (activeChips) {
      handleHidePopper()
    }
  }, [activeChips, handleHidePopper])

  return (
    <Card elevation={2}>
      <div className={styles.searchContainer} ref={selectStatesRef}>
        <SearchIcon className={styles.searchIcon} />
        <ChipInput
          classes={{
            input: styles.chipInput,
          }}
          fullWidth
          disableUnderline
          readOnly
          alwaysShowPlaceholder
          placeholder={getPlaceHolder}
          value={activeChips}
          chipRenderer={renderChip}
          InputProps={{
            onFocus: handleShowPopper,
            startAdornment: (
              <div ref={anchorRef} className={styles.anchorRef} />
            ),
          }}
        />
      </div>
      <ClickAwayListener
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
        onClickAway={handleHidePopper}
      >
        <Popper
          style={{ zIndex: 10 }}
          open={showPopper}
          anchorEl={
            isSelectStatusActive ? selectStatesRef.current : anchorRef.current
          }
          placement="bottom-start"
          modifiers={modifiers}
        >
          <Paper
            elevation={1}
            className={isSelectStatusActive ? styles.paperState : styles.paper}
          >
            <div className={styles.header}>
              <div>
                <span className={styles.title}>{t(String(title))}</span>
                {isSelectStatusActive && <span>{t('MULTI_SELECT')}</span>}
              </div>
              <CloseIcon
                onClick={handleHidePopper}
                className={styles.closeIcon}
              />
            </div>
            {selectedFilter ? (
              <SearchFilter />
            ) : (
              filter.map(item => (
                <ListItem
                  button
                  classes={{
                    root: styles.listItem,
                  }}
                  className={clsx(item.hidden && styles.hidden)}
                  key={item.key}
                  onClick={setActiveFilter(item.key)}
                >
                  {t(item.label)}
                </ListItem>
              ))
            )}
          </Paper>
        </Popper>
      </ClickAwayListener>
    </Card>
  )
}

export default SmartSearch
