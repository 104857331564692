import './style.scss'

import Checkbox from '@/app/base/components/.v2/Checkbox'
import Icon from '@/app/base/components/.v2/Icon'
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  TableRowHeader,
} from '@/app/base/components/.v2/Table'
import StatusLabel from '@/app/base/components/statusLabel'
import ShipmentActions from '@/app/features/shipments/components/shipmentActions'
import { RouterContext } from '@/contexts'
import { useForceUpdate, useScroll, useTranslation } from '@/hooks'
import { IShipment, withT } from '@/models'
import { SmartSearchContext } from '@/store/smartSearch'
import { clsx } from '@/utils'
import moment from 'moment'
import * as React from 'react'
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { SHIPMENT_STATES } from 'Trunkrs-SDK/dist/models/delivery/ShipmentLog'

import { ShipmentsTableContext } from '../../providers'
import styles from './styles.module.scss'

interface IShipmentDataRow extends withT {
  shipment: IShipment
  refreshTable: () => void
  //selected: boolean
  //toggleShipment: (shipmentId: number) => void
}

interface ShipmentsTableProps {
  shipments?: Array<IShipment>
  isFetching?: boolean
  isSearchMode?: boolean
}

export const ShipmentsTable = ({
  shipments = [],
  isFetching = false,
  isSearchMode = false,
}: ShipmentsTableProps) => {
  const { getSelectedShipments, setSelectedShipments } = useContext(
    ShipmentsTableContext
  )
  const [loadedShipments, setLoadedShipments] = useState<IShipment[]>(shipments)
  const [isPristine, setIsPristine] = useState(true)
  const [isScrolling, setIsScrolling] = useState(false)
  const [isViewResultMode, setIsViewResultMode] = useState(false)

  const {
    isEndOfResult,
    handlePagination,
    parsedQuery,
    searchResult,
  } = useContext(SmartSearchContext)

  const { forceUpdate } = useForceUpdate()

  const [t] = useTranslation('ALL_SHIPMENTS')
  const [tStates] = useTranslation('SHIPMENT_STATES')

  const selectedShipments = getSelectedShipments()

  const areAllShipmentsSelected =
    selectedShipments.length === loadedShipments.length &&
    loadedShipments.length > 0

  const someShipmentsAreSelected =
    !areAllShipmentsSelected && selectedShipments.length > 0

  const isToggledAllWithFilter = areAllShipmentsSelected

  const toggleAllShipments = useCallback(async () => {
    if (selectedShipments.length && areAllShipmentsSelected) {
      setSelectedShipments([])
    } else {
      setSelectedShipments(loadedShipments.map(item => item.shipmentId))
    }
    /*force update data rows by assigning new reference points*/
    setLoadedShipments([...loadedShipments])
  }, [selectedShipments, areAllShipmentsSelected])

  const paginate = async () => {
    await handlePagination()
    setIsPristine(false)
    if (isToggledAllWithFilter) {
      setSelectedShipments(shipments.map(item => item.shipmentId))
    }
    setLoadedShipments(shipments)
  }

  const { elementRef, handleScroll: handleTableScroll } = useScroll(paginate)

  const seeShipmentActionResults = useCallback(
    (state: SHIPMENT_STATES, actualUpdatedShipments: number[]) => {
      setIsViewResultMode(true)
      const filteredResults = loadedShipments
        .filter(shipment =>
          actualUpdatedShipments.includes(shipment.shipmentId)
        )
        .map(shipment => ({
          ...shipment,
          statusText: state,
        }))
      setSelectedShipments([])
      setLoadedShipments(filteredResults)
    },
    [selectedShipments, loadedShipments]
  )

  const updateShipmentResults = useCallback(
    (state: SHIPMENT_STATES, actualUpdatedShipments: number[]) => {
      const updatedResults = loadedShipments.map(shipment => ({
        ...shipment,
        statusText: actualUpdatedShipments.includes(shipment.shipmentId)
          ? state
          : shipment.statusText,
      }))
      setSelectedShipments([])
      setLoadedShipments(updatedResults)
    },
    [selectedShipments, loadedShipments]
  )

  const refreshTable = useCallback(() => forceUpdate(), [forceUpdate])

  useEffect(() => {
    setLoadedShipments(shipments)
    setIsViewResultMode(false)
  }, [isFetching])

  useEffect(() => {
    if (areAllShipmentsSelected) {
      setSelectedShipments(loadedShipments.map(item => item.shipmentId))
    }
  }, [isFetching])

  return (
    <>
      <ShipmentActions
        selectedShipments={selectedShipments}
        onShipmentUpdate={seeShipmentActionResults}
        onViewResults={seeShipmentActionResults}
      />
      {!!selectedShipments.length ? (
        <div className={styles.numberOfSelectionContainer}>
          {t('TOTAL_SELECTED', {
            count: isToggledAllWithFilter
              ? loadedShipments.length
              : selectedShipments.length,
            total: loadedShipments.length,
          })}
        </div>
      ) : (
        !!loadedShipments.length && (
          <div className={styles.numberOfSelectionContainer}>
            {t('TOTAL_RESULTS', { count: loadedShipments.length })}
          </div>
        )
      )}
      <Table className={styles.customTable}>
        <TableRowHeader className={clsx(isScrolling && styles.scrolling)}>
          <TableHeader onClick={toggleAllShipments}>
            <Checkbox
              variant={someShipmentsAreSelected ? 'bar' : 'default'}
              checked={
                someShipmentsAreSelected ? true : areAllShipmentsSelected
              }
              onChange={toggleAllShipments}
            />
          </TableHeader>
          <TableHeader>{t('TRUNKRSNR')}</TableHeader>
          <TableHeader>{t('RECIPIENT')}</TableHeader>
          <TableHeader>{t('ADDRESS')}</TableHeader>
          <TableHeader>{t('REGION')}</TableHeader>
          <TableHeader>{t('SENDER')}</TableHeader>
          <TableHeader>{t('LAST_DRIVER')}</TableHeader>
          <TableHeader>{t('DELIVERY_DATE')}</TableHeader>
          <TableHeader>{t('STATUS')}</TableHeader>
        </TableRowHeader>
        <TableBody
          className={clsx(!isPristine && !isEndOfResult && styles.expanded)}
          ref={elementRef}
          onScroll={handleTableScroll}
        >
          {useMemo(
            () =>
              loadedShipments.map((shipment, i) => (
                <ShipmentDataRow
                  key={`${shipment.shipmentId}--${i}`}
                  shipment={shipment}
                  refreshTable={refreshTable}
                  t={tStates}
                />
              )),
            [loadedShipments]
          )}
          {isSearchMode ? null : !isEndOfResult && isFetching && <Loader />}
        </TableBody>
        {!isEndOfResult && (
          <div className={styles.tableFooter}>
            <span>
              <Icon name="mouse" /> {t('SCROLL_FOR_MORE_RESULTS')}
            </span>
          </div>
        )}
        {isEndOfResult && !isViewResultMode && (
          <div className={styles.tableFooter}>
            <span>End of results</span>
          </div>
        )}
      </Table>
    </>
  )
}

const Loader = () => {
  return (
    <div className={styles.loader}>
      <Icon name="spinner" /> Loading more results
    </div>
  )
}

const ShipmentDataRow = memo((props: IShipmentDataRow) => {
  const { history } = useContext(RouterContext)
  const { getSelectedShipments, toggleSelectedShipments } = useContext(
    ShipmentsTableContext
  )
  const { forceUpdate } = useForceUpdate()

  const { shipment, refreshTable } = props
  const {
    shipmentId,
    trunkrsNr,
    recipient: { name, address, city, postalCode },
    region,
    sender: { name: senderName },
    lastDriverName,
    statusText,
    deliveryDate,
  } = shipment

  const selected = getSelectedShipments().includes(shipmentId)

  const date = deliveryDate ? moment(deliveryDate).format('DD-MM-YYYY') : '-'

  const refreshThis = useCallback(() => forceUpdate(), [forceUpdate])

  const handleClick = useCallback(() => {
    history.push(`/shipments/${shipmentId}`)
  }, [shipmentId])

  const handleToggle = useCallback(() => {
    toggleSelectedShipments(shipmentId)
    refreshTable()
    refreshThis()
  }, [selected])

  return (
    <TableRow className={clsx(selected && styles.active)}>
      <TableCell>
        <Checkbox checked={selected} onChange={handleToggle} />
      </TableCell>
      <TableCell onClick={handleClick}>{trunkrsNr}</TableCell>
      <TableCell onClick={handleClick}>{name}</TableCell>
      <TableCell onClick={handleClick}>
        <div className={styles.addressInfo}>
          <div>{address}</div>
          <div>{`${postalCode} ${city}`}</div>
        </div>
      </TableCell>
      <TableCell onClick={handleClick}>{region}</TableCell>
      <TableCell onClick={handleClick}>{senderName}</TableCell>
      <TableCell onClick={handleClick}>{lastDriverName}</TableCell>
      <TableCell onClick={handleClick}>{date}</TableCell>
      <TableCell onClick={handleClick} className="status">
        <StatusLabel statusText={statusText} />
      </TableCell>
    </TableRow>
  )
})
