export const modifiers = {
  offset: {
    enabled: true,
    offset: '0px 8px',
  },
  shift: {
    enabled: true,
  },
  preventOverflow: {
    enabled: true,
    boundariesElement: 'viewport',
    priority: ['right', 'left'],
    padding: 20,
  },
  flip: {
    enabled: false,
  },
}
