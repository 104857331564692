import './style.scss'

import { useTranslation } from '@/hooks'
import { classList, reverseString } from '@/utils'
import { get } from 'lodash'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { Driver, Shipment } from 'Trunkrs-SDK/dist/models'
import { SHIPMENT_STATES } from 'Trunkrs-SDK/dist/models/delivery/ShipmentLog'

interface ICollation {
  eta: string
  collation_id: number
  tour_date: string
  shipment_id: number
  position: number
  driver_id: number
  tour_id: number
  state?: string
}

interface IProps {
  collations: ICollation[]
  driver: {
    longitude: number
    latitude: number
    altitude: number
    is_moving: boolean
    speed: number
    recorded_at: string
    id: number
    name: string
    emailAddress: string
    phoneNumber: string
    picture: string | null
    subcontractorId: number
    active: boolean
  }
}

export const QuickTour = ({ shipment }: { shipment: Shipment | null }) => {
  const [collations, setCollations] = useState([])
  const [driver, setDriver] = useState()

  useEffect(() => {
    shipment &&
      shipment.getTourOverview().then(tour => {
        setCollations(tour.collations)
        setDriver(tour.driver)
      })
  }, [shipment])

  const driverId = get(driver, 'id')

  const getTourId = (collations: any[]) =>
    collations && get([...collations].pop(), 'tour_id')

  const getSpeedDriver = (driver: any) =>
    Math.round(get(driver, 'speed', 0) * 3.6)

  const getLatestPositionDriver = (driver: any) => {
    const latitude = get(driver, 'latitude', 0)
    const longitude = get(driver, 'longitude', 0)
    return `https://maps.google.com/?q=${latitude},${longitude}`
  }

  const getStartTime = (collations: any[]) =>
    collations && moment([...collations].shift().eta).format('HH:mm')

  const getEndTime = (collations: any[]) =>
    collations && moment([...collations].pop().eta).format('HH:mm')

  const getCleanCollations = (collations: any[]) =>
    collations && collations.filter(collation => collation.shipment_id > 0)

  const getClassForShipment = (state: string) => {
    switch (state) {
      case SHIPMENT_STATES.EXCEPTION_SHIPMENT_NOT_ARRIVED:
        return 'quick-tour--not-arrived'
      case SHIPMENT_STATES.DATA_PROCESSED:
        return 'quick-tour--not-scanned'
      case SHIPMENT_STATES.SHIPMENT_DELIVERED:
        return 'quick-tour--delivered'
      case SHIPMENT_STATES.SHIPMENT_DELIVERED_TO_NEIGHBOR:
        return 'quick-tour--delivered-to-neighbour'
      case SHIPMENT_STATES.SHIPMENT_NOT_DELIVERED:
        return 'quick-tour--not-delivered'
      default:
        return ''
    }
  }

  const goToShipment = (shipmentId: number) =>
    (window.location.href = '/shipments/' + shipmentId)

  const tourId = getTourId(collations)

  const [t] = useTranslation(['TOUR_PROGRESS'])

  return (
    <>
      {shipment && !!collations.length && (
        <>
          <h2 className="text">{t('TOUR_PROGRESS')}</h2>
          <div className="quick-tour--wrapper box-shadow">
            <div className="quick-tour--info-row">
              {driver && (
                <>
                  <div className="quick-tour--driver-info">
                    <h1>{t('TOUR_ID', { id: tourId })}</h1>
                    <h2>
                      {driver.name} - {driver.phoneNumber}
                    </h2>
                  </div>
                  {!!driver.latitude && (
                    <div className="quick-tour--driver-position">
                      <span>{t('LOCATION')}</span>
                      <div className="quick-tour--box">
                        <a
                          target="_blank"
                          href={getLatestPositionDriver(driver)}
                        >
                          {t('SHOW_ON_MAPS')}
                        </a>
                      </div>
                    </div>
                  )}
                  <div className="quick-tour--driver-position">
                    <span>{t('SPEED')}</span>
                    <div className="quick-tour--box">
                      {getSpeedDriver(driver)}km/h
                    </div>
                  </div>
                  <div className="quick-tour--driver-position">
                    <span>{t('UPDATE')}</span>
                    <div className="quick-tour--box">
                      <a href={`/tour/recovery/${getTourId(collations)}`}>
                        {t('UPDATE_STATES')}
                      </a>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="quick-tour--tour-row">
              <div className="quick-tour--time">{getStartTime(collations)}</div>
              <div className="quick-tour--stop-bar">
                {getCleanCollations(collations) &&
                  getCleanCollations(collations).map(collation => (
                    <div
                      className={classList(
                        'quick-tour--stop',
                        getClassForShipment(collation.state),
                        (shipment.getId === collation.shipment_id &&
                          'quick-tour--current') ||
                          ''
                      )}
                      key={collation.shipment_id}
                      onClick={() => goToShipment(collation.shipment_id)}
                    >
                      <div className="quick-tour--overlay">
                        <h1>ETA: {moment(collation.eta).format('HH:mm')}</h1>
                      </div>
                    </div>
                  ))}
              </div>
              <div className="quick-tour--time">{getEndTime(collations)}</div>
            </div>
            <div className="quick-tour--position-row">
              {getCleanCollations(collations) &&
                getCleanCollations(collations).map((collation, index) => (
                  <div
                    className="quick-tour--position"
                    key={collation.shipment_id}
                  >
                    {index + 1}
                  </div>
                ))}
            </div>
            <div className="quick-tour--legend">
              <div className="quick-tour--stop quick-tour--not-scanned" />{' '}
              {t('LEGEND_NOT_SCANNED_AT_HUB')}
              <div className="quick-tour--stop" /> {t('LEGEND_ON_ITS_WAY')}
              <div className="quick-tour--stop quick-tour--delivered" />{' '}
              {t('LEGEND_DELIVERED')}
              <div className="quick-tour--stop quick-tour--delivered-to-neighbour" />
              {t('LEGEND_DELIVERED_TO_NEIGHBOUR')}
              <div className="quick-tour--stop quick-tour--not-delivered" />{' '}
              {t('LEGEND_NOT_DELIVERED')}
              <div className="quick-tour--stop quick-tour--not-arrived" />{' '}
              {t('LEGEND_NOT_ARRIVED')}
              <div className="quick-tour--stop quick-tour--current" />{' '}
              {t('THIS_SHIPMENT')}
            </div>
          </div>
        </>
      )}
    </>
  )
}
