import TimeInput from '@/app/base/components/timeInput'
import { SHIPMENT_STATE } from '@/constants'
import { TourRecoveryContext } from '@/contexts'
import { IRecoveryCollation } from '@/store/tourRecovery/types'
import { ClickAwayListener, Popper } from '@material-ui/core'
import clsx from 'clsx'
import { TFunction } from 'i18next'
import get from 'lodash/get'
import uniqueId from 'lodash/uniqueId'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import IconLoader from 'react-inlinesvg'
import { ReasonCode } from 'Trunkrs-SDK/dist/models'

import { TableCell } from '../../../../base/components/table'
import bugerIcon from '../../../../base/img/burger_dot.svg'
import { canBeSet } from '../../helpers'
import ReasonCodesSelect from '../reasonCodesSelect'
import ShipmentStateSelect from '../shipmentStateSelect'
import styles from './styles.module.scss'

interface ControlsProps {
  rowIndex: number
  isCanBeSet: boolean
  collation: IRecoveryCollation
  t: TFunction
}

export const Controls: React.FC<ControlsProps> = ({
  rowIndex,
  isCanBeSet,
  collation,
  t,
}) => {
  const {
    reasonCodes,
    handleModifyRow,
    handleResetRow,
    handleApplyToRowsBelow,
    handleSetReasonCode,
    handleSetTime,
    handleSetShipmentState,
  } = useContext(TourRecoveryContext)

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)
  const id = open ? `popover-${uniqueId()}` : undefined

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) =>
      setAnchorEl(anchorEl ? null : event.currentTarget),
    [anchorEl]
  )

  const handleCloseMenu = useCallback(() => setAnchorEl(null), [anchorEl])

  const handleSelectedTime = useCallback(
    (value: string) => {
      handleSetTime(rowIndex, value)
    },
    [rowIndex, handleSetTime]
  )

  const handleSelectedShipmentState = useCallback(
    (e: any) => {
      handleSetShipmentState(rowIndex, e.target.value)
    },
    [rowIndex, handleSetShipmentState]
  )

  const handleSelectedReasonCode = useCallback(
    (e: any) => {
      const targetReasonCode = reasonCodes.find(
        reasonCode => get(reasonCode, 'code') === e.target.value
      )
      handleSetReasonCode(rowIndex, targetReasonCode as ReasonCode)
    },
    [reasonCodes, handleSetReasonCode, rowIndex]
  )

  const handleReset = useCallback(() => handleResetRow(rowIndex), [
    handleResetRow,
    rowIndex,
  ])

  const handleApplyBelow = useCallback(() => {
    handleApplyToRowsBelow(rowIndex)
  }, [rowIndex, handleApplyToRowsBelow])

  return (
    <>
      <TableCell>
        {isCanBeSet && (
          <TimeInput
            onChange={handleSelectedTime}
            className={styles.timeInput}
            value={collation.selectedTime}
          />
        )}
      </TableCell>
      <TableCell>
        {isCanBeSet && (
          <ShipmentStateSelect
            onChange={handleSelectedShipmentState}
            value={get(collation, 'selectedShipmentState', 'none') as string}
            disabled={!collation.selectedTime}
          />
        )}
      </TableCell>
      <TableCell>
        {isCanBeSet && (
          <ReasonCodesSelect
            disabled={
              get(collation, 'selectedShipmentState') !==
                SHIPMENT_STATE.SHIPMENT_NOT_DELIVERED ||
              collation.selectedTime === ''
            }
            onChange={handleSelectedReasonCode}
            value={get(collation, 'selectedReasonCode.code', 'none')}
            reasonCodes={reasonCodes}
            t={t}
          />
        )}
      </TableCell>
      <TableCell>
        {canBeSet(collation) && collation.selectedTime && (
          <ClickAwayListener onClickAway={handleCloseMenu}>
            <div
              className={clsx(styles.menu, open && styles.open)}
              onClick={handleClick}
            >
              <div aria-describedby={id} onClick={handleClick}>
                <IconLoader src={bugerIcon} />
              </div>
              <Popper
                placement="bottom-end"
                id={id}
                open={open}
                anchorEl={anchorEl}
              >
                <div className={styles.menupopup}>
                  <div onClick={handleReset} className={styles.popper}>
                    {t('TOUR_RECOVERY:RESET_ROW')}
                  </div>
                  <div onClick={handleApplyBelow} className={styles.popper}>
                    {t('APPLY_TO_ROWS_BELOW')}
                  </div>
                </div>
              </Popper>
            </div>
          </ClickAwayListener>
        )}
      </TableCell>
    </>
  )
}

export default Controls
