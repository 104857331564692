import clsx from 'clsx'
import get from 'lodash/get'
import React, {
  HTMLAttributes,
  LiHTMLAttributes,
  memo,
  useMemo,
  useState,
} from 'react'

import styles from './styles.module.scss'

export const TabMenuItem = memo(
  (
    props: React.DetailedHTMLProps<
      LiHTMLAttributes<HTMLLIElement>,
      HTMLLIElement
    >
  ) => <li {...props} />
)

export const VerticalTabMenu = memo(
  ({
    children,
    className,
    ...props
  }: React.DetailedHTMLProps<HTMLAttributes<any>, any>) => {
    const [selectedTabIndex, setSelectedTabIndex] = useState(0)

    const contents = useMemo(() => React.Children.toArray(children), [children])

    const handleTabSelection = (
      tabIndex: number,
      onClick: () => void
    ) => () => {
      setSelectedTabIndex(tabIndex)
      if (typeof onClick === 'function') {
        onClick()
      }
    }

    return (
      <ul className={clsx(styles.verticalTabContainer, className)} {...props}>
        {useMemo(
          () =>
            contents.map((child: any, i) => {
              const onClick = get(child, 'props.onClick')
              return React.cloneElement(child, {
                ...get(child, 'props'),
                key: `tab_menu_${i}`,
                className: clsx(
                  get(child, 'props.className'),
                  selectedTabIndex == i && styles.selected
                ),
                onClick: handleTabSelection(i, onClick),
              })
            }),
          [contents, selectedTabIndex]
        )}
      </ul>
    )
  }
)

export const HorizontalTabMenu = memo(
  ({
    children,
    className,
    ...props
  }: React.DetailedHTMLProps<HTMLAttributes<any>, any>) => {
    const [selectedTabIndex, setSelectedTabIndex] = useState(0)

    const contents = useMemo(() => React.Children.toArray(children), [children])

    const handleTabSelection = (
      tabIndex: number,
      onClick: () => void
    ) => () => {
      setSelectedTabIndex(tabIndex)
      if (typeof onClick === 'function') {
        onClick()
      }
    }

    return (
      <ul
        className={clsx(
          styles.verticalTabContainer,
          styles.horizontalMode,
          className
        )}
        {...props}
      >
        {useMemo(
          () =>
            contents.map((child: any, i) => {
              const onClick = get(child, 'props.onClick')
              return React.cloneElement(child, {
                ...get(child, 'props'),
                key: `tab_menu_${i}`,
                className: clsx(
                  get(child, 'props.className'),
                  selectedTabIndex == i && styles.selected
                ),
                onClick: handleTabSelection(i, onClick),
              })
            }),
          [contents, selectedTabIndex]
        )}
      </ul>
    )
  }
)
