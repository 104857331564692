import addDays from 'date-fns/addDays'
import endOfMonth from 'date-fns/endOfMonth'
import endOfWeek from 'date-fns/endOfWeek'
import format from 'date-fns/format'
import isWeekend from 'date-fns/isWeekend'
import startOfMonth from 'date-fns/startOfMonth'
import startOfWeek from 'date-fns/startOfWeek'
import subDays from 'date-fns/subDays'
import subMonths from 'date-fns/subMonths'
import subWeeks from 'date-fns/subWeeks'

export interface DatePreset {
  key: string
  getValue: () => {
    dateStart?: Date
    dateEnd?: Date
  }
  disabled?: boolean
}

const today = new Date(
  new Date().getFullYear(),
  new Date().getMonth(),
  new Date().getDate()
)

const adjustIfWeekend = (date: Date) => {
  if (date.getDay() === 0) {
    return addDays(date, 1)
  }

  if (date.getDay() === 6) {
    return subDays(date, 1)
  }

  return date
}

export const DatePresets: DatePreset[] = [
  {
    key: 'TODAY',
    getValue: () => ({ dateStart: today }),
    disabled: isWeekend(today),
  },
  {
    key: 'YESTERDAY',
    getValue: () => ({ dateStart: subDays(today, 1) }),
    disabled: isWeekend(subDays(today, 1)),
  },
  {
    key: 'THIS_WEEK',
    getValue: () => ({
      dateStart: adjustIfWeekend(startOfWeek(today)),
      dateEnd: adjustIfWeekend(endOfWeek(today)),
    }),
  },
  {
    key: 'LAST_WEEK',
    getValue: () => {
      const day = subWeeks(today, 1)
      return {
        dateStart: adjustIfWeekend(startOfWeek(day)),
        dateEnd: adjustIfWeekend(endOfWeek(day)),
      }
    },
  },
  {
    key: 'THIS_MONTH',
    getValue: () => ({
      dateStart: adjustIfWeekend(startOfMonth(today)),
      dateEnd: adjustIfWeekend(endOfMonth(today)),
    }),
  },
  {
    key: 'LAST_MONTH',
    getValue: () => {
      const day = subMonths(today, 1)
      return {
        dateStart: adjustIfWeekend(startOfMonth(day)),
        dateEnd: adjustIfWeekend(endOfMonth(day)),
      }
    },
  },
]

export const getDateText = (
  range: {
    dateStart?: Date
    dateEnd?: Date
  },
  dateFormat: string
): string | null | undefined => {
  const start = range.dateStart && format(range.dateStart, dateFormat)
  const end = range.dateEnd && format(range.dateEnd, dateFormat)

  if (!end || start === end) {
    return start
  }

  return `${start} > ${end}`
}

export default DatePresets
