import { useTranslation } from '@/hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'

import classes from './style.module.scss'

interface ITimeBoxProps {
  departure: string
  arrival: string
}

const TimeBox: React.FC<ITimeBoxProps> = ({ departure, arrival }) => {
  const [t] = useTranslation('COLLECTION_TOUR')

  return (
    <div className={classes.main_container}>
      <div className={classes.time}>
        <span>{departure}</span>
        <span>{t('TABLE.DEPARTURE')}</span>
      </div>
      <div className={classes.middle}>
        <div className={classes.line} />
        <FontAwesomeIcon icon="truck" size="2x" />
        <div className={classes.line} />
      </div>
      <div className={classes.time}>
        <span>{arrival}</span>
        <span>{t('TABLE.ARRIVAL')}</span>
      </div>
    </div>
  )
}

export default TimeBox
