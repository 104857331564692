import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
  overrides: {
    MuiSelect: {
      icon: {
        fontSize: 18,
      },
      select: {
        background: 'none !important',
      },
    },
  },
  spacing: 8,
  shape: {
    borderRadius: 5,
  },
  palette: {
    primary: { main: '#201047' },
    secondary: { main: '#00d763' },
    error: { main: '#f04141' },
    warning: { main: '#ffce00' },
    success: { main: '#00d763' },
    info: { main: '#13D2FC' },
    background: {
      default: '#f4f7fc',
    },
    text: {
      primary: '#201047',
      disabled: '#f4f7fc',
    },
  },
  typography: {
    fontSize: 12,
    fontFamily: ['ClanPro-News', 'sans-serif'].join(','),
  },
})

export default theme
