import { FETCH_STATUS } from '@/constants'
import { CollationContext, DriverContext, SubcoContext } from '@/contexts'
import { ITour } from '@/models'
import { clsx } from '@/utils'
import * as React from 'react'
import { useContext, useState } from 'react'

import DriverInfo from './DriverInfo'
import Legend from './Legend'
import StopNumbers from './StopNumbers'
import TourModal from './TourModal'
import TourStops from './TourStops'

interface IProps {
  tour: ITour
  legend: boolean
}

const QuickTour: React.FunctionComponent<IProps> = props => {
  const { tour, legend } = props

  const { drivers } = useContext(DriverContext)
  const { subcos } = useContext(SubcoContext)
  const { collations } = useContext(CollationContext)

  const [showModal, setShowModal] = useState(false)

  const driver = drivers.find(driver => driver.tourId === tour.id)
  const subco = subcos.find(subco => subco.id === tour.subCoId)
  const tourCollations = collations.filter(({ tourId }) => tourId === tour.id)
  const loadingClassName =
    tour.status === FETCH_STATUS.FETCHING ? 'loading' : ''
  const delayedClassName = tour.delayed ? 'delayed' : ''
  const handleShowModal = () => setShowModal(!showModal)

  return (
    <div
      key={tour.id}
      className={clsx('wrapper box-shadow', loadingClassName, delayedClassName)}
    >
      {legend && <Legend />}
      {!!driver && (
        <DriverInfo
          delayed={driver.delayed}
          tourId={tour.id}
          driver={driver}
          showModal={handleShowModal}
        />
      )}
      <TourStops tour={tour} collations={tourCollations} />
      <StopNumbers collations={tourCollations} />
      {showModal && (
        <TourModal
          tour={tour}
          subCo={subco}
          driver={driver}
          collations={tourCollations}
          showModal={handleShowModal}
        />
      )}
    </div>
  )
}

export default QuickTour
