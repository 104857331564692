import Button from '@/app/base/components/.v2/Button'
import ModalBox, {
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '@/app/base/components/.v2/ModalBox'
import Toast from '@/app/base/components/.v2/Toast'
import { SHIPMENT_ACTIONS } from '@/app/features/shipments/components/shipmentActions'
import ToastActions from '@/app/features/shipments/components/shipmentActions/components/ToastActions'
import { AppContext, ShipmentsContext } from '@/contexts'
import { useTranslation } from '@/hooks'
import defaultTo from 'lodash/defaultTo'
import React, { memo, useCallback, useContext, useState } from 'react'
import { SHIPMENT_STATES } from 'Trunkrs-SDK/dist/models/delivery/ShipmentLog'

import styles from './styles.module.scss'

const ShipmentCancelledModal = ({
  selectedShipments,
  selectedAction,
  onViewResults,
  onShipmentUpdate,
  onClose = () => {},
}: {
  selectedShipments: number[]
  selectedAction?: SHIPMENT_ACTIONS
  onViewResults: (
    state: SHIPMENT_STATES,
    actualUpdatedShipments: number[]
  ) => void
  onShipmentUpdate: (
    state: SHIPMENT_STATES,
    actualUpdatedShipments: number[]
  ) => void
  onClose: () => void
}) => {
  const [t] = useTranslation(['SHIPMENT_ACTIONS'])
  const { setLoading } = useContext(AppContext)
  const { massApplyState } = useContext(ShipmentsContext)

  const [toastVisible, setToastVisible] = useState(false)

  const startUpdateCountdown = useCallback(() => {
    setToastVisible(true)
    onClose()
  }, [onClose])

  const handleClose = useCallback(() => {
    setToastVisible(false)
    onClose()
  }, [onClose])

  const executeMassCancel = useCallback(async () => {
    setToastVisible(false)
    setLoading(true)
    const result = await massApplyState({
      shipmentIds: selectedShipments,
      state: SHIPMENT_STATES.EXCEPTION_SHIPMENT_CANCELLED_BY_TRUNKRS,
    })
    setLoading(false)
    return result
  }, [selectedShipments])

  const handleViewResults = useCallback(async () => {
    handleClose()
    const affectedShipments = await executeMassCancel()
    const shipmentIds = affectedShipments
      .filter(
        (shipment: any) => shipment.status >= '200' && shipment.status <= '202'
      )
      .map((shipment: any) => shipment.href)
    onViewResults(
      SHIPMENT_STATES.EXCEPTION_SHIPMENT_CANCELLED_BY_TRUNKRS,
      shipmentIds
    )
  }, [onViewResults])

  const handleMassCancel = useCallback(async () => {
    const affectedShipments = await executeMassCancel()
    const shipmentIds = affectedShipments
      .filter(
        (shipment: any) => shipment.status >= '200' && shipment.status <= '202'
      )
      .map((shipment: any) => shipment.href)
    onShipmentUpdate(
      SHIPMENT_STATES.EXCEPTION_SHIPMENT_CANCELLED_BY_TRUNKRS,
      shipmentIds
    )
  }, [onViewResults])

  return (
    <>
      <ModalBox
        visible={selectedAction === SHIPMENT_ACTIONS.CANCEL}
        onClose={onClose}
      >
        <ModalHeader>{t('CANCEL_MODAL.TITLE')}</ModalHeader>
        <ModalBody>
          <div className={styles.detailsContainer}>
            <div
              dangerouslySetInnerHTML={{
                __html: t('CANCEL_MODAL.BODY', {
                  number: selectedShipments.length,
                  interpolation: { escapeValue: false },
                }),
              }}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button variant="success" onClick={onClose}>
            {t('CANCEL_MODAL.BTN_CANCEL')}
          </Button>
          <Button variant="link-danger" onClick={startUpdateCountdown}>
            {t('CANCEL_MODAL.BTN_OK')}
          </Button>
        </ModalFooter>
      </ModalBox>
      {toastVisible && (
        <Toast
          headerText={t('CANCEL_MODAL.TOAST_TITLE')}
          subText={
            <ToastActions
              label={t('CANCEL_MODAL.TOAST_DETAILS', {
                number: selectedShipments.length,
              })}
              onViewResults={handleViewResults}
              onCancel={handleClose}
            />
          }
          onExit={handleMassCancel}
        />
      )}
    </>
  )
}

export default memo(ShipmentCancelledModal)
