import Map from '@/app/features/tourOverview/components/Map'
import { useTranslation } from '@/hooks'
import { ICollation, IDriver, ISubco, ITour } from '@/models'
import { clsx } from '@/utils'
import { getClassForShipmentState } from '@/utils/getClassForShipmentState'
import { getCleanCollations } from '@/utils/getCleanCollations'
import { getCurrentCollation } from '@/utils/getCurrentCollation'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import first from 'lodash/first'
import moment from 'moment'
import * as React from 'react'
import { useMemo, useState } from 'react'

import closeButton from '../assets/times-solid.svg'

interface IProps {
  tour: ITour
  subCo?: ISubco
  driver?: IDriver
  collations: Array<ICollation>
  showModal: () => void
}

const TourModal: React.FunctionComponent<IProps> = props => {
  const { tour, subCo, driver, collations, showModal } = props
  const firstCollation = first(collations)
  const [activeCollation, setActiveCollation] = useState(1)
  const { latitude, longitude } = firstCollation
    ? firstCollation.recipient
    : { latitude: 0, longitude: 0 }
  const [t] = useTranslation('TOUR_OVERVIEW')
  const handleSetActiveCollation = (id: number) => () => setActiveCollation(id)
  const cleanCollations = useMemo(() => getCleanCollations(collations), [
    collations,
  ])
  const currentCollation = useMemo(() => getCurrentCollation(collations), [
    collations,
  ])
  return (
    <div className="tour-modal row no-gutters">
      <div className="col-md-12">
        <div className="backdrop">
          <div className="modal-container">
            <div className="header">
              <div className={`title ${tour.delayed ? 'delayed' : ''}`}>
                {t('TOUR')} {tour.id} {!!subCo && ` - ${subCo.name}`}{' '}
                {tour.delayed ? `(${t('DELAYED')})` : ''}
              </div>
              <button onClick={showModal}>
                <img src={closeButton} className="close-button" alt="close" />
              </button>
            </div>
            <div className="driver-info">
              {driver && (
                <div className="name">
                  {driver.picture ? (
                    <img
                      className="driver-icon"
                      src={driver.picture}
                      alt={driver.name}
                    />
                  ) : (
                    <FontAwesomeIcon
                      className="driver-icon"
                      icon="user-circle"
                    />
                  )}
                  {driver.name}
                </div>
              )}
              {driver && driver.phoneNumber && (
                <a className="phone-number" href={`tel:${driver.phoneNumber}`}>
                  <FontAwesomeIcon icon="phone" />
                  {driver.phoneNumber}
                </a>
              )}
              <div className="shipments">
                <FontAwesomeIcon icon="truck" />
                {`${currentCollation ? currentCollation.position : 0}/${
                  cleanCollations.length
                }`}
              </div>
              <div className="eta">
                <FontAwesomeIcon icon="flag-checkered" />
                {moment(tour.startTime).format('HH:mm')} -{' '}
                {moment(tour.endTime).format('HH:mm')}
              </div>
            </div>
            <div className="tour-overview row no-gutters">
              <div className="col-md-4 shipments">
                {cleanCollations.map((collation: ICollation) => (
                  <div
                    key={collation.id}
                    className={clsx(
                      'shipment',
                      collation.id === activeCollation ? 'active' : '',
                      collation.state &&
                        getClassForShipmentState(collation.state)
                    )}
                    onClick={handleSetActiveCollation(collation.id)}
                  >
                    <div className="container">
                      <div className="position">{collation.position}</div>
                      <div className="details">
                        <div className="address">
                          <div className="street">
                            {collation.recipient.address}
                          </div>
                          <div className="postal-code">
                            {collation.recipient.postalCode}
                            {collation.recipient.city}
                          </div>
                        </div>
                        <div className="recipient">
                          <div className="name">{collation.recipient.name}</div>
                          <div className="phone-number">
                            {collation.recipient.phoneNumber}
                          </div>
                        </div>
                        <div className="footer">
                          <div className="merchant">
                            {collation.merchant.name}
                          </div>
                        </div>
                      </div>
                      <div className="eta">
                        {moment(collation.eta).format('hh:mm')}
                      </div>
                    </div>
                    <div className="status">
                      {collation.delayed && (
                        <div className="delayed">{t('DELAYED')}</div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
              <div className="col-md-8 map">
                <Map
                  containerElement={
                    <div style={{ display: 'flex', height: '100%', flex: 1 }} />
                  }
                  mapElement={<div style={{ width: '100%' }} />}
                  lat={latitude}
                  lng={longitude}
                  zoom={11}
                  isMarkerShown={true}
                  googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBzWD9a4l8NYzXz7eakuWJpLNBmRnxYEc4&v=3.exp&libraries=geometry,drawing,places"
                  loadingElement={<div />}
                  collations={collations}
                  activeCollation={activeCollation}
                  setActiveCollation={setActiveCollation}
                  color={tour.color}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TourModal
