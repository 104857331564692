import { SHIPMENT_STATE } from '@/constants'

export const isNotAttempted = (shipmentState: SHIPMENT_STATE | undefined) => {
  switch (shipmentState) {
    case SHIPMENT_STATE.SHIPMENT_ACCEPTED_BY_DRIVER:
      return true
    default:
      return false
  }
}
