import React, { memo, useMemo, useContext } from 'react'
import { IShipmentNotification } from '@/models'
import { NotificationsContext } from '@/contexts'
import {
  NoNotification,
  Notification,
} from '@/app/features/user/components/notificationPane/components'

export const NotAcceptedNotifications = () => {
  const { notAcceptedNotifs } = useContext(NotificationsContext)

  return (
    <div className="content not-accepted">
      {useMemo(() => {
        return notAcceptedNotifs.map((notification: IShipmentNotification) => (
          <Notification
            key={notification.alertId}
            notification={notification}
          />
        ))
      }, [notAcceptedNotifs])}
      {notAcceptedNotifs.length === 0 && <NoNotification />}
    </div>
  )
}

export default memo(NotAcceptedNotifications)
