import './styles.scss'

import { useTranslation } from '@/hooks/useTranslation'
import { DebtorResponse } from '@/store/debtors/actions'
import MerchantOverviewContext from '@/store/merchantOverview'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Dialog from '@material-ui/core/Dialog'
import React, { memo, useContext, useMemo } from 'react'

import Spinner from '../../../../../base/img/spinner-gray.svg'
import { tabs } from './resources'
import Table from './Table'

export interface MerchantInformationModalProps {
  open: boolean
  isLoading?: boolean
  isSaving: boolean
  accountCreationResponse?: { statusCode: number; message: string }
  debtorNumber: string | null
  debtors: DebtorResponse
  handleCreateMerchantUser: (values: any) => void
  onDebtorSelected: (debtorNumber: string) => void
  onClose: () => void
  onSaveDebtor: () => void
}

export const MerchantInformationModal: React.FC<
  MerchantInformationModalProps
> = ({
  open,
  isLoading = false,
  isSaving,
  debtorNumber,
  debtors,
  accountCreationResponse,
  onClose,
  onDebtorSelected,
  onSaveDebtor,
  handleCreateMerchantUser,
}: MerchantInformationModalProps) => {
  const [t] = useTranslation('MERCHANT_OVERVIEW')
  const {
    state: {
      sectionActive,
      selectedMerchantId,
      selectedMerchantInfo,
      forecasts,
    },
    actions: { setState },
  } = useContext(MerchantOverviewContext)

  const sectionTabs = useMemo(() => tabs(setState), [tabs])

  return (
    <Dialog
      maxWidth="md"
      open={open}
      transitionDuration={300}
      fullWidth
      PaperProps={{
        className: 'paperRoot',
      }}
      classes={{
        container: 'Dialog-Container',
      }}
    >
      {!selectedMerchantInfo || isLoading ? (
        <div className="loading">
          <img src={Spinner} width={56} height={56} alt="spinner" />
        </div>
      ) : (
        <div id="merchant-info-modal">
          <div className="header">
            <div className="title">{selectedMerchantInfo.merchant.name}</div>
            <div onClick={onClose} className="close-icon">
              <FontAwesomeIcon icon="times" />
            </div>
          </div>
          <span className="merchant-id">{`${t(
            'MERCHANT_ID'
          )} ${selectedMerchantId}`}</span>

          <Table
            isSaving={isSaving}
            debtors={debtors}
            debtorNumber={debtorNumber}
            forecasts={forecasts}
            onDebtorSelected={onDebtorSelected}
            onSaveDebtor={onSaveDebtor}
          />
        </div>
      )}
    </Dialog>
  )
}

export default memo(MerchantInformationModal)
