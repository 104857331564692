import './styles.scss'

import {
  SelectField,
  TextField,
} from '@/app/base/components/reactHookComponents'
import { useTranslation } from '@/hooks'
import Grid from '@material-ui/core/Grid/Grid'
import MenuItem from '@material-ui/core/MenuItem/MenuItem'
import get from 'lodash/get'
import React, { memo, useMemo } from 'react'

import resource from '../../resource.json'

const MerchantITForm = () => {
  const webshopTypes = get(resource, 'WebshopTypes', []) as string[]
  const integrationTypes = get(resource, 'IntegrationTypes', []) as string[]
  const recipientCommunications = get(
    resource,
    'RecipientCommunications',
    []
  ) as string[]

  const [t] = useTranslation('MERCHANT_CREATION')

  return (
    <div id="merchant-it">
      <div className="header-wrapper">
        <div>
          <span className="header-text">{t('IT_HEADER')}</span>
          <span className="header-subtext">{t('IT_SUB_HEADER')}</span>
        </div>
        <div className="icon" />
      </div>
      <div className="content">
        <Grid container>
          <Grid item xs={12}>
            <TextField
              id="it-contact-person"
              label={t('CONTACT_PERSON')}
              name="itDetails.contactPerson"
              placeholder={t('CONTACT_PERSON_PLACEHOLDER')}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="it-email-address"
              label={t('CONTACT_PERSON_EMAIL_ADDRESS')}
              name="itDetails.contactPersonEmail"
              placeholder={t('EMAIL_PLACEHOLDER')}
            />
          </Grid>
          <Grid item xs={12}>
            <SelectField
              required
              label={t('TYPE_OF_WEBSHOP')}
              id="webshop-type"
              name="itDetails.webshopType"
              menuListMaxHeight={150}
            >
              <MenuItem value="" hidden>
                {t('WEBSHOP_TYPE_PLACEHOLDER')}
              </MenuItem>
              {useMemo(
                () =>
                  webshopTypes.map(webshopType => (
                    <MenuItem value={webshopType}>{webshopType}</MenuItem>
                  )),
                []
              )}
            </SelectField>
          </Grid>
          <Grid item xs={12}>
            <SelectField
              required
              label={t('INTEGRATION_TYPE')}
              id="integration-type"
              name="itDetails.integrationType"
              menuListMaxHeight={150}
            >
              <MenuItem value="" hidden selected>
                {t('INTEGRATION_TYPE_PLACEHOLDER')}
              </MenuItem>
              {useMemo(
                () =>
                  integrationTypes.map(integrationType => (
                    <MenuItem value={integrationType}>
                      {integrationType}
                    </MenuItem>
                  )),
                []
              )}
            </SelectField>
          </Grid>
          <Grid item xs={12}>
            <SelectField
              label={t('RECIPIENT_COMMUNICATION')}
              id="recipient-communication"
              name="itDetails.recipientCommunication"
            >
              {useMemo(
                () =>
                  recipientCommunications.map(recipientCommunication => (
                    <MenuItem value={recipientCommunication}>
                      {recipientCommunication}
                    </MenuItem>
                  )),
                []
              )}
            </SelectField>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default memo(MerchantITForm)
