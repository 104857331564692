import DateAndTimePicker from '@/app/base/components/dateAndTimePicker'
import { AddComment } from '@/app/features/shipment/components/setFinalState/components'
import { REASON_CODE } from '@/constants'
import { AppContext, CommentsContext, ShipmentsContext } from '@/contexts'
import { useTranslation } from '@/hooks'
import { ReasonCode, SHIPMENT_ACTIONS, SHIPMENT_STATES } from '@/models'
import { clsx } from '@/utils'
import { get, isEmpty } from 'lodash'
import moment from 'moment'
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

interface IForNonDeliveryProps {
  handleClose?: (...args: any[]) => void
}

const ForNonDelivery = ({ handleClose }: IForNonDeliveryProps) => {
  const { setLoading } = useContext(AppContext)
  const { addComment } = useContext(CommentsContext)
  const [selectedDateTime, setSelectedDateTime] = useState(moment())
  const {
    setShipmentAsNotDelivered,
    getReasonCodesByShipmentState,
  } = useContext(ShipmentsContext)

  const [comment, setComment] = useState('')
  const [reasonCodes, setReasonCodes] = useState<Array<ReasonCode>>([])
  const [selectedReason, setSelectedReason] = useState('')

  const setStateToNotDeliver = useCallback(
    async (ev: React.SyntheticEvent<any>) => {
      setLoading(true)
      const success = await setShipmentAsNotDelivered(
        selectedReason,
        comment,
        moment(selectedDateTime).toString()
      )
      if (success && !isEmpty(comment)) {
        await addComment(comment)
      }
      setLoading(false)
      if (success && handleClose) {
        handleClose(ev)
      }
    },
    [selectedReason, comment]
  )

  useEffect(() => {
    setLoading(true)

    Promise.all([
      getReasonCodesByShipmentState(SHIPMENT_STATES.SHIPMENT_NOT_DELIVERED),
      getReasonCodesByShipmentState(
        SHIPMENT_STATES.EXCEPTION_SHIPMENT_DECLINED_BY_DRIVER
      ),
    ])
      .then(reasonCodes => {
        const rc = get(reasonCodes, 0).concat(get(reasonCodes, 1))
        const normalizedRc = rc.filter(
          (reason: any) =>
            reason.code !== REASON_CODE.SHIPMENT_MISSING &&
            reason.code !== REASON_CODE.HEAVILY_DAMAGED
        )
        setReasonCodes(normalizedRc)
      })
      .finally(() => setLoading(false))
  }, [])

  const handleReasonChange = useCallback(
    (ev: React.SyntheticEvent<HTMLSelectElement>) => {
      setSelectedReason(ev.currentTarget.value)
    },
    []
  )

  const [t] = useTranslation(['SET_FINAL_STATE'])

  return (
    <div>
      <p className="text bold">{t('SET_TIME_AND_DATE')}</p>
      <DateAndTimePicker
        value={selectedDateTime}
        placeholder={t('PICK_TIME_AND_DATE')}
        handleConfirm={setSelectedDateTime}
      />
      <div className="mt-4">
        <p className="text bold">{t('REASON')}</p>
        <select
          value={selectedReason}
          className={clsx(
            'custom-select text',
            isEmpty(selectedReason) && 'gray'
          )}
          onChange={handleReasonChange}
        >
          <option value="" hidden={true}>
            {t('CHOOSE_A_REASON')}
          </option>
          {useMemo(
            () =>
              reasonCodes.map((reasonCode: ReasonCode) => {
                const code = get(reasonCode, 'code', '')
                return (
                  <option key={code} value={code}>
                    {t(`REASON_CODES:${code}`)}
                  </option>
                )
              }),
            [reasonCodes]
          )}
        </select>
      </div>
      <AddComment
        action={SHIPMENT_ACTIONS.NON_DELIVERY}
        callback={setComment}
      />
      <div className="actions">
        <button
          className="btn button inactive icon"
          type="button"
          onClick={handleClose}
        >
          {t('COMMON:BUTTON.NEVERMIND')}
        </button>
        <button
          className={'btn button box-shadow danger'}
          type="button"
          onClick={setStateToNotDeliver}
        >
          {t('BUTTON.MARK_AS_NOT_DELIVERED')}
        </button>
      </div>
    </div>
  )
}
export default memo(ForNonDelivery)
