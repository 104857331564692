import API from '@/api'
import { useResponseErrorHandler } from '@/hooks'
import extractSubcosFromTag from '@/utils/extractSubcosFromTag'
import { chain } from 'lodash'
import { useReducer } from 'react'

import reducer from './reducer'
import { ICollectionTrackingState } from './state'

const subcontractor = API.Subco()
const collectionTour = API.CollectionTour()

export const useCollectionTrackingActions = (
  initialState: ICollectionTrackingState
) => {
  const [
    {
      activeTag,
      availableTags,
      subcontractors,
      activeTagTours,
      errorFetchingTours,
      fetchingAvailableTags,
      fetchingCollectionTours,
      activeCollectionTourDetails,
      fetchingCollectionTourDetails,
    },
    dispatch,
  ] = useReducer(reducer, initialState)

  const set = (type: string, payload: any) => dispatch({ type, payload })

  const setActiveTag = (data: string) => {
    set('activeTag', data)
  }

  const fetchAvailableTags = async () => {
    set('fetchingAvailableTags', true)
    const [, subcos] = await useResponseErrorHandler(subcontractor.fetchAll())
    set('subcontractors', subcos)
    const tags: any[] = chain(subcos)
      .sortBy('getTag')
      .map(subco => subco.getTag)
      .uniq()
      .value()
    set('availableTags', tags)
    set('fetchingAvailableTags', false)
    return tags
  }

  const fetchCollectionTours = async (tag: string) => {
    set('fetchingCollectionTours', true)
    const subcosForTag = extractSubcosFromTag(subcontractors, tag)
    let tours: any = []
    set('activeCollectionTourDetails', null)
    for (const subco of subcosForTag) {
      const [error, result] = await useResponseErrorHandler(
        collectionTour.fetchCollectionTourTracking(
          subco.getId!,
          new Date().toString()
        )
      )
      if (error) {
        set('errorFetchingTours', true)
        break
      }
      tours = tours.concat(result)
    }
    set('activeTagTours', tours)
    set('fetchingCollectionTours', false)
    return tours
  }

  const fetchCollectionTourDetails = async (tourId: number) => {
    set('fetchingCollectionTourDetails', true)
    const [, result] = await useResponseErrorHandler(
      collectionTour.fetchCollectionTourView(tourId)
    )
    set('activeCollectionTourDetails', result)
    set('fetchingCollectionTourDetails', false)
    return result
  }

  return {
    fetchAvailableTags,
    setActiveTag,
    availableTags,
    activeTag,
    activeTagTours,
    subcontractors,
    fetchCollectionTours,
    fetchCollectionTourDetails,
    activeCollectionTourDetails,
    fetchingAvailableTags,
    fetchingCollectionTours,
    fetchingCollectionTourDetails,
    errorFetchingTours,
  }
}
