export enum AUTOCOMPLETE_VARIANTS {
  DEFAULT = 'default',
  CHIP = 'chip',
}

export enum BUTTON_VARIANTS {
  DEFAULT = 'default',
  INFO = 'info',
  WARNING = 'warning',
  DANGER = 'danger',
  SUCCESS = 'success',
}
