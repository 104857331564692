import ItemsNavigation from '@/app/base/components/itemsNavigation'
import LoadingBars from '@/app/base/components/loadingBars'
import no_results_logo from '@/app/base/img/no-results-icon.png'
import { CollectionTrackingContext } from '@/contexts'
import { useTranslation } from '@/hooks'
import Box from '@material-ui/core/Box'
import clsx from 'clsx'
import React, { useContext, useEffect, useMemo, useState } from 'react'

import TourItem from '../tourItem'
import classes from './style.module.scss'

interface IMainContentProps {
  toggleDrawer: (shouldBeOpen: boolean) => void
  isDrawerOpen: boolean
}

const MainContent: React.FC<IMainContentProps> = ({
  toggleDrawer,
  isDrawerOpen,
}) => {
  const [localActiveTour, setLocalActiveTour] = useState<number | null>(null)
  const [t] = useTranslation('COLLECTION_TRACKING')
  const {
    availableTags,
    activeTagTours,
    activeTag,
    fetchCollectionTours,
    setActiveTag,
    fetchCollectionTourDetails,
    fetchingCollectionTours,
  } = useContext(CollectionTrackingContext)

  useEffect(() => {
    fetchCollectionTours(activeTag)
  }, [activeTag])

  const handleSelectTag = (tag: string) => {
    toggleDrawer(false)
    setActiveTag(tag)
  }

  const handleSelectTour = (tourId: number) => {
    setLocalActiveTour(tourId)
    toggleDrawer(true)
    fetchCollectionTourDetails(tourId)
  }

  const activeTagToursList = useMemo(() => {
    return (
      activeTagTours &&
      activeTagTours.map((tour, index) => {
        return (
          <TourItem
            onItemClick={() => handleSelectTour(tour.tourId)}
            key={tour.tourId}
            tour={tour}
            index={index}
            activeTag={activeTag}
            active={localActiveTour === tour.tourId}
          />
        )
      })
    )
  }, [activeTagTours, handleSelectTour, isDrawerOpen, localActiveTour])

  return (
    <div>
      <h1 className={clsx('h2 mt-4 text-left title', classes.main_title)}>
        {t('COLLECTION_TRACKING')}
      </h1>
      <ItemsNavigation
        items={availableTags}
        activeItem={activeTag}
        onSelectItem={handleSelectTag}
      />
      {fetchingCollectionTours ? (
        <LoadingBars numberOfBar={5} barHeight={71} />
      ) : (
        <table className={clsx('table', classes.tour_table)}>
          <tbody>{activeTagToursList}</tbody>
        </table>
      )}

      {activeTagToursList &&
        !activeTagToursList.length &&
        !fetchingCollectionTours && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <img className={classes.no_results} src={no_results_logo} />

            <h3 className={classes.noTours}>{t('NO_TOURS_TODAY')}</h3>
          </Box>
        )}
    </div>
  )
}

export default MainContent
