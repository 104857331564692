import React, { memo } from 'react'
import { useFormContext } from 'react-hook-form'

const InputHidden = ({
  name,
  ...props
}: {
  name: string
  [key: string]: any
}) => {
  const { register } = useFormContext()
  return <input type="hidden" name={name} ref={register} {...props} />
}

export default memo(InputHidden)
