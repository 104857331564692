import * as React from 'react'
import { useCommentsActions } from '@/store/comments/actions'
import { CommentsContext } from '@/contexts'

export default ({ children }: { children: React.ReactNode }) => {
  const state = useCommentsActions()
  return (
    <CommentsContext.Provider value={state}>
      {children}
    </CommentsContext.Provider>
  )
}
