import { IMerchant, ISearchFilters } from '@/models'

export interface IMerchantState {
  merchants: IMerchant[]
  setFilterAction: (filterProps?: ISearchFilters) => void
  fetchMerchants: () => Promise<IMerchant[]>
  filters?: { date?: Date; merchant_name?: string }
}

export const initialState: IMerchantState = {
  merchants: [],
  filters: {
    merchant_name: '',
  },
  fetchMerchants: () => Promise.resolve([]),
  setFilterAction: () => {},
}
