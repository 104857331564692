import API from '@/api'
import { useResponseErrorHandler } from '@/hooks'
import { IShipmentReturnsState } from '@/store/shipmentReturns/state'
import defaultTo from 'lodash/defaultTo'
import get from 'lodash/get'
import groupBy from 'lodash/groupBy'
import moment from 'moment'
import { useState } from 'react'
import { HUBLOG_STATE } from 'Trunkrs-SDK/dist/models/delivery/Shipment'

export const useShipmentReturnsActions = (
  initialState: IShipmentReturnsState
) => {
  const [shipmentReturns, setShipmentReturns] = useState(
    initialState.shipmentReturns
  )

  const shipment = API.Shipment()

  const getShipmentReturns = async (subcoRegion?: string) => {
    const [, response] = await useResponseErrorHandler(
      shipment.fetchCSPReturnsList({
        subcoRegion,
      })
    )

    const groupByRegion = groupBy(response, 'region')

    const shipmentReturns = Object.keys(groupByRegion).map(region => {
      const groupBySubcos = groupBy(get(groupByRegion, region), 'subcoName')
      const subcos = Object.keys(groupBySubcos).map(subcoName => {
        const shipments = get(groupBySubcos, subcoName).map(shipment => ({
          returnedToTrunkrs: shipment.hubState === HUBLOG_STATE.HUB_IN_CENTRAL,
          shipmentId: shipment.shipmentId,
          trunkrsNr: shipment.trunkrsNr,
          driver: {
            id: shipment.lastDriver,
          },
          merchant: {
            name: shipment.merchant,
          },
          recipient: {
            address: shipment.address,
            postalCode: shipment.postalCode,
            city: shipment.city,
            country: shipment.country,
          },
          subco: {
            name: subcoName,
          },
        }))

        return {
          name: subcoName,
          shipments,
        }
      })

      return {
        name: region,
        subcos,
      }
    })

    setShipmentReturns(defaultTo(shipmentReturns as any, []))
  }

  const submitReturnedShipment = async (trunkrsNrOrBarcode: string) => {
    const [err, response] = await useResponseErrorHandler(
      shipment.scanReturnedShipments({ trunkrsNrOrBarcode })
    )

    return {
      data: response,
      error:
        get(response, 'length') === 0 || get(err, 'response.data', undefined),
    }
  }

  const printShipmentReturns = async (region?: string, subcoId?: number) => {
    const date = moment().format('YYYY-MM-DD')
    const [, response] = await useResponseErrorHandler(
      shipment.printShipmentsReturnedToTrunkrs(region, subcoId, date)
    )

    return defaultTo(response, false)
  }

  return {
    shipmentReturns,
    getShipmentReturns,
    submitReturnedShipment,
    printShipmentReturns,
  }
}
