import { useDOMWidgets, usePersistedState } from '@/hooks'
import { IAppState } from '@/store/app/state'
import { useCallback, useEffect, useState } from 'react'

export const useAppActions = (initialState: IAppState) => {
  const [error, setError] = useState(initialState.error)
  const [sidebarCollapsed, setSidebarCollapsed] = usePersistedState<boolean>(
    '__CSP_LS_SIDEBAR',
    initialState.sidebarCollapsed
  )
  const [isNotificationPaneVisible, setIsNotificationPaneVisible] = useState<
    boolean
  >(initialState.isNotificationPaneVisible)

  useEffect(() => {
    setIsNotificationPaneVisible(false)
  }, [location])

  const {
    Loader: {
      methods: { setLoading },
      ...props
    },
  } = useDOMWidgets()

  return {
    Loader: props,
    setLoading,
    sidebarCollapsed,
    setSidebarCollapsed,
    isNotificationPaneVisible,
    setIsNotificationPaneVisible,
    error,
    setError,
  }
}
