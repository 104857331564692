import './styles.scss'

import { useTranslation } from '@/hooks'
import { IReactHookFormBase } from '@/models'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import React, { memo } from 'react'
import { useWatch } from 'react-hook-form'

export interface IMerchantFormNotificationProps extends IReactHookFormBase {
  isOpen?: boolean
  isError?: boolean
}

const MerchantFormNotification = ({
  isOpen,
  isError,
}: IMerchantFormNotificationProps) => {
  const [t] = useTranslation('MERCHANT_CREATION')

  const webshopName = useWatch({ name: 'merchant.name' })

  return (
    <>
      {isOpen && (
        <div
          className={clsx(
            'merchant-notification-container',
            isError && 'error'
          )}
        >
          <div className="icon">
            <FontAwesomeIcon icon={!isError ? 'check-circle' : 'ban'} />
          </div>
          <div className="message-container">
            <span className="header-text">
              {t(!isError ? 'MERCHANT_CREATED' : 'UNABLE_TO_CREATE_MERCHANT')}
            </span>
            {!isError ? (
              <span
                className="sub-text"
                dangerouslySetInnerHTML={{
                  __html: t('MERCHANT_CREATION_SUCCESS', {
                    webshopName,
                    interpolation: { escapeValue: false },
                  }),
                }}
              />
            ) : (
              <span className="sub-text">{t('MERCHANT_CREATION_ERROR')}</span>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default memo(MerchantFormNotification)
