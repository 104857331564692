import './styles.scss'

import CustomSelect from '@/app/base/components/select/Select'
import { useTranslation } from '@/hooks'
import { DebtorResponse } from '@/store/debtors/actions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import MenuItem from '@material-ui/core/MenuItem'
import React, { useMemo } from 'react'

interface DebtorListProps {
  isSaving: boolean
  debtors: DebtorResponse
  debtorNumber: string | null
  onSelection?: (debtorNumber: string) => void
  onSaveDebtor: () => void
}

const DebtorsList: React.FC<DebtorListProps> = ({
  isSaving,
  debtors,
  debtorNumber,
  onSaveDebtor,
  onSelection,
}) => {
  const [isSaveEnabled, setIsSaveEnabled] = React.useState(false)
  const [t] = useTranslation()

  const handleChange = React.useCallback(
    (event: React.FormEvent<HTMLSelectElement>) => {
      if (onSelection) onSelection.call(null, (event.target as any).value)
      setIsSaveEnabled(true)
    },
    [onSelection]
  )

  return (
    <div id="debtors-wrapper">
      <CustomSelect
        id="debtors-list"
        name="debtors-list"
        label="Invoicing debtor"
        value={debtorNumber || ''}
        onChange={handleChange}
      >
        <MenuItem hidden value="">
          {t('None')}
        </MenuItem>

        {useMemo(
          () =>
            debtors.map(debtor => (
              <MenuItem key={debtor.debtorNumber} value={debtor.debtorNumber}>
                {debtor.name}
              </MenuItem>
            )),
          [debtors]
        )}
      </CustomSelect>

      <Button
        id="btn-save-debtor"
        className="btn button action"
        disabled={!isSaveEnabled || isSaving}
        startIcon={
          isSaving ? (
            <CircularProgress size={20} color="inherit" />
          ) : (
            <FontAwesomeIcon icon="save" />
          )
        }
        onClick={onSaveDebtor}
      >
        {t(isSaving ? 'Saving...' : 'Save')}
      </Button>
    </div>
  )
}

export default DebtorsList
