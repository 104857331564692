import API from '@/api'
import { useResponseErrorHandler } from '@/hooks'
import i18n from '@/i18n'
import extractSubcosFromTag from '@/utils/extractSubcosFromTag'
import extractTagsFromSubcos from '@/utils/extractTagsFromSubcos'
import { useReducer } from 'react'
import { IPublishTourValidations } from 'Trunkrs-SDK/dist/models/spatial/Collections/CollectionTour'

import reducer from './reducer'
import { ICollectionTourState, PublishType } from './state'

const subcontractor = API.Subco()
const collectionTour = API.CollectionTour()

const optimizationTimestamp = 'optimization-timestamp'

export const useCollectionTourActions = (
  initialState: ICollectionTourState
): ICollectionTourState => {
  const [
    {
      fetchingAvailableTags,
      subcontractors,
      availableTags,
      activeTag,
      fetchingCollectionTours,
      activeTagTours,
      errorFetchingTours,
      activeCollectionTourView,
      fetchingCollectionTourView,
      publishingNewTours,
      publishType,
      publishValidations,
    },
    dispatch,
  ] = useReducer(reducer, initialState)

  const set = (type: string, payload: any) => dispatch({ type, payload })

  const setActiveTag = (data: string) => {
    set('activeTag', data)
  }

  const fetchAvailableTags = async () => {
    set('fetchingAvailableTags', true)
    const [, subcos] = await useResponseErrorHandler(subcontractor.fetchAll())
    set('subcontractors', subcos)
    const tags: any[] = extractTagsFromSubcos(subcos)
    set('availableTags', tags)
    set('fetchingAvailableTags', false)
    return tags
  }

  const fetchCollectionTours = async (tag: string) => {
    set('fetchingCollectionTours', true)
    const subcosForTag = extractSubcosFromTag(subcontractors, tag)
    let tours: any = []
    set('activeCollectionTourView', null)
    for (const subco of subcosForTag) {
      const [error, result] = await useResponseErrorHandler(
        collectionTour.fetchCollectionTourEstimations(subco.getId!)
      )
      if (error) {
        set('errorFetchingTours', true)
        break
      }
      tours = tours.concat(result)
    }
    set('activeTagTours', tours)
    set('fetchingCollectionTours', false)
    return tours
  }

  const fetchCollectionTourView = async (tourId: number) => {
    set('fetchingCollectionTourView', true)
    const [, result] = await useResponseErrorHandler(
      collectionTour.fetchSingleCollectionTourEstimation(tourId)
    )
    set('activeCollectionTourView', result)
    set('fetchingCollectionTourView', false)
    return result
  }

  const publishNewTours = async () => {
    set('publishValidations', null)
    set('publishType', PublishType.Publish)
    set('publishingNewTours', true)
    const [error, result] = await useResponseErrorHandler(
      collectionTour.publishTours()
    )

    if (result) {
      set('publishValidations', result)
    }
    if (error) {
      if (error.response && error.response.status === 400) {
        const { fullAddress } = error.response.data

        set('publishValidations', {
          status: 'error',
          messages: [
            {
              type: 'bad-address',
              message: i18n.t('PUBLISHING.BAD_ADDRESS', {
                fullAddress,
                ns: 'COLLECTION_TOUR',
              }),
            },
          ],
        } as IPublishTourValidations)
      } else {
        set('publishValidations', {
          status: 'error',
          messages: [
            {
              type: 'broken-request',
              message: i18n.t('PUBLISHING.REQUEST_ERROR', {
                ns: 'COLLECTION_TOUR',
              }),
            },
          ],
        } as IPublishTourValidations)
      }
    }

    await fetchAvailableTags()
    setActiveTag(availableTags[0])
    await fetchCollectionTours(activeTag)
    return result
  }

  const optimiseTours = async () => {
    set('publishType', PublishType.Optimise)
    set('publishingNewTours', true)
    setOptimizationTimestamp()
    const [, result] = await useResponseErrorHandler(
      collectionTour.startCollectionTourVRPFromGoogleSheet()
    )

    return result
  }

  const setPublishingNewTours = (isPublishing: boolean) => {
    set('publishingNewTours', isPublishing)
  }

  const setOptimizationTimestamp = () => {
    localStorage.setItem(optimizationTimestamp, new Date().getTime().toString())
  }

  const getOptimizationRemainingTime = () => {
    const timestamp = Number(localStorage.getItem(optimizationTimestamp))
    const currentTimestamp = new Date().getTime()
    const difference = (currentTimestamp - timestamp) / 1000 // in seconds
    const isRunning = difference < 3599 && difference > 0 ? true : false
    const remainingTime = 3599 - difference

    set('publishType', PublishType.Optimise)
    set('publishingNewTours', isRunning)

    return isRunning ? remainingTime : 0
  }

  return {
    fetchAvailableTags,
    fetchingAvailableTags,
    subcontractors,
    availableTags,
    setActiveTag,
    activeTag,
    activeTagTours,
    errorFetchingTours,
    fetchCollectionTours,
    fetchingCollectionTours,
    activeCollectionTourView,
    fetchCollectionTourView,
    fetchingCollectionTourView,
    publishNewTours,
    publishingNewTours,
    publishType,
    setPublishingNewTours,
    optimiseTours,
    getOptimizationRemainingTime,
    publishValidations,
  }
}
