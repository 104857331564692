import Select from '@/app/base/components/select'
import { ReasonCode } from '@/models'
import { TFunction } from 'i18next'
import { get } from 'lodash'
import React from 'react'

import classes from './styles.module.scss'

export interface ReasonCodesSelectProps {
  disabled?: boolean
  reasonCodes: ReasonCode[]
  value: string
  t: TFunction
  onChange: (e: any) => void
}

export const ReasonCodesSelect: React.FC<ReasonCodesSelectProps> = ({
  disabled,
  reasonCodes,
  value,
  onChange,
  t,
}) => {
  return (
    <Select
      className={classes.reason_code_select}
      onChange={onChange}
      value={value}
      disabled={disabled}
    >
      <option value="none">Select reason code</option>
      {React.useMemo(
        () =>
          reasonCodes.map((reasonCode: ReasonCode) => {
            const code = get(reasonCode, 'code', '')
            return (
              <option key={code} value={code}>
                {`${code}`}
              </option>
            )
          }),
        [reasonCodes]
      )}
    </Select>
  )
}

export default ReasonCodesSelect
