import './style.scss'

import clsx from 'clsx'
import React from 'react'
import TimeField from 'react-simple-timefield'

export interface ITimeInputProps {
  onChange: (time: string) => void
  className?: string
  value?: string
}

const TimeInput: React.FC<ITimeInputProps> = ({
  onChange,
  value,
  className,
}) => {
  return (
    <TimeField
      className={clsx('form-control time-field', className)}
      value={value}
      colon=":"
      onChange={(event: any) => onChange(event.target.value)}
    />
  )
}

export default TimeInput
