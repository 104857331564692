import Button from '@/app/base/components/.v2/Button'
import { useTranslation } from '@/hooks'
import React, { memo, useCallback } from 'react'
import { SHIPMENT_STATES } from 'Trunkrs-SDK/dist/models/delivery/ShipmentLog'

import styles from './styles.module.scss'

const ToastActions = ({
  onCancel,
  onViewResults,
  label,
}: {
  onCancel: () => void
  onViewResults: () => void
  label: string
}) => {
  const [t] = useTranslation('ALL_SHIPMENTS')

  const handleViewResult = useCallback(() => onViewResults(), [])
  return (
    <span className={styles.toastActionsContainer}>
      <span>{label}</span>
      <span>
        <Button onClick={onCancel}>Undo</Button>
        <Button variant="link" onClick={handleViewResult}>
          {t('SEE_RESULTS')}
        </Button>
      </span>
    </span>
  )
}

export default memo(ToastActions)
