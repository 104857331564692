import LoadingBars from '@/app/base/components/loadingBars'
import LoadingTile from '@/app/base/components/loadingTile'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import React from 'react'

import classes from './style.module.scss'

interface IDrawerLoadingProps {
  handleCloseDrawer: () => void
}

const DrawerLoading: React.FC<IDrawerLoadingProps> = ({
  handleCloseDrawer,
}) => {
  return (
    <div className={classes.drawer_content_loading}>
      <div className={classes.header}>
        <LoadingTile width={200} height={25} />
        <button
          className={clsx('icon close', classes.no_outline)}
          onClick={handleCloseDrawer}
        >
          <FontAwesomeIcon icon="times" />
        </button>
      </div>
      <LoadingTile width={250} height={35} />
      <div className={classes.middle}>
        <LoadingTile width={600} height={125} />
      </div>
      <LoadingBars numberOfBar={4} barHeight={100} />
    </div>
  )
}

export default DrawerLoading
