import { DataSourceConfig } from '@/app/base/components/.v2/SmartSearch/helpers/interfaces'
import { IShipment } from '@/models'

export interface ISmartSearchState {
  activeChips: DataSourceConfig[] | []
  selectedFilter: string | null
  dateStart: Date | null
  dateEnd: Date | null
  parsedQuery: Record<string, any>
  searchResult: Array<IShipment>
  isFetching: boolean
  isEndOfResult: boolean
  isScrolling: boolean
  queryParams: string | null
  clearState: () => void
  setDateStart: React.Dispatch<React.SetStateAction<Date | null>>
  setDateEnd: React.Dispatch<React.SetStateAction<Date | null>>
  setSelectedFilter: React.Dispatch<React.SetStateAction<string | null>>
  handleAddChip: (chip: DataSourceConfig) => void
  handleAddBulkChip: (chip: DataSourceConfig[]) => void
  handleRemoveChip: (id: number) => void
  fetchFilteredShipments: (shipmentQuery?: Record<string, any>) => void
  handlePagination: () => void
}

export const initialState: ISmartSearchState = {
  selectedFilter: null,
  dateStart: null,
  dateEnd: null,
  activeChips: [],
  parsedQuery: { skip: 0, take: 25 },
  searchResult: [],
  isFetching: false,
  isEndOfResult: false,
  isScrolling: false,
  queryParams: null,
  setDateStart: () => {},
  setDateEnd: () => {},
  clearState: () => {},
  setSelectedFilter: () => {},
  handleAddBulkChip: () => {},
  handleAddChip: () => {},
  handleRemoveChip: () => {},
  fetchFilteredShipments: () => {},
  handlePagination: () => {},
}
