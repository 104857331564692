import { MESSAGE_TYPE } from '@/constants'
import { IDriver } from '@/models'

export interface IDriverState {
  drivers: Array<IDriver>
  allDrivers: Array<IDriverProfile>
  allDriversWithSubcoId: Array<{
    name: string
    userSub: string
  }>
  addDriver: (driver: IDriver) => void
  updateDriver: (driver: IDriver) => void
  fetchImage: (driver: IDriver) => void
  activeDriverId: number | null
  updateActiveDriverId: (driverId: number | null) => void
  fetchAllDrivers: () => Promise<void>
  storeDriverMessage: (message: IDriverMessage) => Promise<boolean>
  fetchAllDriversWithSubcoId: () => Promise<any>
  setAllDriversWithSubcoId: React.Dispatch<React.SetStateAction<never[]>>
}

export const initialDriverState: IDriverState = {
  drivers: [],
  allDrivers: [],
  allDriversWithSubcoId: [],
  addDriver: () => null,
  updateDriver: () => null,
  fetchImage: () => null,
  activeDriverId: null,
  updateActiveDriverId: () => null,
  fetchAllDrivers: () => Promise.resolve(),
  storeDriverMessage: () => Promise.resolve(false),
  fetchAllDriversWithSubcoId: () => Promise.resolve(),
  setAllDriversWithSubcoId: () => {},
}

export interface IDriverMessage {
  driverId: number
  message: string
  type: MESSAGE_TYPE
}

export interface IDriverProfile {
  subcoName: string
  subcoId: number
  driverName: string
  driverId: number
}
