import './styles.scss'

import {
  FileInput,
  FileInputContext,
} from '@/app/base/components/reactHookComponents'
import { FILE_INPUT_STATE } from '@/constants'
import { useTranslation } from '@/hooks'
import { formatBytes } from '@/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Grid from '@material-ui/core/Grid/Grid'
import clsx from 'clsx'
import get from 'lodash/get'
import React, { memo, useContext } from 'react'

const MerchantPhotoUpload = () => {
  const [t] = useTranslation('MERCHANT_CREATION')

  return (
    <Grid container item>
      <Grid item xs={12}>
        <label className="w-100">{t('SHIPMENT_EXAMPLE_PHOTO')}</label>
        <FileInput
          accept="image/png, image/jpg, image/jpeg"
          name="collection.parcelImage"
          maxSize={20971520}
        >
          <PhotoUploader />
        </FileInput>
      </Grid>
    </Grid>
  )
}

const PhotoUploader = () => {
  const { fileInputState, getThumbnails, files, isDragActive } = useContext(
    FileInputContext
  )
  const [t] = useTranslation('MERCHANT_CREATION')

  return (
    <div
      className={clsx('merchant-photo-content', {
        dragging: isDragActive,
        uploaded: fileInputState === FILE_INPUT_STATE.FILE_UPLOADED,
      })}
    >
      {fileInputState === FILE_INPUT_STATE.INITIAL && (
        <div className="photo-content-initial">
          <button
            type="button"
            className="btn btn-primary button action box-shadow"
          >
            <FontAwesomeIcon icon="cloud-upload-alt" className="icon" />
            {t('BUTTON.SELECT_PHOTO')}
          </button>
          <div
            className="upload-action-text"
            dangerouslySetInnerHTML={{
              __html: t('DRAG_AND_DROP_PHOTO', {
                interpolation: { escapeValue: false },
              }),
            }}
          />
        </div>
      )}
      {fileInputState === FILE_INPUT_STATE.FILE_REJECTED && (
        <div className="photo-content-rejected">
          <div className="header-text">{t('PHOTO_CANNOT_BE_USED')}</div>
          <div className="sub-text">{t('PHOTO_CANNOT_BE_USED_DETAILS')}</div>
          <button
            type="button"
            className="btn btn-danger button danger box-shadow"
          >
            <FontAwesomeIcon icon="undo" className="icon" />
            {t('BUTTON.TRY_DIFFERENT_PHOTO')}
          </button>
        </div>
      )}
      {fileInputState === FILE_INPUT_STATE.FILE_UPLOADED && (
        <div className="photo-content-uploaded h-100">
          <div className="img-thumbnail-container">{getThumbnails()}</div>
          <div className="file-description-container">
            <div className="file-description">
              <div className="name">{get(files, '[0].name', '')}</div>
              <div className="size">
                {formatBytes(get(files, '[0].size', 0))}
              </div>
            </div>
            <button
              type="button"
              className="btn btn-primary button action box-shadow"
            >
              <FontAwesomeIcon icon="undo" className="icon" />
              {t('BUTTON.CHANGE_PHOTO')}
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default memo(MerchantPhotoUpload)
