import { useTranslation } from '@/hooks'
import moment, { Moment } from 'moment'
import React, { useCallback, useEffect, useState } from 'react'

import TimeInput from '../timeInput'
import { dateFormatForView } from '.'

export interface IOverlayComponentProps {
  timeInputComponent: any
  selectedDay: Date
  input: HTMLInputElement
  confirm: (value: Moment) => void
  closeOverlay: () => void
  classNames: any
}

export const OverlayComponent: React.FC<IOverlayComponentProps> = ({
  timeInputComponent,
  classNames,
  selectedDay,
  children,
  confirm,
  closeOverlay,
  input,
  ...props
}) => {
  const [time, setTime] = useState(
    moment(input.value, dateFormatForView).format('HH:mm')
  )
  const handleConfirm = () => {
    // sends changes to parent component
    confirm(moment(input.value, dateFormatForView))
    closeOverlay()
  }

  const checkShouldCloseOverlay = () => closeOverlay()

  const handleTimeChange = useCallback((time: string) => {
    const newDateTimeValue = moment(selectedDay)
    newDateTimeValue.hour(Number(time.substr(0, 2)))
    newDateTimeValue.minute(Number(time.substr(3, 5)))

    input.value = newDateTimeValue.format(dateFormatForView)
  }, [])

  const [t] = useTranslation(['SET_FINAL_STATE'])

  return (
    <div className={classNames.overlayWrapper} {...props}>
      <div className="backdrop" onClick={checkShouldCloseOverlay} />
      <div className={classNames.overlay}>
        {children}
        <div className="bottom-section-wrapper">
          <div className="time-inputs">
            <TimeInput onChange={handleTimeChange} value={time} />
          </div>
          <button
            className="btn button success box-shadow"
            onClick={handleConfirm}
            disabled={!input.value}
          >
            {t('CONFIRM')}
          </button>
        </div>
      </div>
    </div>
  )
}
