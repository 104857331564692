import { Subco } from 'Trunkrs-SDK/dist/models'
import {
  ICollectionTourEstimationResponseRow,
  IPublishTourValidations,
  ISingleCollectionTourEstimation,
  IStopPolylineRow,
} from 'Trunkrs-SDK/dist/models/spatial/Collections/CollectionTour'

export interface IColoredCollectionTour
  extends ICollectionTourEstimationResponseRow {
  color: string
}

export enum PublishType {
  Publish,
  Optimise,
}

export interface ICollectionTourState {
  fetchAvailableTags: () => Promise<string[]>
  fetchingAvailableTags: boolean
  subcontractors: Subco[] | null
  availableTags: string[]
  setActiveTag: (data: string) => void
  activeTag: string
  fetchingCollectionTours: boolean
  fetchCollectionTours: (
    tag: string
  ) => Promise<IColoredCollectionTour[] | null>
  errorFetchingTours: boolean
  publishValidations: IPublishTourValidations | null
  activeTagTours: IColoredCollectionTour[] | null
  fetchingCollectionTourView: boolean
  fetchCollectionTourView: (
    tourId: number
  ) => Promise<ISingleCollectionTourEstimation | null>
  activeCollectionTourView: ISingleCollectionTourEstimation | null
  publishNewTours: () => Promise<IPublishTourValidations | null>
  optimiseTours: () => Promise<void>
  publishingNewTours: boolean
  publishType?: PublishType
  setPublishingNewTours: (isPublishing: boolean) => void
  getOptimizationRemainingTime: () => number
}

export const initialState: ICollectionTourState = {
  fetchAvailableTags: () => Promise.resolve(['']),
  fetchingAvailableTags: false,
  subcontractors: null,
  availableTags: [],
  setActiveTag: () => {},
  activeTag: '',
  fetchCollectionTours: () => Promise.resolve(null),
  fetchingCollectionTours: false,
  errorFetchingTours: false,
  publishValidations: null,
  activeTagTours: null,
  fetchingCollectionTourView: false,
  fetchCollectionTourView: () => Promise.resolve(null),
  activeCollectionTourView: null,
  publishNewTours: () => Promise.resolve(null),
  optimiseTours: () => Promise.reject(new Error('Not implemented')),
  publishingNewTours: false,
  setPublishingNewTours: () => false,
  getOptimizationRemainingTime: () => 0,
}
