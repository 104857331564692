import clsx from 'clsx'
import React, { ReactNode, memo, useEffect, useState } from 'react'

import styles from './styles.module.scss'

enum CHECKBOX_TYPES {
  DEFAULT = 'default',
  BAR = 'bar',
}

export interface ICheckboxProps {
  checked?: boolean
  children?: ReactNode
  className?: string
  onChange?: (isChecked: boolean) => void
  variant?: CHECKBOX_TYPES | string
}

const Checkbox = ({
  children,
  onChange,
  className,
  checked,
  variant = CHECKBOX_TYPES.DEFAULT,
}: ICheckboxProps) => {
  const [isChecked, setIsChecked] = useState(checked)

  useEffect(() => {
    setIsChecked(checked)
  }, [checked, variant])

  const toggleCheck = () => {
    const nextValue = !isChecked
    setIsChecked(nextValue)
    if (typeof onChange === 'function') {
      onChange(nextValue)
    }
  }

  return (
    <span
      className={clsx(styles.checkboxContainer, className)}
      onClick={toggleCheck}
    >
      {children}
      <input type="checkbox" checked={isChecked} />
      <span
        className={clsx(styles.checkmark, variant === 'bar' && styles.checkBar)}
      />
    </span>
  )
}

export default memo(Checkbox)
