import clsx from 'clsx'
import React from 'react'

import styles from './styles.module.scss'

export interface SelectProps
  extends React.SelectHTMLAttributes<HTMLSelectElement> {
  classes?: {
    root?: string
    select?: string
  }
}

export const Select: React.FC<SelectProps> = ({
  children,
  disabled,
  className,
  classes = {
    root: '',
    select: '',
  },
  ...rest
}) => {
  return (
    <div className={clsx(styles.root, className, classes.root)}>
      <select
        disabled={disabled}
        className={clsx(styles.select, classes.select)}
        {...rest}
      >
        {children}
      </select>
    </div>
  )
}

export default Select
