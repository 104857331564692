import warningIcon from '@/app/base/img/warning-round.svg'
import { FREEZER_STATE } from '@/constants'
import { useTranslation } from '@/hooks'
import clsx from 'clsx'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { IFreezerLog } from 'Trunkrs-SDK/dist/models/delivery/Shipment'

interface IAccumulatedOutOfFreezerProps {
  time: number
  maxTime: number
  lastLogStatus?: IFreezerLog
}

const AccumulatedOutOfFreezer: React.FunctionComponent<
  IAccumulatedOutOfFreezerProps
> = ({ time, maxTime, lastLogStatus }) => {
  const [elapsed, setElapsed] = useState(0)
  const [expired, setExpired] = useState(false)

  const [t] = useTranslation(['FREEZER_LOGS'])

  const getFormattedTime = useCallback(
    (time: number) =>
      `${moment.duration(time).days() * 24 +
        moment.duration(time).hours()}h ${moment.duration(time).minutes()}m`,
    []
  )

  useEffect(() => {
    if (lastLogStatus && lastLogStatus.logState === FREEZER_STATE.OUT) {
      const timer = setInterval(() => {
        const newElapsed = moment()
          .utc()
          .add(time, 'milliseconds')
          .diff(
            moment(lastLogStatus.createdAt)
              .utc()
              .subtract(1, 'minute')
          )

        setExpired(newElapsed > maxTime)
        setElapsed(newElapsed)
      }, 1000)

      return () => {
        clearInterval(timer)
      }
    } else {
      setExpired(time > maxTime)
      setElapsed(time)
    }
  }, [time, lastLogStatus, maxTime])

  return (
    <div className="box-shadow daily-card">
      <div className="wrapper out-of-freezer">
        <div className="header out-of-freezer">
          <div className="max-time">
            <div className="label">{t('MAX_ALLOWED_TIME_OUT_OF_FREEZER')}</div>
            <div className="time">{getFormattedTime(maxTime)}</div>
          </div>
          <div className="accumulated-time">
            <div className="label">{t('ACCUMULATED_OUT_OF_FREEZER')}</div>
            <div className={clsx('time', expired && 'expired')}>
              {!!expired && <img alt={t('EXPIRED')} src={warningIcon} />}
              {getFormattedTime(elapsed)}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccumulatedOutOfFreezer
