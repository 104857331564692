import './style.scss'

import { Modal } from '@/app/base/components'
import * as React from 'react'
import { useEffect, useState } from 'react'

type statusType = 'success' | 'error' | 'info'

export const AlertModal = ({
  title,
  show,
  type,
  className,
  handleClose,
  children,
}: {
  children: React.ReactNode
  title: string
  show: boolean
  className?: string
  type: statusType
  handleClose?: (ev?: React.SyntheticEvent<any>) => void
}) => {
  const [isVisible, setIsVisible] = useState(show)

  useEffect(() => {
    setIsVisible(show)
  }, [show])

  const closeModal = (ev: React.MouseEvent<HTMLButtonElement>) => {
    setIsVisible(false)
    if (typeof handleClose === 'function') {
      handleClose(ev)
    }
  }

  return (
    <Modal className={className} show={isVisible}>
      <div className="modal-form modal-shadow">
        <div className="title-bar">
          <h3 className={`title ${type}`}>{title}</h3>
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={closeModal}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="text content">{children}</div>
      </div>
    </Modal>
  )
}
