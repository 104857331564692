import { SHIPMENT_STATE } from '@/constants'
import { ReasonCode } from 'Trunkrs-SDK/dist/models'
import { SHIPMENT_STATES } from 'Trunkrs-SDK/dist/models/delivery/ShipmentLog'

export interface IRecoveryCollation {
  tour_id: number
  eta: string
  collation_id: number
  tour_date: string
  shipment_id: number
  position: number
  driver_id: number
  state?: SHIPMENT_STATES
  address?: string
  postalCode?: string
  city?: string
  selectedTime?: string
  selectedShipmentState?: SHIPMENT_STATE
  selectedReasonCode?: ReasonCode
}

export interface Row {
  original: IRecoveryCollation
  modified?: IRecoveryCollation
  isSelected?: boolean
}

export interface IRouteParams {
  tourId: number
}

export interface IDriver {
  driver_id: number
  longitude: number
  latitude: number
  altitude: number
  is_moving: boolean
  speed: number
  recorded_at: string
  id: number
  name: string
  emailAddress: string
  phoneNumber: string
  picture: string
  subcontractorId: number
  active: boolean
}

export enum TOUR_RECOVERY_ACTIONS {
  SET_INITIAL_STATE = 'setInitialState',
  TOGGLE_ROW = 'toggleRow',
  TOGGLE_ALL_ROWS = 'toggleAllRows',
  APPLY_PREVIEW = 'applyPreview',
  MODIFY_ROW = 'modifyRow',
  RESET_ROW = 'resetRow',
  APPLY_TO_ROWS_BELOW = 'applyToRowsBelow',
  CLEAR_SELECTION = 'clearSelection',
  SET_ROW_TIME = 'setRowTime',
  SET_ROW_STATE = 'setRowState',
  SET_ROW_REASON_CODE = 'setRowReasonCode',
}

export type Action =
  | {
      type: TOUR_RECOVERY_ACTIONS.SET_INITIAL_STATE
      payload: {
        collations: IRecoveryCollation[]
        driver: IDriver
        reasonCodes: ReasonCode[]
      }
    }
  | {
      type: TOUR_RECOVERY_ACTIONS.TOGGLE_ROW
      rowIndex: number
    }
  | {
      type: TOUR_RECOVERY_ACTIONS.TOGGLE_ALL_ROWS
    }
  | {
      type: TOUR_RECOVERY_ACTIONS.APPLY_PREVIEW
      payload: {
        selectedTime?: string
        selectedShipmentState?: SHIPMENT_STATE
        selectedReasonCode?: ReasonCode
      }
    }
  | {
      type: TOUR_RECOVERY_ACTIONS.MODIFY_ROW
      payload: {
        rowIndex: number
        selectedTime?: string
        selectedShipmentState?: SHIPMENT_STATE
        selectedReasonCode?: ReasonCode
      }
    }
  | {
      type: TOUR_RECOVERY_ACTIONS.RESET_ROW
      rowIndex: number
    }
  | {
      type: TOUR_RECOVERY_ACTIONS.APPLY_TO_ROWS_BELOW
      payload: {
        rowIndex: number
        selectedTime?: string
        selectedShipmentState?: SHIPMENT_STATE
        selectedReasonCode?: ReasonCode
      }
    }
  | {
      type: TOUR_RECOVERY_ACTIONS.CLEAR_SELECTION
    }
  | {
      type: TOUR_RECOVERY_ACTIONS.SET_ROW_REASON_CODE
      payload: {
        rowIndex: number
        reasonCode: ReasonCode
      }
    }
  | {
      type: TOUR_RECOVERY_ACTIONS.SET_ROW_TIME
      payload: {
        rowIndex: number
        time: string
      }
    }
  | {
      type: TOUR_RECOVERY_ACTIONS.SET_ROW_STATE
      payload: {
        rowIndex: number
        state: SHIPMENT_STATE
      }
    }
