import API from '@/api'
import { useResponseErrorHandler } from '@/hooks'
import { ICollation, IFetchCollationData, IShipmentState } from '@/models'
import { arrayReplace } from '@/utils'
import uniq from 'lodash/uniq'
import moment from 'moment'
import { useCallback, useState } from 'react'

import { ICollationState } from './state'

export const useCollationActions = (
  initialState: ICollationState
): ICollationState => {
  const [collations, setCollations] = useState(initialState.collations)
  const [lastFetchedShipmentStates, setLastFetchedShipmentStates] = useState(
    initialState.lastFetchedShipmentStates
  )
  const [shipmentIds, setShipmentIds] = useState<number[]>([])

  const addCollation = useCallback((collation: ICollation) => {
    setCollations(currentCollations => [...currentCollations, collation])
    setShipmentIds(currentShipmentIds =>
      uniq([...currentShipmentIds, collation.shipmentId])
    )
  }, [])

  const updateCollation = useCallback((collation: ICollation) => {
    setCollations(currentCollations => {
      const currentCollation = currentCollations.find(
        ({ id }) => id === collation.id
      )
      return arrayReplace(
        currentCollations,
        { id: collation.id },
        {
          ...currentCollation,
          ...collation,
        }
      )
    })
  }, [])

  const fetchShipmentStates = async () => {
    const lastUpdatedString = lastFetchedShipmentStates
      ? lastFetchedShipmentStates.format('YYYY-MM-DD HH:mm:ss')
      : undefined

    const [err, result] = await useResponseErrorHandler(
      API.Shipment().fetchShipmentStates(shipmentIds, lastUpdatedString)
    )

    if (!err) {
      setLastFetchedShipmentStates(moment())
      result.map((shipmentState: IShipmentState) => {
        const collation = collations.find(
          (collation: ICollation) =>
            collation.shipmentId === shipmentState.shipmentId
        )
        if (collation) {
          updateCollation({
            ...collation,
            state: shipmentState.shipmentStateName,
          })
        }
      })
    }
  }

  const mapCollations = (
    newCollations: Array<IFetchCollationData>
  ): Array<ICollation> =>
    newCollations.map(collation => ({
      driverId: collation.driverId,
      eta: collation.eta,
      id: collation.collationId,
      position: collation.position,
      shipmentId: collation.shipmentId,
      tourDate: collation.tourDate,
      tourId: collation.tourId,
      polyline: collation.polyline,
      recipient: {
        name: collation.recipientName,
        phoneNumber: collation.recipientPhoneNumber,
        address: collation.address,
        postalCode: collation.postalCode,
        country: collation.country,
        city: collation.city,
        latitude: collation.latitude,
        longitude: collation.longitude,
      },
      merchant: {
        name: collation.merchantName,
      },
    }))

  return {
    collations,
    addCollation,
    updateCollation,
    mapCollations,
    fetchShipmentStates,
    lastFetchedShipmentStates,
  }
}
