import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import { isEmpty, get } from 'lodash'

import { ModalForm } from '@/app/base/components'

import './style.scss'

import { classList, eventHandler } from '@/utils'
import { AppContext, CommentsContext, ShipmentsContext } from '@/contexts'
import { useTranslation } from '@/hooks'
import { ReasonCode, SHIPMENT_STATES } from '@/models'

export const CancelShipment = ({
  handleClose,
}: {
  handleClose?: (ev?: React.SyntheticEvent<any>) => void
}) => {
  const {
    activeShipment: shipment,
    cancelShipment,
    getReasonCodesByShipmentState,
  } = useContext(ShipmentsContext)
  const { addComment } = useContext(CommentsContext)
  const { setLoading } = useContext(AppContext)
  const [reasonCodes, setReasonCodes] = useState<Array<ReasonCode>>([])
  const [selectedReason, setSelectedReason] = useState('')
  const [comment, setComment] = useState('')

  const handleCancel = async (ev?: React.SyntheticEvent<any>) => {
    if (shipment) {
      setLoading(true)
      const result = await cancelShipment(selectedReason)
      if (result) {
        if (!isEmpty(comment)) {
          await addComment(comment)
        }
      }
      setLoading(false)
      if (typeof handleClose === 'function') {
        handleClose(ev)
      }
    }
  }

  useEffect(() => {
    setLoading(true)
    getReasonCodesByShipmentState(
      SHIPMENT_STATES.EXCEPTION_SHIPMENT_CANCELLED_BY_TRUNKRS
    )
      .then(setReasonCodes)
      .finally(() => setLoading(false))
  }, [])

  const [t] = useTranslation(['CANCEL_SHIPMENT', 'COMMON', 'REASON_CODES'])

  return (
    <ModalForm
      show={true}
      title={t('CANCEL_SHIPMENT')}
      handleClose={handleClose}
    >
      <div id="cancel-shipment">
        <div className="reason">
          <p className="text bold">{t('REASON')}</p>
          <select
            value={selectedReason}
            className={classList('custom-select text', {
              gray: isEmpty(selectedReason),
            })}
            onChange={eventHandler(setSelectedReason)}
          >
            <option value="" hidden={true}>
              {t('CHOOSE_A_REASON')}
            </option>
            {reasonCodes.map((reasonCode: ReasonCode) => {
              const code = get(reasonCode, 'code', '')
              return <option value={code}>{t(`REASON_CODES:${code}`)}</option>
            })}
          </select>
        </div>
        <div className="comment mt-4">
          <p className="text bold">{t('ADD_COMMENT')}</p>
          <textarea
            className="form-control text"
            rows={5}
            placeholder={t('ADD_COMMENT_PLACEHOLDER')}
            value={comment}
            onChange={eventHandler(setComment)}
          />
        </div>
        <div className="actions">
          <button
            className="btn button inactive icon"
            type="button"
            onClick={handleClose}
          >
            {t('COMMON:BUTTON.NEVERMIND')}
          </button>
          <button
            className="btn button danger box-shadow"
            type="button"
            onClick={handleCancel}
            disabled={isEmpty(selectedReason)}
          >
            {t('BUTTON.CANCEL_SHIPMENT', { keySeparator: false } as any)}
          </button>
        </div>
      </div>
    </ModalForm>
  )
}
