import Icon from '@/app/base/components/.v2/Icon'
import clsx from 'clsx'
import React, { memo } from 'react'

import styles from './styles.module.scss'

export const ModalHeader = memo(
  ({ children, className, ...props }: React.HTMLAttributes<any>) => (
    <div className={clsx(styles.modalHeader, className)} {...props}>
      {children}
    </div>
  )
)
export const ModalBody = memo(
  ({ children, className, ...props }: React.HTMLAttributes<any>) => (
    <div className={clsx(styles.modalBody, className)} {...props}>
      {children}
    </div>
  )
)
export const ModalFooter = memo(
  ({ children, className, ...props }: React.HTMLAttributes<any>) => (
    <div className={clsx(styles.modalFooter, className)} {...props}>
      {children}
    </div>
  )
)

const ModalBox = ({
  visible,
  onClose,
  withCloseButton,
  children,
  className,
  ...props
}: React.HTMLAttributes<any> & {
  visible?: boolean
  withCloseButton?: boolean
  onClose?: () => void
}) => {
  return (
    <div
      className={clsx(styles.modalBox, className, !visible && styles.hidden)}
      {...props}
    >
      <div className={styles.modalContent}>
        {withCloseButton && (
          <span className="close-button" onClick={onClose}>
            <Icon name="close" />
          </span>
        )}
        <div className="content">{children}</div>
      </div>
    </div>
  )
}

export default memo(ModalBox)
