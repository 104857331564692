import clsx from 'clsx'
import addDays from 'date-fns/addDays'
import format from 'date-fns/format'
import startOfWeek from 'date-fns/startOfWeek'
import React, { useMemo } from 'react'

import styles from './styles.module.scss'

export interface WeekdaysProps {
  showWeekends?: boolean
}

export const Weekdays: React.FC<WeekdaysProps> = ({ showWeekends = true }) => {
  const firstDOW = startOfWeek(new Date())
  const shortWeekDaysArray = useMemo(
    () =>
      Array.from(Array(7)).map((_e, i) =>
        format(addDays(firstDOW, i), 'EEEEEE')
      ),
    [firstDOW]
  )

  return (
    <div className={clsx(styles.weekdays, showWeekends && styles.showWeekends)}>
      {shortWeekDaysArray.map(weekdayName => (
        <span className={styles.weekdayName} key={weekdayName}>
          {weekdayName}
        </span>
      ))}
    </div>
  )
}

export default React.memo(Weekdays)
