import { IRegionShipmentReturn } from '@/models'

export interface IShipmentReturnsState {
  shipmentReturns: IRegionShipmentReturn[]
  getShipmentReturns: (region?: string, subcoId?: number) => Promise<void>
  submitReturnedShipment: (
    barcode: string
  ) => Promise<{
    data: { trunkrsNr: string; region: string }
    error: { message: string; code: number }
  } | null>
  printShipmentReturns: (region?: string, subcoId?: number) => Promise<any>
}

export const initialState: IShipmentReturnsState = {
  shipmentReturns: [],
  getShipmentReturns: () => Promise.resolve(),
  submitReturnedShipment: () => Promise.resolve(null),
  printShipmentReturns: () => Promise.resolve(),
}
