import './styles.scss'

import Checkbox from '@/app/base/components/.v2/Checkbox'
import Icon from '@/app/base/components/.v2/Icon'
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  TableRowHeader,
} from '@/app/base/components/.v2/Table'
import { TabMenuItem, VerticalTabMenu } from '@/app/base/components/.v2/TabMenu'
import Tooltip from '@/app/base/components/.v2/Tooltip'
import StatusLabel from '@/app/base/components/statusLabel'
import styles from '@/app/features/shipments/components/shipmentsTable/styles.module.scss'
import { AppContext, InventoryContext, RouterContext } from '@/contexts'
import { useTranslation } from '@/hooks'
import { IInventory } from '@/models'
import { clsx } from '@/utils'
import debounce from 'lodash/debounce'
import get from 'lodash/get'
import unionBy from 'lodash/unionBy'
import moment from 'moment'
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

const Loader = memo(() => {
  return (
    <div className={styles.loader}>
      <Icon name="spinner" /> Loading more results
    </div>
  )
})

const EmptyResult = memo(() => {
  const [t] = useTranslation('MORNING_SCAN_REPORT')

  return (
    <div className="empty-result-container">
      <Icon name="no-result" />
      <div className="header">{t('NO_REPORTS_FOUND')}</div>
      <div className="sub-details">{t('NO_REPORTS_FOUND_DETAILS')}</div>
    </div>
  )
})

const MorningScanReport = memo(() => {
  const { setLoading } = useContext(AppContext)
  const { history } = useContext(RouterContext)
  const { fetchInventory, fetchAvailableRegions, regions } = useContext(
    InventoryContext
  )

  const [t] = useTranslation(['MORNING_SCAN_REPORT', 'SHIPMENT_STATES'])

  const [loadedInventory, setLoadedInventory] = useState<Array<IInventory>>([])
  const [selectedRegion, setSelectedRegion] = useState<{
    tag: string
    name: string
  }>()

  const [currentPage, setCurrentPage] = useState(1)
  const [isPristine, setIsPristine] = useState(true)
  const [isScrolling, setIsScrolling] = useState(false)
  const [isEndOfResults, setIsEndOfResults] = useState(false)

  useEffect(() => {
    setLoading(true)
    fetchAvailableRegions()
      .then(regions => {
        const defaultRegion = get(regions, '[0]')
        setSelectedRegion(defaultRegion)
        return Promise.resolve(fetchInventory(defaultRegion.tag))
      })
      .then(nextInventory => {
        if (nextInventory.length < 25) {
          setIsEndOfResults(true)
        }
        setLoadedInventory(nextInventory)
      })
      .finally(() => setLoading(false))
  }, [])

  const getMorningScanReport = useCallback(
    async (region: string, pageNum: number = 1) => {
      const nextInventory = await fetchInventory(region, pageNum)
      const combinedInventory = loadedInventory.concat(...nextInventory)
      if (nextInventory.length < 25) {
        setIsEndOfResults(true)
      }
      setLoadedInventory(combinedInventory)
    },
    [loadedInventory]
  )

  const handleSelectRegion = (region: { tag: string; name: string }) => () => {
    if (region != selectedRegion) {
      setLoading(true)
      setCurrentPage(1)
      setIsEndOfResults(false)
      setLoadedInventory([])
      setSelectedRegion(region)
      getMorningScanReport(region.tag).finally(() => setLoading(false))
    }
  }

  const paginate = async () => {
    if (isEndOfResults) return
    const nextPage = currentPage + 1
    selectedRegion && getMorningScanReport(selectedRegion.tag, nextPage)
    setCurrentPage(nextPage)
  }

  const handleScroll = useCallback(
    (ev: any) => {
      const target = ev.target

      if (target.scrollHeight - target.scrollTop === target.clientHeight) {
        paginate()
      }
      setIsPristine(false)
      setIsScrolling(true)
      activateScrollEnd()
    },
    [paginate]
  )

  const activateScrollEnd = useMemo(
    () => debounce(() => setIsScrolling(false), 500),
    []
  )

  const redirectToShipmentPage = (shipmentId: number) => () =>
    history.push(`/shipments/${shipmentId}`)

  return (
    <div id="morning-scan-report" className="container-fluid">
      <h1 className="h2 text-left title bold">{t('MORNING_SCAN_REPORT')}</h1>
      <div className="wrapper">
        <div className="row h-100">
          <div className="h-100">
            <VerticalTabMenu
              className={clsx('vertical-tab-menu', !regions.length && 'hidden')}
            >
              {useMemo(
                () =>
                  regions.map(region => (
                    <TabMenuItem onClick={handleSelectRegion(region)}>
                      {region.tag}
                    </TabMenuItem>
                  )),
                [regions, selectedRegion]
              )}
            </VerticalTabMenu>
          </div>
          <div className="col">
            <div className="region-header">
              {selectedRegion &&
                `${selectedRegion.tag} | ${selectedRegion.name}`}
            </div>
            <div className="table-container">
              <Table>
                {!!loadedInventory.length && (
                  <TableRowHeader className={clsx(isScrolling && 'scrolling')}>
                    <TableHeader>{t('TABLE_HEADERS.SHIPMENT_ID')}</TableHeader>
                    <TableHeader>{t('TABLE_HEADERS.TRUNKRS_NR')}</TableHeader>
                    <TableHeader>{t('TABLE_HEADERS.MERCHANT')}</TableHeader>
                    <TableHeader>{t('TABLE_HEADERS.SCAN_STATUS')}</TableHeader>
                    <TableHeader>{t('TABLE_HEADERS.DATE_TIME')}</TableHeader>
                    <TableHeader>
                      {t('TABLE_HEADERS.SHIPMENT_STATE')}
                    </TableHeader>
                    <TableHeader>{t('TABLE_HEADERS.REASON')}</TableHeader>
                    <TableHeader>{t('TABLE_HEADERS.OTHER_REASON')}</TableHeader>
                  </TableRowHeader>
                )}
                <TableBody
                  onScroll={handleScroll}
                  className={clsx(!isPristine && !isEndOfResults && 'expanded')}
                >
                  {useMemo(
                    () =>
                      loadedInventory.map(inventory => (
                        <TableRow>
                          <TableCell>
                            {get(inventory, 'shipmentId', '-')}
                          </TableCell>
                          <TableCell>
                            {get(inventory, 'trunkrsNr', '-')}
                          </TableCell>
                          <TableCell>
                            {get(inventory, 'merchantName', '-')}
                          </TableCell>
                          <TableCell>
                            {get(inventory, 'inventoryStatus')
                              ? t(
                                  `SCAN_STATUS.${get(
                                    inventory,
                                    'inventoryStatus'
                                  )}`
                                )
                              : '-'}
                          </TableCell>
                          <TableCell>
                            {get(inventory, 'scannedAt')
                              ? moment(get(inventory, 'scannedAt')).format(
                                  'DD-MM-YYYY | HH:mm'
                                )
                              : '-'}
                          </TableCell>
                          <TableCell>
                            {get(inventory, 'shipmentState')
                              ? t(
                                  `SHIPMENT_STATES:${get(
                                    inventory,
                                    'shipmentState'
                                  )}`
                                )
                              : '-'}
                          </TableCell>
                          <TableCell>
                            {get(inventory, 'notScannableReason')
                              ? t(
                                  `REASON_CODES.${get(
                                    inventory,
                                    'notScannableReason'
                                  )}`
                                )
                              : '-'}
                          </TableCell>
                          <TableCell>
                            {!!get(inventory, 'notScannableComment') ? (
                              get(inventory, 'notScannableComment')
                            ) : (
                              <span className="gray">{t('NOT_AVAILABLE')}</span>
                            )}
                          </TableCell>
                          <TableCell>
                            <Tooltip
                              text={t('GO_TO_SHIPMENT')}
                              placement="bottom"
                              eventTriggers={['hover']}
                            >
                              <Icon
                                name="redirect"
                                onClick={redirectToShipmentPage(
                                  get(inventory, 'shipmentId')
                                )}
                              />
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      )),
                    [loadedInventory]
                  )}
                  {!isEndOfResults && !!loadedInventory.length && <Loader />}
                </TableBody>
                {!!loadedInventory.length ? (
                  <>
                    {isPristine && !isEndOfResults && (
                      <div className="table-footer">
                        <span>
                          <Icon name="mouse" /> {t('SCROLL_FOR_MORE_RESULTS')}
                        </span>
                      </div>
                    )}
                    {isEndOfResults && (
                      <div className="table-footer">
                        <span>End of results</span>
                      </div>
                    )}
                  </>
                ) : (
                  <EmptyResult />
                )}
              </Table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})

export default MorningScanReport
