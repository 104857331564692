import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import React, { memo } from 'react'

import styles from './styles.module.scss'

export default memo(
  ({
    children,
    className,
    handleClose,
  }: React.HTMLAttributes<HTMLDivElement> & { handleClose?: () => void }) => {
    return (
      <div className={clsx(styles.chip, className)}>
        {children}
        <span onClick={handleClose} className={styles['close-icon']}>
          <FontAwesomeIcon icon="times" />
        </span>
      </div>
    )
  }
)
