import { SHIPMENT_STATE } from '@/constants'
import { clsx } from '@/utils'
import { getClassForShipmentState } from '@/utils/getClassForShipmentState'
import * as React from 'react'

interface ITourStopProps {
  shipmentId: number
  shipmentClick: (shipmentId: number) => void
  eta: string
  delayed?: boolean
  shipmentState?: SHIPMENT_STATE
}

const TourStop: React.FunctionComponent<ITourStopProps> = ({
  shipmentId,
  shipmentClick,
  eta,
  delayed,
  shipmentState,
}) => {
  const handleOnClick = React.useCallback(() => shipmentClick(shipmentId), [
    shipmentId,
  ])
  const containerClassName = React.useMemo(
    () => shipmentState && getClassForShipmentState(shipmentState),
    [shipmentState]
  )

  return (
    <div
      className={clsx(['stop', delayed ? 'delayed' : containerClassName])}
      onClick={handleOnClick}
    >
      <div className="overlay">
        <h1>ETA: {eta}</h1>
      </div>
    </div>
  )
}

export default TourStop
