import Select from '@/app/base/components/select'
import { SHIPMENT_STATES } from '@/models'
import React from 'react'

import classes from './styles.module.scss'

export interface ShipmentStateSelectProps {
  onChange: (e: any) => void
  value: string
  disabled?: boolean
}

export const ShipmentStateSelect: React.FC<ShipmentStateSelectProps> = ({
  onChange,
  value,
  disabled,
}) => {
  return (
    <Select
      className={classes.shipment_state_select}
      onChange={onChange}
      value={value}
      disabled={disabled}
    >
      <option value="none">Select state</option>
      <option value={SHIPMENT_STATES.SHIPMENT_DELIVERED}>Delivered</option>
      <option value={SHIPMENT_STATES.SHIPMENT_NOT_DELIVERED}>
        Not Delivered
      </option>
    </Select>
  )
}

export default ShipmentStateSelect
