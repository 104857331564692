import ItemsNavigationWithCount from '@/app/base/components/itemsNavigationWithCount'
import Legends from '@/app/base/components/legends'
import LoadingBars from '@/app/base/components/loadingBars'
import launcher from '@/app/base/img/launcher.png'
import { LinehaulTrackingContext } from '@/contexts'
import { useTranslation } from '@/hooks'
import { seeInMapWithOriginDestination } from '@/utils/seeInMap'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import clsx from 'clsx'
import { isEmpty } from 'lodash'
import React, { useContext, useEffect, useMemo, useState } from 'react'

import LinehaulItem from '../linehaulItem'
import classes from './style.module.scss'

const MainContent: React.FC = () => {
  const [t] = useTranslation('LINEHAUL_TRACKING')

  const {
    availableTags,
    activeTag,
    setActiveTag,
    activeTagTours,
    driverLocation,
    fetchingDriverLocation,
    fetchingLinehauls,
    fetchLinehauls,
    fetchDriverLocation,
  } = useContext(LinehaulTrackingContext)

  const [isFetching, setIsFetching] = useState(false)

  useEffect(() => {
    fetchLinehauls(activeTag)
  }, [activeTag])

  useEffect(() => {
    if (fetchingDriverLocation) {
      setIsFetching(true)
    }
  }, [fetchingDriverLocation])

  useEffect(() => {
    if (!isEmpty(driverLocation)) {
      const location = seeInMapWithOriginDestination(driverLocation)
      window.open(location, '_blank')
      setIsFetching(false)
    }
  }, [driverLocation])

  const getDriverLocation = (tourId: number) => {
    fetchDriverLocation(tourId)
  }

  const linehaulItems = useMemo(
    () =>
      activeTagTours &&
      activeTagTours.map(linehaul => {
        return (
          <LinehaulItem
            onItemClick={() => getDriverLocation(linehaul.tourId)}
            key={linehaul.tourId}
            linehaul={linehaul}
          />
        )
      }),
    [activeTagTours]
  )

  const handleSelectTag = (tag: string) => {
    setActiveTag(tag)
  }

  return (
    <div className={classes.main_content}>
      <h1 className={clsx('h2 mt-4 text-left title', classes.main_title)}>
        {t('LINEHAUL_TRACKING')}
      </h1>
      <div className={clsx(classes.navs_legends)}>
        <ItemsNavigationWithCount
          items={availableTags}
          activeItem={activeTag}
          onSelectItem={handleSelectTag}
        />
        <Legends />
      </div>
      {fetchingLinehauls ? (
        <LoadingBars numberOfBar={5} barHeight={62} />
      ) : (
        <table className={clsx('table', classes.linehaul_table)}>
          <tbody>
            <tr className={classes.table_header_row}>
              <th>{t('TABLE.DRIVER')}</th>
              <th>{t('TABLE.DEPARTURE_TIME')}</th>
              <th>{t('TABLE.PLANNED_ETA')}</th>
              <th>{t('TABLE.ACTUAL_ETA')}</th>
              <th>{t('TABLE.ARRIVAL_TIME')}</th>
              <th>{t('TABLE.STATUS')}</th>
              <th className={classes.thlocation}>{t('TABLE.GET_LOCATION')}</th>
            </tr>
            {activeTagTours && activeTagTours.length ? (
              linehaulItems
            ) : (
              <tr className={classes.noTours}>
                <td colSpan={7}>{t('TABLE.NO_LINEHAULS_TODAY')}</td>
              </tr>
            )}
          </tbody>
        </table>
      )}
      <Backdrop open={isFetching} className={classes.back_drop}>
        <CircularProgress size={72} color="inherit" />
        <img src={launcher} className={classes.location_progress} />
        <div className={classes.text}>{t('LOCATING')}</div>
      </Backdrop>
    </div>
  )
}

export default MainContent
