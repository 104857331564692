import { ReactComponent as ArrowLeftIcon } from '../../../img/.lib/arrow-left-icon.svg'
import { ReactComponent as CalendarIcon } from '../../../img/.lib/calendar-icon.svg'
import { ReactComponent as CaretDownIcon } from '../../../img/.lib/caret-down-icon.svg'
import { ReactComponent as CheckCircleIcon } from '../../../img/.lib/check-circle-icon.svg'
import { ReactComponent as ChevronDownIcon } from '../../../img/.lib/chevron-down-icon.svg'
import { ReactComponent as ClockIcon } from '../../../img/.lib/clock-icon.svg'
import { ReactComponent as CloseIcon } from '../../../img/.lib/close-icon.svg'
import { ReactComponent as CopyIcon } from '../../../img/.lib/copy-icon.svg'
import { ReactComponent as DangerIcon } from '../../../img/.lib/danger-icon.svg'
import { ReactComponent as EditIcon } from '../../../img/.lib/edit-icon.svg'
import { ReactComponent as HouseIcon } from '../../../img/.lib/house-icon.svg'
import { ReactComponent as InfoCircleIcon } from '../../../img/.lib/info-circle-icon.svg'
import { ReactComponent as MouseIcon } from '../../../img/.lib/mouse-icon.svg'
import { ReactComponent as NoResultIcon } from '../../../img/.lib/no-result-icon.svg'
import { ReactComponent as OutgoingIcon } from '../../../img/.lib/outgoing-icon.svg'
import { ReactComponent as PencilEditIcon } from '../../../img/.lib/pencil-edit-icon.svg'
import { ReactComponent as PhoneIcon } from '../../../img/.lib/phone-icon.svg'
import { ReactComponent as PlusIcon } from '../../../img/.lib/plus-icon.svg'
import { ReactComponent as PrintIcon } from '../../../img/.lib/print-icon.svg'
import { ReactComponent as RedirectIcon } from '../../../img/.lib/redirect-icon.svg'
import { ReactComponent as RetryIcon } from '../../../img/.lib/retry-icon.svg'
import { ReactComponent as SearchIcon } from '../../../img/.lib/search-icon.svg'
import { ReactComponent as SpinnerIcon } from '../../../img/.lib/spinner-icon.svg'
import { ReactComponent as SwapIcon } from '../../../img/.lib/swap-icon.svg'
import { ReactComponent as TrashIcon } from '../../../img/.lib/trash-icon.svg'
import { ReactComponent as WarningIcon } from '../../../img/.lib/warning-icon.svg'

export const IconLibrary = {
  plus: PlusIcon,
  edit: EditIcon,
  trash: TrashIcon,
  swap: SwapIcon,
  close: CloseIcon,
  'check-circle': CheckCircleIcon,
  'info-circle': InfoCircleIcon,
  warning: WarningIcon,
  danger: DangerIcon,
  search: SearchIcon,
  phone: PhoneIcon,
  house: HouseIcon,
  'chevron-down': ChevronDownIcon,
  retry: RetryIcon,
  mouse: MouseIcon,
  spinner: SpinnerIcon,
  'caret-down': CaretDownIcon,
  'no-result': NoResultIcon,
  calendar: CalendarIcon,
  clock: ClockIcon,
  redirect: RedirectIcon,
  pencil: PencilEditIcon,
  print: PrintIcon,
  outgoing: OutgoingIcon,
  'arrow-left': ArrowLeftIcon,
  copy: CopyIcon,
}
