import { TourRecoveryContext } from '@/contexts'
import { useTranslation } from '@/hooks'
import { IRecoveryCollation } from '@/store/tourRecovery/types'
import clsx from 'clsx'
import { defaultTo } from 'lodash'
import get from 'lodash/get'
import React, { useCallback, useContext, useMemo } from 'react'
import { SHIPMENT_STATES } from 'Trunkrs-SDK/dist/models/delivery/ShipmentLog'
import moment from 'Trunkrs-SDK/node_modules/moment'

import Checkbox from '../../../../base/components/checkBox'
import { TableCell, TableRow } from '../../../../base/components/table'
import { canBeSet } from '../../helpers'
import StateLabel from '../StateLabel'
import Controls from './Controls'
import styles from './styles.module.scss'

export interface CollationRowProps {
  isModified?: boolean
  collation: IRecoveryCollation
  selected: boolean
  rowIndex: number
}

export const CollationRow: React.FC<CollationRowProps> = ({
  collation,
  selected,
  isModified,
  rowIndex,
}) => {
  const { handleToggleRow } = useContext(TourRecoveryContext)

  const [t] = useTranslation(['SHIPMENT_STATES', 'TOUR_RECOVERY'])

  const handleCheckbox = useCallback(() => handleToggleRow(rowIndex), [
    handleToggleRow,
    rowIndex,
  ])

  const isCanBeSet = useMemo(() => canBeSet(collation), [collation])

  return (
    <TableRow
      className={clsx(
        styles.row,
        isModified && !selected && styles.modified,
        selected && styles.highlighted
      )}
    >
      <TableCell>
        {isCanBeSet && (
          <Checkbox checked={selected} onChange={handleCheckbox} />
        )}
      </TableCell>
      <TableCell>{collation.position}</TableCell>
      <TableCell>{collation.shipment_id}</TableCell>
      <TableCell>
        <div className={styles.address}>
          <div>
            <div>{`${get(collation, 'city')} ${get(
              collation,
              'postalCode'
            )}`}</div>
            <div>{get(collation, 'address')}</div>
          </div>
        </div>
      </TableCell>
      <TableCell>{moment(collation.eta).format('DD/MM')}</TableCell>
      <TableCell>{moment(collation.eta).format('HH:mm')}</TableCell>
      <TableCell>
        <StateLabel state={get(collation, 'state') as SHIPMENT_STATES} />
      </TableCell>
      <Controls
        rowIndex={rowIndex}
        isCanBeSet={isCanBeSet}
        collation={collation}
        t={t}
      />
    </TableRow>
  )
}

export default CollationRow
