import { InventoryContext } from '@/contexts'
import { useInventoryActions } from '@/store/inventory/actions'
import { initialState } from '@/store/inventory/state'
import * as React from 'react'

export default ({ children }: { children: React.ReactNode }) => {
  const state = useInventoryActions(initialState)
  return (
    <InventoryContext.Provider value={state}>
      {children}
    </InventoryContext.Provider>
  )
}
