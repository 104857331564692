import { CollectionTrackingContext } from '@/contexts'
import { useTranslation } from '@/hooks'
import { seeInMap } from '@/utils/seeInMap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Avatar } from '@material-ui/core'
import clsx from 'clsx'
import moment from 'moment'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { IDriverDetails } from 'Trunkrs-SDK/dist/models/spatial/Collections/CollectionTour'

import classes from './style.module.scss'

interface IDriverDetailsProps {
  driver: IDriverDetails
  tourId: number
}

const DriverInfo: React.FC<IDriverDetailsProps> = ({ driver, tourId }) => {
  const [subco, setSubco] = useState('')
  const [driverPicture, setDriverPicture] = useState('')
  const { activeTagTours } = useContext(CollectionTrackingContext)

  const [t] = useTranslation('COLLECTION_TRACKING')

  useEffect(() => {
    const active = activeTagTours!.find(tour => tour.tourId === tourId)
    setSubco(active!.driver.subcoName)
    setDriverPicture(active!.driver.picture)
  }, [activeTagTours, tourId])

  return (
    <div className={classes.driver_info}>
      <Avatar className={classes.user_image_default} src={driverPicture}>
        <FontAwesomeIcon icon="user-circle" className={classes.fallback} />
      </Avatar>
      <div className={classes.driver_part}>
        <span>{driver.name}</span>
        <span>{subco}</span>
        <span>{driver.phoneNumber}</span>
      </div>
      <div className={classes.driver_location}>
        <a
          className={clsx(classes.btn, 'button', 'action')}
          href={seeInMap(driver)}
          target="_blank"
          rel="noreferrer noopener"
        >
          <FontAwesomeIcon icon="crosshairs" />
          {t('SEE_LATEST_LOCATION')}
        </a>
        <span>
          {t('LAST_UPDATE')}
          {moment(driver.timeRecorded).format('HH:mm:ss DD-MM-YYYY')}
        </span>
      </div>
    </div>
  )
}

export default DriverInfo
