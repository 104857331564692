import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'

import classes from './style.module.scss'

interface IIconWithLabelProps {
  icon?: IconProp
  label: string
  iconColor?: string
  labelColor?: string
  iconSize?: SizeProp
}

const IconWithLabel: React.FC<IIconWithLabelProps> = ({
  icon,
  label,
  labelColor,
  iconColor,
  iconSize,
}) => {
  return (
    <div className={classes.icon_with_label_container}>
      {icon && (
        <FontAwesomeIcon
          size={iconSize || 'lg'}
          color={iconColor || labelColor}
          icon={icon}
        />
      )}
      <div style={{ color: labelColor }}>{label}</div>
    </div>
  )
}

export default IconWithLabel
