import { chain } from 'lodash'
import { Subco } from 'Trunkrs-SDK/dist/models'

export const extractSubcoTagsWithName = (subcos: Subco[]) =>
  chain(subcos)
    .sortBy('getTag')
    .map(subco => ({
      tag: subco.getTag,
      name: subco.getName,
    }))
    .uniq()
    .value()

export default (subcos: Subco[]) =>
  chain(subcos)
    .sortBy('getTag')
    .map(subco => subco.getTag)
    .uniq()
    .value()
