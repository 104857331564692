import Select, { SelectItem } from '@/app/base/components/.v2/Select'
import clsx from 'clsx'
import defaultTo from 'lodash/defaultTo'
import moment from 'moment'
import React, { memo, useEffect, useMemo, useState } from 'react'
import DayPicker from 'react-day-picker'

import styles from './styles.module.scss'

const Calendar = ({
  className,
  selectedDate,
  onChange,
}: {
  className?: string
  selectedDate?: Date
  onChange?: (date: any) => void
}) => {
  const currentYear = new Date().getFullYear()
  const years = [...(Array(20) as any).keys()].map(n => n + (currentYear - 10))
  const fromMonth = new Date(years[0], 0)
  const toMonth = new Date(years[years.length - 1], 11)

  const [date, setDate] = useState(defaultTo(selectedDate, new Date()))

  useEffect(() => {
    setDate(defaultTo(selectedDate, new Date()))
  }, [selectedDate])

  const YearMonthCaption = memo(({ date, localeUtils, onChange }: any) => {
    const months = localeUtils.getMonths()

    const selectedMonth = date.getMonth()
    const selectedYear = date.getFullYear()
    const handleMonthChange = (month: any) =>
      onChange(new Date(selectedYear, month))
    const handleYearChange = (year: any) =>
      onChange(new Date(year, selectedMonth))

    return (
      <div className={clsx('DayPicker-Caption', styles.caption)}>
        <div>
          <Select compact value={selectedMonth} onChange={handleMonthChange}>
            {useMemo(
              () =>
                months.map((month: string, i: number) => (
                  <SelectItem value={i}>
                    {moment()
                      .set('month', i)
                      .format('MMM')}
                  </SelectItem>
                )),
              [months]
            )}
          </Select>
          <Select compact value={selectedYear} onChange={handleYearChange}>
            {useMemo(
              () =>
                years.map(year => <SelectItem value={year}>{year}</SelectItem>),
              [years]
            )}
          </Select>
        </div>
      </div>
    )
  })

  return (
    <DayPicker
      className={clsx(styles.calendar, className)}
      selectedDays={date}
      month={date}
      fromMonth={fromMonth}
      toMonth={toMonth}
      showOutsideDays={true}
      onDayClick={onChange}
      captionElement={({ date, localeUtils }) => (
        <YearMonthCaption
          date={date}
          localeUtils={localeUtils}
          onChange={setDate}
        />
      )}
    />
  )
}

export default memo(Calendar)
