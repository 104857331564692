import { LocalizationContext } from '@/contexts'
import { TFunction, i18n } from 'i18next'
import React from 'react'

export const useTranslation = (
  ns: string | string[] = 'main'
): [TFunction, i18n] => {
  const i18n = React.useContext(LocalizationContext)
  const t = i18n.getFixedT(i18n.language, ns)

  return React.useMemo<[TFunction, i18n]>(() => [t, i18n], [t, i18n])
}
