import './style.scss'

import { clsx } from '@/utils'
import * as React from 'react'

import SmartSearch from '../../../../base/components/.v2/SmartSearch'
import styles from './styles.module.scss'

export const ShipmentSearchBar = () => {
  return (
    <div id="search-bar" className={clsx('row mt-4', styles.searchBar)}>
      <SmartSearch />
    </div>
  )
}
