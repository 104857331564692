import DropdownMenu, {
  DropdownMenuItem,
} from '@/app/base/components/.v2/DropdownMenu'
import ShipmentCancelledModal from '@/app/features/shipments/components/shipmentActions/components/ShipmentCancelledModal'
import ShipmentDeliveredModal from '@/app/features/shipments/components/shipmentActions/components/ShipmentDeliveredModal'
import ShipmentNotDeliveredModal from '@/app/features/shipments/components/shipmentActions/components/ShipmentNotDeliveredModal'
import ShipmentPostponedModal from '@/app/features/shipments/components/shipmentActions/components/ShipmentPostponedModal'
import { useTranslation } from '@/hooks'
import { clsx } from '@/utils'
import React, { memo, useCallback, useMemo, useState } from 'react'
import { SHIPMENT_STATES } from 'Trunkrs-SDK/dist/models/delivery/ShipmentLog'

import styles from './styles.module.scss'

export enum SHIPMENT_ACTIONS {
  CANCEL = 'CANCEL',
  DELIVERED = 'DELIVERED',
  NOT_DELIVERED = 'NOT_DELIVERED',
  POSTPONE = 'POSTPONE',
}

const ShipmentActions = ({
  selectedShipments = [],
  onViewResults,
  onShipmentUpdate,
}: {
  selectedShipments?: number[]
  onViewResults: (
    state: SHIPMENT_STATES,
    actualUpdatedShipments: number[]
  ) => void
  onShipmentUpdate: (
    state: SHIPMENT_STATES,
    actualUpdatedShipments: number[]
  ) => void
}) => {
  const actions = useMemo(
    () => ({
      [SHIPMENT_ACTIONS.CANCEL]: 'Cancel',
      [SHIPMENT_ACTIONS.DELIVERED]: 'Delivered',
      [SHIPMENT_ACTIONS.NOT_DELIVERED]: 'Not delivered',
      [SHIPMENT_ACTIONS.POSTPONE]: 'Postpone',
    }),
    []
  )

  const [selectedAction, setSelectedAction] = useState<SHIPMENT_ACTIONS>()

  const hideModal = useCallback(() => setSelectedAction(undefined), [])

  const [t] = useTranslation('ALL_SHIPMENTS')

  return (
    <>
      <div
        className={clsx(
          styles.shipmentActionsContainer,
          !!selectedShipments.length && styles.active
        )}
      >
        <DropdownMenu
          disabled={!selectedShipments.length}
          placeholder={t('ACTIONS')}
          onChange={setSelectedAction}
        >
          {useMemo(
            () =>
              Object.keys(actions).map((key: string) => {
                const textValue = actions[key as SHIPMENT_ACTIONS]
                return (
                  <DropdownMenuItem value={key}>{textValue}</DropdownMenuItem>
                )
              }),
            []
          )}
        </DropdownMenu>
      </div>
      <ShipmentCancelledModal
        selectedShipments={selectedShipments}
        selectedAction={selectedAction}
        onClose={hideModal}
        onShipmentUpdate={onShipmentUpdate}
        onViewResults={onViewResults}
      />
      <ShipmentDeliveredModal
        selectedShipments={selectedShipments}
        selectedAction={selectedAction}
        onClose={hideModal}
        onShipmentUpdate={onShipmentUpdate}
        onViewResults={onViewResults}
      />
      <ShipmentNotDeliveredModal
        selectedShipments={selectedShipments}
        selectedAction={selectedAction}
        onClose={hideModal}
        onShipmentUpdate={onShipmentUpdate}
        onViewResults={onViewResults}
      />
      <ShipmentPostponedModal
        selectedShipments={selectedShipments}
        selectedAction={selectedAction}
        onClose={hideModal}
        onShipmentUpdate={onShipmentUpdate}
        onViewResults={onViewResults}
      />
    </>
  )
}

export default memo(ShipmentActions)
