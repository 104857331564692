import { useHistory, useLocation, useParams } from 'react-router'

export const useRouterActions = () => {
  const history = useHistory()
  const params = useParams()
  const location = useLocation()

  return {
    history,
    params,
    location,
    getHistory: useHistory,
    getParams: useParams,
    getLocation: useLocation,
  }
}
