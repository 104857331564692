import * as yup from 'yup'

export const defaultValues = {
  merchant: {
    name: '',
    location: {
      address: '',
      city: '',
      country: 'NL',
      postalCode: '',
    },
    productCategoryId: 1,
    accountManagerId: '',
  },
  collection: {
    importantInfo: '',
    contactPerson: '',
    phoneNumber: '',
    sameAsWebshopAddress: false,
    location: {
      address: '',
      city: '',
      country: 'NL',
      postalCode: '',
    },
  },
  operations: {
    startingVolumeFrom: '',
    startingVolumeTo: '',
    forecastedVolumeFrom: '',
    forecastedVolumeTo: '',
    weeksUntilForecastedVolume: '',
    shipmentLengthCm: '',
    shipmentWidthCm: '',
    shipmentHeightCm: '',
    expectedWeight: '',
  },
  invoicing: {
    contactPerson: '',
    emailAddressInvoice: '',
    kvkNumber: '',
    btwNumber: '',
    phoneNumber: '',
    sameAsWebshopAddress: false,
    location: {
      address: '',
      city: '',
      country: 'NL',
      postalCode: '',
    },
  },
  pricing: {
    deliveryTier1: 7.5,
    deliveryTier2: 6.95,
    deliveryTier3: 6.55,
    deliveryTier4: 6.2,
    deliveryTier5: 5.9,
    deliveryTier6: 5.45,
    deliveryXlSurcharge: 4.5,
    collection: 25,
  },
  itDetails: {
    webshopType: '',
    integrationType: '',
    recipientCommunication: 'E-mail and text messages',
    contactPersonEmail: '',
    contactPerson: '',
  },
}

export default yup.object().shape({
  merchant: yup.object().shape({
    name: yup.string().required(),
    location: yup.object().shape({
      address: yup.string().required(),
      city: yup.string().required(),
      country: yup.string().required(),
      postalCode: yup.string().required(),
    }),
    productCategoryId: yup.number().required(),
    accountManagerId: yup.number().required(),
  }),
  timeSlotConfig: yup.object().shape({
    dataWindowClosesAt: yup.string().required(),
  }),
  collection: yup.object().shape({
    importantInfo: yup.string().notRequired(),
    contactPerson: yup.string().required(),
    phoneNumber: yup.string().required(),
    sameAsWebshopAddress: yup
      .bool()
      .default(false)
      .notRequired(),
    location: yup.object().shape({
      address: yup.string().required(),
      city: yup.string().required(),
      country: yup.string().required(),
      postalCode: yup.string().required(),
    }),
  }),
  operations: yup.object().shape({
    startingVolumeFrom: yup.number().required(),
    startingVolumeTo: yup.number().required(),
    forecastedVolumeFrom: yup.number().required(),
    forecastedVolumeTo: yup.number().required(),
    weeksUntilForecastedVolume: yup.number().required(),
    shipmentLengthCm: yup.number().required(),
    shipmentWidthCm: yup.number().required(),
    shipmentHeightCm: yup.number().required(),
    expectedWeight: yup.string().required(),
  }),
  invoicing: yup.object().shape({
    contactPerson: yup.string().required(),
    emailAddressInvoice: yup.string().required(),
    kvkNumber: yup.string().matches(/^\d{8}$/g),
    btwNumber: yup.string().matches(/^[a-z]{2}\d{9}[a-z]\d{2}$/gi),
    phoneNumber: yup.string().required(),
    sameAsWebshopAddress: yup
      .bool()
      .default(false)
      .notRequired(),
    location: yup.object().shape({
      address: yup.string().required(),
      city: yup.string().required(),
      country: yup.string().required(),
      postalCode: yup.string().required(),
    }),
  }),
  pricing: yup.object().shape({
    deliveryTier1: yup.number().required(),
    deliveryTier2: yup.number().required(),
    deliveryTier3: yup.number().required(),
    deliveryTier4: yup.number().required(),
    deliveryTier5: yup.number().required(),
    deliveryTier6: yup.number().required(),
    deliveryXlSurcharge: yup.number().required(),
    collection: yup.number().required(),
  }),
  itDetails: yup.object().shape({
    webshopType: yup.string().required(),
    integrationType: yup.string().required(),
    recipientCommunication: yup.string().required(),
    contactPersonEmail: yup.string().required(),
    contactPerson: yup.string().required(),
  }),
})
