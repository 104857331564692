import { useTranslation } from '@/hooks'
import clsx from 'clsx'
import { chain } from 'lodash'
import moment from 'moment'
import React, { useMemo } from 'react'
import {
  ICollectionCollationResult,
  ITourDetails,
} from 'Trunkrs-SDK/dist/models/spatial/Collections/CollectionTour'

import RemarksBox from '../remarksBox'
import StatusBox from '../statusBox'
import classes from './style.module.scss'

interface ICollectionsProps {
  collations: ICollectionCollationResult[]
  tourState: string
  tourTimeDetails: ITourDetails
}

const Collections: React.FC<ICollectionsProps> = ({
  collations,
  tourState,
  tourTimeDetails,
}) => {
  const [t] = useTranslation('COLLECTION_TRACKING')

  const collectionList = useMemo(
    () =>
      chain(collations)
        .sortBy('position')
        .map(collation => {
          let position: number | string = collation.position
          if (collation.position === 0) {
            position = `${t('START')}`
          } else if (collation.position === collations.length - 1) {
            position = `${t('END')}`
          }

          const isHub = position === t('START') || position === t('END')

          const {
            collationRemark,
            timeOfArrival,
            stop: {
              stateName,
              reasonCode,
              address,
              city,
              companyName,
              postalCode,
              shipmentsCollected,
            },
          } = collation
          let actualTimeOfArrival = collation.stop.actualTimeOfArrival

          if (isHub) {
            if (position === t('START')) {
              actualTimeOfArrival = tourTimeDetails.start.time
            } else {
              actualTimeOfArrival = tourTimeDetails.end.time
            }
          }

          return (
            <tr key={position}>
              <td>
                <div className={classes.item_container}>
                  <div className={classes.position_status}>
                    <span>{position}</span>
                    <StatusBox
                      state={stateName}
                      tourState={tourState}
                      position={position}
                    />
                    {reasonCode && (
                      <span className={classes.reason}>{t(reasonCode)}</span>
                    )}
                    <div className={classes.filler} />
                    {collationRemark && (
                      <RemarksBox
                        color="yellow"
                        fontColor="red"
                        content={collationRemark}
                      />
                    )}
                  </div>
                  <div className={classes.stop_info}>
                    <div className={classes.shop}>
                      <span className={classes.label}>
                        {isHub ? t('HUB') : t('SHOP')}
                      </span>
                      <span className={classes.data}>{companyName}</span>
                      <span className={classes.address}>{address}</span>
                      <span
                        className={classes.address}
                      >{`${postalCode} ${city}`}</span>
                    </div>
                    <div>
                      <span className={classes.label}>{t('ETA')}</span>
                      <span className={classes.data}>
                        {moment(timeOfArrival).format('HH:mm')}
                      </span>
                    </div>
                    <div>
                      <span className={classes.label}>{t('ACTUAL')}</span>
                      <span className={classes.data}>
                        {actualTimeOfArrival
                          ? moment(actualTimeOfArrival).format('HH:mm')
                          : '-'}
                      </span>
                    </div>
                    <div style={{ visibility: isHub ? 'hidden' : 'visible' }}>
                      <span className={classes.label}>{t('PICKED_UP')}</span>
                      <span className={classes.data}>
                        {shipmentsCollected || '-'}
                      </span>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          )
        })
        .value(),

    [collations]
  )

  return (
    <table className={clsx('table', classes.collection_list)}>
      <tbody>{collectionList}</tbody>
    </table>
  )
}

export default Collections
