import { Debtor } from 'Trunkrs-SDK/dist/models/finance/Debtor'

type extractGeneric<Type> = Type extends Promise<infer X> ? X : never
export type DebtorResponse = extractGeneric<
  ReturnType<typeof Debtor.fetchAllDebtors>
>

export interface SetLoadingAction {
  type: 'set-loading'
  isLoading: boolean
}

export interface SetLoadingDetailsAction {
  type: 'set-loading-details'
  isLoadingDetails: boolean
}

export interface SetSavingAction {
  type: 'set-saving'
  isSaving: boolean
}

export interface SetDebtorAction {
  type: 'set-debtors'
  debtors: DebtorResponse
}

export interface SetCurrentMerchantAction {
  type: 'set-current-merchant'
  debtorNumber: string | null
}

export type Action =
  | SetDebtorAction
  | SetCurrentMerchantAction
  | SetLoadingAction
  | SetSavingAction
  | SetLoadingDetailsAction

export const setDebtors = (debtors: DebtorResponse): SetDebtorAction => ({
  type: 'set-debtors',
  debtors,
})

export const setCurrentMerchant = (
  debtorNumber: string | null
): SetCurrentMerchantAction => ({
  type: 'set-current-merchant',
  debtorNumber,
})

export const setLoading = (isLoading: boolean): SetLoadingAction => ({
  type: 'set-loading',
  isLoading,
})

export const setSaving = (isSaving: boolean): SetSavingAction => ({
  type: 'set-saving',
  isSaving,
})

export const setLoadingDetails = (
  isLoadingDetails: boolean
): SetLoadingDetailsAction => ({
  type: 'set-loading-details',
  isLoadingDetails,
})
