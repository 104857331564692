import { useTranslation } from '@/hooks'
import clsx from 'clsx'
import React, { memo } from 'react'

import emptyCallsIcon from '../../images/no-calls-illustration.svg'
import styles from './styles.module.scss'

const EmptyCallRecords = () => {
  const [t] = useTranslation(['SHIPMENT'])

  return (
    <div className={clsx('box-shadow', styles['empty-call-container'])}>
      <img src={emptyCallsIcon} />
      <span className={styles.header}>
        {t('CALL_RECORDING_NOTHING_TO_SHOW')}
      </span>
      <span className={styles['sub-details']}>
        {t('CALL_RECORDING_NOTHING_TO_SHOW_SUB_DETAILS')}
      </span>
    </div>
  )
}

export default memo(EmptyCallRecords)
