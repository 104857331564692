import { useTranslation } from '@/hooks'
import React, { memo } from 'react'

const NoFreezerLogs = () => {
  const [t] = useTranslation('FREEZER_STATES')

  return (
    <div id="no-freezer-logs-container">
      <div className="icon" />
      <div className="header-text">{t('NO_FREEZER_LOGS')}</div>
      <div className="sub-text">{t('NO_FREEZER_LOGS_SUBTEXT')}</div>
    </div>
  )
}

export default memo(NoFreezerLogs)
