export interface DataSourceConfig {
  id: number
  key: string
  value: string
}

export enum SearchableItemKey {
  ORDER_REFERENCE = 'orderReference',
  TRUNKRS_NUMBER = 'trunkrsNumber',
  CURRENT_RECIPIENT = 'currentRecipient',
  RECIPIENT_ADDRESS = 'recipientAddress',
  RECIPIENT_POSTALCODE = 'recipientPostalCode',
  RECIPIENT_CITY = 'recipientCity',
  CREATION_DATE = 'creationDate',
  CURRENT_DELIVERY_DATE = 'currentDeliveryDate',
  CURRENT_SHIPMENT_STATE = 'currentShipmentState',
  SENDER = 'merchantName',
}

export interface SearchableItem {
  orderReference: string
  trunkrsNumber: string
  currentRecipient: string
  recipientAddress: string
  recipientPostalCode: string
  recipientCity: string
  creationDate: string
  currentDeliveryDate: string
  currentShipmentState: string
  merchantName: string
}
