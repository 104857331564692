import '../style.scss'

import { FREEZER_STATE } from '@/constants'
import { useTranslation } from '@/hooks'
import moment from 'moment'
import React, { useMemo } from 'react'
import { IFreezerLog } from 'Trunkrs-SDK/dist/models/delivery/Shipment'

interface IDailyRecordCardProps {
  logs: IFreezerLog[]
  date: string
  attemptNum: number
}

const DailyRecordCard: React.FunctionComponent<IDailyRecordCardProps> = ({
  logs,
  date,
  attemptNum,
}) => {
  const [t] = useTranslation(['FREEZER_STATES', 'SHIPMENT'])

  const getClassName = (state: string) => {
    switch (state) {
      case FREEZER_STATE.IN:
        return 'in'
      case FREEZER_STATE.OUT:
        return 'out'
      default:
        return 'default'
    }
  }

  const logsComponent = useMemo(
    () =>
      logs.map((log: IFreezerLog, index: number) => (
        <div className="log" key={index}>
          <div className="data">{moment(log.createdAt).format('HH:mm')}</div>
          <div className="data">
            <div className={getClassName(log.logState)}>
              {t(`FREEZER_STATES:${log.logState}`)}
            </div>
          </div>
        </div>
      )),
    [logs]
  )

  return (
    <div className="box-shadow daily-card">
      <div className="wrapper">
        <div className="header">
          <div className="date">{date}</div>
          <div className="label">
            {t(`SHIPMENT:ATTEMPT_NUM`, { number: attemptNum })}
          </div>
        </div>
      </div>
      <div className="wrapper">{logsComponent}</div>
    </div>
  )
}

export default DailyRecordCard
