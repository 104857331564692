import { LinehaulTrackingContext } from '@/contexts'
import React, { useContext, useEffect, useState } from 'react'

import MainContent from './components/mainContent'

interface ILinehaulTrackingProps {}

const LinehaulTracking: React.FC<ILinehaulTrackingProps> = () => {
  const [initializing, setInitializing] = useState(true)
  const { fetchAvailableTags } = useContext(LinehaulTrackingContext)

  useEffect(() => {
    setInitializing(true)
    fetchAvailableTags().finally(() => {
      setInitializing(false)
    })
  }, [])

  if (initializing) return null

  return <MainContent />
}

export default LinehaulTracking
