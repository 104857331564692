import * as React from 'react'
import * as ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'

import { SENTRY_DSN, ENVIRONMENT } from './constants'
import Root from './app/'
import * as serviceWorker from './serviceWorker'

import './index.css'
import './i18n'

// @ts-ignore
library.add(fas, far)

Sentry.init({
  dsn: SENTRY_DSN,
  environment: ENVIRONMENT,
})

window.addEventListener('load', () =>
  ReactDOM.render(<Root />, document.getElementById('root'))
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
