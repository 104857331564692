import RemarksBox from '@/app/features/collectionTracking/components/drawerContent/remarksBox'
import { CollectionTourContext } from '@/contexts'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext } from 'react'

import DrawerLoading from '../drawerLoading'
import CollectionList from './collectionList'
import Header from './header'
import classes from './style.module.scss'
import TimeBox from './timeBox'

interface IDrawerContentProps {
  toggleDrawer: (shouldBeOpen: boolean) => void
}

const DrawerContent: React.FC<IDrawerContentProps> = ({ toggleDrawer }) => {
  const { activeCollectionTourView, fetchingCollectionTourView } = useContext(
    CollectionTourContext
  )

  const closeDrawer = () => {
    toggleDrawer(false)
  }

  if (fetchingCollectionTourView || !activeCollectionTourView)
    return <DrawerLoading handleCloseDrawer={closeDrawer} />

  return (
    <div className={classes.drawer_content}>
      <Header closeDrawer={closeDrawer} />
      {activeCollectionTourView.tourRemark && (
        <RemarksBox
          color="orange"
          content={
            <div>
              <FontAwesomeIcon
                icon="exclamation-circle"
                className={classes.attentionIcon}
              />
              <span>Attention: </span>
              <span>{activeCollectionTourView.tourRemark}</span>
            </div>
          }
        />
      )}
      <TimeBox
        departure={activeCollectionTourView.timeOfDeparture.substr(0, 5)}
        arrival={activeCollectionTourView.timeOfArrival.substr(0, 5)}
      />
      <CollectionList activeTour={activeCollectionTourView} />
    </div>
  )
}

export default DrawerContent
