import {
  createMerchantUserAccount,
  fetchForecasts,
  fetchMerchantInfoById,
  initializeMerchantOverview,
  onUpdateMerchantData,
  onUpdateMerchantInvoicing,
  setState,
  updateForecasts,
} from '@/store/merchantOverview/actions'
import {
  IMerchantOverviewState,
  MerchantAccount,
} from '@/store/merchantOverview/IMerchantOverviewState'
import MerchantOverviewContext from '@/store/merchantOverview/index'
import reducer, { initialState } from '@/store/merchantOverview/reducer'
import React, { useCallback, useMemo, useReducer } from 'react'

const MerchantOverviewProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const initializeMerchantOverviewFunction = useCallback(
    initializeMerchantOverview(dispatch),
    [dispatch]
  )

  const addMerchantAccountFunction = useCallback(createMerchantUserAccount, [])

  const fetchMerchantInfoByIdFuction = useCallback(
    fetchMerchantInfoById(dispatch),
    [dispatch]
  )

  const setStateFunction = useCallback(setState(dispatch), [dispatch])

  const updateMerchantInvoicingFunction = useCallback(
    onUpdateMerchantInvoicing(dispatch),
    [dispatch]
  )
  const updateMerchantDataFunction = useCallback(
    onUpdateMerchantData(dispatch),
    [dispatch]
  )

  const fetchForecastsFunction = useCallback(fetchForecasts(dispatch), [
    dispatch,
  ])

  const updateForecastsFunction = useCallback(updateForecasts(dispatch), [
    dispatch,
  ])

  const context = useMemo(
    (): IMerchantOverviewState => ({
      state: state.state,
      actions: {
        onUpdateMerchantInvoicing: updateMerchantInvoicingFunction,
        setState: setStateFunction,
        addMerchantUserAccount: addMerchantAccountFunction,
        fetchMerchantInfoById: fetchMerchantInfoByIdFuction,
        initialize: initializeMerchantOverviewFunction,
        onUpdateMerchantData: updateMerchantDataFunction,
        fetchForecasts: fetchForecastsFunction,
        updateForecasts: updateForecastsFunction,
      },
    }),
    [state.state, initializeMerchantOverviewFunction, setState]
  )

  return (
    <MerchantOverviewContext.Provider value={context}>
      {children}
    </MerchantOverviewContext.Provider>
  )
}

export default MerchantOverviewProvider
