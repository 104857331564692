import RemarksBox from '@/app/features/collectionTracking/components/drawerContent/remarksBox'
import * as React from 'react'
import {
  IShopsRow,
  ISingleCollectionTourEstimation,
} from 'Trunkrs-SDK/dist/models/spatial/Collections/CollectionTour'

import classes from './style.module.scss'

interface ICollectionItemProps {
  collection: IShopsRow
  position: number | string
}

const CollectionItem: React.FC<ICollectionItemProps> = ({
  collection: { shop, remark, timeOfArrival },
  position,
}) => {
  return (
    <div className={classes.item_container}>
      <div className={classes.position}>{position}</div>
      <div className={classes.middle}>
        <span>{shop.name}</span>
        <span>{shop.address}</span>
        <span>{`${shop.postalCode} ${shop.city}`}</span>
        {remark && (
          <RemarksBox
            className={classes.remarks}
            color="yellow"
            content={remark}
            fontColor="red"
          />
        )}
      </div>
      <div className={classes.eta}>{timeOfArrival.substr(0, 5)}</div>
    </div>
  )
}

export default CollectionItem
