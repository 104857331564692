import { MERCHANT_INFO_SECTIONS } from '@/app/features/clientProfiles/MerchantOverview/components/MerchantInfomationModal/helper'
import { IMerchant, IMerchantInfo } from '@/models'
import {
  IMerchantOverviewAction,
  MERCHANT_OVERVIEW_ACTIONS,
} from '@/store/merchantOverview/actions'
import { IMerchantOverviewState } from '@/store/merchantOverview/IMerchantOverviewState'
import get from 'lodash/get'
import moment from 'moment'

export const initialState: IMerchantOverviewState = {
  actions: {
    onUpdateMerchantInvoicing: () => Promise.resolve(),
    setState: () => {},
    initialize: () => Promise.resolve(),
    addMerchantUserAccount: () => Promise.resolve(),
    fetchMerchantInfoById: () => Promise.resolve(),
    onUpdateMerchantData: () => Promise.resolve(),
    fetchForecasts: () => Promise.resolve(),
    updateForecasts: () => Promise.resolve(),
  },
  state: {
    isEditActive: false,
    sectionActive: MERCHANT_INFO_SECTIONS.WEBSHOP,
    fetchingMerchantInfo: false,
    isInitialized: false,
    merchantList: [],
    selectedMerchantInfo: null,
    updateMerchantErr: undefined,
    updateMerchantPending: false,
    selectedMerchantId: undefined,
    forecasts: { merchant: {} as any, forecast: [] },
    fetchingForecasts: false,
    updatingForecasts: false,
  },
}

const reducer = (
  state: IMerchantOverviewState,
  action: IMerchantOverviewAction
): IMerchantOverviewState => {
  const oldProps = state.state

  switch (action.type) {
    case MERCHANT_OVERVIEW_ACTIONS.INITIALIZE_MERCHANT_LIST:
      return {
        actions: state.actions,
        state: {
          ...oldProps,
          isInitialized: true,
          merchantList: action.payload,
        },
      }

    case MERCHANT_OVERVIEW_ACTIONS.FETCH_MERCHANT_PENDING:
      return {
        actions: state.actions,
        state: { ...oldProps, fetchingMerchantInfo: true },
      }

    case MERCHANT_OVERVIEW_ACTIONS.FETCH_MERCHANT_COMPLETE:
      return {
        actions: state.actions,
        state: {
          ...oldProps,
          fetchingMerchantInfo: false,
          selectedMerchantInfo: action.payload,
        },
      }

    case MERCHANT_OVERVIEW_ACTIONS.SET_STATE:
      return {
        actions: state.actions,
        state: {
          ...oldProps,
          ...action.payload.state,
        },
      }

    case MERCHANT_OVERVIEW_ACTIONS.UPDATE_MERCHANT_INVOICING_COMPLETE:
      return {
        actions: state.actions,
        state: {
          ...oldProps,
          isEditActive: false,
          updateMerchantPending: false,
          updateMerchantErr: undefined,
          selectedMerchantInfo: {
            ...oldProps.selectedMerchantInfo,
            ...action.payload,
          } as IMerchantInfo,
        },
      }

    case MERCHANT_OVERVIEW_ACTIONS.UPDATE_MERCHANT_DATA_COMPLETE:
      return {
        actions: state.actions,
        state: {
          ...oldProps,
          isEditActive: false,
          updateMerchantPending: false,
          updateMerchantErr: undefined,
          selectedMerchantInfo: {
            ...oldProps.selectedMerchantInfo,
            ...action.payload,
          } as IMerchantInfo,
        },
      }
    case MERCHANT_OVERVIEW_ACTIONS.UPDATE_MERCHANT_FAILED:
      return {
        actions: state.actions,
        state: {
          ...oldProps,
          isEditActive: true,
          updateMerchantPending: false,
          updateMerchantErr:
            get(action, 'payload', '').indexOf('INVALID') >= 0
              ? action.payload
              : undefined,
        },
      }

    case MERCHANT_OVERVIEW_ACTIONS.UPDATE_MERCHANT_NAME:
      const merchantList = oldProps.merchantList.filter(
        x => x.id != oldProps.selectedMerchantId
      )
      return {
        actions: state.actions,
        state: {
          ...oldProps,
          merchantList: [
            ...merchantList,
            {
              id: Number(oldProps.selectedMerchantId),
              name: get(action, 'payload'),
              hasUsers: false,
            },
          ],
        },
      }

    case MERCHANT_OVERVIEW_ACTIONS.UPDATE_PENDING:
      return {
        actions: state.actions,
        state: {
          ...oldProps,
          updateMerchantErr: undefined,
          updateMerchantPending: true,
        },
      }

    case MERCHANT_OVERVIEW_ACTIONS.FETCH_FORECASTS_PENDING:
      return {
        actions: state.actions,
        state: { ...oldProps, fetchingForecasts: true },
      }

    case MERCHANT_OVERVIEW_ACTIONS.FETCH_FORECASTS_COMPLETE:
      return {
        actions: state.actions,
        state: {
          ...oldProps,
          fetchingForecasts: false,
          forecasts: action.payload,
        },
      }
    case MERCHANT_OVERVIEW_ACTIONS.UPDATE_FORECASTS_PENDING:
      return {
        actions: state.actions,
        state: { ...oldProps, updatingForecasts: true },
      }

    case MERCHANT_OVERVIEW_ACTIONS.UPDATE_FORECASTS_COMPLETE:
      return {
        actions: state.actions,
        state: {
          ...oldProps,
          updatingForecasts: false,
        },
      }
    case MERCHANT_OVERVIEW_ACTIONS.UPDATE_FORECASTS_MERGE:
      const forecast = oldProps.forecasts.forecast.map(fr => {
        const py =
          Array.isArray(action.payload) &&
          action.payload.find(p => moment(p.date).isSame(fr.date, 'day'))
        if (py !== undefined) {
          fr.forecast = py.forecast
        }
        return fr
      })
      return {
        actions: state.actions,
        state: {
          ...oldProps,
          forecasts: {
            merchant: oldProps.forecasts.merchant,
            forecast: forecast,
          },
        },
      }
    default:
      return {
        actions: state.actions,
        state: {
          ...oldProps,
        },
      }
  }
}

export default reducer
