import API from '@/api'
import {
  DataSourceConfig,
  SearchableItemKey,
} from '@/app/base/components/.v2/SmartSearch/helpers/interfaces'
import { useResponseErrorHandler } from '@/hooks'
import moment from 'moment'
import { useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { disabledPaths, toActiveChips, toSearchableItem } from './helper'
import { ISmartSearchState, initialState } from './state'

export const useSmartSearchActions = (): ISmartSearchState => {
  const history = useHistory()
  const location = useLocation()

  const validPathnameParsedQuery =
    !disabledPaths.includes(location.pathname) &&
    toSearchableItem(location.search)

  const [activeChips, setActiveChips] = useState<DataSourceConfig[] | []>(
    location.search && !disabledPaths.includes(location.pathname)
      ? toActiveChips(toSearchableItem(location.search))
      : initialState.activeChips
  )
  const [selectedFilter, setSelectedFilter] = useState<string | null>(null)
  const [dateStart, setDateStart] = useState<Date | null>(null)
  const [dateEnd, setDateEnd] = useState<Date | null>(null)

  const [isScrolling, setIsScrolling] = useState<boolean>(false)
  const [isEndOfResult, setIsEndOfResult] = useState<boolean>(false)

  const [isFetching, setFetching] = useState<boolean>(false)
  const [searchResult, setSearchResult] = useState(initialState.searchResult)

  const [queryParams, setQueryParams] = useState<string | null>(null)

  const [parsedQuery, setParsedQuery] = useState<Record<string, any>>({
    ...initialState.parsedQuery,
    ...validPathnameParsedQuery,
  })

  const shipment = API.Shipment()
  const fetchFilteredShipments = async () => {
    const { take, skip } = parsedQuery
    setFetching(true)
    const [err, response] = await useResponseErrorHandler(
      shipment.fetchFilteredShipment(parsedQuery)
    )
    if (!err) {
      const mappedResult = response.map(shipmentResultMapper)

      if (mappedResult.length < take || mappedResult.length === 0) {
        setIsEndOfResult(true)
      }
      if (isScrolling) {
        setSearchResult([...searchResult, ...mappedResult])
      } else {
        setSearchResult(mappedResult)
      }
    } else {
      setSearchResult([])
    }

    setFetching(false)
  }

  useEffect(() => {
    fetchFilteredShipments()
  }, [parsedQuery])

  const shipmentResultMapper = (shipment: any) => ({
    ...shipment,
    trunkrsNr: shipment.trunkrsNumber,
    statusText: shipment.state,
    recipient: {
      ...shipment.recipient,
      address: shipment.recipient.addressLine,
    },
  })

  const doParsingQuery = useCallback((chips: DataSourceConfig[]) => {
    const groupedByKey = chips.reduce((prev: any, current: any) => {
      const { key, value } = current
      const newDateFormat = 'YYYY-MM-DD'
      setIsScrolling(false)
      switch (key) {
        case SearchableItemKey.CURRENT_SHIPMENT_STATE:
          if (!!!prev[key]) {
            prev[key] = [value]
          } else {
            prev[key].push(value)
          }
          break
        case SearchableItemKey.CURRENT_RECIPIENT:
          prev[`${key}[name]`] = value
          break
        case SearchableItemKey.RECIPIENT_ADDRESS:
          prev[`${SearchableItemKey.CURRENT_RECIPIENT}[addressLine]`] = value
          break
        case SearchableItemKey.RECIPIENT_POSTALCODE:
          prev[`${SearchableItemKey.CURRENT_RECIPIENT}[postalCode]`] = value
          break
        case SearchableItemKey.RECIPIENT_CITY:
          prev[`${SearchableItemKey.CURRENT_RECIPIENT}[city]`] = value
          break
        case SearchableItemKey.CREATION_DATE:
        case SearchableItemKey.CURRENT_DELIVERY_DATE:
          if (value.includes(' > ')) {
            const [startDate, endDate] = value.split(` > `)
            prev[`${key}[from]`] = moment(startDate, 'DD-MM-YYYY').format(
              newDateFormat
            )
            prev[`${key}[to]`] = moment(endDate, 'DD-MM-YYYY').format(
              newDateFormat
            )
          } else {
            prev[`${key}[to]`] = moment(value, 'DD-MM-YYYY').format(
              newDateFormat
            )
            prev[`${key}[from]`] = moment(value, 'DD-MM-YYYY').format(
              newDateFormat
            )
          }
          break
        default:
          prev[key] = value
      }
      return prev
    }, {})

    if (!!groupedByKey.currentShipmentState) {
      groupedByKey.currentShipmentState = groupedByKey.currentShipmentState.join(
        '|'
      )
    }
    appendQueryParams(groupedByKey)
    const { take, skip } = parsedQuery
    setParsedQuery({ ...groupedByKey, take, skip })
  }, [])

  const handleRemoveChip = useCallback(
    (id: number) => {
      const copyOfChips = [...activeChips]
      const updatedChips = copyOfChips.filter(chip => chip.id !== id)
      setActiveChips(updatedChips)
      doParsingQuery(updatedChips)
      setIsEndOfResult(false)
      setIsScrolling(false)
    },
    [activeChips, setActiveChips]
  )

  const handleAddChip = useCallback(
    (chip: DataSourceConfig) => {
      const copyOfChips = [...activeChips]
      copyOfChips.push(chip)
      setActiveChips(copyOfChips)
      doParsingQuery(copyOfChips)
      setIsEndOfResult(false)
      setIsScrolling(false)
    },
    [activeChips, setActiveChips]
  )

  const handleAddBulkChip = useCallback(
    (chips: DataSourceConfig[]) => {
      const allChips = [...activeChips]
      const chipsWithoutStatus = allChips.filter(
        chip => chip.key !== SearchableItemKey.CURRENT_SHIPMENT_STATE
      )

      setActiveChips([...chipsWithoutStatus, ...chips])
      doParsingQuery([...chipsWithoutStatus, ...chips])
      setIsEndOfResult(false)
      setIsScrolling(false)
      // setActiveChips(uniqBy(updatedChips, 'value'))
    },
    [activeChips, setActiveChips]
  )

  const appendQueryParams = (query: string) => {
    const params = decodeURIComponent(new URLSearchParams(query).toString())
    history.push({ pathname: '/shipments', search: params })
    setQueryParams(params)
  }

  const clearState = useCallback(() => {
    setDateEnd(null)
    setDateStart(null)
    setSelectedFilter(null)
    setIsEndOfResult(false)
    setIsScrolling(false)
  }, [setDateEnd, setDateStart, setSelectedFilter, setIsEndOfResult])

  const handlePagination = async () => {
    if (isEndOfResult) {
      return
    }
    setIsScrolling(true)
    const { take, skip } = parsedQuery
    setParsedQuery({ ...parsedQuery, skip: skip + take })
  }

  return {
    activeChips,
    parsedQuery,
    selectedFilter,
    dateStart,
    dateEnd,
    clearState,
    setDateStart,
    setDateEnd,
    handleAddBulkChip,
    setSelectedFilter,
    handleAddChip,
    handleRemoveChip,
    searchResult,
    isFetching,
    fetchFilteredShipments,
    isEndOfResult,
    handlePagination,
    isScrolling,
    queryParams,
  }
}
