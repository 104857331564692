import { IDOMWidgetBase, ILoadingProps } from '@/models'
import * as React from 'react'

export interface IAppState {
  Loader: IDOMWidgetBase<ILoadingProps>
  setLoading: (isLoading: boolean) => void
  sidebarCollapsed: boolean
  setSidebarCollapsed: React.Dispatch<React.SetStateAction<boolean>>
  isNotificationPaneVisible: boolean
  setIsNotificationPaneVisible: React.Dispatch<React.SetStateAction<boolean>>
  error: {
    value: any
    description: string
  } | null
  setError: React.Dispatch<
    React.SetStateAction<{
      value: any
      description: string
    } | null>
  >
}

export const initialState: IAppState = {
  Loader: { component: () => null },
  setLoading: () => {},
  sidebarCollapsed: false,
  setSidebarCollapsed: () => {},
  isNotificationPaneVisible: false,
  setIsNotificationPaneVisible: () => {},
  error: null,
  setError: () => {},
}
