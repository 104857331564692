import { useTranslation } from '@/hooks'
import { clsx } from '@/utils'
import React, { memo } from 'react'

export const StatusLabel = memo(({ isReturned }: { isReturned: boolean }) => {
  const [t] = useTranslation('SHIPMENT_RETURNS')

  return (
    <span className={clsx('return-status-label', isReturned && 'returned')}>
      {t(isReturned ? 'RETURNED_TO_TRUNKRS' : 'NOT_RETURNED_TO_TRUNKRS')}
    </span>
  )
})
