import React, { memo, useEffect, useRef } from 'react'

export default memo(
  ({
    children,
    callback,
  }: React.HTMLAttributes<HTMLDivElement> & { callback: () => void }) => {
    const container = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
      const handleClickAway = (ev: MouseEvent) => {
        if (
          container.current &&
          !container.current.contains(ev.target as any)
        ) {
          if (typeof callback === 'function') {
            callback()
          }
        }
      }
      document.addEventListener('mousedown', handleClickAway)
      return () => document.removeEventListener('mousedown', handleClickAway)
    }, [])

    return <div ref={container}>{children}</div>
  }
)
