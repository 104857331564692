import { SmartSearchContext } from '@/store/smartSearch'
import clsx from 'clsx'
import format from 'date-fns/format'
import React, { useCallback, useContext, useMemo, useState } from 'react'

import { useTranslation } from '../../../../../../../hooks'
import Button from '../../../Button'
import DateRangePicker from '../DateRangePicker'
import DatePresets, { DatePreset } from './helpers'
import styles from './styles.module.scss'

export const DateSelect: React.FC = () => {
  const [t] = useTranslation(['SHIPMENT_STATES', 'SMART_SEARCH'])
  const [preset, setPreset] = useState<DatePreset | null>(null)
  const {
    selectedFilter,
    activeChips,
    dateStart,
    dateEnd,
    setDateStart,
    setDateEnd,
    clearState,
    handleAddChip,
  } = useContext(SmartSearchContext)

  const handlePresetSelect = useCallback(
    (datePreset: DatePreset) => {
      setPreset(datePreset)
      const dateValues = datePreset.getValue()
      setDateStart(dateValues.dateStart as Date)
      setDateEnd(dateValues.dateEnd as Date)
    },
    [setDateStart, setDateEnd, setPreset]
  )

  const handleDateClick = useCallback(
    (date: Date) => {
      setPreset(null)

      if (!dateStart) {
        return setDateStart(date)
      } else {
        if (dateStart.getTime() > date.getTime()) {
          return
        } else {
          return setDateEnd(date)
        }
      }
    },
    [dateStart, setDateStart, setDateEnd]
  )

  const renderPresets = useMemo(
    () =>
      DatePresets.map(item => (
        <Button
          key={item.key}
          disabled={item.disabled}
          className={clsx(
            item.disabled && styles.disabled,
            styles.presetButton,
            preset && preset.key === item.key && styles.active
          )}
          onClick={() => handlePresetSelect(item)}
        >
          {t(item.key)}
        </Button>
      )),
    [preset, styles, handlePresetSelect, t]
  )

  const handleSearch = useCallback(() => {
    if (dateStart && dateEnd) {
      handleAddChip({
        id: activeChips.length + 1,
        key: String(selectedFilter),
        value: `${format(dateStart as Date, 'dd-MM-yyyy')} > ${format(
          dateEnd as Date,
          'dd-MM-yyyy'
        )}`,
      })
    } else {
      handleAddChip({
        id: activeChips.length + 1,
        key: String(selectedFilter),
        value: `${format(dateStart as Date, 'dd-MM-yyyy')}`,
      })
    }
    clearState()
  }, [
    activeChips,
    selectedFilter,
    dateStart,
    dateEnd,
    clearState,
    handleAddChip,
  ])

  return (
    <div className={styles.root}>
      <div className={styles.presetPanel}>{renderPresets}</div>
      <div className={styles.calendarPanel}>
        <div className={styles.pickerContainer}>
          <DateRangePicker handleDateClick={handleDateClick} />
        </div>
        <div className={styles.footer}>
          <span color="grey">
            {/* {getDateText(selected, t(`DateFormat.datePicker`))} */}
          </span>
          <Button variant="primary" onClick={handleSearch}>
            {t('SEARCH')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default DateSelect
