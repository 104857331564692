import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FormControl from '@material-ui/core/FormControl/FormControl'
import Select from '@material-ui/core/Select/Select'
import clsx from 'clsx'
import get from 'lodash/get'
import React, { HTMLProps, memo, useCallback, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

interface ISelectFieldProps extends HTMLProps<HTMLSelectElement> {
  id: string
  name: string
  label?: string
  popOverClassName?: string
  menuListMaxHeight?: number
  isEdit?: boolean
}

const SelectField = ({
  name,
  id,
  label,
  children,
  className,
  onChange,
  onBlur,
  popOverClassName,
  placeholder,
  menuListMaxHeight,
  isEdit,
  ...rest
}: ISelectFieldProps) => {
  const { errors, control } = useFormContext()
  const fieldError = get(errors, `${name}.message`)
  const menuPopoverId = `${id}-select-menu-popover`

  const paperClass = useMemo(
    () => (isEdit ? 'edit-popover' : 'select-menu-popover-container'),
    [isEdit]
  )

  const handleOnEnter = useCallback(() => {
    const targetElement = get(
      document.getElementById(id),
      'parentNode'
    ) as Element

    if (targetElement) {
      const targetElementWidth = targetElement.getBoundingClientRect().width
      const menuPopover = document.querySelector(
        `#${menuPopoverId} .${paperClass}`
      ) as any

      menuPopover.style.width = isEdit
        ? `${Math.ceil(targetElementWidth)}px`
        : `${Math.floor(targetElementWidth)}px`
    }
  }, [isEdit, id])

  const renderControllerProps = useCallback(
    (props: any) => {
      const injectedProps = {
        ...props,
        id,
        value: get(props, 'value', ''),
        onChange: (ev: any) => {
          ev.persist()
          props.onChange(ev)
          onChange && onChange(ev)
        },
        onBlur: (ev: any) => {
          ev.persist()
          props.onBlur()
          onBlur && onBlur(ev)
        },
        ...rest,
      }

      return (
        <Select
          {...injectedProps}
          variant="outlined"
          displayEmpty={true}
          MenuProps={{
            PopoverClasses: {
              paper: clsx(paperClass, popOverClassName),
            },
            PaperProps: {
              style: {
                maxHeight: menuListMaxHeight,
              },
            },
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 10,
              horizontal: 1,
            },
            getContentAnchorEl: null,
            onEnter: handleOnEnter,
            marginThreshold: -999,
            id: menuPopoverId,
          }}
          IconComponent={({ className }) => (
            <FontAwesomeIcon
              icon={
                className.includes('MuiSelect-iconOpen')
                  ? 'chevron-up'
                  : 'chevron-down'
              }
            />
          )}
        >
          {children}
        </Select>
      )
    },
    [onChange, onBlur, children, isEdit]
  )

  return isEdit ? (
    <div className={clsx('field-block', fieldError && 'has-error', className)}>
      {label && <span className="label">{label}</span>}
      <div className="dropdownMenuContainer">
        <Controller
          {...rest as any[]}
          as={renderControllerProps}
          name={name as never}
          placeholder={placeholder}
          control={control as any}
        />
      </div>
      <div className="error-container" />
    </div>
  ) : (
    <FormControl
      className={clsx('select-field', className, fieldError && 'has-error')}
      fullWidth={true}
    >
      {label && <label htmlFor={id}>{label}</label>}
      <Controller
        {...rest as any[]}
        id={id}
        as={renderControllerProps}
        name={name as never}
        className={clsx('form-control', className)}
        control={control as any}
      />
      {fieldError && <div className="invalid-tooltip">{fieldError}</div>}
    </FormControl>
  )
}

export default memo(SelectField)
