import React, { memo } from 'react'

export const NotificationBadge = ({ count }: { count: number }) => {
  return (
    <>
      {count > 0 && (
        <span className="badge badge-pill badge-danger">{count}</span>
      )}
    </>
  )
}

export default memo(NotificationBadge)
