import { LinehaulTrackingContext } from '@/contexts'
import { useLinehaulTrackingActions } from '@/store/linehaulTracking/actions'
import * as React from 'react'

export default ({ children }: { children: React.ReactNode }) => {
  const state = useLinehaulTrackingActions()
  return (
    <LinehaulTrackingContext.Provider value={state}>
      {children}
    </LinehaulTrackingContext.Provider>
  )
}
