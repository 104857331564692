import { SHIPMENT_STATE } from '@/constants'

import blueIcon from '../app/features/tourOverview/assets/location-blue.svg'
import greenIcon from '../app/features/tourOverview/assets/location-green.svg'
import greyIcon from '../app/features/tourOverview/assets/location-grey.svg'
import redIcon from '../app/features/tourOverview/assets/location-red.svg'
import whiteGreenIcon from '../app/features/tourOverview/assets/location-whitegreen.svg'

// import whiteIcon from '../app/features/tourOverview/assets/location-white.svg'
// import yellowIcon from '../app/features/tourOverview/assets/location-yellow.svg'

export const getIconForShipmentState = (
  state: SHIPMENT_STATE | string = 'default'
): string => {
  switch (state) {
    case SHIPMENT_STATE.SHIPMENT_ACCEPTED_BY_DRIVER:
      return blueIcon
    case SHIPMENT_STATE.SHIPMENT_DELIVERED:
      return greenIcon
    case SHIPMENT_STATE.SHIPMENT_DELIVERED_TO_NEIGHBOR:
      return whiteGreenIcon
    case SHIPMENT_STATE.SHIPMENT_NOT_DELIVERED:
      return redIcon
    case SHIPMENT_STATE.DATA_PROCESSED:
    case SHIPMENT_STATE.EXCEPTION_SHIPMENT_DECLINED_BY_DRIVER:
    default:
      return greyIcon
  }
}
