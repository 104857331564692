import OnTimeSpan from '@/app/base/components/onTimeSpan'
import StatusWithoutIcon from '@/app/base/components/statusWithoutIcon'
import TimestampToTime from '@/utils/timestampToTime'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Avatar from '@material-ui/core/Avatar'
import ButtonBase from '@material-ui/core/ButtonBase'
import clsx from 'clsx'
import React from 'react'
import { ILinehaulTrackingResponseRow } from 'Trunkrs-SDK/dist/models/spatial/Linehauls/LinehaulTour'
import { TOUR_STATES } from 'Trunkrs-SDK/dist/models/spatial/TourState'

import classes from './style.module.scss'

interface ILinehaulItemProps {
  linehaul: ILinehaulTrackingResponseRow
  onItemClick: () => void
}

const LinehaulItem: React.FC<ILinehaulItemProps> = ({
  linehaul,
  onItemClick,
}) => {
  const actualEta = React.useMemo(
    () =>
      linehaul.tourStatus === TOUR_STATES.CREATED
        ? '-'
        : TimestampToTime(linehaul.actualEta.timestamp),
    []
  )

  return (
    <tr className={classes.linehaul_item}>
      <td>
        <div className={classes.driver}>
          <Avatar src={linehaul.driver.picture} />
          <div className={classes.driver_info}>
            <span>{linehaul.driver.name}</span>
            <span>{linehaul.driver.number}</span>
          </div>
        </div>
      </td>
      <td>
        <span>{TimestampToTime(linehaul.departureTime)}</span>
      </td>
      <td>
        <span>{TimestampToTime(linehaul.plannedEta)}</span>
      </td>
      <td>
        <OnTimeSpan status={linehaul.actualEta.status} extraClassNames={[]}>
          {actualEta}
        </OnTimeSpan>
      </td>
      <td>
        <OnTimeSpan status={linehaul.arrivalTime.status} extraClassNames={[]}>
          {TimestampToTime(linehaul.arrivalTime.timestamp)}
        </OnTimeSpan>
      </td>
      <td>
        <StatusWithoutIcon tourState={linehaul.tourStatus} />
      </td>
      <td className={classes.last_td}>
        <ButtonBase
          disableRipple={true}
          className={clsx('btn button', classes.btn_loc)}
          onClick={onItemClick}
        >
          <FontAwesomeIcon icon="crosshairs" className={classes.location} />
        </ButtonBase>
      </td>
    </tr>
  )
}

export default LinehaulItem
