import { InputNumber } from '@/app/base/components/reactHookComponents'
import clsx from 'clsx'
import get from 'lodash/get'
import React, { ReactNode, memo } from 'react'
import { useFormContext } from 'react-hook-form'

interface ITextFieldWithIconProps
  extends React.HTMLAttributes<HTMLInputElement> {
  label?: string
  name: string
  icon: ReactNode | string
  prependIcon?: boolean
  numeric?: boolean
  decimalScale?: number
  fixedDecimalScale?: boolean
  showError?: boolean
}

const TextFieldWithIcon = ({
  label,
  name,
  icon,
  prependIcon,
  numeric,
  placeholder,
  className,
  showError = true,
  ...props
}: ITextFieldWithIconProps) => {
  const { register, errors } = useFormContext()
  const fieldError = get(errors, `${name}.message`)

  return (
    <div className={clsx('input-group', className, fieldError && 'has-error')}>
      {label && <label>{label}</label>}
      <div className="input-group">
        {prependIcon && icon && (
          <div className="input-group-prepend input-group-append">
            <div className="input-group-text prepend">{icon}</div>
          </div>
        )}
        {numeric ? (
          <InputNumber
            {...props as any[]}
            name={name}
            placeholder={placeholder}
          />
        ) : (
          <input
            {...props}
            className="form-control"
            name={name}
            ref={register}
            placeholder={placeholder}
            {...props}
          />
        )}
        {!prependIcon && icon && (
          <div className="input-group-prepend input-group-append">
            <div className="input-group-text prepend">{icon}</div>
          </div>
        )}
      </div>
      {fieldError && showError && (
        <div className="invalid-tooltip">{fieldError}</div>
      )}
    </div>
  )
}

export default memo(TextFieldWithIcon)
