import { IAppState } from '@/store/app/state'
import { IAuthState } from '@/store/auth/state'
import { IClientState } from '@/store/client/state'
import { ICollationState } from '@/store/collation/state'
import { ICollectionTourState } from '@/store/collectionTour/state'
import { ICollectionTrackingState } from '@/store/collectionTracking/state'
import { ICommentsState } from '@/store/comments/state'
import { IDriverState } from '@/store/driver/state'
import { IInventoryState } from '@/store/inventory/state'
import { ILinehaulTrackingState } from '@/store/linehaulTracking/state'
import { IMerchantState } from '@/store/merchants/state'
import { INotificationsState } from '@/store/notifications/state'
import { IRouterState } from '@/store/router/state'
import { IShipmentReturnsState } from '@/store/shipmentReturns/state'
import { IShipmentsState } from '@/store/shipments/state'
import { ISubcoState } from '@/store/subco/state'
import { ITourState } from '@/store/tour/state'
import { ITourRecoveryState } from '@/store/tourRecovery/state'
import { i18n } from 'i18next'
import * as React from 'react'

export { DebtorContext } from '@/store/debtors'

export const LocalizationContext = React.createContext({} as i18n)
export const RouterContext = React.createContext({} as IRouterState)
export const AuthContext = React.createContext({} as IAuthState)
export const ShipmentsContext = React.createContext({} as IShipmentsState)
export const TourRecoveryContext = React.createContext({} as ITourRecoveryState)
export const AppContext = React.createContext({} as IAppState)
export const ClientContext = React.createContext({} as IClientState)
export const CommentsContext = React.createContext({} as ICommentsState)
export const CollectionTourContext = React.createContext(
  {} as ICollectionTourState
)
export const NotificationsContext = React.createContext(
  {} as INotificationsState
)
export const ShipmentReturnsContext = React.createContext(
  {} as IShipmentReturnsState
)
export const CollectionTrackingContext = React.createContext(
  {} as ICollectionTrackingState
)
export const LinehaulTrackingContext = React.createContext(
  {} as ILinehaulTrackingState
)
export const MerchantContext = React.createContext({} as IMerchantState)
export const SubcoContext = React.createContext({} as ISubcoState)
export const TourContext = React.createContext({} as ITourState)
export const DriverContext = React.createContext({} as IDriverState)
export const CollationContext = React.createContext({} as ICollationState)
export const InventoryContext = React.createContext({} as IInventoryState)

LocalizationContext.displayName = 'Localization'
RouterContext.displayName = 'Routing'
AuthContext.displayName = 'Authentication'
ShipmentsContext.displayName = 'Shipments'
AppContext.displayName = 'Application'
ClientContext.displayName = 'Client'
CommentsContext.displayName = 'Comments'
CollectionTourContext.displayName = 'Collection Tour'
NotificationsContext.displayName = 'Notifications'
ShipmentReturnsContext.displayName = 'Shipment Returns'
CollectionTrackingContext.displayName = 'Collection Tracking'
LinehaulTrackingContext.displayName = 'Linehaul Tracking'
MerchantContext.displayName = 'Merchants'
SubcoContext.displayName = 'Subcos'
TourContext.displayName = 'Tours'
DriverContext.displayName = 'Drivers'
CollationContext.displayName = 'Collations'
InventoryContext.displayName = 'Inventory'
