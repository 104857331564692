import { Button } from '@/app/base/components/.lib'
import DebtorsList from '@/app/features/clientProfiles/DebtorsList'
import { useTranslation } from '@/hooks/useTranslation'
import { DebtorResponse } from '@/store/debtors/actions'
import MerchantOverviewContext from '@/store/merchantOverview'
import {
  Box,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core'
import clsx from 'clsx'
import moment from 'moment'
import React, { useContext } from 'react'
import {
  IMerchantForecast,
  IMerchantForecastInfo,
} from 'Trunkrs-SDK/dist/models/delivery/Merchant'

import styles from './styles.module.scss'

export interface TableProps {
  isSaving: boolean
  debtorNumber: string | null
  debtors: DebtorResponse
  forecasts: { merchant: IMerchantForecastInfo; forecast: IMerchantForecast[] }
  onDebtorSelected: (debtorNumber: string) => void
  onSaveDebtor: () => void
}

const Table: React.FC<TableProps> = ({
  isSaving,
  debtors,
  debtorNumber,
  forecasts,
  onDebtorSelected,
  onSaveDebtor,
}) => {
  const [t] = useTranslation('MERCHANT_OVERVIEW')
  const [edit, setEdit] = React.useState<boolean>(false)
  const [changes, setChanges] = React.useState<
    { index: number; date: string; forecast: number }[]
  >([])
  const [hasInvalidValue, setHasInvalidValue] = React.useState<boolean>(false)

  const {
    state: { selectedMerchantInfo, updatingForecasts },
    actions: { updateForecasts },
  } = useContext(MerchantOverviewContext)

  const handleEditDetails = React.useCallback(
    (index, date, value) => {
      const container = changes
      const contIndex = container.findIndex(cnt => cnt.date === date)
      if (contIndex !== -1) {
        container[contIndex].forecast = parseInt(value)
      } else {
        container.push({ index, date, forecast: parseInt(value) })
      }

      setHasInvalidValue(
        container.filter(cnt => isNaN(cnt.forecast) || cnt.forecast < 0)
          .length > 0
      )

      setChanges(container)
    },
    [setEdit, changes, setChanges, setHasInvalidValue]
  )

  const handleSaveDetails = React.useCallback(async () => {
    if (changes.length > 0) {
      const payload = changes.map(({ date, forecast }) => ({ date, forecast }))
      await updateForecasts(forecasts.merchant.id, payload)
    }
    setEdit(prev => !prev)
    setChanges([])
  }, [updateForecasts, changes])

  const handleCancelEditDetails = React.useCallback(() => {
    setEdit(prev => !prev)
    setChanges([])
  }, [setEdit, setChanges])

  const checkIfValueHasZero = React.useCallback(
    (index: number) => {
      return (
        changes.filter(
          chng =>
            chng.index === index && (isNaN(chng.forecast) || chng.forecast < 1)
        ).length > 0
      )
    },
    [changes]
  )

  return (
    <>
      <Box my={2} display="flex" justifyContent="space-between">
        <div>
          <span className={styles.title}>Pick up Address</span>
          <p style={{ marginBottom: 0 }}>
            {selectedMerchantInfo && selectedMerchantInfo.merchant.address}
          </p>
          <p style={{ marginBottom: 0 }}>
            {selectedMerchantInfo && selectedMerchantInfo.merchant.city}
          </p>
          <p style={{ marginBottom: 0 }}>
            {selectedMerchantInfo && selectedMerchantInfo.merchant.country}
          </p>
        </div>

        <DebtorsList
          isSaving={isSaving}
          debtors={debtors}
          debtorNumber={debtorNumber}
          onSelection={onDebtorSelected}
          onSaveDebtor={onSaveDebtor}
        />
      </Box>
      <Box mt={2} mb={1}>
        <span className={styles.title}>{t('10_DAY_FORECAST')}</span>
      </Box>
      <MuiTable aria-label="simple table">
        <TableHead>
          <TableRow className={styles['header-row']}>
            <TableCell>#</TableCell>
            <TableCell>{t('DATE')}</TableCell>
            <TableCell style={{ width: '50%' }}>
              {t('FORECAST_SHIPMENTS')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={styles.body}>
          {Array.isArray(forecasts.forecast) &&
            forecasts.forecast.length > 0 &&
            forecasts.forecast.map((cpy, index) => (
              <TableRow
                className={clsx(styles.row, {
                  [styles['has-error']]:
                    checkIfValueHasZero(index) || cpy.forecast < 1,
                })}
                key={cpy.id}
              >
                <TableCell>{index + 1}</TableCell>
                <TableCell>{`${moment(cpy.date).format('dddd')} ${
                  cpy.date
                }`}</TableCell>
                <TableCell>
                  {edit ? (
                    <TextField
                      fullWidth
                      type="number"
                      variant="outlined"
                      size="small"
                      defaultValue={cpy.forecast}
                      onChange={event =>
                        handleEditDetails(index, cpy.date, event.target.value)
                      }
                    />
                  ) : (
                    <span style={{ height: 37, lineHeight: '37px' }}>
                      {cpy.forecast}
                    </span>
                  )}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </MuiTable>

      {forecasts.forecast === undefined ||
        forecasts.forecast === null ||
        (Array.isArray(forecasts.forecast) && forecasts.forecast.length === 0 && (
          <div style={{ padding: '45px 0px' }}>
            <p style={{ textAlign: 'center' }}>{t('NO_FORECAST')}</p>
          </div>
        ))}

      <Box my={2} display="flex" justifyContent="flex-end">
        {edit ? (
          <>
            <Button
              className={styles.button}
              variant="success"
              onClick={handleSaveDetails}
              disabled={updatingForecasts || hasInvalidValue}
            >
              {updatingForecasts ? 'Saving details...' : 'Save details'}
            </Button>
            <Box width={16} />
            <Button
              className={clsx(styles.button, styles.close)}
              onClick={handleCancelEditDetails}
              disabled={updatingForecasts}
            >
              Close
            </Button>
          </>
        ) : (
          <Button
            className={clsx(styles.button, styles.edit)}
            onClick={() => setEdit(prev => !prev)}
          >
            Edit details
          </Button>
        )}
      </Box>
    </>
  )
}

export default Table
