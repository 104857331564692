import React, { memo, useMemo, useContext } from 'react'
import { IShipmentNotification } from '@/models'
import { NotificationsContext } from '@/contexts'
import {
  NoNotification,
  Notification,
} from '@/app/features/user/components/notificationPane/components'

export const NotSortedNotifications = () => {
  const { notSortedNotifs } = useContext(NotificationsContext)
  return (
    <div className="content not-sorted">
      {useMemo(() => {
        return notSortedNotifs.map((notification: IShipmentNotification) => (
          <Notification
            key={notification.alertId}
            notification={notification}
          />
        ))
      }, [notSortedNotifs])}
      {notSortedNotifs.length === 0 && <NoNotification />}
    </div>
  )
}

export default memo(NotSortedNotifications)
