import { useState } from 'react'
import { useResponseErrorHandler } from '@/hooks'
import { IAdminProfileDetails, IShipmentComment } from '@/models'
import { sortByDesc } from '@/utils'
import { Shipment, ShipmentComment } from '@/models'
import API from '@/api'

export const useCommentsActions = () => {
  const communication = API.Communication()
  const [shipmentComments, setShipmentComments] = useState<
    Array<IShipmentComment>
  >([])
  const [activeShipment, setActiveShipment] = useState()

  const fetchShipmentComments = async (shipment?: Shipment | null) => {
    setComments([])
    if (shipment) {
      setActiveShipment(shipment)
      const [err, response] = await useResponseErrorHandler(
        shipment.getComments
      )
      if (!err) {
        const commentsWithUserProfile = ((await Promise.all(
          response.map(async (comment: IShipmentComment) => {
            const { getUserSub: userSub } = comment
            if (userSub) {
              comment.userProfile = await fetchAdminProfile(userSub)
            }
            return comment
          })
        )) as unknown) as Array<IShipmentComment>
        setShipmentComments(sortByDesc(commentsWithUserProfile, 'id'))
      }
    }
  }

  const addComment = async (message: string, parentId?: number) => {
    if (activeShipment) {
      const { getId: shipmentId } = activeShipment
      if (shipmentId) {
        const shipmentComment = new ShipmentComment(
          parentId
            ? {
                shipmentId,
                parentId,
                message,
              }
            : {
                shipmentId,
                message,
              }
        )
        const nextComment = (await shipmentComment.save()) as IShipmentComment
        const { getUserSub: userSub } = nextComment
        if (userSub) {
          nextComment.userProfile = await fetchAdminProfile(userSub)
        }
        setShipmentComments(
          sortByDesc([...shipmentComments, nextComment], 'id')
        )
      }
    }
  }

  const removeComment = async (comment: IShipmentComment) => {
    const [err] = await useResponseErrorHandler(comment.delete())
    if (!err) {
      const nextComments = shipmentComments.filter(
        nextComment => nextComment.getId !== comment.getId
      )
      setShipmentComments(nextComments)
    }
  }

  const fetchAdminProfile = async (userSub: string) => {
    let userProfile: IAdminProfileDetails = {
      name: '',
      picture: '',
      email: '',
    }
    const [, result] = await useResponseErrorHandler(
      communication.fetchAdminProfile(userSub)
    )
    if (result) {
      userProfile = result
    }
    return userProfile
  }

  const setComments = (comments: Array<IShipmentComment>) =>
    setShipmentComments(comments)

  return {
    shipmentComments,
    fetchShipmentComments,
    addComment,
    removeComment,
    setComments,
  }
}
