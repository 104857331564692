import { CollectionTourContext } from '@/contexts'
import clsx from 'clsx'
import React, { useContext } from 'react'

import Map from './map'
import classes from './style.module.scss'

interface ICollectionTourMapProps {
  isDrawerOpen: boolean
}

const CollectionTourMap: React.FC<ICollectionTourMapProps> = ({
  isDrawerOpen,
}) => {
  const { activeTagTours } = useContext(CollectionTourContext)

  return (
    <Map
      tours={activeTagTours}
      containerElement={
        <div
          className={clsx(
            classes.map_container,
            isDrawerOpen && classes.add_height
          )}
        />
      }
      loadingElement={<div style={{ height: `100%` }} />}
      mapElement={<div style={{ height: `100%` }} />}
      googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBzWD9a4l8NYzXz7eakuWJpLNBmRnxYEc4&v=3.exp&libraries=geometry,drawing,places"
    />
  )
}

export default CollectionTourMap
