import DateAndTimePicker from '@/app/base/components/dateAndTimePicker'
import { AddComment } from '@/app/features/shipment/components/setFinalState/components'
import { AppContext, CommentsContext, ShipmentsContext } from '@/contexts'
import { useTranslation } from '@/hooks'
import { SHIPMENT_ACTIONS } from '@/models'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import React, { memo, useCallback, useContext, useState } from 'react'
import { SHIPMENT_STATES } from 'Trunkrs-SDK/dist/models/delivery/ShipmentLog'

interface IForLostProps {
  handleClose?: (...args: any[]) => void
}
const ForLost = ({ handleClose }: IForLostProps) => {
  const { setLoading } = useContext(AppContext)
  const { addComment } = useContext(CommentsContext)
  const { setShipmentState } = useContext(ShipmentsContext)

  const [comment, setComment] = useState('')
  const [selectedDateTime, setSelectedDateTime] = useState(moment())

  const setStateToDelivered = useCallback(
    async (ev: React.SyntheticEvent<any>) => {
      setLoading(true)
      const success = await setShipmentState({
        stateName: SHIPMENT_STATES.EXCEPTION_SHIPMENT_LOST,
        comment,
        overrideDate: moment(selectedDateTime).toString(),
      })
      if (success) {
        if (!isEmpty(comment)) {
          await addComment(comment)
        }
      }
      setLoading(false)
      if (success && handleClose) {
        handleClose(ev)
      }
    },
    [comment, selectedDateTime]
  )

  const [t] = useTranslation(['SET_FINAL_STATE'])

  return (
    <div>
      <p className="text bold">{t('SET_TIME_AND_DATE')}</p>
      <DateAndTimePicker
        value={selectedDateTime}
        placeholder={t('PICK_TIME_AND_DATE')}
        handleConfirm={setSelectedDateTime}
      />
      <AddComment action={SHIPMENT_ACTIONS.LOST} callback={setComment} />
      <div className="actions">
        <button
          className="btn button inactive icon"
          type="button"
          onClick={handleClose}
        >
          {t('COMMON:BUTTON.NEVERMIND')}
        </button>
        <button
          className={'btn button box-shadow danger'}
          type="button"
          onClick={setStateToDelivered}
        >
          {t('BUTTON.MARK_AS_LOST')}
        </button>
      </div>
    </div>
  )
}
export default memo(ForLost)
