import {
  CallRecording,
  EmptyCallRecords,
} from '@/app/features/shipment/components/callLogs/components/'
import { AppContext, ShipmentsContext } from '@/contexts'
import { useTranslation } from '@/hooks'
import { ICallLog } from '@/models'
import clsx from 'clsx'
import get from 'lodash/get'
import moment from 'moment'
import React, { memo, useCallback, useContext, useMemo, useState } from 'react'

import playIcon from './images/play-btn.svg'
import styles from './style.module.scss'

const CallLogs = () => {
  const { callLogs, getRecordLink } = useContext(ShipmentsContext)
  const { setLoading } = useContext(AppContext)

  const [selectedCallLog, setSelectedCallLog] = useState<ICallLog | null>()
  const [callRecordingVisible, setCallRecordingVisible] = useState(false)

  const showCallRecording = useCallback(
    (callLog: ICallLog) => () => {
      setLoading(true)
      getRecordLink(callLog.recordingId)
        .then(responseUrl => {
          const recordingUrl = get(responseUrl, 'recordingUrl')
          if (recordingUrl) {
            setSelectedCallLog({
              ...callLog,
              recordingUrl,
            })
            setCallRecordingVisible(true)
          }
        })
        .finally(() => setLoading(false))
    },
    [getRecordLink]
  )

  const closeCallRecording = useCallback(() => {
    setCallRecordingVisible(false)
    setSelectedCallLog(null)
  }, [])

  const callLogsDates = Object.keys(callLogs)

  const [t] = useTranslation(['COMMON'])

  return (
    <div className={styles['call-logs-container']}>
      {useMemo(
        () =>
          callLogsDates.map(date => {
            const callLogsByDate = callLogs[date]
            return (
              <div className={clsx('box-shadow', styles['call-log'])}>
                <div className={styles['call-log-header']}>
                  {moment(date).format('MM-DD-YYYY')}
                </div>
                <div className={styles['call-log-item-wrapper']}>
                  {callLogsByDate.map(callLog => (
                    <div
                      className={styles['call-log-item']}
                      onClick={showCallRecording(callLog)}
                    >
                      <img src={playIcon} />{' '}
                      {moment(callLog.createdAt).format(
                        'ddd, MM-DD-YYYY, HH:mm:ss'
                      )}
                      {callLog.recordingId ===
                        get(selectedCallLog, 'recordingId') && (
                        <span>{t('PLAYING')}</span>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )
          }),
        [callLogsDates]
      )}
      {!callLogsDates.length && <EmptyCallRecords />}
      {callRecordingVisible && selectedCallLog && (
        <CallRecording
          callLog={selectedCallLog}
          handleClose={closeCallRecording}
        />
      )}
    </div>
  )
}

export default memo(CallLogs)
