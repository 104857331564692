import { clsx } from '@/utils'
import * as React from 'react'
import { useState } from 'react'

export const FlexText = ({
  children,
  className,
  onChange,
  lineHeight = 52,
  ...attr
}: React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
  lineHeight?: number
}) => {
  const [currentHeight, setCurrentHeight] = useState(58)

  const handleChange = (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
    const lines = Math.max(1, ev.currentTarget.value.split('\n').length)
    setCurrentHeight(Math.max(58, lines * lineHeight))

    if (typeof onChange === 'function') {
      onChange(ev)
    }
  }

  return (
    <textarea
      className={clsx('flex-text', className)}
      style={{ height: currentHeight }}
      onChange={handleChange}
      {...attr}
    >
      {children}
    </textarea>
  )
}
