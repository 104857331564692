import { SHIPMENT_STATES } from '@/models'
import { IRecoveryCollation, Row } from '@/store/tourRecovery/types'
import { get, groupBy, without } from 'lodash'
import moment from 'moment'

interface PayloadItem {
  shipmentIds: number[]
  state: string
  setAt: string
  reasonCode: string
}

export const getCollation = (
  shipmentId: number,
  collations: IRecoveryCollation[] | undefined
): IRecoveryCollation | undefined =>
  collations && collations.find(c => c.shipment_id === shipmentId)

export const splitTime = (time: string) =>
  time.match(/([0-2][0-9]):([0-6][0-9])$/)

export const isDepot = (shipmentId: number) => shipmentId < 0
export const isFinalState = (state?: SHIPMENT_STATES) =>
  state &&
  [
    SHIPMENT_STATES.SHIPMENT_NOT_DELIVERED,
    SHIPMENT_STATES.SHIPMENT_DELIVERED_TO_NEIGHBOR,
    SHIPMENT_STATES.SHIPMENT_DELIVERED,
    SHIPMENT_STATES.EXCEPTION_SHIPMENT_NOT_ARRIVED,
    SHIPMENT_STATES.EXCEPTION_SHIPMENT_INVESTIGATE,
  ].includes(state)

export const canBeSet = (collation: IRecoveryCollation) =>
  !isFinalState(collation.state) && !isDepot(collation.shipment_id)

export const isDelivered = (state: SHIPMENT_STATES) => {
  return state === SHIPMENT_STATES.SHIPMENT_DELIVERED
}

export const isDeliveredToNeighbour = (state: SHIPMENT_STATES) => {
  return state === SHIPMENT_STATES.SHIPMENT_DELIVERED_TO_NEIGHBOR
}

export const isAcceptedByDriver = (state: SHIPMENT_STATES) => {
  return (
    state === SHIPMENT_STATES.RETURN_ACCEPTED_BY_DRIVER ||
    state === SHIPMENT_STATES.DATA_PROCESSED
  )
}

export const isNotDelivered = (state: SHIPMENT_STATES) => {
  return (
    state === SHIPMENT_STATES.EXCEPTION_SHIPMENT_DECLINED_BY_DRIVER ||
    state === SHIPMENT_STATES.SHIPMENT_NOT_DELIVERED ||
    state === SHIPMENT_STATES.RETURN_SHIPMENT_TO_SENDER ||
    state === SHIPMENT_STATES.EXCEPTION_SHIPMENT_NOT_ARRIVED
  )
}

export const formatCollationTime = (timeValue: any) => {
  return moment()
    .set('hour', timeValue.substring(0, 2))
    .set('m', timeValue.substring(3, 5))
    .set('second', 0)
    .format('YYYY-MM-DDTHH:mm:ss')
}

/**
 * This filters out callation without `modified` and `isSelected` identifier
 * @param collations: array source to filter
 */
export const setModifiedCollation = (collations: Row[]) => {
  const modifiedCollations = without(
    collations.map(row => {
      if (row.modified && row.isSelected) {
        return row.modified
      }
    }),
    undefined
  )

  return modifiedCollations
}

/**
 * Group collation values
 * @param modified: final modified collation
 * @return final payload
 */
export const setCollationFinalValue = (modified: any[]): any => {
  const groupedCollation = groupBy(
    modified,
    collation =>
      `"${get(collation, 'selectedShipmentState')}+${get(
        collation,
        'selectedReasonCode.code',
        ''
      )}${get(collation, 'selectedTime')}"`
  )

  const cleanValue = Object.keys(groupedCollation)
    .map(key => {
      return groupedCollation[key]
    })
    .reduce((acc, items) => {
      const shipmentIds = items.map(item => get(item, 'shipment_id'))
      acc.push(new Array({
        shipmentIds,
        state: get(items, `0.selectedShipmentState`),
        reasonCode: get(items, `0.selectedReasonCode.code`),
        setAt: formatCollationTime(get(items, `0.selectedTime`)),
      }) as any)
      return acc
    }, [])
  return cleanValue
}
/**
 * A function that calls API for state mass update
 * @param payloadItem: Payload to send
 * @param massUpdateFunc: API call for mass update
 */
const handleMassUpdate = async (
  payloadItem: PayloadItem,
  massUpdateFunc: any
): Promise<void> => {
  await massUpdateFunc(payloadItem)
}

/**
 * A function to split API call based on shipmentId count
 * @param payload: Unsplitted payload with shipmentIds
 * @param massUpdateFunc: API func
 */
export const handleCleanPayload = async (
  payload: any[],
  massUpdateFunc: any
): Promise<void> => {
  const { setAt, state, shipmentIds, reasonCode } = payload[0][0]
  const maxShipmentIds = 10

  let index = 0
  while (index < shipmentIds.length) {
    const group = shipmentIds.slice(index, index + maxShipmentIds)
    const payloadItem: PayloadItem = {
      shipmentIds: group,
      state,
      setAt,
      reasonCode,
    }

    await handleMassUpdate(payloadItem, massUpdateFunc)

    index += maxShipmentIds
  }
}
