import * as React from 'react'
import { useNotificationActions } from '@/store/notifications/actions'
import { initialState } from '@/store/notifications/state'
import { NotificationsContext } from '@/contexts'

export default ({ children }: { children: React.ReactNode }) => {
  const state = useNotificationActions(initialState)
  return (
    <NotificationsContext.Provider value={state}>
      {children}
    </NotificationsContext.Provider>
  )
}
