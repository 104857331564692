import Icon from '@/app/base/components/.v2/Icon'
import { useTranslation } from '@/hooks'
import React, { memo } from 'react'

import styles from './styles.module.scss'

const EmptyResult = () => {
  const [t] = useTranslation('ALL_SHIPMENTS')

  return (
    <div className={styles.emptyResultContainer}>
      <Icon name="no-result" />
      <div className={styles.header}>{t('NO_MATCHING_SHIPMENTS_TITLE')}</div>
      <div className={styles.subDetails}>
        {t('NO_MATCHING_SHIPMENTS_FOUND')}
      </div>
    </div>
  )
}

export default memo(EmptyResult)
