import clsx from 'clsx'
import * as React from 'react'
import { LINEHAUL_DELAY_STATUS } from 'Trunkrs-SDK/dist/models/spatial/Linehauls/LinehaulTour'

import classes from './style.module.scss'

interface IOnTimeSpanProps {
  status: LINEHAUL_DELAY_STATUS | undefined
  extraClassNames: string[]
}

const OnTimeSpan: React.FC<IOnTimeSpanProps> = ({
  status,
  extraClassNames,
  children,
}) => {
  return (
    <span
      className={clsx(
        status === LINEHAUL_DELAY_STATUS.ON_TIME && classes.on_time,
        status === LINEHAUL_DELAY_STATUS.MIN_15_DELAY && classes.fifteen_delay,
        status === LINEHAUL_DELAY_STATUS.MIN_30_DELAY && classes.thirty_delay,
        ...extraClassNames
      )}
    >
      {children}
    </span>
  )
}

export default OnTimeSpan
