import { MerchantContext } from '@/contexts'
import { useMerchantActions } from '@/store/merchants/actions'
import { initialState } from '@/store/merchants/state'
import React from 'react'

export default ({ children }: { children: React.ReactNode }) => {
  const state = useMerchantActions(initialState)
  return (
    <MerchantContext.Provider value={state}>
      {children}
    </MerchantContext.Provider>
  )
}
