import './style.scss'

import { ModalForm } from '@/app/base/components'
import { ShipmentsContext } from '@/contexts'
import { useTranslation } from '@/hooks'
import { eventHandler } from '@/utils'
import React, { useCallback, useContext, useState } from 'react'

interface IDriverRemarkProps {
  handleClose?: (ev?: React.SyntheticEvent<any>) => void
}

const DriverRemark = ({ handleClose }: IDriverRemarkProps) => {
  const { activeShipment: shipment } = useContext(ShipmentsContext)
  const [remark, setRemark] = useState((shipment && shipment.getRemarks) || '')
  const [t] = useTranslation(['DRIVER_REMARK', 'COMMON'])

  const handleSave = useCallback(
    async (ev: React.SyntheticEvent<any>) => {
      const target = ev.currentTarget

      if (shipment) {
        shipment.setRemarks = remark
        await shipment.save()
        if (typeof handleClose === 'function') {
          handleClose(target)
        }
      }
    },
    [shipment, remark]
  )

  const handleSetRemark = useCallback(
    (ev: React.SyntheticEvent<HTMLTextAreaElement>) =>
      setRemark(ev.currentTarget.value),
    [setRemark, eventHandler]
  )

  return (
    <ModalForm
      show={true}
      title={t('SET_DRIVER_REMARK')}
      handleClose={handleClose}
    >
      <div id="driver-remark">
        <div className="caption text mb-3">
          {t('SET_DRIVER_REMARK_DESCRIPTION')}
        </div>
        <div className="remark">
          <textarea
            className="form-control text"
            rows={5}
            placeholder={t('WRITE_A_REMARK_HERE')}
            value={remark}
            onChange={handleSetRemark}
          />
        </div>
        <div className="actions">
          <button
            className="btn button inactive icon"
            type="button"
            onClick={handleClose}
          >
            {t('COMMON:BUTTON.CLOSE')}
          </button>
          <button
            className="btn button success box-shadow"
            type="button"
            onClick={handleSave}
          >
            {t('COMMON:BUTTON.SAVE')}
          </button>
        </div>
      </div>
    </ModalForm>
  )
}

export default DriverRemark
