import { ITour } from '@/models'

export interface ITourState {
  tours: Array<ITour>
  addTour: (tour: ITour) => void
  updateTour: (tour: ITour) => void
  fetchTour: (id: number) => any | null
}

export const initialTourState: ITourState = {
  tours: [],
  addTour: () => null,
  updateTour: () => null,
  fetchTour: () => null,
}
