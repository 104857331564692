import FormControl from '@material-ui/core/FormControl/FormControl'
import TextareaAutosize, {
  TextareaAutosizeProps,
} from '@material-ui/core/TextareaAutosize'
import clsx from 'clsx'
import get from 'lodash/get'
import React, { memo } from 'react'
import { useFormContext } from 'react-hook-form'

interface ITextAreaFieldProps extends TextareaAutosizeProps {
  id: string
  name: string
  label?: string
}

const TextAreaField = ({
  id,
  label,
  name,
  className,
  ...props
}: ITextAreaFieldProps) => {
  const { register, errors } = useFormContext()
  const fieldError = get(errors, `${name}.message`)

  return (
    <FormControl
      className={clsx('textarea-field', className, fieldError && 'has-error')}
      fullWidth={true}
    >
      {label && <label htmlFor={id}>{label}</label>}
      <TextareaAutosize id={id} name={name} ref={register} {...props} />
      {fieldError && <div className="invalid-tooltip">{fieldError}</div>}
    </FormControl>
  )
}

export default memo(TextAreaField)
