import './style.scss'

import {
  CancelShipment,
  PostponeShipment,
  SetFinalState,
} from '@/app/features/shipment/components'
import { SHIPMENT_STATE } from '@/constants'
import { ShipmentsContext } from '@/contexts'
import { useTranslation } from '@/hooks'
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

const ShipmentActions = () => {
  const {
    activeShipment: shipment,
    reopenCancelledShipment,
    reOpenShipmentLoading,
  } = useContext(ShipmentsContext)

  const [showCancelShipment, setShowCancelShipment] = useState(false)
  const [showPostponeShipment, setShowPostponeShipment] = useState(false)
  const [showSetFinalState, setShowSetFinalState] = useState(false)
  const [canCancel, setCanCancel] = useState(true)
  const [canPostpone, setCanPostpone] = useState(true)

  const canSetToFinalState = true

  useEffect(() => {
    if (shipment) {
      shipment
        .canTransitToState(
          SHIPMENT_STATE.EXCEPTION_SHIPMENT_CANCELLED_BY_TRUNKRS
        )
        .then(setCanCancel)
      shipment
        .canTransitToState(SHIPMENT_STATE.CHANGE_SHIPMENT_POST_PONED)
        .then(setCanPostpone)
    }
  }, [shipment, reOpenShipmentLoading])

  const handleOpenTours = useCallback(() => reopenCancelledShipment(), [])

  const handleShowFinalState = useCallback(() => setShowSetFinalState(true), [])
  const handleShowPostponeShipment = useCallback(
    () => setShowPostponeShipment(true),
    []
  )
  const handleShowCancelShipment = useCallback(
    () => setShowCancelShipment(true),
    []
  )
  const handleHideFinalState = useCallback(
    () => setShowSetFinalState(false),
    []
  )
  const handleHidePostponeShipment = useCallback(
    () => setShowPostponeShipment(false),
    []
  )
  const handleHideCancelShipment = useCallback(
    () => setShowCancelShipment(false),
    []
  )

  const [t] = useTranslation(['SHIPMENT_ACTIONS', 'COMMON'])

  return (
    <div id="shipment-actions-panel">
      <div className="wrapper">
        <button
          disabled={!canSetToFinalState}
          className="btn button success box-shadow"
          type="button"
          onClick={handleShowFinalState}
        >
          {t('BUTTON.SET_FINAL_STATE')}
        </button>
        <button
          disabled={!canPostpone}
          className="btn button assist box-shadow"
          type="button"
          onClick={handleShowPostponeShipment}
        >
          {t('BUTTON.POSTPONE')}
        </button>
        <button
          disabled={!canCancel}
          className="btn button danger box-shadow"
          type="button"
          onClick={handleShowCancelShipment}
        >
          {t('COMMON:BUTTON.CANCEL')}
        </button>
        <button
          disabled={canCancel}
          className="btn button overrule box-shadow"
          type="button"
          onClick={handleOpenTours}
        >
          {t('COMMON:BUTTON.OVERRULE')}
          {reOpenShipmentLoading}
        </button>
      </div>
      <div className="modal-section">
        {showCancelShipment && (
          <CancelShipment handleClose={handleHideCancelShipment} />
        )}
        {showPostponeShipment && (
          <PostponeShipment handleClose={handleHidePostponeShipment} />
        )}
        {showSetFinalState && (
          <SetFinalState handleClose={handleHideFinalState} />
        )}
      </div>
    </div>
  )
}

export default memo(ShipmentActions)
