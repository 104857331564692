import { ClientContext } from '@/contexts'
import { initialState } from '@/store/client/state'
import { useClientActions } from '@/store/client/actions'
import React from 'react'

export default ({ children }: { children: React.ReactNode }) => {
  const state = useClientActions(initialState)
  return (
    <ClientContext.Provider value={state}>{children}</ClientContext.Provider>
  )
}
