import { TourRecoveryContext } from '@/contexts'
import * as React from 'react'

import { useTourRecovery } from './actions'
import { initialTourRecoveryState } from './state'

export default ({ children }: { children: React.ReactNode }) => {
  const state = useTourRecovery(initialTourRecoveryState)
  return (
    <TourRecoveryContext.Provider value={state}>
      {children}
    </TourRecoveryContext.Provider>
  )
}
