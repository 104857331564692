import * as React from 'react'
import './style.scss'
import { classList } from '@/utils'
import { defaultTo } from 'lodash'

interface IModalProps {
  show?: boolean
  className?: string
  children?: React.ReactNode
}

export const Modal = (props: IModalProps) => {
  const { className, show, children } = props
  return (
    <div
      className={classList(
        'modal',
        { 'd-block': defaultTo(show, false) },
        defaultTo(className, '')
      )}
      tabIndex={-1}
      role="dialog"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">{children}</div>
      </div>
    </div>
  )
}
