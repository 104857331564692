import TimeInput from '@/app/base/components/timeInput'
import { SHIPMENT_STATE } from '@/constants'
import { TourRecoveryContext } from '@/contexts'
import { useTranslation } from '@/hooks'
import { ReasonCode } from '@/models'
import { get } from 'lodash'
import React, { useCallback, useContext, useMemo, useState } from 'react'

import ReasonCodeSelect from '../reasonCodesSelect'
import ShipmentStateSelect from '../shipmentStateSelect'
import classes from './styles.module.scss'

export const MassUpdateForm: React.FC = () => {
  const { selectedRows, reasonCodes, handleApplyPreview } = useContext(
    TourRecoveryContext
  )

  const [selectedState, setSelectedState] = useState<SHIPMENT_STATE | 'none'>(
    'none'
  )
  const [selectedReasonCode, setSelectedReasonCode] = useState<
    ReasonCode | 'none'
  >('none')
  const [selectedTime, setSelectedTime] = useState('')

  const [t] = useTranslation('TOUR_RECOVERY')

  const handleShipmentState = useCallback((event: any) => {
    const isNonDelivered =
      event.target.value === SHIPMENT_STATE.SHIPMENT_NOT_DELIVERED
    if (!isNonDelivered) {
      setSelectedReasonCode('none')
    }

    setSelectedState(event.target.value)
  }, [])

  const handleReasonCode = useCallback(
    (event: any) => {
      const selected = reasonCodes.find(
        reasonCode => get(reasonCode, 'code') === event.target.value
      )
      setSelectedReasonCode(selected || 'none')
    },
    [reasonCodes]
  )

  const handleTime = useCallback(value => {
    setSelectedTime(value)
  }, [])

  const handlePreview = useCallback(() => {
    if (selectedState === 'none') return
    handleApplyPreview(
      selectedState,
      selectedTime,
      selectedReasonCode !== 'none' ? selectedReasonCode : undefined
    )
  }, [selectedState, selectedReasonCode, selectedTime, handleApplyPreview])

  const shouldDisablePreviewButton = useMemo(
    () => selectedState === 'none' || !selectedRows.length,
    [selectedRows, selectedState]
  )

  return (
    <div className={classes.root}>
      <ShipmentStateSelect
        onChange={handleShipmentState}
        value={selectedState}
      />

      <ReasonCodeSelect
        onChange={handleReasonCode}
        value={get(selectedReasonCode, 'code', 'none')}
        reasonCodes={reasonCodes}
        disabled={selectedState !== SHIPMENT_STATE.SHIPMENT_NOT_DELIVERED}
        t={t}
      />

      <TimeInput
        onChange={handleTime}
        value={selectedTime}
        className={classes.time_input}
      />

      <button
        className="btn button success"
        onClick={handlePreview}
        disabled={shouldDisablePreviewButton}
      >
        {t('PREVIEW')}
      </button>
    </div>
  )
}

export default MassUpdateForm
