import clsx from 'clsx'
import React, { useMemo } from 'react'

import LoadingTile from '../loadingTile'
import classes from './style.module.scss'

interface ILoadingBarsProps {
  numberOfBar: number
  barHeight: number
}

const LoadingBars: React.FC<ILoadingBarsProps> = ({
  numberOfBar,
  barHeight,
}) => {
  const rows = useMemo(
    () =>
      Array(numberOfBar)
        .fill(0)
        .map((_, index) => (
          <tr key={index}>
            <td className={classes.bar}>
              <LoadingTile height={barHeight} />
            </td>
          </tr>
        )),
    [numberOfBar, barHeight]
  )

  return (
    <table className={clsx('table', classes.container)}>
      <tbody>{rows}</tbody>
    </table>
  )
}

export default LoadingBars
