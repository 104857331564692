import SidebarMenuIcon from '@/app/base/components/icons/sidebar-menu-icon'
import returnIcon from '@/app/base/img/return-icon.svg'
import ClientProfiles from '@/app/features/clientProfiles'
import MerchantCreation from '@/app/features/merchantCreation'
import TourRecovery from '@/app/features/tourRecovery'
import { IRoute } from '@/models'
import {
  ClientStore,
  CollectionTourStore,
  CollectionTrackingStore,
  InventoryStore,
  LinehaulTrackingStore,
  ShipmentReturnsStore,
  TourRecoveryStore,
} from '@/store'
import MerchantOverviewProvider from '@/store/merchantOverview/Provider'
import { isFeatureEnabled } from '@/utils/featureSwitch'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'

import CollectionTour from './features/collectionTour'
import CollectionTracking from './features/collectionTracking'
import DriverMessages from './features/driverMessages'
import LinehaulTracking from './features/linehaulTracking'
import MorningScanReport from './features/morningScanReport'
import { Shipment } from './features/shipment'
import ShipmentReturns from './features/shipmentReturns'
import Shipments from './features/shipments'
import TourOverview from './features/tourOverview'
import { UploadCSV } from './features/uploadcsv'
import { Login } from './features/user/components/login'
import { Warehouse } from './features/warehouse'

export default [
  {
    path: '/shipments/:id',
    exact: true,
    reactNode: <Shipment />,
  },
  {
    path: '/tour/recovery/:tourId',
    exact: true,
    reactNode: (
      <TourRecoveryStore>
        <TourRecovery />
      </TourRecoveryStore>
    ),
  },
  {
    path: '/shipments',
    exact: false,
    label: 'ALL_SHIPMENTS',
    icon: <SidebarMenuIcon icon="shipments" />,
    reactNode: <Shipments />,
    disabled: !isFeatureEnabled('ALL_SHIPMENTS'),
  },
  {
    path: '/warehouse',
    label: 'WAREHOUSE',
    icon: <SidebarMenuIcon icon="warehouse" />,
    reactNode: <Warehouse />,
    disabled: !isFeatureEnabled('WAREHOUSE'),
  },
  {
    path: '/upload',
    label: 'UPLOAD_CSV',
    icon: <FontAwesomeIcon icon="file-csv" />,
    reactNode: <UploadCSV />,
    disabled: !isFeatureEnabled('UPLOAD_CSV'),
  },
  {
    path: ['/profile', '/profile/merchant/:id'],
    exact: true,
    label: 'CLIENT_PROFILES',
    icon: <SidebarMenuIcon icon="user" />,
    reactNode: (
      <MerchantOverviewProvider>
        <ClientStore>
          <ClientProfiles />
        </ClientStore>
      </MerchantOverviewProvider>
    ),
    disabled: !isFeatureEnabled('CLIENT_PROFILES'),
  },
  {
    path: '/profile/add',
    exact: true,
    reactNode: (
      <MerchantOverviewProvider>
        <ClientStore>
          <MerchantCreation />
        </ClientStore>
      </MerchantOverviewProvider>
    ),
  },
  {
    path: '/authentication',
    exact: true,
    label: 'LOGIN',
    reactNode: <Login />,
    public: true,
  },
  {
    path: '/tour-overview',
    exact: true,
    label: 'TOUR_OVERVIEW',
    icon: <SidebarMenuIcon icon="touroverview" />,
    reactNode: <TourOverview />,
    disabled: !isFeatureEnabled('TOUR_OVERVIEW'),
  },
  {
    path: '/returns',
    exact: true,
    label: 'RETURNS',
    icon: <SidebarMenuIcon icon="returns" />,
    reactNode: (
      <ShipmentReturnsStore>
        <ShipmentReturns />
      </ShipmentReturnsStore>
    ),
  },
  {
    path: '/collection-tour',
    exact: true,
    label: 'COLLECTION_TOUR',
    icon: <FontAwesomeIcon icon="boxes" />,
    reactNode: (
      <CollectionTourStore>
        <CollectionTour />
      </CollectionTourStore>
    ),
    forCollectionTourUser: false,
    disabled: !isFeatureEnabled('COLLECTION_TOUR'),
  },
  {
    path: '/collection-tracking',
    exact: true,
    label: 'COLLECTION_TRACKING',
    icon: <FontAwesomeIcon icon="search-location" />,
    reactNode: (
      <CollectionTrackingStore>
        <CollectionTracking />
      </CollectionTrackingStore>
    ),
    disabled: !isFeatureEnabled('COLLECTION_TRACKING'),
  },
  {
    path: '/linehaul-tracking',
    exact: true,
    label: 'LINEHAUL_TRACKING',
    icon: <FontAwesomeIcon icon="map-marked-alt" />,
    reactNode: (
      <LinehaulTrackingStore>
        <LinehaulTracking />
      </LinehaulTrackingStore>
    ),
    disabled: !isFeatureEnabled('LINEHAUL_TRACKING'),
  },
  {
    path: '/driver-messages',
    exact: true,
    label: 'DRIVER_MESSAGES',
    icon: <SidebarMenuIcon icon="messages" />,
    reactNode: <DriverMessages />,
    disabled: !isFeatureEnabled('DRIVER_MESSAGES'),
  },
  {
    path: '/morning-scan-report',
    exact: true,
    label: 'MORNING_SCAN_REPORT',
    icon: <SidebarMenuIcon icon="report" />,
    reactNode: (
      <InventoryStore>
        <MorningScanReport />
      </InventoryStore>
    ),
    disabled: !isFeatureEnabled('MORNING_SCAN_REPORT'),
  },
  {
    label: 'NOTIFICATIONS',
    public: true,
    disabled: !isFeatureEnabled('NOTIFICATIONS'),
  },
] as Array<IRoute>
