/* eslint-disable no-undef */
import { useCallback, useEffect, useRef, useState } from 'react'

export const useScroll = (callback: Function) => {
  const elementRef = useRef<HTMLTableSectionElement>(null)
  const container = elementRef.current
  const [isScrolledAtBottom, setIsScrolledAtBottom] = useState(false)

  useEffect(() => {
    if (isScrolledAtBottom) {
      callback()
    }
  }, [isScrolledAtBottom])

  const handleScroll = useCallback(() => {
    if (container) {
      const { scrollHeight, scrollTop, clientHeight } = container
      const isAtBottom = scrollHeight - scrollTop === clientHeight
      setIsScrolledAtBottom(isAtBottom)
    }
  }, [isScrolledAtBottom, setIsScrolledAtBottom, container])

  return {
    elementRef,
    handleScroll,
  }
}
