import { IDriver } from '@/models'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'

interface IProps {
  driver: IDriver
  selectedDriverId: number | null
  setSelectedDriverId: (driverId: number | null) => void
  setSelectedSubCoId: (subCoId: number) => void
  setQuery: (query: string) => void
  setQueriedDrivers: (drivers: IDriver[]) => void
}

const DriverButton: React.FunctionComponent<IProps> = ({
  driver,
  selectedDriverId,
  setSelectedDriverId,
  setSelectedSubCoId,
  setQuery,
  setQueriedDrivers,
}) => {
  const handleSelectDriver = () => {
    if (driver.id === selectedDriverId) {
      setSelectedDriverId(null)
    } else {
      setSelectedDriverId(driver.id)
    }
    setSelectedSubCoId(driver.subCoId)
    setQuery('')
    setQueriedDrivers([])
  }

  return (
    <button
      key={driver.id}
      className={`${driver.delayed ? 'delayed' : ''} ${
        !!selectedDriverId
          ? selectedDriverId === driver.id
            ? 'selected'
            : 'not-selected'
          : ''
      }`}
      onClick={handleSelectDriver}
    >
      {driver.picture ? (
        <img src={driver.picture} alt={driver.name} />
      ) : (
        <FontAwesomeIcon className="driver-icon" icon="user-circle" />
      )}
      {driver.name}
    </button>
  )
}

export default DriverButton
