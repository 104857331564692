import { ILinehaulTrackingResponseRow } from 'Trunkrs-SDK/dist/models/spatial/Linehauls/LinehaulTour'
export enum LINEHAUL_ACTION_TYPES {
  FETCHING_AVAILABLE_TAGS = 'FETCHING_AVAILABLE_TAGS',
  FETCHED_AVAILABLE_TAGS = 'FETCHED_AVAILABLE_TAGS',
  FETCHING_LINEHAULS = 'FETCHING_LINEHAULS',
  FETCHED_LINEHAULS = 'FETCHED_LINEHAULS',
  FETCHING_LINEHAULS_FAILED = 'FETCHING_LINEHAULS_FAILED',
  FETCHING_DRIVER_LOCATION = 'FETCHING_DRIVER_LOCATION',
  FETCHED_DRIVER_LOCATION = 'FETCHED_DRIVER_LOCATION',
  SET_ACTIVE_TAG = 'SET_ACTIVE_TAG',
}

export type LinehaulActions =
  | {
      type: LINEHAUL_ACTION_TYPES.FETCHING_AVAILABLE_TAGS
    }
  | {
      type: LINEHAUL_ACTION_TYPES.FETCHED_AVAILABLE_TAGS
      availableTags: any[]
      activeTag: string
    }
  | {
      type: LINEHAUL_ACTION_TYPES.FETCHING_LINEHAULS
    }
  | {
      type: LINEHAUL_ACTION_TYPES.FETCHING_LINEHAULS_FAILED
    }
  | {
      type: LINEHAUL_ACTION_TYPES.FETCHED_LINEHAULS
      activeTagTours: ILinehaulTrackingResponseRow[]
    }
  | {
      type: LINEHAUL_ACTION_TYPES.FETCHING_DRIVER_LOCATION
    }
  | {
      type: LINEHAUL_ACTION_TYPES.FETCHED_DRIVER_LOCATION
      driverLocation: string[]
    }
  | {
      type: LINEHAUL_ACTION_TYPES.SET_ACTIVE_TAG
      activeTag: string
    }
