import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import { AuthContext, AppContext, RouterContext } from '@/contexts'
import { ErrorAlert } from '@/app/base/components'

import logo from '@/app/base/img/logo-dark.svg'
import g_logo from '@/app/base/img/g_search.svg'
import * as qs from 'qs'
import './style.scss'
import { useTranslation } from '@/hooks'
import { ROOT_URL } from '@/constants'

export const Login = () => {
  const [t] = useTranslation(['LOGIN', 'COMMON'])

  const { history, location } = useContext(RouterContext)
  const { requestLogin, completeLogin } = useContext(AuthContext)
  const { error, setError } = useContext(AppContext)
  const [authenticating, setAuthenticating] = useState(false)

  const { search } = location

  useEffect(() => {
    const { state, code, error, error_description } = qs.parse(search, {
      ignoreQueryPrefix: true,
    })

    if (error) {
      setError({
        value: error,
        description: error_description,
      })
    } else if (state && code) {
      setAuthenticating(true)
      ;(async () => {
        if (await completeLogin(state, code)) {
          history.push(ROOT_URL)
        }
        setAuthenticating(false)
      })()
    }
  }, [search])

  const handleLogin = () => {
    setAuthenticating(true)
    requestLogin()
  }

  return (
    <div id="form-login-container" className="container-fluid">
      {error && (
        <ErrorAlert>
          <h4 className="alert-heading">{t('COMMON:ERROR')}</h4>
          <p>{error.description}</p>
        </ErrorAlert>
      )}
      <div className="d-flex vh-100 align-items-center">
        <form id="form-login" className="box-shadow">
          <div className="logo">
            <img src={logo} alt="" />
          </div>
          {!authenticating ? (
            <button
              id="btn-login"
              className="btn button default box-shadow"
              onClick={handleLogin}
              type="button"
            >
              <img src={g_logo} alt="Login with Google" />
              {t('SIGN_IN')}
            </button>
          ) : (
            <p className="col align-self-center text">
              {t('AUTHENTICATING')}
              <span
                className="spinner-grow spinner-grow-sm  ml-1"
                role="status"
              />
            </p>
          )}
        </form>
      </div>
    </div>
  )
}
