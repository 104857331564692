import { FILE_INPUT_STATE } from '@/constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, {
  ReactNode,
  createContext,
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { useDropzone } from 'react-dropzone'
import { useFormContext } from 'react-hook-form'

export interface IFileInput
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  name: string
  accept?: string
  minSize?: number
  maxSize?: number
}

export const FileInputContext = createContext<{
  fileInputState: FILE_INPUT_STATE
  files: File[]
  getThumbnails: () => ReactNode | ReactNode[]
  isDragActive?: boolean
  isDragAccept?: boolean
  isDragReject?: boolean
}>({
  fileInputState: FILE_INPUT_STATE.INITIAL,
  getThumbnails: () => [],
  files: [],
})

const FileInput = ({
  name,
  accept,
  minSize = 0,
  maxSize = 20971520,
  children,
  ...props
}: IFileInput) => {
  const { register, unregister, watch, setValue } = useFormContext()

  const [fileInputState, setFileInputState] = useState<FILE_INPUT_STATE>(
    FILE_INPUT_STATE.INITIAL
  )

  const files: File[] = watch(name)
  const onDrop = useCallback(
    (droppedFiles: File[]) => {
      if (!!droppedFiles.length) {
        setValue(name, droppedFiles)
        setFileInputState(FILE_INPUT_STATE.FILE_UPLOADED)
      }
    },
    [setValue, name]
  )
  const {
    getRootProps,
    getInputProps,
    fileRejections,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept,
    minSize,
    maxSize,
  })

  useEffect(() => {
    if (!!fileRejections.length) {
      setFileInputState(FILE_INPUT_STATE.FILE_REJECTED)
    }
  }, [fileRejections])

  useEffect(() => {
    if (!files && fileRejections.length === 0) {
      setFileInputState(FILE_INPUT_STATE.INITIAL)
    }
  }, [files])

  useEffect(() => {
    register(name as any)
    return () => {
      unregister(name as any)
    }
  }, [register, unregister, name])

  const getThumbnails = useCallback(() => {
    if (files && !!files.length) {
      return (
        <div>
          {files.map(file => {
            return (
              <div key={file.name}>
                <img
                  src={URL.createObjectURL(file)}
                  alt={file.name}
                  className="img-thumbnail"
                />
              </div>
            )
          })}
        </div>
      )
    }
  }, [files])

  const reset = useCallback(() => {
    setValue(name, [])
    setFileInputState(FILE_INPUT_STATE.INITIAL)
  }, [])

  return (
    <FileInputContext.Provider
      value={{
        fileInputState,
        getThumbnails,
        files,
        isDragActive,
        isDragAccept,
        isDragReject,
      }}
    >
      <div className="file-upload-container" {...getRootProps()}>
        <input {...props} {...getInputProps()} />
        {children}
      </div>
      {fileInputState === FILE_INPUT_STATE.FILE_UPLOADED && (
        <button
          type="button"
          className="btn button action icon bg-transparent danger file-upload-reset"
          onClick={reset}
        >
          <FontAwesomeIcon icon="trash-alt" className="icon" />
        </button>
      )}
    </FileInputContext.Provider>
  )
}

export default memo(FileInput)
