import clsx from 'clsx'
import React, { memo } from 'react'

import styles from './styles.module.scss'

export const Table = memo(
  ({ children, className, ...props }: React.HTMLAttributes<any>) => (
    <table className={clsx(styles.table, className)} {...props}>
      {children}
    </table>
  )
)
export const TableRowHeader = memo(
  ({ children, className, ...props }: React.HTMLAttributes<any>) => (
    <tr className={clsx(styles.tableHeaderRow, className)} {...props}>
      {children}
    </tr>
  )
)
export const TableHeader = memo(
  ({ children, className, ...props }: React.HTMLAttributes<any>) => (
    <th className={clsx(styles.tableHeader, className)} {...props}>
      {children}
    </th>
  )
)
export const TableBody = memo(
  ({ children, className, ...props }: React.HTMLAttributes<any>) => (
    <tbody className={clsx(styles.tableBody, className)} {...props}>
      {children}
    </tbody>
  )
)
export const TableRow = memo(
  ({ children, className, ...props }: React.HTMLAttributes<any>) => (
    <tr className={clsx(styles.tableRow, className)} {...props}>
      {children}
    </tr>
  )
)
export const TableCell = memo(
  ({ children, className, ...props }: React.HTMLAttributes<any>) => (
    <td className={clsx(styles.tableCell, className)} {...props}>
      {children}
    </td>
  )
)
