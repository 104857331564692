import { SubcoContext } from '@/contexts'
import * as React from 'react'

import { useSubcoActions } from './actions'
import { initialSubcoState } from './state'

export default ({ children }: { children: React.ReactNode }) => {
  const state = useSubcoActions(initialSubcoState)
  return <SubcoContext.Provider value={state}>{children}</SubcoContext.Provider>
}
