import * as React from 'react'
import get from 'lodash/get'

export const eventHandler = (
  func: (args: any) => void,
  definedValue: any = undefined
) => {
  return (ev?: React.SyntheticEvent<any>) => {
    if (definedValue) {
      func(definedValue)
    } else {
      func(get(ev, 'currentTarget.value'))
    }
  }
}
