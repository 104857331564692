import Button from '@/app/base/components/.v2/Button'
import ModalBox, {
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '@/app/base/components/.v2/ModalBox'
import Select, { SelectItem } from '@/app/base/components/.v2/Select'
import SelectCalendar from '@/app/base/components/.v2/SelectCalendar'
import Toast from '@/app/base/components/.v2/Toast'
import { SHIPMENT_ACTIONS } from '@/app/features/shipments/components/shipmentActions'
import ToastActions from '@/app/features/shipments/components/shipmentActions/components/ToastActions'
import { AppContext, ShipmentsContext } from '@/contexts'
import { useTranslation } from '@/hooks'
import React, { memo, useCallback, useContext, useMemo, useState } from 'react'
import { SHIPMENT_STATES } from 'Trunkrs-SDK/dist/models/delivery/ShipmentLog'

import styles from './styles.module.scss'

const ShipmentDeliveredModal = ({
  selectedShipments,
  selectedAction,
  onViewResults,
  onShipmentUpdate,
  onClose = () => {},
}: {
  selectedShipments: number[]
  selectedAction?: SHIPMENT_ACTIONS
  onViewResults: (
    state: SHIPMENT_STATES,
    actualUpdatedShipments: number[]
  ) => void
  onShipmentUpdate: (
    state: SHIPMENT_STATES,
    actualUpdatedShipments: number[]
  ) => void
  onClose: () => void
}) => {
  const [t] = useTranslation(['SHIPMENT_ACTIONS'])

  const { massApplyState } = useContext(ShipmentsContext)
  const { setLoading } = useContext(AppContext)

  const [toastVisible, setToastVisible] = useState(false)
  const [deliveredDate, setDeliveredDate] = useState()

  const startUpdateCountdown = useCallback(() => {
    setToastVisible(true)
    onClose()
  }, [onClose])

  const handleClose = useCallback(() => {
    setToastVisible(false)
    onClose()
  }, [onClose])

  const executeMassDelivered = useCallback(async () => {
    setToastVisible(false)
    setLoading(true)
    const result = await massApplyState({
      shipmentIds: selectedShipments,
      state: SHIPMENT_STATES.SHIPMENT_DELIVERED,
      setAt: deliveredDate,
    })
    setLoading(false)
    return result
  }, [selectedShipments, deliveredDate])

  const handleViewResults = useCallback(async () => {
    handleClose()
    const affectedShipments = await executeMassDelivered()
    const shipmentIds = affectedShipments
      .filter(
        (shipment: any) => shipment.status >= '200' && shipment.status <= '202'
      )
      .map((shipment: any) => shipment.href)
    onViewResults(SHIPMENT_STATES.SHIPMENT_DELIVERED, shipmentIds)
  }, [onViewResults, executeMassDelivered])

  const handleMassDelivered = useCallback(async () => {
    const affectedShipments = await executeMassDelivered()
    const shipmentIds = affectedShipments
      .filter(
        (shipment: any) => shipment.status >= '200' && shipment.status <= '202'
      )
      .map((shipment: any) => shipment.href)
    onShipmentUpdate(SHIPMENT_STATES.SHIPMENT_DELIVERED, shipmentIds)
  }, [onViewResults, executeMassDelivered])

  return (
    <>
      <ModalBox
        className={styles.modalBox}
        visible={selectedAction === SHIPMENT_ACTIONS.DELIVERED}
        onClose={onClose}
      >
        <ModalHeader>{t('DELIVERED_MODAL.TITLE')}</ModalHeader>
        <ModalBody>
          <div className={styles.detailsContainer}>
            <div
              dangerouslySetInnerHTML={{
                __html: t('DELIVERED_MODAL.BODY', {
                  number: selectedShipments.length,
                  interpolation: { escapeValue: false },
                }),
              }}
            />
            <div className={styles.calendarLabel}>
              {t('DELIVERED_MODAL.SET_DATE_TIME')}
            </div>
            <SelectCalendar
              className={styles.calendarContainer}
              placeholder={t('DELIVERED_MODAL.SELECT_DATE_TIME')}
              onChange={setDeliveredDate}
              value={deliveredDate}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button variant="success" onClick={onClose}>
            {t('DELIVERED_MODAL.BTN_CANCEL')}
          </Button>
          <Button variant="link-success" onClick={startUpdateCountdown}>
            {t('DELIVERED_MODAL.BTN_OK')}
          </Button>
        </ModalFooter>
      </ModalBox>
      {toastVisible && (
        <Toast
          headerText={t('DELIVERED_MODAL.TOAST_TITLE')}
          subText={
            <ToastActions
              label={t('DELIVERED_MODAL.TOAST_DETAILS', {
                number: selectedShipments.length,
              })}
              onViewResults={handleViewResults}
              onCancel={handleClose}
            />
          }
          onExit={handleMassDelivered}
        />
      )}
    </>
  )
}

export default memo(ShipmentDeliveredModal)
