import { useTranslation } from '@/hooks'
import Chip from '@material-ui/core/Chip'
import CloseIcon from '@material-ui/icons/Close'
import snakeCase from 'lodash/snakeCase'
import React, { useCallback, useContext } from 'react'

import { SmartSearchContext } from '../../../../../../../store/smartSearch'
import { DataSourceConfig } from '../../helpers/interfaces'
import styles from './styles.module.scss'

export interface SearchChipProps {
  value: DataSourceConfig
}

export const SearchChip: React.FC<SearchChipProps> = ({ value }) => {
  const { handleRemoveChip } = useContext(SmartSearchContext)
  const [t] = useTranslation(['SMART_SEARCH', 'SHIPMENT_STATES'])

  const handleRemove = useCallback(() => {
    // remove  id from array
    handleRemoveChip(value.id)
  }, [value, handleRemoveChip])

  return (
    <div className={styles.container}>
      <Chip
        label={
          <span>
            {`${t(snakeCase(value.key).toUpperCase())}: ${t(value.value)}`}
          </span>
        }
        classes={{
          root: styles.root,
          label: styles.label,
        }}
        onDelete={handleRemove}
        deleteIcon={<CloseIcon className={styles.closeIcon} />}
      />
    </div>
  )
}

export default SearchChip
