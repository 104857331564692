import './style.scss'

import { useTranslation } from '@/hooks'
import * as React from 'react'

interface ISender {
  name: string
  address: string
  postalCode: string
  city: string
  country: string
  contact: string
  email: string
}

export interface ISenderProps {
  sender: ISender
}

export const Sender: React.SFC<ISenderProps> = ({ sender }) => {
  const [t] = useTranslation(['SENDER'])
  const { address, city, contact, email, name, postalCode } = sender

  return (
    <div id="sender-panel">
      <h2 className="text">{t('SENDER')}</h2>
      <div className="wrapper box-shadow">
        <div className="sender-name">{name}</div>
        <div className="sender-address">
          {`${address}\n${postalCode} ${city}`}
        </div>
        <div className="sender-contact">{contact}</div>
        <div className="sender-email">{email}</div>
      </div>
    </div>
  )
}
