import { ICollectionTrackingState } from './state'

interface IAction {
  type: string
  payload?: any
}

const reducer = (
  state: ICollectionTrackingState,
  action: IAction
): ICollectionTrackingState => {
  const { type, payload } = action
  return { ...state, [type]: payload }
}

export default reducer
