import ItemsNavigation from '@/app/base/components/itemsNavigation'
import LoadingBars from '@/app/base/components/loadingBars'
import { CollectionTourContext } from '@/contexts'
import { useTranslation } from '@/hooks'
import clsx from 'clsx'
import React, { useContext, useEffect, useMemo, useState } from 'react'

import CollectionTourMap from '../collectionTourMap'
import PublishModal from '../publishModal'
import classes from './style.module.scss'

interface IMainContentProps {
  toggleDrawer: (shouldBeOpen: boolean) => void
  isDrawerOpen: boolean
}

const MainContent: React.FC<IMainContentProps> = ({
  toggleDrawer,
  isDrawerOpen,
}) => {
  const [t] = useTranslation('COLLECTION_TOUR')
  const [localActiveTour, setLocalActiveTour] = useState<number | null>(null)
  const [openModal, setOpenModal] = useState(false)
  const {
    availableTags,
    activeTag,
    setActiveTag,
    fetchCollectionTours,
    activeTagTours,
    fetchingCollectionTours,
    fetchCollectionTourView,
  } = useContext(CollectionTourContext)

  useEffect(() => {
    fetchCollectionTours(activeTag)
  }, [activeTag])

  const handleSelectTour = (tourId: number) => {
    toggleDrawer(true)
    setLocalActiveTour(tourId)
    fetchCollectionTourView(tourId)
  }

  const handleModalOpen = () => {
    toggleDrawer(false)
    setOpenModal(true)
  }

  const handleModalClose = () => {
    setOpenModal(false)
  }

  const tourList = useMemo(
    () =>
      activeTagTours &&
      activeTagTours.map((tour, index) => {
        const isActive = localActiveTour === tour.tourId
        return (
          <tr
            onClick={() => handleSelectTour(tour.tourId)}
            key={tour.tourId}
            className={clsx(isActive && isDrawerOpen && classes.active)}
          >
            <td>{`${activeTag}-${index + 1}`}</td>
            <td>{tour.subcoName}</td>
            <td>{tour.stops.map(stop => stop.shopName).join(', ')}</td>
            <td>{tour.timeOfDeparture.substring(0, 5)}</td>
            <td>{tour.timeOfArrival.substring(0, 5)}</td>
          </tr>
        )
      }),
    [activeTagTours, localActiveTour]
  )

  const handleSelectTag = (tag: string) => {
    toggleDrawer(false)
    setActiveTag(tag)
  }

  return (
    <div>
      <div className={classes.header}>
        <h1 className={clsx('h2 mt-4 text-left title', classes.main_title)}>
          {t('COLLECTION_TOURS')}
        </h1>
        <button
          type="button"
          onClick={handleModalOpen}
          className="btn button action"
        >
          {t('PUBLISH_NEW_TOURS')}
        </button>
      </div>
      <CollectionTourMap isDrawerOpen={isDrawerOpen} />
      <ItemsNavigation
        items={availableTags}
        activeItem={activeTag}
        onSelectItem={handleSelectTag}
      />
      {fetchingCollectionTours ? (
        <LoadingBars numberOfBar={3} barHeight={55} />
      ) : (
        <table className={clsx('table', classes.tour_table)}>
          <tbody>
            <tr className={classes.table_header_row}>
              <th>{t('TABLE.TOUR')}</th>
              <th>{t('TABLE.SUBCO')}</th>
              <th>{t('TABLE.SHOPS')}</th>
              <th>{t('TABLE.DEPARTURE')}</th>
              <th>{t('TABLE.ARRIVAL')}</th>
            </tr>
            {activeTagTours && activeTagTours.length ? (
              tourList
            ) : (
              <tr className={classes.noTours}>
                <td colSpan={5}>{t('TABLE.NO_COLLECTIONS_TODAY')}</td>
              </tr>
            )}
          </tbody>
        </table>
      )}
      <PublishModal openModal={openModal} handleModalClose={handleModalClose} />
    </div>
  )
}

export default MainContent
