import { useTranslation } from '@/hooks'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { COLLECTION_LOG_STATES } from 'Trunkrs-SDK/dist/models/spatial/Collections/CollectionTour'
import { TOUR_STATES } from 'Trunkrs-SDK/dist/models/spatial/TourState'

import classes from '../style.module.scss'

interface IStatusBoxProps {
  state: string
  tourState: string
  position: number | string
}

const checkIfGreenState = (tourState: string) =>
  ['TOUR_IN_TRANSIT', 'TOUR_COMPLETED'].includes(tourState)

const StatusBox: React.FC<IStatusBoxProps> = ({
  state,
  tourState,
  position,
}) => {
  const [labelState, setLabelState] = useState({
    className: '',
    text: '',
  })

  const [t] = useTranslation('COLLECTION_TRACKING')

  useEffect(() => {
    let item = { className: '', text: '' }
    switch (state) {
      case COLLECTION_LOG_STATES.COLLECTION_MARKED_FOR_COLLECTION:
        item = {
          className: 'dark-gray',
          text: t('STATE.AWAITING_PICK_UP'),
        }
        break
      case COLLECTION_LOG_STATES.COLLECTION_COLLECTED:
        item = {
          className: 'green',
          text: t('STATE.PICKED_UP'),
        }
        break
      case COLLECTION_LOG_STATES.COLLECTION_NOT_COLLECTED:
        item = {
          className: 'red',
          text: t('STATE.NOT_COLLECTED'),
        }
        break
      default:
        break
    }

    if (position === t('START') && checkIfGreenState(tourState)) {
      item = {
        className: 'green',
        text: t('STATE.TOUR_STARTED'),
      }
    }

    if (position === t('START') && !checkIfGreenState(tourState)) {
      item = {
        className: 'dark-gray',
        text: t('STATE.NOT_STARTED'),
      }
    }

    if (position === t('END') && tourState === 'TOUR_COMPLETED') {
      item = {
        className: 'green',
        text: t('STATE.FINISH'),
      }
    }

    if (position === t('END') && tourState !== 'TOUR_COMPLETED') {
      item = {
        className: 'dark-gray',
        text: t('STATE.AWAITING_FINISH'),
      }
    }

    setLabelState(item)
  }, [state, position, tourState])

  return (
    <div className={clsx(classes.text_on_box, classes[labelState.className])}>
      {labelState.text}
    </div>
  )
}

export default StatusBox
