import { SmartSearchContext } from '@/store/smartSearch'
import format from 'date-fns/format'
import isAfter from 'date-fns/isAfter'
import isBefore from 'date-fns/isBefore'
import isEqual from 'date-fns/isEqual'
import isSameMonth from 'date-fns/isSameMonth'
import isWithinInterval from 'date-fns/isWithinInterval'
import lastDayOfMonth from 'date-fns/lastDayOfMonth'
import startOfDay from 'date-fns/startOfDay'
import defaultTo from 'lodash/defaultTo'
import { useContext, useEffect, useState } from 'react'

export interface DateHighlightResult {
  isHighlighted: boolean
  isStart: boolean
  isEnd: boolean
}

const useDateHighlight = (
  date: Date,
  outsideDayType: boolean | 'prev' | 'next',
  calendarMonth?: Date
): DateHighlightResult => {
  const [isHighlighted, setIsHighlighted] = useState(false)
  const [isStart, setIsStart] = useState(false)
  const [isEnd, setIsEnd] = useState(false)
  const { dateStart, dateEnd } = useContext(SmartSearchContext)

  useEffect(() => {
    // const left = format(date, 'dd-MM-yyyy')

    // if date is the start OR end
    setIsStart(isEqual(date, defaultTo(dateStart, 0)))
    setIsEnd(isEqual(date, startOfDay(new Date(defaultTo(dateEnd, 0)))))

    if (dateStart && !dateEnd) {
      setIsHighlighted(false)
    }

    if (!dateStart || !dateEnd) return

    let isDateWithinSelectedRange = false

    if (calendarMonth) {
      if (outsideDayType === 'prev') {
        isDateWithinSelectedRange =
          (isBefore(calendarMonth, dateEnd) ||
            isSameMonth(calendarMonth, dateEnd)) &&
          isAfter(calendarMonth, dateStart)
      }

      if (outsideDayType === 'next') {
        isDateWithinSelectedRange =
          (isAfter(calendarMonth, dateStart) ||
            isSameMonth(calendarMonth, dateStart)) &&
          isBefore(lastDayOfMonth(calendarMonth), dateEnd)
      }
    } else {
      isDateWithinSelectedRange = isWithinInterval(date, {
        start: dateStart,
        end: dateEnd,
      })
    }

    setIsHighlighted(isDateWithinSelectedRange)
  }, [date, outsideDayType, calendarMonth, dateEnd, dateStart])

  return { isHighlighted, isStart, isEnd }
}

export default useDateHighlight
