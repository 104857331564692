import { useRef, useEffect, useState, useMemo } from 'react'

export const useInterval = (
  callback: (...args: any[]) => void,
  delay: number = 120000
) => {
  const savedCallback = useRef<any>()
  const interval = useRef<any>()
  const [shouldStart, setShouldStart] = useState(false)

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  const start = () => setShouldStart(true)
  const stop = () => {
    if (interval.current) {
      clearInterval(interval.current)
    }
  }

  useEffect(() => {
    if (shouldStart) {
      const tick = () => savedCallback.current()
      stop()
      interval.current = setInterval(tick, delay)
      return () => stop()
    }
  }, [delay, start])

  return useMemo(() => [start, stop], [])
}
