import './style.scss'

import Icon from '@/app/base/components/.v2/Icon'
import {
  AddressHistory,
  EditRecipient,
} from '@/app/features/shipment/components'
import { ShipmentsContext } from '@/contexts'
import { useTranslation } from '@/hooks'
import { IPersonalInfo, IShipment, Shipment } from '@/models'
import { copyToClipboard, eventHandler } from '@/utils'
import get from 'lodash/get'
import md5 from 'md5'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { normalizeString } from 'Trunkrs-SDK/dist/utils/normalizeString'

import ToolTip from '../../../../base/components/.v2/Tooltip'

interface IRecipientProps {
  shipment: IShipment
  addressChangeFeatures?: {
    isAddressChangeDisabled: boolean
    reasonText: string
  }
}

export const Recipient = ({
  shipment,
  addressChangeFeatures,
}: IRecipientProps) => {
  const {
    fetchAddressHistory,
    addressHistory,
    activeShipmentDetails,
  } = useContext(ShipmentsContext)

  const [showEditRecipientForm, setShowEditRecipientForm] = useState(false)
  const [showAddressHistory, setShowAddressHistory] = useState(false)
  const [showTooltip, setTooltipShow] = useState(false)

  const handlePrint = useCallback(async () => {
    if (shipment && shipment.trunkrsNr) {
      window.location.href = `${Shipment.getBaseUrl()
        .replace('v2', 'api/v1')
        .replace('app', 'nl')}/label/${md5(
        shipment.trunkrsNr + normalizeString(shipment.recipient.postalCode)
      )}/${shipment.trunkrsNr}`
    }
  }, [shipment])

  useEffect(() => {
    fetchAddressHistory()
  }, [shipment])

  const showAddressHistoryModal = useCallback(
    (open: boolean) => eventHandler(setShowAddressHistory, open),
    [setShowAddressHistory, eventHandler]
  )

  const showEditRecipientFormModal = useCallback(
    (open: boolean) => eventHandler(setShowEditRecipientForm, open),
    [setShowEditRecipientForm, eventHandler]
  )

  const [t] = useTranslation(['RECIPIENT'])

  const {
    name,
    address,
    postalCode,
    city,
    phoneNumber,
    emailAddress,
  } = shipment.recipient as IPersonalInfo

  const copyRecipientDetails = (text: string) => {
    copyToClipboard(text)
    setTooltipShow(true)
    setTimeout(() => {
      setTooltipShow(false)
    }, 400)
  }

  const isAddressChangeDisabled = get(
    addressChangeFeatures,
    'isAddressChangeDisabled',
    false
  )
  const addressChangeDisabledReasonText = get(
    addressChangeFeatures,
    'reasonText',
    ''
  )

  return (
    <>
      <div id="recipient-panel">
        <h2 className="text">{t('RECIPIENT')}</h2>
        <div className="wrapper box-shadow">
          <div className="recipient-info">
            <div className="recipient-name">{name}</div>
            <div className="recipient-address">
              {`${address}\n${postalCode} ${city}`}
            </div>
            <div className="recipient-contact">{phoneNumber}</div>
            <div className="recipient-email">{emailAddress}</div>
          </div>
          <div className="actions">
            {addressChangeFeatures == undefined ? (
              <span className="edit-recipient-loader">
                <Icon name="spinner" />
              </span>
            ) : isAddressChangeDisabled ? (
              <span className="edit-recipient-disabled-button">
                <ToolTip
                  text={addressChangeDisabledReasonText}
                  placement="bottom-end"
                  className="tooltip-recipient-change-address"
                >
                  <button className="btn button action icon bg-transparent edit">
                    <Icon name="pencil" />
                  </button>
                </ToolTip>
              </span>
            ) : (
              <button
                className="btn button action icon bg-transparent edit"
                onClick={showEditRecipientFormModal(true)}
              >
                <Icon name="pencil" />
              </button>
            )}
            <button
              className="btn button action icon bg-transparent print"
              onClick={handlePrint}
            >
              <Icon name="print" />
            </button>
            <span className="span-tooltip">
              <span
                className={
                  showTooltip
                    ? 'copy-tooltip-success visible'
                    : 'copy-tooltip-success'
                }
              >
                {t('TEXT_COPIED')}
              </span>
              <button
                className="btn button action icon bg-transparent copy"
                onClick={() =>
                  copyRecipientDetails(
                    `${name}\n${address}\n${postalCode} ${city}`
                  )
                }
              >
                <Icon name="copy" />
              </button>
            </span>
          </div>
        </div>
        {addressHistory.length > 1 && (
          <div className="notification d-flex justify-content-between align-items-center">
            <p className="text">{t('SHIPMENT_ADDRESS_CHANGED')}</p>
            <button
              className="btn button icon"
              type="button"
              onClick={showAddressHistoryModal(true)}
            >
              {t('BUTTON.SEE_CHANGE')}
            </button>
          </div>
        )}
      </div>
      {showEditRecipientForm && (
        <EditRecipient handleClose={showEditRecipientFormModal(false)} />
      )}
      {showAddressHistory && (
        <AddressHistory handleClose={showAddressHistoryModal(false)} />
      )}
    </>
  )
}
