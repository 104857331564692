import './style.scss'

import StatusLabel from '@/app/base/components/statusLabel'
import { ShipmentsContext } from '@/contexts'
import { useTranslation } from '@/hooks'
import { getNextBusinessDay } from '@/utils'
import * as checkStatus from '@/utils/checkStatus'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import get from 'lodash/get'
import moment from 'moment'
import React, { useContext } from 'react'

export const ExpectedArrival = () => {
  const {
    activeShipmentDetails: shipmentDetails,
    activeOverviewShipment,
  } = useContext(ShipmentsContext)

  const [t] = useTranslation('SHIPMENT')

  const reasonCodeText = getReasonCodeText()

  if (!shipmentDetails || !activeOverviewShipment) return null

  const { statusText } = activeOverviewShipment

  const eta = get(shipmentDetails, 'actualETA')

  const date = get(eta, 'expectedDate', new Date())

  const isToday = moment(date).isSame(moment(), 'd')
  const isDelivered = checkStatus.isDelivered(statusText)
  const isDeliveredToNeighbour = checkStatus.isDeliveredToNeigbour(statusText)
  const isNotDelivered = checkStatus.isNotDelivered(statusText)
  const isCancelled = checkStatus.isCancelled(statusText)
  const isMissorted = checkStatus.isMissortedState(statusText)
  const isReturnToSender = checkStatus.isReturnToSender(statusText)
  const isForInvestigation = checkStatus.isForInvestigation(statusText)

  const reasonCode = isNotDelivered && reasonCodeText
  const deliveryTime = moment(date).format('HH:mm')

  const timeOpen = get(eta, 'expectedTimeOpen', moment().set({ h: 17, m: 0 }))
  const timeClose = get(eta, 'expectedTimeClose', moment().set({ h: 22, m: 0 }))
  const timeLabel = `${moment(timeOpen).format('HH:mm')} - ${moment(
    timeClose
  ).format('HH:mm')}`

  const notDelivered =
    isNotDelivered || isCancelled || isMissorted || isReturnToSender

  return (
    <div id="expected-panel">
      <h2 className="text">{t('EXPECTED')}</h2>
      <div className="wrapper box-shadow">
        <div className="status">
          <StatusLabel statusText={statusText} />
        </div>
        <div className="date">
          {isToday && t('TODAY')}
          {moment(date).format('DD MMMM YYYY')}
        </div>
        <div
          className={clsx(
            'time',
            (isDelivered || isDeliveredToNeighbour) && 'delivered',
            notDelivered && 'not-delivered',
            isForInvestigation && 'investigation'
          )}
        >
          {isDelivered || isDeliveredToNeighbour ? (
            <>
              <p>{deliveryTime}</p>
              {`${t(
                isDeliveredToNeighbour
                  ? 'ETA_SUCCESSFULY_DELIVERED_TO_NEIGHBOUR'
                  : 'ETA_SUCCESSFULY_DELIVERED'
              )}`}
            </>
          ) : isNotDelivered ? (
            reasonCode
          ) : isCancelled ? (
            t('ETA_SHIPMENT_CANCELLED')
          ) : isMissorted ? (
            t('ETA_SHIPMENT_MISSORTED')
          ) : isReturnToSender ? (
            t('ETA_SHIPMENT_RETURN_TO_SENDER')
          ) : isForInvestigation ? (
            t('ETA_SHIPMENT_FOR_INVESTIGATION')
          ) : (
            timeLabel
          )}
        </div>
        {(isNotDelivered || isMissorted) && (
          <div className="alert-info">
            <div className="icon">
              <FontAwesomeIcon icon="info-circle" />
            </div>
            <div className="text">
              {t('ETA_SHIPMENT_WILL_BE_DELIVERED_NEXT_DAY', {
                date: moment(getNextBusinessDay(date)).format('DD MMMM'),
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

const getReasonCodeText = () => {
  const { activeShipmentDetails: shipmentDetails } = useContext(
    ShipmentsContext
  )
  const [t] = useTranslation('REASON_CODES')

  const latestLog = [
    ...get(shipmentDetails, 'shipmentHistory', []),
  ].reverse()[0]
  const reasonCode = get(latestLog, 'nonDeliveryReason', '')

  return t(reasonCode)
}
