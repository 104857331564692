import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

const resources = require('i18next-resource-store-loader?overrideDir=override&baseDir=locales&include=\\.json$!../i18n/index.ts')

i18n.use(LanguageDetector).init({
  resources,
  fallbackLng: ['en', 'nl'],
  interpolation: {
    escapeValue: false,
  },
})

export default i18n
