import Drawer from '@material-ui/core/Drawer'
import clsx from 'clsx'
import React, { useState } from 'react'
import { OverflowDetector } from 'react-overflow'

import classes from './style.module.scss'

interface ILayoutWithRightDrawerProps {
  drawerContent: any
  drawerOpen: boolean
}

const LayoutWithRightDrawer: React.FC<ILayoutWithRightDrawerProps> = ({
  drawerContent,
  drawerOpen,
  children,
}) => {
  const [contentOverflowed, setContentOverflowed] = useState(false)

  const contentOverflowChange = (isOverflowed: boolean) =>
    setContentOverflowed(isOverflowed)

  return (
    <div className={classes.layout_main_container}>
      <OverflowDetector
        onOverflowChange={contentOverflowChange}
        className={clsx(
          classes.content,
          {
            [classes.content_shift]: drawerOpen,
          },
          classes.scrollable
        )}
      >
        {children}
      </OverflowDetector>
      <Drawer
        className={classes.drawer}
        open={drawerOpen}
        anchor="right"
        variant="persistent"
        classes={{
          paper: clsx(classes.drawer_paper),
        }}
        PaperProps={{
          elevation: contentOverflowed ? 0 : 3,
        }}
      >
        {drawerContent}
      </Drawer>
    </div>
  )
}

export default LayoutWithRightDrawer
