import API from '@/api'
import { useResponseErrorHandler } from '@/hooks'
import { IClientState } from '@/store/client/state'
import get from 'lodash/get'
import { useState } from 'react'

export interface ICreateMerchantParams {
  merchant: {
    v1Credentials?: {
      clientId: string
      clientSecret: string
    }
    location: {
      address: string
      city: string
      country: string
      postalCode: string
    }
    name: string
    sendEmails?: boolean
    accountManagerId: number
    productCategoryId: number
  }
  timeSlotConfig: {
    dataWindowClosesAt: string
  }
  operations: {
    startingVolumeFrom: number
    startingVolumeTo: number
    forecastedVolumeFrom: number
    forecastedVolumeTo: number
    weeksUntilForecastedVolume: number
    shipmentLengthCm: number
    shipmentWidthCm: number
    shipmentHeightCm: number
    expectedWeight: string
    cutOffTime: string
  }
  collection: {
    importantInfo: string
    contactPerson: string
    phoneNumber: string
    sameAsWebshopAddress: boolean
    parcelImage?: {
      data: string
      fileType?: string
    }
    location?: {
      address: string
      city: string
      country: string
      postalCode: string
    }
  }
  invoicing: {
    contactPerson: string
    emailAddressInvoice: string
    kvkNumber: string
    btwNumber: string
    phoneNumber: string
    sameAsWebshopAddress: boolean
    location?: {
      address: string
      city: string
      country: string
      postalCode: string
    }
  }
  pricing: {
    deliveryTier1: number
    deliveryTier2: number
    deliveryTier3: number
    deliveryTier4: number
    deliveryTier5: number
    deliveryTier6: number
    deliveryTier7: number
    deliveryXlSurcharge: number
    deliveryHeavySurcharge: number
    collection: number
  }
  itDetails: {
    webshopType: string
    integrationType: string
    recipientCommunication: string
    contactPersonEmail: string
    contactPerson: string
  }
}

export const useClientActions = (initialState: IClientState) => {
  const merchant = API.Merchant()
  const debtor = API.Debtor()
  const [accountManagers, setAccountManagers] = useState([])
  const [productCategories, setProductCategories] = useState([])

  const createMerchant = async (params: ICreateMerchantParams) => {
    const [, response] = await useResponseErrorHandler(
      merchant.saveNewMerchant(params)
    )
    // this is merchant id after created
    console.log(response)

    return response
  }

  const fetchMerchantFormResource = async () => {
    const [err, response] = await useResponseErrorHandler(
      merchant.fetchDropdownValues()
    )
    if (!err) {
      setAccountManagers(get(response, 'accountManagers', []))
      setProductCategories(get(response, 'productCategories', []))
    }
  }

  const fetchDebtorsList = async () => {
    const [err, response] = await useResponseErrorHandler(
      debtor.fetchAllDebtors()
    )
    if (!err) {
      console.log(response)
    }
  }

  const fetchMerchantDebtorInfo = async (merchantId: number) => {
    const [err, response] = await useResponseErrorHandler(
      debtor.fetchDebtorMerchant(merchantId)
    )
    if (!err) {
      console.log(response)
    }
  }

  const addMerchantToDebtor = async (
    merchantId: number,
    debtorNumber: string
  ) => {
    const [err, response] = await useResponseErrorHandler(
      debtor.linkMerchantToDebtor(merchantId, debtorNumber)
    )
    if (!err) {
      console.log(response)
    }
  }

  return {
    createMerchant,
    fetchMerchantFormResource,
    fetchMerchantDebtorInfo,
    fetchDebtorsList,
    addMerchantToDebtor,
    accountManagers,
    productCategories,
  }
}
