import clsx from 'clsx'
import * as React from 'react'

import classes from '../style.module.scss'

interface IRemarksBoxProps {
  color: string
  content: any
  fontColor?: string
  className?: string
}

const RemarksBox: React.FC<IRemarksBoxProps> = ({
  color,
  content,
  fontColor,
  className,
}) => {
  return (
    <div
      style={{ color: fontColor }}
      className={clsx(
        classes.text_on_box,
        classes.remarks,
        classes[color],
        className
      )}
    >
      {content}
    </div>
  )
}

export default RemarksBox
