import {
  IAddressChangeParameters,
  IAddressHistory,
  IAuditLogs,
  ICallLog,
  IDeliveryDetails,
  IFrozenFoodDeliveryAttempt,
  IHubLog,
  INeighbourParams,
  IOverviewShipmentsResultFilter,
  IProofOfDeliveryResult,
  IShipment,
  IShipmentDetails,
  IShipmentHistoryDetails,
  IShipmentTourEstimation,
  ReasonCode,
  Recipient,
  SHIPMENT_STATES,
  Shipment,
  ShipmentFeatures,
  ShipmentLog,
} from '@/models'
import * as React from 'react'
import { REASON_CODE } from 'Trunkrs-SDK/dist/models/delivery/ReasonCode'

export interface IShipmentsState {
  overviewShipments: Array<IShipment>
  overviewShipmentsResultFilter: IOverviewShipmentsResultFilter
  filteredShipmentIds: Array<number>
  activeShipment: Shipment | null
  activeOverviewShipment: IShipment | null
  activeShipmentLogs: Array<ShipmentLog>
  setActiveShipment: React.Dispatch<React.SetStateAction<Shipment | null>>
  fetchShipment: (id?: number) => Promise<Shipment | null>
  quickFetchShipment: (id?: number) => Promise<Shipment | null>
  getTourOverview: (shipment?: Shipment) => Promise<any>
  getActiveOverviewShipment: (id?: number) => Promise<IShipment | null>
  setActiveOverviewShipment: React.Dispatch<
    React.SetStateAction<IShipment | null>
  >
  updateOverviewShipments: (shipment: IShipment) => void
  fetchShipmentLogs: (shipment?: Shipment) => Promise<Array<ShipmentLog>>
  fetchShipmentRecipient: (shipment?: Shipment) => Promise<Recipient | null>
  fetchShipmentTourEstimation: (
    shipment?: Shipment
  ) => Promise<IShipmentTourEstimation | null>
  fetchShipmentByBarcode: (barcode: string) => Promise<Shipment | null>
  fetchOverviewShipments: (page?: number, perPage?: number) => Promise<void>
  fetching: boolean
  searchOverviewShipments: (
    filters: IOverviewShipmentsResultFilter
  ) => Promise<void>
  searchMode: boolean
  setOverviewShipmentsResultFilter: React.Dispatch<
    React.SetStateAction<IOverviewShipmentsResultFilter>
  >
  setSearchMode: React.Dispatch<React.SetStateAction<boolean>>
  postponeShipment: (date: string) => Promise<boolean>
  queuePostponeShipment: (barcode: string, date: string) => Promise<boolean>
  setShipmentAsDelivered: (
    comment?: string,
    overrideDate?: string,
    noReload?: boolean
  ) => Promise<boolean>
  setShipmentAsDeliveredToNeighbour: (
    neighbour: INeighbourParams,
    comment?: string,
    overrideDate?: string
  ) => Promise<boolean>
  setShipmentAsNotDelivered: (
    reasonCode: string,
    comment?: string,
    overrideDate?: string,
    noReload?: boolean
  ) => Promise<boolean>
  setShipmentState: (payload: {
    stateName:
      | 'SHIPMENT_DELIVERED'
      | 'SHIPMENT_NOT_DELIVERED'
      | 'CHANGE_SHIPMENT_POST_PONED'
      | 'EXCEPTION_SHIPMENT_MISSING'
      | 'EXCEPTION_SHIPMENT_LOST'
    comment?: string
    neighbour?: {
      name: string
      address: string
      postalCode: string
      city: string
      country: string
    }
    overrideDate?: string
  }) => Promise<boolean>
  printLabel: (
    trunkrsNr: string | number,
    postalCode?: string
  ) => Promise<any | null>
  fetchAddress: (postalCode: string, houseNo: string) => Promise<any | null>
  fetchAddressHistory: () => Promise<void>
  addressHistory: Array<IAddressHistory>
  cancelShipment: (reason: string) => Promise<boolean>
  fetchMerchants: () => Promise<Array<{ id: number; name: string }> | null>
  changeAddress: (address: IAddressChangeParameters) => Promise<boolean>
  getDeliveryDetails: () => Promise<IDeliveryDetails | null>
  getReasonCodesByShipmentState: (
    state: SHIPMENT_STATES
  ) => Promise<ReasonCode[]>
  activeShipmentDetails:
    | IShipmentDetails & {
        shipmentFeatures: ShipmentFeatures
        proofOfDelivery: IProofOfDeliveryResult
      }
    | null
  quickSearch: (query: string) => Promise<Array<IShipment>>
  setActiveShipmentDetails: React.Dispatch<
    React.SetStateAction<IShipmentHistoryDetails | null>
  >
  frozenFoodDeliveryAttempts: IFrozenFoodDeliveryAttempt[]
  accumulatedOutOfFreezerTime: number
  maxTimeOutOfFreezer: number
  callLogs: { [key: string]: Array<ICallLog> }
  getRecordLink: (recordId: number) => Promise<{ recordingLink: string } | null>
  fetchHubLogs: (shipmentId: number) => Promise<any>
  hubLogs: {
    [id: string]: IHubLog[]
  }
  fetchAuditLogs: (shipmentId: number) => Promise<any>
  auditLogs: {
    [id: string]: IAuditLogs[]
  }
  massApplyState: (payload: {
    shipmentIds: number[]
    state: SHIPMENT_STATES
    postponedTo?: string
    setAt?: Date
    reasonCode?: REASON_CODE | undefined
  }) => Promise<any | null>
  searchShipments: (
    filters: IOverviewShipmentsResultFilter
  ) => Promise<Array<IShipment>>
  availableRegions: string[]
  fetchRegions: () => Promise<void>
  getShipmentIdsByFilter: (
    filters: IOverviewShipmentsResultFilter
  ) => Promise<number[]>
  reopenCancelledShipment: () => Promise<any>
  reOpenShipmentLoading: boolean
  fetchMerchantConfig: (
    merchantId?: number
  ) => Promise<{
    result: {
      configuration: 'DISABLE_ADDRESS_CHANGE' | 'LOGGING'
      enabled: boolean
    }[]
  }>
}

export const initialState: IShipmentsState = {
  overviewShipments: [],
  overviewShipmentsResultFilter: {
    endOfResults: false,
    currentPage: 1,
    resultsPerPage: 25,
  },
  filteredShipmentIds: [],
  activeShipment: null,
  activeOverviewShipment: null,
  activeShipmentLogs: [],
  searchMode: false,
  reOpenShipmentLoading: false,
  setOverviewShipmentsResultFilter: () => {},
  setSearchMode: () => {},
  setActiveShipment: () => {},
  fetchShipment: () => Promise.resolve(null),
  quickFetchShipment: () => Promise.resolve(null),
  getTourOverview: () => Promise.resolve(null),
  getActiveOverviewShipment: () => Promise.resolve(null),
  setActiveOverviewShipment: () => {},
  updateOverviewShipments: () => {},
  fetchShipmentLogs: () => Promise.resolve([]),
  fetchShipmentRecipient: () => Promise.resolve(null),
  fetchShipmentTourEstimation: () => Promise.resolve(null),
  fetchShipmentByBarcode: () => Promise.resolve(null),
  fetchOverviewShipments: () => Promise.resolve(),
  fetching: false,
  searchOverviewShipments: () => Promise.resolve(),
  cancelShipment: () => Promise.resolve(false),
  postponeShipment: () => Promise.resolve(false),
  queuePostponeShipment: () => Promise.resolve(false),
  setShipmentAsDelivered: () => Promise.resolve(false),
  setShipmentAsDeliveredToNeighbour: () => Promise.resolve(false),
  setShipmentAsNotDelivered: () => Promise.resolve(false),
  setShipmentState: () => Promise.resolve(false),
  printLabel: () => Promise.resolve(),
  fetchAddress: () => Promise.resolve(),
  fetchAddressHistory: () => Promise.resolve(),
  addressHistory: [],
  fetchMerchants: () => Promise.resolve(null),
  changeAddress: () => Promise.resolve(false),
  getDeliveryDetails: () => Promise.resolve(null),
  getReasonCodesByShipmentState: () => Promise.resolve([]),
  activeShipmentDetails: null,
  quickSearch: () => Promise.resolve([]),
  setActiveShipmentDetails: () => {},
  frozenFoodDeliveryAttempts: [],
  accumulatedOutOfFreezerTime: 0,
  maxTimeOutOfFreezer: 0,
  callLogs: {},
  getRecordLink: () => Promise.resolve(null),
  fetchHubLogs: () => Promise.resolve(),
  hubLogs: {},
  fetchAuditLogs: () => Promise.resolve(),
  auditLogs: {},
  massApplyState: () => Promise.resolve(),
  searchShipments: () => Promise.resolve([]),
  availableRegions: [],
  fetchRegions: () => Promise.resolve(),
  getShipmentIdsByFilter: () => Promise.resolve([]),
  reopenCancelledShipment: () => Promise.resolve([]),
  fetchMerchantConfig: () => Promise.resolve({ result: [] }),
}
