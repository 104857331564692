export enum SHEET_NAMES {
  INVOICING = 'invoicing',
  MERCHANT = 'merchant',
  OPERATIONS = 'operations',
  IT_DETAILS = 'itDetails',
  PRICING = 'pricing',
  COLLECTION = 'collection',
}

export const CURRENCY_EURO = '€'
