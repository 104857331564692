import { CollectionTrackingContext } from '@/contexts'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import React, { useContext, useEffect, useState } from 'react'

import classes from './style.module.scss'

interface IHeaderProps {
  closeDrawer: () => void
  tourId: number
}

const Header: React.FC<IHeaderProps> = ({ closeDrawer, tourId }) => {
  const [title, setTitle] = useState('')
  const { activeTag, activeTagTours } = useContext(CollectionTrackingContext)

  useEffect(() => {
    const newTitle = `${activeTag}-${activeTagTours!.findIndex(
      tour => tour.tourId === tourId
    ) + 1}`
    setTitle(newTitle)
  }, [activeTag, activeTagTours, tourId])

  return (
    <div className={classes.header}>
      <div className={classes.header_title}>{title}</div>
      <button
        className={clsx('icon close', classes.no_outline)}
        onClick={closeDrawer}
      >
        <FontAwesomeIcon icon="times" />
      </button>
    </div>
  )
}

export default Header
