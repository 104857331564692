export const convertFileToBase64: (file: File) => Promise<string> = (
  file: File
) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve((reader.result as string).split(',').pop())
    reader.onerror = error => reject(error)
  })
}
