import Button from '@/app/base/components/.v2/Button'
import ModalBox, {
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '@/app/base/components/.v2/ModalBox'
import Select, { SelectItem } from '@/app/base/components/.v2/Select'
import Toast from '@/app/base/components/.v2/Toast'
import { SHIPMENT_ACTIONS } from '@/app/features/shipments/components/shipmentActions'
import ToastActions from '@/app/features/shipments/components/shipmentActions/components/ToastActions'
import { AppContext, ShipmentsContext } from '@/contexts'
import { useTranslation } from '@/hooks'
import { get } from 'lodash'
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { ReasonCode } from 'Trunkrs-SDK/dist/models'
import { SHIPMENT_STATES } from 'Trunkrs-SDK/dist/models/delivery/ShipmentLog'

import styles from './styles.module.scss'

const ShipmentNotDeliveredModal = ({
  selectedShipments,
  selectedAction,
  onViewResults,
  onShipmentUpdate,
  onClose = () => {},
}: {
  selectedShipments: number[]
  selectedAction?: SHIPMENT_ACTIONS
  onViewResults: (
    state: SHIPMENT_STATES,
    actualUpdatedShipments: number[]
  ) => void
  onShipmentUpdate: (
    state: SHIPMENT_STATES,
    actualUpdatedShipments: number[]
  ) => void
  onClose: () => void
}) => {
  const [t] = useTranslation(['SHIPMENT_ACTIONS'])
  const [reasonCodes, setReasonCodes] = useState<ReasonCode[]>([])

  const { setLoading } = useContext(AppContext)
  const { getReasonCodesByShipmentState, massApplyState } = useContext(
    ShipmentsContext
  )

  useEffect(() => {
    getReasonCodesByShipmentState(SHIPMENT_STATES.SHIPMENT_NOT_DELIVERED).then(
      (result: Array<ReasonCode>) => setReasonCodes(result)
    )
  }, [])

  const [selectedReason, setSelectedReason] = useState()
  const [toastVisible, setToastVisible] = useState(false)

  const startUpdateCountdown = useCallback(() => {
    setToastVisible(true)
    onClose()
  }, [onClose])

  const handleClose = useCallback(() => {
    setToastVisible(false)
    onClose()
  }, [onClose])

  const executeMassNotDelivered = useCallback(async () => {
    setToastVisible(false)
    setLoading(true)
    const result = await massApplyState({
      shipmentIds: selectedShipments,
      state: SHIPMENT_STATES.SHIPMENT_NOT_DELIVERED,
      reasonCode: selectedReason,
    })
    setLoading(false)
    return result
  }, [selectedShipments, selectedReason])

  const handleViewResults = useCallback(async () => {
    handleClose()
    const affectedShipments = await executeMassNotDelivered()
    const shipmentIds = affectedShipments
      .filter(
        (shipment: any) => shipment.status >= '200' && shipment.status <= '202'
      )
      .map((shipment: any) => shipment.href)
    onViewResults(SHIPMENT_STATES.SHIPMENT_NOT_DELIVERED, shipmentIds)
  }, [onViewResults, executeMassNotDelivered])

  const handleMassNotDelivered = useCallback(async () => {
    const affectedShipments = await executeMassNotDelivered()
    const shipmentIds = affectedShipments
      .filter(
        (shipment: any) => shipment.status >= '200' && shipment.status <= '202'
      )
      .map((shipment: any) => shipment.href)
    onShipmentUpdate(SHIPMENT_STATES.SHIPMENT_NOT_DELIVERED, shipmentIds)
  }, [onViewResults, executeMassNotDelivered])

  return (
    <>
      <ModalBox
        visible={selectedAction === SHIPMENT_ACTIONS.NOT_DELIVERED}
        onClose={onClose}
      >
        <ModalHeader>{t('NOT_DELIVERED_MODAL.TITLE')}</ModalHeader>
        <ModalBody>
          <div className={styles.detailsContainer}>
            <div
              dangerouslySetInnerHTML={{
                __html: t('NOT_DELIVERED_MODAL.BODY', {
                  number: selectedShipments.length,
                  interpolation: { escapeValue: false },
                }),
              }}
            />
            <div>{t('NOT_DELIVERED_MODAL.BODY_SUB_DETAILS')}</div>
          </div>
          <div className={styles.reasonCodesDropdownContainer}>
            <Select
              placeholder={t('NOT_DELIVERED_MODAL.SELECT_REASON')}
              value={selectedReason}
              onChange={setSelectedReason}
            >
              {useMemo(
                () =>
                  reasonCodes &&
                  reasonCodes.map(reasonCode => {
                    const value = get(reasonCode, 'code', '')
                    const text = get(reasonCode, 'description', '')
                    return <SelectItem value={value}>{text}</SelectItem>
                  }),
                [reasonCodes]
              )}
            </Select>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button variant="success" onClick={onClose}>
            {t('NOT_DELIVERED_MODAL.BTN_CANCEL')}
          </Button>
          <Button variant="link-danger" onClick={startUpdateCountdown}>
            {t('NOT_DELIVERED_MODAL.BTN_OK')}
          </Button>
        </ModalFooter>
      </ModalBox>
      {toastVisible && (
        <Toast
          headerText={t('NOT_DELIVERED_MODAL.TOAST_TITLE')}
          subText={
            <ToastActions
              label={t('NOT_DELIVERED_MODAL.TOAST_DETAILS', {
                number: selectedShipments.length,
              })}
              onViewResults={handleViewResults}
              onCancel={handleClose}
            />
          }
          onExit={handleMassNotDelivered}
        />
      )}
    </>
  )
}

export default memo(ShipmentNotDeliveredModal)
