import { ICollation, IFetchCollationData } from '@/models'
import * as moment from 'moment'

export interface ICollationState {
  collations: Array<ICollation>
  addCollation: (collation: ICollation) => void
  updateCollation: (collation: ICollation) => void
  mapCollations: (collations: Array<IFetchCollationData>) => Array<ICollation>
  fetchShipmentStates: () => Promise<void>
  lastFetchedShipmentStates: moment.Moment | null
}

export const initialCollationState: ICollationState = {
  collations: [] as Array<ICollation>,
  addCollation: () => null,
  updateCollation: () => null,
  mapCollations: () => [] as Array<ICollation>,
  fetchShipmentStates: () => Promise.resolve(),
  lastFetchedShipmentStates: null,
}
