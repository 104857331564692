import clsx from 'clsx'
import get from 'lodash/get'
import React, { memo } from 'react'

import styles from './styles.module.scss'

export enum STATUS_BADGE_TYPES {
  DEFAULT = 'default',
  PRIMARY = 'primary',
  PRIMARY_PURPLE = 'primaryPurple',
  SUCCESS = 'success',
  DANGER = 'danger',
  WARNING = 'warning',
  WARNING_YELLOW = 'warningYellow',
  DARK = 'dark',
}

const StatusBadge = ({
  children,
  className,
  variant = STATUS_BADGE_TYPES.DEFAULT,
  ...props
}: React.DetailedHTMLProps<any, any> & {
  variant?: STATUS_BADGE_TYPES | string
}) => {
  return (
    <div {...props} className={clsx(styles.statusBadge, get(styles, variant))}>
      <div />
      {children}
    </div>
  )
}

export default memo(StatusBadge)
