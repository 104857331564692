import * as React from 'react'
import { useRef } from 'react'
import { IDOMWidget, ILoaderMethods, ILoadingProps } from '@/models'
import { Loader } from '@/app/base/components'

export const useDOMWidgets: () => {
  Loader: IDOMWidget<ILoadingProps, ILoaderMethods>
} = () => {
  const loaderRef = useRef<HTMLDivElement>(null)

  const loaderMethods: ILoaderMethods = {
    setLoading: (isLoading: boolean) => {
      if (loaderRef && loaderRef.current) {
        if (isLoading) {
          loaderRef.current.classList.add('d-block')
        } else {
          loaderRef.current.classList.remove('d-block')
        }
      }
    },
    destroy: () => {
      if (loaderRef && loaderRef.current) {
        loaderRef.current.remove()
      }
    },
  }

  return {
    Loader: {
      component: Loader,
      ref: loaderRef,
      methods: loaderMethods,
    },
  }
}
