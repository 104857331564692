import { ClickAway } from '@/app/base/components/.lib'
import { useSelect } from '@/app/base/components/.lib/hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import React, { memo, useCallback, useState } from 'react'

import styles from './styles.module.scss'

interface ISelectProps extends React.HTMLAttributes<any> {
  value?: string
  onValueChange?: (value: string) => void
}

export default memo(
  ({
    children,
    value,
    onValueChange,
    className,
    placeholder = 'Type of message',
    ...props
  }: ISelectProps) => {
    const [show, setShow] = useState(false)
    const { Options, SelectedOption } = useSelect(
      children,
      value,
      onValueChange
    )

    const handleClick = () => {
      setShow(!show)
    }

    const hideSuggestions = useCallback(() => {
      setShow(false)
    }, [])

    return (
      <ClickAway callback={hideSuggestions}>
        <div
          className={clsx(styles['select-container'], className)}
          onClick={handleClick}
          {...props}
        >
          <div className={styles['select-input']}>
            <div>
              {SelectedOption ? (
                <SelectedOption />
              ) : (
                <span className={styles.placeholder}>{placeholder}</span>
              )}
            </div>
            <div>
              <FontAwesomeIcon icon={show ? 'caret-up' : 'caret-down'} />
            </div>
          </div>
          {show && (
            <div className={styles['h-0']}>
              <div className={styles['select-options']}>
                <Options />
              </div>
            </div>
          )}
        </div>
      </ClickAway>
    )
  }
)
