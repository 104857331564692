export enum SHIPMENT_STATE {
  DATA_RECEIVED = 'DATA_RECEIVED',
  DATA_PROCESSED = 'DATA_PROCESSED',
  SHIPMENT_SORTED = 'SHIPMENT_SORTED',
  SHIPMENT_SORTED_AT_SUB_DEPOT = 'SHIPMENT_SORTED_AT_SUB_DEPOT',
  SHIPMENT_ACCEPTED_BY_DRIVER = 'SHIPMENT_ACCEPTED_BY_DRIVER',
  SHIPMENT_DELIVERED = 'SHIPMENT_DELIVERED',
  SHIPMENT_DELIVERED_TO_NEIGHBOR = 'SHIPMENT_DELIVERED_TO_NEIGHBOR',
  SHIPMENT_NOT_DELIVERED = 'SHIPMENT_NOT_DELIVERED',
  PICKUP_DRIVER_ASSIGNED = 'PICKUP_DRIVER_ASSIGNED',
  PICKUP_ACCEPTED_BY_DRIVER = 'PICKUP_ACCEPTED_BY_DRIVER',
  PICKUP_PICKED_UP = 'PICKUP_PICKED_UP',
  PICKUP_ARRIVED_ON_TIME = 'PICKUP_ARRIVED_ON_TIME',
  LINEHAUL_IN_TRANSIT = 'LINEHAUL_IN_TRANSIT',
  LINEHAUL_ARRIVED_ON_TIME = 'LINEHAUL_ARRIVED_ON_TIME',
  RETURN_ASSIGNED_TO_LINEHAUL = 'RETURN_ASSIGNED_TO_LINEHAUL',
  RETURN_ACCEPTED_BY_DRIVER = 'RETURN_ACCEPTED_BY_DRIVER',
  RETURN_ACCEPTED_BY_TRUNKRS = 'RETURN_ACCEPTED_BY_TRUNKRS',
  RETURN_ASSIGNED_TO_PICKUP = 'RETURN_ASSIGNED_TO_PICKUP',
  RETURN_ACCEPTED_BY_PICKUP_DRIVER = 'RETURN_ACCEPTED_BY_PICKUP_DRIVER',
  RETURN_ACCEPTED_BY_SENDER = 'RETURN_ACCEPTED_BY_SENDER',
  CHANGE_SHIPMENT_POST_PONED = 'CHANGE_SHIPMENT_POST_PONED',
  CHANGE_SHIPMENT_ADDRESS_CHANGED = 'CHANGE_SHIPMENT_ADDRESS_CHANGED',
  CHANGE_SHIPMENT_NEIGHBOUR_CHANGE = 'CHANGE_SHIPMENT_NEIGHBOUR_CHANGE',
  EXCEPTION_PICKUP_REFUSED_BY_DRIVER = 'EXCEPTION_PICKUP_REFUSED_BY_DRIVER',
  EXCEPTION_PICKUP_NOT_PICKED_UP = 'EXCEPTION_PICKUP_NOT_PICKED_UP',
  EXCEPTION_PICKUP_ARRIVED_LATE = 'EXCEPTION_PICKUP_ARRIVED_LATE',
  EXCEPTION_SHIPMENT_NOT_ARRIVED = 'EXCEPTION_SHIPMENT_NOT_ARRIVED',
  EXCEPTION_LINEHAUL_NOT_ON_LINEHAUL = 'EXCEPTION_LINEHAUL_NOT_ON_LINEHAUL',
  EXCEPTION_SHIPMENT_MIS_SORTED = 'EXCEPTION_SHIPMENT_MIS_SORTED',
  EXCEPTION_SHIPMENT_DECLINED_BY_DRIVER = 'EXCEPTION_SHIPMENT_DECLINED_BY_DRIVER',
  EXCEPTION_SHIPMENT_MISSING = 'EXCEPTION_SHIPMENT_MISSING',
  EXCEPTION_SHIPMENT_LOST = 'EXCEPTION_SHIPMENT_LOST',
  EXCEPTION_RETURN_NOT_ACCEPTED_BY_LINEHAUL = 'EXCEPTION_RETURN_NOT_ACCEPTED_BY_LINEHAUL',
  EXCEPTION_RETURN_NOT_ACCEPTED_BY_PICKUP = 'EXCEPTION_RETURN_NOT_ACCEPTED_BY_PICKUP',
  EXCEPTION_RETURN_NOT_ACCEPTED_BY_SENDER = 'EXCEPTION_RETURN_NOT_ACCEPTED_BY_SENDER',
  EXCEPTION_SHIPMENT_CANCELLED_BY_SENDER = 'EXCEPTION_SHIPMENT_CANCELLED_BY_SENDER',
  EXCEPTION_SHIPMENT_CANCELLED_BY_TRUNKRS = 'EXCEPTION_SHIPMENT_CANCELLED_BY_TRUNKRS',
  EXCEPTION_SHIPMENT_DESTROYED = 'EXCEPTION_SHIPMENT_DESTROYED',
  EXCEPTION_LINEHAUL_ARRIVED_LATE = 'EXCEPTION_LINEHAUL_ARRIVED_LATE',
  RETURN_SHIPMENT_TO_SENDER = 'RETURN_SHIPMENT_TO_SENDER',
  ADDRESS_CHANGE_REQUIRED = 'ADDRESS_CHANGE_REQUIRED',
}
