import { LINEHAUL_ACTION_TYPES, LinehaulActions } from './actionTypes'
import { ILinehaulTrackingReducerState } from './state'

const reducer = (
  state: ILinehaulTrackingReducerState,
  action: LinehaulActions
): ILinehaulTrackingReducerState => {
  switch (action.type) {
    case LINEHAUL_ACTION_TYPES.FETCHING_AVAILABLE_TAGS: {
      return {
        ...state,
        fetchingAvailableTags: true,
      }
    }
    case LINEHAUL_ACTION_TYPES.FETCHED_AVAILABLE_TAGS: {
      return {
        ...state,
        fetchingAvailableTags: false,
        availableTags: action.availableTags,
        activeTag: action.activeTag,
      }
    }
    case LINEHAUL_ACTION_TYPES.SET_ACTIVE_TAG: {
      return {
        ...state,
        activeTag: action.activeTag,
      }
    }
    case LINEHAUL_ACTION_TYPES.FETCHING_LINEHAULS: {
      return {
        ...state,
        fetchingLinehauls: true,
      }
    }
    case LINEHAUL_ACTION_TYPES.FETCHING_LINEHAULS_FAILED: {
      return {
        ...state,
        fetchingLinehauls: false,
        errorFetchingTours: true,
      }
    }
    case LINEHAUL_ACTION_TYPES.FETCHED_LINEHAULS: {
      return {
        ...state,
        fetchingLinehauls: false,
        errorFetchingTours: false,
        activeTagTours: action.activeTagTours,
      }
    }
    case LINEHAUL_ACTION_TYPES.FETCHING_DRIVER_LOCATION: {
      return {
        ...state,
        fetchingDriverLocation: true,
      }
    }
    case LINEHAUL_ACTION_TYPES.FETCHED_DRIVER_LOCATION: {
      return {
        ...state,
        fetchingDriverLocation: false,
        driverLocation: action.driverLocation,
      }
    }
    default:
      return state
  }
}

export default reducer
