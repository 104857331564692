import { TourStates } from '@/constants/TOUR_STATES'
import { useTranslation } from '@/hooks'
import clsx from 'clsx'
import * as React from 'react'

import classes from './style.module.scss'

interface IStatusWithoutIconProps {
  tourState: string
}

const StatusWithoutIcon: React.FC<IStatusWithoutIconProps> = ({
  tourState,
}) => {
  const [t] = useTranslation('LINEHAUL_STATUS')

  return (
    <span
      className={clsx(
        classes.status_label,
        tourState === TourStates.TOUR_CREATED && classes.loading_shipments,
        tourState === TourStates.TOUR_IN_TRANSIT && classes.onto_regional,
        tourState === TourStates.TOUR_COMPLETED && classes.arrived_regional
      )}
    >
      {t(tourState)}
    </span>
  )
}

export default StatusWithoutIcon
