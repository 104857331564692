import './style.scss'

import CallLogs from '@/app/features/shipment/components/callLogs'
import FreezerLogs from '@/app/features/shipment/components/freezerLogs'
import HubLogs from '@/app/features/shipment/components/hubLogs'
import { useTranslation } from '@/hooks'
import { IFrozenFoodDeliveryAttempt, IShipmentHistoryDetails } from '@/models'
import { clsx } from '@/utils'
import React, { useMemo, useState } from 'react'
import { IProofOfDeliveryResult } from 'Trunkrs-SDK/dist/models/delivery/Shipment'

import AuditLogs from '../auditLogs/index'
import DeliveryAttempts from '../deliveryAttempts'
import ShipmentLogs from '../shipmentLogs'

export interface IRightPanelProps {
  data: IShipmentHistoryDetails[]
  proofOfDelivery: IProofOfDeliveryResult
  isSameDayFrozenFood: boolean
  freezerData?: IFrozenFoodDeliveryAttempt[]
  accumulatedOutOfFreezerTime: number
  maxTimeOutOfFreezer: number
}

interface ITab {
  title: string
  component: React.ReactNode
  enabled: boolean
}

const RightPanel: React.SFC<IRightPanelProps> = ({
  data,
  isSameDayFrozenFood,
  freezerData,
  accumulatedOutOfFreezerTime,
  maxTimeOutOfFreezer,
  proofOfDelivery,
}) => {
  const [activeTab, setActiveTab] = useState(1)
  const [t] = useTranslation(['SHIPMENT'])
  const tabs: ITab[] = [
    {
      title: t('TAB.DELIVERY'),
      component: (
        <DeliveryAttempts attempts={data} proofOfDelivery={proofOfDelivery} />
      ),
      enabled: true,
    },
    {
      title: t('TAB.SHIPMENT_LOGS'),
      component: <ShipmentLogs attempts={data} />,
      enabled: true,
    },
    {
      title: t('TAB.HUB_LOGS'),
      component: <HubLogs />,
      enabled: true,
    },
    {
      title: t('TAB.FREEZER_LOGS'),
      component: (
        <FreezerLogs
          attempts={freezerData}
          accumulatedOutOfFreezerTime={accumulatedOutOfFreezerTime}
          maxTimeOutOfFreezer={maxTimeOutOfFreezer}
        />
      ),
      enabled: isSameDayFrozenFood,
    },
    {
      title: t('TAB.CALL_LOGS'),
      component: <CallLogs />,
      enabled: false,
    },
    {
      title: t('TAB.AUDIT_LOGS'),
      component: <AuditLogs />,
      enabled: true,
    },
  ]

  const enabledTabs = tabs.filter((tab: ITab) => tab.enabled)

  return (
    <div id="right-panel-container">
      <div className="tabs">
        {useMemo(
          () =>
            enabledTabs.map((tab: ITab, index: number) => (
              <a
                key={`tab-${index}`}
                className={clsx(activeTab === index && 'tab-active')}
                onClick={() => setActiveTab(index)}
              >
                {tab.title}
              </a>
            )),
          [enabledTabs]
        )}
      </div>
      {enabledTabs[activeTab].component}
    </div>
  )
}

export default RightPanel
