import {
  DataSourceConfig,
  SearchableItem,
  SearchableItemKey,
} from '@/app/base/components/.v2/SmartSearch/helpers/interfaces'
import moment from 'moment'

export const toYearMonthDay = 'YYYY-MM-DD'
export const toDayMonthYear = 'DD-MM-YYYY'
export const disabledPaths = ['/authentication']

const parseQuery = (url: string): Record<string, string> => {
  const params = url.replace('?', '').split('&')
  return params.reduce((prev, curr) => {
    const [key, value] = curr.split('=')
    return {
      ...prev,
      [key]: value,
    }
  }, {})
}
const unpipeFilter = (filter: string) => filter.split('|')

const filterObjectSeparator = (filterObject: any) => {
  const filterArray = [] as any
  let dateIndex

  Object.keys(filterObject).forEach(function(key) {
    if (key === SearchableItemKey.CURRENT_SHIPMENT_STATE) {
      const unpipeValue = unpipeFilter(filterObject[key])
      return unpipeValue.map(value => {
        return filterArray.push({ key, value })
      })
    } else if (key.includes(SearchableItemKey.CREATION_DATE)) {
      const from = `${SearchableItemKey.CREATION_DATE}[from]`
      const to = `${SearchableItemKey.CREATION_DATE}[to]`

      const dateFrom = moment(filterObject[from], toYearMonthDay).format(
        toDayMonthYear
      )
      const dateTo = moment(filterObject[to], toYearMonthDay).format(
        toDayMonthYear
      )

      dateIndex = filterArray.findIndex(
        (arr: Record<string, string>) =>
          arr.key === SearchableItemKey.CREATION_DATE
      )
      const creationDateFilter = filterArray[dateIndex]
      if (creationDateFilter) {
        return
      }
      return filterArray.push({
        key: SearchableItemKey.CREATION_DATE,
        value: dateTo ? `${dateFrom} > ${dateTo}` : dateFrom,
      })
    } else if (key.includes(SearchableItemKey.CURRENT_DELIVERY_DATE)) {
      // this else if code block runs the exact same logic as for the CREATION_DATE else if block.
      // this is nasty asf but and is not final. I will have to improve this implementation in the future.
      const from = `${SearchableItemKey.CURRENT_DELIVERY_DATE}[from]`
      const to = `${SearchableItemKey.CURRENT_DELIVERY_DATE}[to]`

      const dateFrom = moment(filterObject[from], toYearMonthDay).format(
        toDayMonthYear
      )
      const dateTo = moment(filterObject[to], toYearMonthDay).format(
        toDayMonthYear
      )

      dateIndex = filterArray.findIndex(
        (arr: Record<string, string>) =>
          arr.key === SearchableItemKey.CURRENT_DELIVERY_DATE
      )
      const creationDateFilter = filterArray[dateIndex]
      if (creationDateFilter) {
        return
      }
      return filterArray.push({
        key: SearchableItemKey.CURRENT_DELIVERY_DATE,
        value: dateTo ? `${dateFrom} > ${dateTo}` : dateFrom,
      })
    } else {
      return filterArray.push({
        key,
        value: filterObject[key],
      })
    }
  })

  return filterArray
}

export const toSearchableItem = (query: string): Partial<SearchableItem> => {
  const urlParams = parseQuery(query)
  return Object.keys(urlParams).reduce((prev, curr) => {
    return {
      ...prev,
      [curr]: urlParams[curr],
    }
  }, {})
}

export const toActiveChips = (chipObject: any): DataSourceConfig[] => {
  const chips = filterObjectSeparator(chipObject)
  const arrayChips = chips.map((chip: object, index: number) => {
    return { id: index, ...chip }
  })

  return arrayChips
}
