import MerchantOverviewContainer from '@/app/features/clientProfiles/MerchantOverview'
import React from 'react'

const ClientProfiles: React.FC = () => {
  return (
    <div className="px-4">
      <MerchantOverviewContainer />
    </div>
  )
}

export default ClientProfiles
