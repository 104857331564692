import { IIdentity } from '@/models'

export interface IAuthState {
  authenticated: boolean
  identity: IIdentity
  checkAccess: () => Promise<void>
  requestLogin: (baseUri?: string) => Promise<void>
  completeLogin: (
    state: string,
    code: string,
    baseUri?: string
  ) => Promise<void | boolean>
  requestLogout: () => Promise<void | boolean>
}

export const initialState: IAuthState = {
  authenticated: false,
  identity: { group: [], profile: { name: '', picture: '', email: '' } },
  checkAccess: () => Promise.resolve(),
  requestLogin: () => Promise.resolve(),
  completeLogin: () => Promise.resolve(),
  requestLogout: () => Promise.resolve(),
}
