import { DriverContext } from '@/contexts'
import * as React from 'react'

import { useDriverActions } from './actions'
import { initialDriverState } from './state'

export default ({ children }: { children: React.ReactNode }) => {
  const state = useDriverActions(initialDriverState)
  return (
    <DriverContext.Provider value={state}>{children}</DriverContext.Provider>
  )
}
