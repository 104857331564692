import { useTranslation } from '@/hooks'
import { SHIPMENT_ACTIONS } from '@/models'
import get from 'lodash/get'
import React, { memo, useCallback, useState } from 'react'

interface IAddCommentProps {
  action: SHIPMENT_ACTIONS
  callback: (...args: any[]) => void
}

const AddComment = ({ action, callback }: IAddCommentProps) => {
  const [t] = useTranslation(['SET_FINAL_STATE'])
  const [comment, setComment] = useState()

  const handleChange = useCallback(
    (ev: React.SyntheticEvent<HTMLTextAreaElement>) => {
      setComment(ev.currentTarget.value)
      if (callback) {
        callback(ev.currentTarget.value)
      }
    },
    [callback]
  )

  const textPlaceholders = {
    [SHIPMENT_ACTIONS.NON_DELIVERY]: 'ADD_COMMENT_PLACEHOLDER_NOT_DELIVERED',
    [SHIPMENT_ACTIONS.DELIVERY]: 'ADD_COMMENT_PLACEHOLDER_DELIVERED',
    [SHIPMENT_ACTIONS.MISSING]: 'ADD_COMMENT_PLACEHOLDER_MISSING',
    [SHIPMENT_ACTIONS.LOST]: 'ADD_COMMENT_PLACEHOLDER_LOST',
  }

  return (
    <div className="comment mt-4">
      <p className="text bold">{t('ADD_COMMENT')}</p>
      <textarea
        className="form-control text"
        rows={5}
        placeholder={t(get(textPlaceholders, action, ''))}
        value={comment}
        onChange={handleChange}
        onBlur={handleChange}
      />
    </div>
  )
}

export default memo(AddComment)
