import API from '@/api'
import { useResponseErrorHandler } from '@/hooks'
import { IDriver } from '@/models'
import { arrayReplace } from '@/utils'
import defaultTo from 'lodash/defaultTo'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { useState } from 'react'

import { IDriverMessage, IDriverState } from './state'

export const useDriverActions = (initialState: IDriverState): IDriverState => {
  const [drivers, setDrivers] = useState(initialState.drivers)
  const [allDrivers, setAllDrivers] = useState([])
  const [activeDriverId, setActiveDriverId] = useState(
    initialState.activeDriverId
  )

  const [allDriversWithSubcoId, setAllDriversWithSubcoId] = useState([])

  const addDriver = (driver: IDriver) =>
    setDrivers(drivers => [...drivers, driver])

  const updateDriver = (driver: IDriver) => {
    setDrivers(currentDrivers => {
      const currentDriver = currentDrivers.find(({ id }) => id === driver.id)
      return arrayReplace(
        currentDrivers,
        { id: driver.id },
        {
          ...currentDriver,
          ...driver,
        }
      )
    })
  }

  const fetchImage = async (driver: IDriver) => {
    const [err, result] = await useResponseErrorHandler(
      API.Image.fetch(driver.pictureLocation)
    )
    if (!err) {
      const updatedDriver = {
        ...driver,
        picture: result.base64string,
      }
      updateDriver(updatedDriver)
    }
  }

  const updateActiveDriverId = (driverId: number | null) =>
    setActiveDriverId(driverId)

  const fetchAllDrivers = async () => {
    const [, result] = await useResponseErrorHandler(API.Driver().fetchAll())
    setAllDrivers(defaultTo(result, []))
  }

  const fetchAllDriversWithSubcoId = async () => {
    if (isEmpty(allDriversWithSubcoId)) {
      const [, res] = await useResponseErrorHandler(
        API.Driver().fetchAllDrivers()
      )
      return get(res, 'result', [])
    }
  }

  const storeDriverMessage = async (message: IDriverMessage) => {
    const [err] = await useResponseErrorHandler(
      API.Communication().storeDriverMessage(message)
    )
    return !!err
  }

  return {
    drivers,
    allDrivers,
    addDriver,
    updateDriver,
    fetchImage,
    activeDriverId,
    updateActiveDriverId,
    fetchAllDrivers,
    storeDriverMessage,
    fetchAllDriversWithSubcoId,
    allDriversWithSubcoId,
    setAllDriversWithSubcoId,
  }
}
