import * as React from 'react'
import './style.scss'

export const ErrorAlert = (props: { children: React.ReactNode }) => {
  return (
    <div className="error-alert">
      <div className="alert alert-danger" role="alert">
        {props.children}
      </div>
    </div>
  )
}
