import {
  IShipmentAlertResponse,
  SHIPMENT_ALERT_CATEGORY,
  IShipmentNotification,
} from '@/models'

export interface INotificationsState {
  notSortedNotifs: Array<IShipmentNotification>
  notAcceptedNotifs: Array<IShipmentNotification>
  lateShipmentsNotifs: Array<IShipmentNotification>
  totalNotifications: number
  notificationCount: {
    notAcceptedByDriver: number
    notSortedAtSubDepot: number
    runningLate: number
  }
  fetchNotifications: (
    fetchAll?: boolean,
    timestamp?: string
  ) => Promise<IShipmentAlertResponse | null>
  clearNotifications: (
    shipmentIds: number[],
    category: SHIPMENT_ALERT_CATEGORY
  ) => Promise<void>
  markNotifications: (
    notifications: IShipmentNotification[],
    category: SHIPMENT_ALERT_CATEGORY
  ) => Promise<void>
  currentTimestamp?: string
}

export const initialState: INotificationsState = {
  notSortedNotifs: [],
  notAcceptedNotifs: [],
  lateShipmentsNotifs: [],
  totalNotifications: 0,
  notificationCount: {
    notAcceptedByDriver: 0,
    notSortedAtSubDepot: 0,
    runningLate: 0,
  },
  fetchNotifications: () => Promise.resolve(null),
  clearNotifications: () => Promise.resolve(),
  markNotifications: () => Promise.resolve(),
}
