import { canBeSet } from '@/app/features/tourRecovery/helpers'
import { SHIPMENT_STATE } from '@/constants'
import get from 'lodash/get'

import { ITourRecoveryState } from './state'
import { Action, IRecoveryCollation, Row, TOUR_RECOVERY_ACTIONS } from './types'

const reducer = (
  state: ITourRecoveryState,
  action: Action
): ITourRecoveryState => {
  switch (action.type) {
    case TOUR_RECOVERY_ACTIONS.SET_INITIAL_STATE:
      const rows: Row[] = action.payload.collations
        .map((collation: IRecoveryCollation) => ({
          original: collation,
        }))
        .filter(collation => collation.original.shipment_id > 1)

      return {
        ...state,
        reasonCodes: action.payload.reasonCodes,
        driver: action.payload.driver,
        rows,
      }

    case TOUR_RECOVERY_ACTIONS.TOGGLE_ROW:
      const temp = [...state.rows]
      temp[action.rowIndex].isSelected = !temp[action.rowIndex].isSelected

      return {
        ...state,
        rows: temp,
      }

    case TOUR_RECOVERY_ACTIONS.TOGGLE_ALL_ROWS:
      const currentPilot = state.rows.findIndex(item => canBeSet(item.original))

      if (currentPilot === undefined) return state

      const toggledRows = state.rows.map(row =>
        !canBeSet(row.original)
          ? row
          : { ...row, isSelected: !state.rows[currentPilot].isSelected }
      )

      return {
        ...state,
        rows: toggledRows,
      }

    case TOUR_RECOVERY_ACTIONS.APPLY_PREVIEW:
      const modifiedForPreview = state.rows.map(row => {
        if (!row.isSelected) return row
        return {
          ...row,
          modified: {
            ...row.original,
            ...action.payload,
          },
        }
      })

      return {
        ...state,
        rows: modifiedForPreview,
      }

    case TOUR_RECOVERY_ACTIONS.MODIFY_ROW:
      const { rowIndex, ...payload } = action.payload
      const allRows = [...state.rows]
      allRows[rowIndex].isSelected = true
      allRows[rowIndex].modified = {
        ...allRows[rowIndex].original,
        ...payload,
      }

      return {
        ...state,
        rows: allRows,
      }

    case TOUR_RECOVERY_ACTIONS.RESET_ROW:
      const all = [...state.rows]
      all[action.rowIndex].modified = undefined
      all[action.rowIndex].isSelected = false

      return {
        ...state,
        rows: all,
      }

    case TOUR_RECOVERY_ACTIONS.APPLY_TO_ROWS_BELOW:
      const rowsBelow = state.rows.map((row, idx) => {
        const currentRow = state.rows.find(
          (r, i) => i === action.payload.rowIndex
        )
        const isRowBelow = idx >= action.payload.rowIndex
        return !canBeSet(row.original)
          ? row
          : {
              ...row,
              modified: isRowBelow
                ? {
                    ...row.original,
                    selectedReasonCode: get(
                      currentRow,
                      'modified.selectedReasonCode'
                    ),
                    selectedShipmentState: get(
                      currentRow,
                      'modified.selectedShipmentState'
                    ),
                    selectedTime: get(currentRow, 'modified.selectedTime'),
                  }
                : row.modified,
              isSelected: isRowBelow,
            }
      })

      return {
        ...state,
        rows: rowsBelow,
      }

    case TOUR_RECOVERY_ACTIONS.CLEAR_SELECTION:
      const clearedRows = state.rows.map(row => ({ ...row, isSelected: false }))

      return {
        ...state,
        rows: clearedRows,
      }

    case TOUR_RECOVERY_ACTIONS.SET_ROW_STATE:
      const updateRowForState = [...state.rows]
      const selectedState = action.payload.state
      updateRowForState[action.payload.rowIndex].modified = {
        ...get(updateRowForState, `${action.payload.rowIndex}.modified`),
        selectedShipmentState: selectedState,
        selectedReasonCode:
          selectedState === SHIPMENT_STATE.SHIPMENT_DELIVERED
            ? undefined
            : get(
                updateRowForState,
                `${action.payload.rowIndex}.modified.selectedReasonCode`
              ),
      }
      return {
        ...state,
        rows: updateRowForState,
      }

    case TOUR_RECOVERY_ACTIONS.SET_ROW_REASON_CODE:
      const updateRowForReasonCode = [...state.rows]
      updateRowForReasonCode[action.payload.rowIndex].modified = {
        ...get(updateRowForReasonCode, `${action.payload.rowIndex}.modified`),
        selectedReasonCode: action.payload.reasonCode,
      }
      return {
        ...state,
        rows: updateRowForReasonCode,
      }

    case TOUR_RECOVERY_ACTIONS.SET_ROW_TIME:
      const updateRowTime = [...state.rows]
      updateRowTime[action.payload.rowIndex].modified = {
        ...get(
          updateRowTime,
          `${action.payload.rowIndex}.modified`,
          get(updateRowTime, `${action.payload.rowIndex}.original`)
        ),
        selectedTime: action.payload.time,
      }

      return {
        ...state,
        rows: updateRowTime,
      }

    default:
      return state
  }
}

export default reducer
