import { RouterContext } from '@/contexts'
import { ICollation, ITour } from '@/models'
import { getCleanCollations } from '@/utils/getCleanCollations'
import moment from 'moment'
import * as React from 'react'

import TourStop from './TourStop'

interface IProps {
  tour: ITour
  collations: Array<ICollation>
}

const TourStops: React.FunctionComponent<IProps> = ({ tour, collations }) => {
  const { history } = React.useContext(RouterContext)
  const handleShipmentClick = React.useCallback(
    (shipmentId: number) => () => history.push(`/shipments/${shipmentId}`),
    []
  )
  const cleanCollations = React.useMemo(() => getCleanCollations(collations), [
    collations,
  ])
  const tourStops = React.useMemo(
    () =>
      cleanCollations.map((collation: ICollation) => (
        <TourStop
          key={collation.id}
          shipmentId={collation.shipmentId}
          shipmentClick={handleShipmentClick}
          eta={moment(collation.eta).format('HH:mm')}
          delayed={collation.delayed}
          shipmentState={collation.state}
        />
      )),
    [collations]
  )
  return (
    <div className="tour-row">
      <div className="time">{moment(tour.startTime).format('HH:mm')}</div>
      <div className="stop-bar">{tourStops}</div>
      <div className="time">{moment(tour.endTime).format('HH:mm')}</div>
    </div>
  )
}

export default TourStops
