import API from '@/api'
import { useResponseErrorHandler } from '@/hooks'
import { IInventoryState } from '@/store/inventory/state'
import { extractSubcoTagsWithName } from '@/utils/extractTagsFromSubcos'
import { defaultTo, get } from 'lodash'
import { useMemo, useState } from 'react'

export const useInventoryActions = (initialState: IInventoryState) => {
  const inventoryAPI = API.Inventory()
  const subcontractor = API.Subco()

  const [inventory, setInventory] = useState([])
  const [regions, setRegions] = useState<Array<{ tag: any; name: any }>>([])

  const fetchInventory = async (region: string, pageNum: number = 1) => {
    const [, response] = await useResponseErrorHandler(
      inventoryAPI.fetchBySubcoTag(region, 25, (pageNum - 1) * 25)
    )

    const nextInventory = get(response, 'items', [])

    setInventory(nextInventory)

    return nextInventory
  }

  const fetchAvailableRegions = async () => {
    const [, subcos] = await useResponseErrorHandler(subcontractor.fetchAll())
    const tags: Array<{ tag: any; name: any }> = extractSubcoTagsWithName(
      subcos
    )
    const regions = defaultTo(tags, [
      { tag: 'ANT', name: 'ANT' },
      { tag: 'CNN', name: 'CNN' },
      { tag: 'CNO', name: 'CNO' },
      { tag: 'CNW', name: 'CNW' },
      { tag: 'CNZ', name: 'CNZ' },
      { tag: 'DDT', name: 'DDT' },
      { tag: 'MOS', name: 'MOS' },
      { tag: 'MWS', name: 'MWS' },
      { tag: 'NOS', name: 'NOS' },
      { tag: 'NRD', name: 'NRD' },
      { tag: 'NWS', name: 'NWS' },
      { tag: 'OST', name: 'OST' },
      { tag: 'WBR', name: 'WBR' },
      { tag: 'WDX', name: 'WDX' },
      { tag: 'WST', name: 'WST' },
      { tag: 'XXX', name: 'XXX' },
      { tag: 'ZOS', name: 'ZOS' },
      { tag: 'ZUD', name: 'ZUD' },
      { tag: 'ZWO', name: 'ZWO' },
      { tag: 'ZWS', name: 'ZWS' },
    ]).filter(tag => tag.tag.length <= 4)
    setRegions(regions)
    return regions
  }

  return useMemo(
    () => ({
      inventory,
      regions,
      fetchInventory,
      fetchAvailableRegions,
    }),
    [inventory, fetchInventory]
  )
}
