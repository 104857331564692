import { TourContext } from '@/contexts'
import * as React from 'react'

import { useTourActions } from './actions'
import { initialTourState } from './state'

export default ({ children }: { children: React.ReactNode }) => {
  const state = useTourActions(initialTourState)
  return <TourContext.Provider value={state}>{children}</TourContext.Provider>
}
