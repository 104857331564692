import clsx from 'clsx'
import React, { memo } from 'react'

import { IconLibrary } from './library'
import styles from './styles.module.scss'

const Icon = memo(
  ({
    name,
    className,
    disabled,
    ...props
  }: { name: string } & React.DetailedHTMLProps<any, any>) => {
    const Icon = (IconLibrary as any)[name]
    return (
      <Icon
        className={clsx(
          className,
          styles.icon,
          disabled && styles.disabled,
          props.onClick && styles.clickable,
          name === 'spinner' && styles.spin
        )}
        {...props}
      />
    )
  }
)

Icon.displayName = 'Icon'

export default Icon
