import './style.scss'

import { useTranslation } from '@/hooks'
import { IRegionShipmentReturn } from '@/models'
import { clsx } from '@/utils'
import React, { memo, useMemo } from 'react'

export const ShipmentReturnsTabs = memo(
  ({
    regionShipmentReturns,
    handleTabClick,
    activeTab,
  }: {
    regionShipmentReturns: Array<IRegionShipmentReturn>
    handleTabClick: (regionName?: string) => () => void
    activeTab?: string
  }) => {
    const [t] = useTranslation('SHIPMENT_RETURNS')
    const totalReturns = useMemo(
      () =>
        regionShipmentReturns.reduce(
          (acc, { subcos }) =>
            subcos.reduce((acc, { shipments }) => acc + shipments.length, 0) +
            acc,
          0
        ),
      [regionShipmentReturns]
    )

    return (
      <div className="shipment-returns-tab">
        <span
          className={clsx('tab text', !activeTab && 'active')}
          onClick={handleTabClick()}
        >{`${t('ALL')} (${totalReturns})`}</span>
        {useMemo(
          () =>
            regionShipmentReturns.map(({ name: regionName, subcos }, index) => {
              const returnCount = subcos.reduce(
                (acc, { shipments }) => acc + shipments.length,
                0
              )
              return (
                <span
                  className={clsx(
                    'tab text',
                    activeTab === regionName && 'active'
                  )}
                  key={`return-tabs-${index}`}
                  onClick={handleTabClick(regionName)}
                >{`${regionName} (${returnCount})`}</span>
              )
            }),
          [regionShipmentReturns, activeTab]
        )}
      </div>
    )
  }
)
