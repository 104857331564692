import { useTranslation } from '@/hooks'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import Collapse from '@material-ui/core/Collapse'
import Warning from '@material-ui/icons/Warning'
import clsx from 'clsx'
import React from 'react'

import classes from './ValidationsContainer.module.scss'

interface ValidationsContainerProps {
  className?: string
  open: boolean
  messages: string[]
  onClose: () => Promise<void> | void
}

const ValidationsContainer: React.FC<ValidationsContainerProps> = ({
  className,
  open,
  messages,
  onClose,
}) => {
  const [t] = useTranslation('COLLECTION_TOUR')

  return (
    <Collapse in={open}>
      <Card className={clsx(classes.card, className)} elevation={3}>
        <div className={classes.sideContainer}>
          <Warning className={classes.icon} />
        </div>
        <div className={classes.contentContainer}>
          <h5>{t('PUBLISHING.ATTENTION')}</h5>

          <ul className={classes.messageContainer}>
            {React.useMemo(
              () => messages.map(message => <li key={message}>{message}</li>),
              [messages]
            )}
          </ul>

          <Button
            disableRipple
            className={classes.button}
            variant="outlined"
            onClick={onClose}
          >
            {t('PUBLISHING.I_UNDERSTAND')}
          </Button>
        </div>
      </Card>
    </Collapse>
  )
}

export default ValidationsContainer
