import { Chip, ClickAway } from '@/app/base/components/.lib'
import { AUTOCOMPLETE_VARIANTS } from '@/app/base/components/.lib/@types'
import { useAutoComplete } from '@/app/base/components/.lib/hooks'
import clsx from 'clsx'
import get from 'lodash/get'
import React, { memo, useMemo, useRef } from 'react'

import styles from './styles.module.scss'

interface IAutoCompleteProps extends React.HTMLAttributes<any> {
  collection: Array<{ value: any; text: string; content?: any }>
  callback: (value: any) => void
  variant?: AUTOCOMPLETE_VARIANTS | 'default' | 'chip'
  value?: any
}

export default memo(
  ({
    className,
    collection,
    callback,
    variant = AUTOCOMPLETE_VARIANTS.DEFAULT,
    value,
  }: IAutoCompleteProps) => {
    const {
      searchString,
      currentValue,
      suggestions,
      clearValue,
      clearSuggestions,
      handleInputChange,
      handleSuggestionSelect,
    } = useAutoComplete(collection, callback, value)

    const textValue = get(currentValue, 'text')

    return (
      <ClickAway callback={clearSuggestions}>
        <div className={clsx(styles['autocomplete-container'], className)}>
          {variant === AUTOCOMPLETE_VARIANTS.CHIP && textValue ? (
            <Chip handleClose={clearValue}>{textValue}</Chip>
          ) : (
            <input
              type="text"
              className={styles.input}
              value={textValue || searchString}
              onChange={handleInputChange}
            />
          )}
          <div
            className={clsx(
              styles['h-0'],
              !suggestions.length && styles.hidden
            )}
          >
            <div className={styles['suggestions-container']}>
              {useMemo(
                () =>
                  suggestions.map(suggestion => (
                    <div onClick={handleSuggestionSelect(suggestion)}>
                      {get(suggestion, 'content', suggestion.text)}
                    </div>
                  )),
                [suggestions]
              )}
            </div>
          </div>
        </div>
      </ClickAway>
    )
  }
)
