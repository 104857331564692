import { INIT_URL, ROOT_URL } from '@/constants'
import { AuthContext, DriverContext } from '@/contexts'
import { IRoute } from '@/models'
import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

interface IRoutesProps {
  routes: Array<IRoute>
}

export const Routes = (props: IRoutesProps) => {
  const { routes } = props
  const [isInitialized, setIsInitialized] = useState(false)
  const { checkAccess, authenticated } = useContext(AuthContext)
  const { fetchAllDriversWithSubcoId, setAllDriversWithSubcoId } = useContext(
    DriverContext
  )

  useEffect(() => {
    checkAccess()
      .then(async () => {
        const drivers = await fetchAllDriversWithSubcoId()
        setAllDriversWithSubcoId(drivers as any)
      })
      .finally(() => setIsInitialized(true))
  }, [])

  return (
    <Switch>
      {routes.map(
        ({ exact, path, reactNode, ...route }: IRoute, index: number) => (
          <Route
            {...{ exact, path }}
            key={index}
            render={props => {
              if (isInitialized) {
                if (!authenticated && props.match.path !== INIT_URL) {
                  return <Redirect to={INIT_URL} />
                } else if (authenticated && route.public) {
                  return <Redirect to={ROOT_URL} />
                }
              }
              return reactNode
            }}
          />
        )
      )}
      <Route render={() => <Redirect to={{ pathname: INIT_URL }} />} />
    </Switch>
  )
}
