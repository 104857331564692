import './style.scss'

import { useTranslation } from '@/hooks'
import { IShipmentReturn } from '@/models'
import React, { memo, useMemo } from 'react'

import { ShipmentReturnDataRow } from './components'

export const ShipmentReturnsTable = memo(
  ({ shipmentReturns }: { shipmentReturns: Array<IShipmentReturn> }) => {
    const [t] = useTranslation('SHIPMENT_RETURNS')

    return (
      <table className="shipments-returns-table table mb-3">
        <thead>
          <tr className="table-heading">
            <th>{t('ADDRESS')}</th>
            <th>{t('TRUNKRSNR')}</th>
            <th>{t('MERCHANT')}</th>
            <th>{t('SUBCO')}</th>
            <th>{t('LAST_DRIVER')}</th>
            <th>{t('STATUS')}</th>
          </tr>
        </thead>
        <tbody>
          {useMemo(
            () =>
              shipmentReturns.map(shipmentReturn => (
                <ShipmentReturnDataRow
                  key={shipmentReturn.shipmentId}
                  shipmentReturn={shipmentReturn}
                />
              )),
            [shipmentReturns]
          )}
        </tbody>
      </table>
    )
  }
)
