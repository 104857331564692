import './base/scss/main.scss'

import { Loader } from '@/app/base/components'
import App from '@/app/features/app'
import { AppStore, AuthStore, RouterStore } from '@/store'
import * as React from 'react'
import { BrowserRouter } from 'react-router-dom'

import ThemeProvider from './base/components/themeProvider'

const Root: React.FC = () => {
  return (
    <React.Suspense fallback={<Loader loading={true} />}>
      <ThemeProvider>
        <AppStore>
          <AuthStore>
            <BrowserRouter>
              <RouterStore>
                <App />
              </RouterStore>
            </BrowserRouter>
          </AuthStore>
        </AppStore>
      </ThemeProvider>
    </React.Suspense>
  )
}

export default Root
