import React, { memo } from 'react'
import { useTranslation } from '@/hooks'
import icon from '@/app/base/img/no-notifications-icon.svg'

export const NoNotification = () => {
  const [t] = useTranslation('NOTIFICATIONS')

  return (
    <div className="no-notification">
      <img src={icon} />
      <p className="title">{t('NO_NOTIFICATION_TITLE')}</p>
      <p className="text">{t('NO_NOTIFICATION_DESCRIPTION')}</p>
    </div>
  )
}

export default memo(NoNotification)
