import { TourRecoveryContext } from '@/contexts'
import { TFunction } from 'i18next'
import React, { useContext } from 'react'

import Checkbox from '../../../../base/components/checkBox'
import {
  Table,
  TableBody,
  TableHeader,
  TableRowHeader,
} from '../../../../base/components/table'
import CollationRow from '../CollationRow'
import styles from './styles.module.scss'

export interface ShipmentsTableProps {
  t: TFunction
}

export const ShipmentsTable: React.FC<ShipmentsTableProps> = ({ t }) => {
  const { handleToggleAllRows, isAllRowsSelected, rows } = useContext(
    TourRecoveryContext
  )

  return (
    <Table className={styles['custom-table-style']}>
      <TableRowHeader className="table-header-row">
        <TableHeader>
          <Checkbox
            checked={isAllRowsSelected}
            onChange={handleToggleAllRows}
          />
        </TableHeader>
        <TableHeader>{t('POSITION')}</TableHeader>
        <TableHeader>{t('SHIPMENT_ID')}</TableHeader>
        <TableHeader>{t('ADDRESS')}</TableHeader>
        <TableHeader>{t('DELIVERY_DATE')}</TableHeader>
        <TableHeader>{t('ETA')}</TableHeader>
        <TableHeader>{t('STATES')}</TableHeader>
        <TableHeader>{t('SET_TIME')}</TableHeader>
        <TableHeader>{t('SET_STATE')}</TableHeader>
        <TableHeader>{t('REASON_CODE')}</TableHeader>
        <TableHeader />
      </TableRowHeader>
      <TableBody>
        {rows.map((row, index) => (
          <CollationRow
            key={row.original.collation_id}
            rowIndex={index}
            collation={row.modified || row.original}
            isModified={!!row.modified}
            selected={!!row.isSelected}
          />
        ))}
      </TableBody>
    </Table>
  )
}

export default ShipmentsTable
