import { ModalForm } from '@/app/base/components'
import { useTranslation } from '@/hooks'
import { ICallLog } from '@/models'
import { formatSeconds } from '@/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ClickAwayListener } from '@material-ui/core'
import clsx from 'clsx'
import moment from 'moment'
import React, { memo, useCallback, useEffect, useRef, useState } from 'react'

import styles from './style.module.scss'

interface ICallRecordingProps {
  callLog: ICallLog
  handleClose?: (ev?: React.SyntheticEvent<any>) => void
}

const CallRecording = ({
  callLog: { recordingUrl: url, createdAt },
  handleClose,
}: ICallRecordingProps) => {
  const [audio] = useState(new Audio(url))
  const [loading, setLoading] = useState(true)
  const [playing, setPlaying] = useState(false)
  const [duration, setDuration] = useState(0)
  const [currentTime, setCurrentTime] = useState(0)
  const [currentVolume, setCurrentVolume] = useState()
  const [showVolumeControl, setShowVolumeControl] = useState(false)

  const setAudioData = useCallback(() => {
    debugger
    if (audio) {
      setLoading(false)
      setDuration(audio.duration)
      setCurrentTime(audio.currentTime)
      setCurrentVolume(audio.volume)
      togglePlay()
    }
  }, [audio])

  const setAudioTime = useCallback(() => {
    if (audio) {
      setCurrentTime(audio.currentTime)
    }
  }, [audio])

  const togglePlay = useCallback(() => {
    if (playing) {
      setPlaying(false)
      audio.pause()
    } else {
      setPlaying(true)
      audio.play()
    }
  }, [playing])

  const toggleVolumeControl = useCallback(
    () => setShowVolumeControl(!showVolumeControl),
    [showVolumeControl]
  )

  const selectTime = useCallback(
    (e: any) => {
      const target = e.currentTarget
      const pos =
        (e.pageX - (target.getBoundingClientRect().left + window.scrollX)) /
        target.offsetWidth
      const time = pos * duration
      setCurrentTime(time)
      audio.currentTime = time
    },
    [currentTime]
  )

  const adjustVolume = useCallback(
    (e: any) => {
      const target = e.currentTarget
      let volume = Math.max(
        1 -
          (e.pageY - (target.getBoundingClientRect().top + window.scrollY)) /
            target.offsetHeight,
        0.15
      )
      if (volume >= 0.95) {
        volume = 1
      }
      setCurrentVolume(volume)
      audio.volume = volume === 0.15 ? 0 : volume
    },
    [currentVolume]
  )

  const stopAudio = useCallback(() => {
    setPlaying(false)
    setCurrentTime(0)
    audio.pause()
    audio.currentTime = 0
  }, [])

  useEffect(() => {
    audio.addEventListener('loadeddata', setAudioData, false)
    audio.addEventListener('timeupdate', setAudioTime, false)
    audio.addEventListener('ended', stopAudio, false)

    return () => {
      stopAudio()
      audio.removeEventListener('loadeddata', setAudioData)
      audio.removeEventListener('timeupdate', setAudioTime)
      audio.removeEventListener('ended', stopAudio)
    }
  }, [])

  const formattedDuration = formatSeconds(duration)
  const formattedTime = formatSeconds(currentTime)
  const currentPercentage = (currentTime / duration) * 100
  const currentVolumePercentage = currentVolume * 100

  const [t] = useTranslation(['SHIPMENT'])

  return (
    <ModalForm
      show={true}
      title={t('CALL_RECORDING')}
      className={styles['call-recording-modal']}
      handleClose={handleClose}
    >
      <div className={styles['call-recording-wrapper']}>
        <div className={styles['call-recording-header']}>
          {moment(createdAt).format('ddd, MM-DD-YYYY, HH:mm:ss')}
        </div>
        <div className={styles['call-recording-player']}>
          <div className={styles['call-recording-controls']}>
            <span className={styles['play-button']} onClick={togglePlay}>
              {loading ? (
                <FontAwesomeIcon icon="spinner" className="fa-spin" />
              ) : (
                <FontAwesomeIcon icon={playing ? 'pause' : 'play'} />
              )}
            </span>
          </div>
          <div className={styles['call-recording-progress']}>
            <span className={styles['current-time']}>{formattedTime}</span>
            <div className={styles['seek-slider']} onClick={selectTime}>
              <div
                className={styles.knob}
                style={{ left: `${currentPercentage - 2}%` }}
              />
            </div>
            <span className={styles.duration}>{formattedDuration}</span>
          </div>
          <div
            className={clsx(
              styles['call-recording-volume-control'],
              showVolumeControl && styles['show-control']
            )}
          >
            {showVolumeControl && (
              <ClickAwayListener onClickAway={toggleVolumeControl}>
                <div className={styles['volume-slider-container']}>
                  <div
                    className={styles['volume-slider']}
                    onClick={adjustVolume}
                    style={{
                      background: `linear-gradient(to top, #00d763 ${currentVolumePercentage -
                        2}%, #f5f5f5 0)`,
                    }}
                  />
                  <div
                    className={styles.knob}
                    style={{
                      top: `calc(-${currentVolumePercentage + 4}% + 62px`,
                    }}
                  />
                </div>
              </ClickAwayListener>
            )}
            <div onClick={toggleVolumeControl}>
              <FontAwesomeIcon
                icon={
                  currentVolumePercentage === 100
                    ? 'volume-up'
                    : currentVolumePercentage <= 15
                    ? 'volume-mute'
                    : 'volume-down'
                }
              />
            </div>
          </div>
        </div>
      </div>
    </ModalForm>
  )
}

export default memo(CallRecording)
