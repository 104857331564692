import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Avatar from '@material-ui/core/Avatar'
import Chip from '@material-ui/core/Chip'
import React from 'react'

import classes from './style.module.scss'

interface IChipProps {
  label: string
  avatarUrl: string
}

const CustomChip: React.FC<IChipProps> = ({ avatarUrl, label }) => (
  <Chip
    avatar={
      <Avatar src={avatarUrl}>
        <FontAwesomeIcon icon="user-circle" className={classes.fallback} />
      </Avatar>
    }
    label={label}
    classes={{
      label: classes.label,
      avatar: classes.avatar,
    }}
  />
)

export default CustomChip
