import { ILinehaulTrackingResponseRow } from 'Trunkrs-SDK/dist/models/spatial/Linehauls/LinehaulTour'

export interface ILinehaulTrackingReducerState {
  fetchingAvailableTags: boolean
  fetchingLinehauls: boolean
  fetchingDriverLocation: boolean
  errorFetchingTours: boolean
  availableTags: any[]
  activeTag: string
  activeTagTours: ILinehaulTrackingResponseRow[]
  driverLocation: string[]
}

export interface ILinehaulTrackingState extends ILinehaulTrackingReducerState {
  fetchAvailableTags(): Promise<void>
  setActiveTag(data: string): void
  fetchLinehauls(tag: string): Promise<void>
  fetchDriverLocation(tourId: number): Promise<void>
}

export const initialState: ILinehaulTrackingState = {
  fetchingAvailableTags: false,
  fetchingLinehauls: false,
  fetchingDriverLocation: false,
  errorFetchingTours: false,
  availableTags: [],
  activeTag: '',
  activeTagTours: [],
  driverLocation: [],
  fetchAvailableTags: () => Promise.resolve(),
  setActiveTag: data => null,
  fetchLinehauls: tag => Promise.resolve(),
  fetchDriverLocation: tourId => Promise.resolve(),
}
