import * as React from 'react'
import { isEmpty } from 'lodash'

import { ModalForm } from '@/app/base/components'

import './style.scss'
import { useState } from 'react'
import { classList, eventHandler, isWeekend } from '@/utils'
import { useContext } from 'react'
import { AppContext, CommentsContext, ShipmentsContext } from '@/contexts'

import DayPicker from 'react-day-picker/DayPickerInput'
import 'react-day-picker/lib/style.css'
import moment from 'moment'
import { useTranslation } from '@/hooks'

export const PostponeShipment = (props: {
  handleClose?: (ev?: React.SyntheticEvent<any>) => void
}) => {
  const { postponeShipment } = useContext(ShipmentsContext)
  const { addComment } = useContext(CommentsContext)
  const { setLoading } = useContext(AppContext)
  const { handleClose } = props
  const [isPostponeToSpecificDate, setIsPostponeToSpecificDate] = useState(
    false
  )
  const [postponeDate, setPostponeDate] = useState()
  const [comment, setComment] = useState('')

  const handlePostpone = async (ev?: React.SyntheticEvent<any>) => {
    setLoading(true)
    const result = await postponeShipment(
      isPostponeToSpecificDate ? postponeDate : new Date().toISOString()
    )
    if (result) {
      if (!isEmpty(comment)) {
        await addComment(comment)
      }
    }
    setLoading(false)
    if (typeof handleClose === 'function') {
      handleClose(ev)
    }
  }

  const handleDateFormatting = (date: Date) => moment(date).format('DD-MM-YYYY')

  const handleDayChange = (day: Date) => {
    setPostponeDate(day)
  }

  const [t] = useTranslation(['POSTPONE_SHIPMENT', 'COMMON'])

  return (
    <ModalForm
      show={true}
      title={t('POSTPONE_SHIPMENT')}
      handleClose={handleClose}
    >
      <div id="postpone-shipment">
        <div className="wrapper">
          <button
            className={classList(
              'btn button',
              !isPostponeToSpecificDate ? 'assist' : 'inactive'
            )}
            type="button"
            onClick={eventHandler(setIsPostponeToSpecificDate, false)}
          >
            {t('BUTTON.POSTPONE_TO_TODAY')}
          </button>
          <button
            className={classList(
              'btn-postpone btn button',
              isPostponeToSpecificDate ? 'assist' : 'inactive'
            )}
            type="button"
            onClick={eventHandler(setIsPostponeToSpecificDate, true)}
          >
            {t('BUTTON.POSTPONE_TO_SPECIFIC_DATE')}
            {isPostponeToSpecificDate && (
              <DayPicker
                dayPickerProps={{
                  fromMonth: new Date(),
                  disabledDays: [
                    {
                      before: new Date(),
                    },
                    isWeekend,
                  ],
                  showOutsideDays: true,
                  enableOutsideDaysClick: false,
                  hideWeekEnd: true,
                  selectedDays: [postponeDate],
                }}
                format={'DD-MM-YYYY'}
                keepFocus={false}
                formatDate={handleDateFormatting}
                placeholder={t('COMMON:PICK_DATE')}
                onDayChange={handleDayChange}
              />
            )}
          </button>
        </div>
        <div className="comment mt-4">
          <p className="text bold">{t('ADD_COMMENT')}</p>
          <textarea
            className="form-control text"
            rows={5}
            placeholder={t('ADD_COMMENT_PLACEHOLDER')}
            value={comment}
            onChange={eventHandler(setComment)}
          />
        </div>
        <div className="actions">
          <button
            className="btn button inactive icon"
            type="button"
            onClick={handleClose}
          >
            {t('COMMON:BUTTON.NEVERMIND')}
          </button>
          <button
            className="btn button assist box-shadow"
            type="button"
            onClick={handlePostpone}
          >
            {t('BUTTON.POSTPONE_SHIPMENT')}
          </button>
        </div>
      </div>
    </ModalForm>
  )
}
