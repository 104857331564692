import API from '@/api'
import { useResponseErrorHandler } from '@/hooks'
import extractSubcosWithCountFromTag from '@/utils/extractSubcosWithCountFromTag'
import { chain, compact, flatten } from 'lodash'
import { useCallback, useMemo, useReducer } from 'react'

import { LINEHAUL_ACTION_TYPES } from './actionTypes'
import reducer from './reducer'
import { ILinehaulTrackingState, initialState } from './state'

const linehaulTour = API.LinehaulTour()

export const useLinehaulTrackingActions = (): ILinehaulTrackingState => {
  const [
    {
      activeTag,
      activeTagTours,
      availableTags,
      errorFetchingTours,
      fetchingAvailableTags,
      fetchingDriverLocation,
      fetchingLinehauls,
      driverLocation,
    },
    dispatch,
  ] = useReducer(reducer, initialState)

  const fetchAvailableTags = async () => {
    dispatch({ type: LINEHAUL_ACTION_TYPES.FETCHING_AVAILABLE_TAGS })
    const subcos = await useResponseErrorHandler(
      linehaulTour.getLinehaulTourCountForSubco()
    )
    const availableSubcos = flatten(compact(subcos))

    const tags: any[] = chain(availableSubcos)
      .sortBy('tag')
      .value()

    dispatch({
      type: LINEHAUL_ACTION_TYPES.FETCHED_AVAILABLE_TAGS,
      availableTags: tags,
      activeTag: tags[0].tag,
    })
  }

  const setActiveTag = (tag: string) => {
    dispatch({ type: LINEHAUL_ACTION_TYPES.SET_ACTIVE_TAG, activeTag: tag })
  }

  const fetchLinehauls = useCallback(
    async (tag: string) => {
      dispatch({ type: LINEHAUL_ACTION_TYPES.FETCHING_LINEHAULS })
      let linehauls: any = []
      const subcosForTag = extractSubcosWithCountFromTag(availableTags, tag)
      for (const subco of subcosForTag) {
        const [error, result] = await useResponseErrorHandler(
          linehaulTour.getLinehaulTracking(subco.id!, new Date().toString())
        )
        if (error) {
          dispatch({ type: LINEHAUL_ACTION_TYPES.FETCHING_LINEHAULS_FAILED })
          break
        }
        linehauls = linehauls.concat(result)
      }
      dispatch({
        type: LINEHAUL_ACTION_TYPES.FETCHED_LINEHAULS,
        activeTagTours: linehauls,
      })
    },
    [availableTags]
  )

  const fetchDriverLocation = useCallback(async (tourId: number) => {
    dispatch({ type: LINEHAUL_ACTION_TYPES.FETCHING_DRIVER_LOCATION })
    const [, result] = await useResponseErrorHandler(
      linehaulTour.getDriverLocationInLinehaulTour(tourId)
    )
    dispatch({
      type: LINEHAUL_ACTION_TYPES.FETCHED_DRIVER_LOCATION,
      driverLocation: result,
    })
  }, [])

  return useMemo(
    () => ({
      fetchingAvailableTags,
      availableTags,
      activeTag,
      fetchingLinehauls,
      fetchingDriverLocation,
      errorFetchingTours,
      activeTagTours,
      driverLocation,
      setActiveTag,
      fetchAvailableTags,
      fetchLinehauls,
      fetchDriverLocation,
    }),
    [
      fetchingAvailableTags,
      availableTags,
      activeTag,
      fetchingLinehauls,
      fetchingDriverLocation,
      errorFetchingTours,
      activeTagTours,
      driverLocation,
      setActiveTag,
      fetchAvailableTags,
      fetchLinehauls,
      fetchDriverLocation,
    ]
  )
}
