import { Subco } from 'Trunkrs-SDK/dist/models'
import {
  ICollectionTourDetails,
  ICollectionTourTrackingResponseRow,
} from 'Trunkrs-SDK/dist/models/spatial/Collections/CollectionTour'

export interface ICollectionTrackingState {
  fetchAvailableTags: () => Promise<string[]>
  fetchCollectionTours: (
    tag: string
  ) => Promise<ICollectionTourTrackingResponseRow[] | null>
  availableTags: string[]
  setActiveTag: (data: string) => void
  activeTag: string
  activeTagTours: ICollectionTourTrackingResponseRow[] | null
  subcontractors: Subco[] | null
  fetchCollectionTourDetails: (
    tourId: number
  ) => Promise<ICollectionTourDetails | null>
  activeCollectionTourDetails: ICollectionTourDetails | null
  fetchingAvailableTags: boolean
  fetchingCollectionTours: boolean
  fetchingCollectionTourDetails: boolean
  errorFetchingTours: boolean
}

export const initialState: ICollectionTrackingState = {
  fetchAvailableTags: () => Promise.resolve(['']),
  fetchCollectionTours: () => Promise.resolve(null),
  availableTags: [],
  setActiveTag: () => {},
  activeTagTours: null,
  activeTag: '',
  subcontractors: null,
  fetchCollectionTourDetails: () => Promise.resolve(null),
  activeCollectionTourDetails: null,
  fetchingAvailableTags: false,
  fetchingCollectionTours: false,
  fetchingCollectionTourDetails: false,
  errorFetchingTours: false,
}
