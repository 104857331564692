import { useCallback, useEffect, useRef, useState } from 'react'
import * as validate from 'validate.js'

export function useFieldValidation<T = undefined>(
  defaultValue: T | undefined,
  constraints: any
): [
  T | undefined,
  (val: T | undefined) => void,
  string[],
  () => Promise<boolean>,
  boolean | undefined
] {
  const [value, setValue] = useState(defaultValue)
  const [errors, setErrors] = useState<string[]>([])
  const pristineRef = useRef(true)

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  const handleValueChange = useCallback((val: T | undefined) => {
    pristineRef.current = false
    setErrors([])
    setValue(val)
  }, [])

  const checkValidity = async () => {
    return await validate.asyncSingle(value, constraints).then(
      () => {
        setErrors([])
        return true
      },
      (result: string[]) => {
        setErrors(result)
        return false
      }
    )
  }

  return [value, handleValueChange, errors, checkValidity, pristineRef.current]
}
