import API from '@/api'
import { AxiosError } from 'axios'
import React from 'react'

import * as actions from './actions'
import * as debtorState from './state'

const debtorClient = API.Debtor()

interface ContextState extends debtorState.State {
  loadDebtors: () => Promise<void>
  loadDebtorDetails: (merchantId: number) => Promise<void>
  saveDebtorNumber: (merchantId: number) => Promise<void>
  setDebtorNumber: (debtorNumber: string) => void
}

export const DebtorContext = React.createContext<ContextState>({
  ...debtorState.initialState,
  loadDebtors: () => {
    throw new Error('Not implemented')
  },
  loadDebtorDetails: () => {
    throw new Error('Not implemented')
  },
  saveDebtorNumber: () => {
    throw new Error('Not implemented')
  },
  setDebtorNumber: () => {
    throw new Error('Not implemented')
  },
})

const Debtors: React.FC = ({ children }) => {
  const [state, dispatch] = React.useReducer(
    debtorState.reducer,
    debtorState.initialState
  )

  const loadDebtors = React.useCallback(async (): Promise<void> => {
    dispatch(actions.setLoading(true))

    try {
      const debtors = await debtorClient.fetchAllDebtors()
      dispatch(actions.setDebtors(debtors))
    } finally {
      dispatch(actions.setLoading(false))
    }
  }, [])

  const loadDebtorDetails = React.useCallback(
    async (merchantId: number): Promise<void> => {
      dispatch(actions.setCurrentMerchant(null))
      dispatch(actions.setLoadingDetails(true))

      try {
        const { debtorNumber } = await debtorClient.fetchDebtorMerchant(
          merchantId
        )
        dispatch(actions.setCurrentMerchant(debtorNumber))
      } catch (error) {
        const axiosError = error as AxiosError

        const isDebtorNotFound =
          axiosError.isAxiosError &&
          axiosError.response &&
          axiosError.response.status === 404
        if (isDebtorNotFound) {
          return
        }

        throw axiosError
      } finally {
        dispatch(actions.setLoadingDetails(false))
      }
    },
    []
  )

  const saveDebtorNumber = React.useCallback(
    async (merchantId: number): Promise<void> => {
      dispatch(actions.setSaving(true))

      try {
        const { debtorNumber } = state
        if (!debtorNumber) {
          console.warn('Trying to save when no debtor selected...')
          return
        }

        await debtorClient.linkMerchantToDebtor(merchantId, debtorNumber)
        dispatch(actions.setCurrentMerchant(debtorNumber))
      } finally {
        dispatch(actions.setSaving(false))
      }
    },
    [state]
  )

  const setDebtorNumber = React.useCallback((debtorNumber: string): void => {
    dispatch(actions.setCurrentMerchant(debtorNumber))
  }, [])

  const contextValue = React.useMemo(
    () => ({
      ...state,
      loadDebtors,
      loadDebtorDetails,
      saveDebtorNumber,
      setDebtorNumber,
    }),
    [state, loadDebtors, loadDebtorDetails, saveDebtorNumber]
  )

  return (
    <DebtorContext.Provider value={contextValue}>
      {children}
    </DebtorContext.Provider>
  )
}

export default Debtors
