import { legends } from '@/constants/LEGENDS'
import { useTranslation } from '@/hooks'
import clsx from 'clsx'
import * as React from 'react'
import { LINEHAUL_DELAY_STATUS } from 'Trunkrs-SDK/dist/models/spatial/Linehauls/LinehaulTour'

import classes from './style.module.scss'

interface ILegendsProps {}

const Legends: React.FC<ILegendsProps> = () => {
  const [t] = useTranslation('LEGENDS')
  const legendItems = React.useMemo(
    () =>
      legends.map((legend, index) => (
        <div key={index} className={clsx(classes.legends)}>
          <span
            className={clsx(
              classes.dot,
              legend === LINEHAUL_DELAY_STATUS.ON_TIME && classes.on_time,
              legend === LINEHAUL_DELAY_STATUS.MIN_15_DELAY &&
                classes.fifteen_delay,
              legend === LINEHAUL_DELAY_STATUS.MIN_30_DELAY &&
                classes.thirty_delay
            )}
          />
          <span>{t(legend)}</span>
        </div>
      )),
    [legends]
  )

  return <div className={classes.legends_box}>{legendItems}</div>
}

export default Legends
