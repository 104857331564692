import './styles.scss'

import { AppContext, NotificationsContext } from '@/contexts'
import { useTranslation } from '@/hooks'
import { useInterval } from '@/hooks'
import { SHIPMENT_ALERT_CATEGORY } from '@/models'
import { clsx } from '@/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

import {
  LateShipmentsNotifications,
  NotAcceptedNotifications,
  NotSortedNotifications,
  NotificationBadge,
} from './components'

const {
  NOT_SORTED_AT_SUB_DEPOT,
  NOT_ACCEPTED_BY_DRIVER,
  RUNNING_LATE,
} = SHIPMENT_ALERT_CATEGORY

interface INotificationPaneProps extends React.HTMLAttributes<HTMLDivElement> {
  show?: boolean
  onClose?: (...args: any[]) => void
}

export const NotificationPane = ({ show, onClose }: INotificationPaneProps) => {
  const { setLoading, sidebarCollapsed } = useContext(AppContext)
  const {
    notSortedNotifs,
    notAcceptedNotifs,
    lateShipmentsNotifs,
    clearNotifications,
    fetchNotifications,
    markNotifications,
    notificationCount: {
      notSortedAtSubDepot,
      notAcceptedByDriver,
      runningLate,
    },
  } = useContext(NotificationsContext)

  const [activeTab, setActiveTab] = useState(NOT_SORTED_AT_SUB_DEPOT)
  const [paneClass, setPaneClass] = useState('')
  const [startPolling, stopPolling] = useInterval(fetchNotifications)

  useEffect(() => {
    if (sidebarCollapsed && show) {
      setPaneClass('collapsed')
    } else if (!sidebarCollapsed && show) {
      setPaneClass('notCollapsed')
    } else {
      setPaneClass('')
    }
  }, [sidebarCollapsed, show])

  useEffect(() => {
    setLoading(true)
    fetchNotifications(true).finally(() => setLoading(false))
    startPolling()
    return () => stopPolling()
  }, [])

  const handleSelectNotSortedTab = useCallback(
    () => setActiveTab(NOT_SORTED_AT_SUB_DEPOT),
    []
  )
  const handleSelectNotAcceptedTab = useCallback(
    () => setActiveTab(NOT_ACCEPTED_BY_DRIVER),
    []
  )
  const handleSelectLateShipmentsTab = useCallback(
    () => setActiveTab(RUNNING_LATE),
    []
  )

  const activeNotifications = {
    [NOT_SORTED_AT_SUB_DEPOT]: notSortedNotifs,
    [NOT_ACCEPTED_BY_DRIVER]: notAcceptedNotifs,
    [RUNNING_LATE]: lateShipmentsNotifs,
  }[activeTab]

  const markAllAsDone = useCallback(async () => {
    const notifications = activeNotifications.map(notification => ({
      ...notification,
      isDone: true,
    }))
    await markNotifications(notifications, activeTab)
  }, [activeNotifications])

  const clearSelectedNotifications = useCallback(async () => {
    const shipmentIds = activeNotifications
      .map(({ shipmentsAffected }) => shipmentsAffected)
      .flat()
    await clearNotifications(shipmentIds, activeTab)
  }, [activeNotifications])

  const isAllMarkedAsDone =
    activeNotifications.length > 0 &&
    activeNotifications.every(({ isDone }) => isDone)

  const [t] = useTranslation('NOTIFICATIONS')

  return (
    <div
      className={clsx('notification-container container box-shadow', paneClass)}
    >
      <div className="header-wrapper">
        <button className="btn button action icon close" onClick={onClose}>
          <FontAwesomeIcon className="text" icon="times" />
        </button>
        <h1 className="header title">{t('NOTIFICATIONS')}</h1>
        <span className={clsx(!activeNotifications.length && 'invisible')}>
          {isAllMarkedAsDone ? (
            <button
              className="btn button action icon bg-transparent sub-text clear-all"
              onClick={clearSelectedNotifications}
            >
              {t('CLEAR_ALL')}
            </button>
          ) : (
            <button
              className="btn button action icon bg-transparent sub-text mark-all"
              onClick={markAllAsDone}
            >
              {t('MARK_ALL')}
            </button>
          )}
        </span>
      </div>
      <div
        className={clsx('content-wrapper', {
          'not-sorted': activeTab === NOT_SORTED_AT_SUB_DEPOT,
          'not-accepted': activeTab === NOT_ACCEPTED_BY_DRIVER,
          'late-shipments': activeTab === RUNNING_LATE,
        })}
      >
        <div className="tabs-container">
          <button
            className="tab btn button default text"
            onClick={handleSelectNotSortedTab}
          >
            {t('NOT_SORTED_AT_SUB_DEPOT')}
            <NotificationBadge count={notSortedAtSubDepot} />
          </button>
          <button
            className="tab btn button default text"
            onClick={handleSelectNotAcceptedTab}
          >
            {t('NOT_ACCEPTED_BY_DRIVER')}
            <NotificationBadge count={notAcceptedByDriver} />
          </button>
          <button
            className="tab btn button default text"
            onClick={handleSelectLateShipmentsTab}
          >
            {t('LATE_SHIPMENTS')}
            <NotificationBadge count={runningLate} />
          </button>
        </div>
        <NotSortedNotifications />
        <NotAcceptedNotifications />
        <LateShipmentsNotifications />
      </div>
    </div>
  )
}

export default memo(NotificationPane)
