import { SHIPMENT_STATES } from 'Trunkrs-SDK/dist/models/delivery/ShipmentLog'

const checkIfStateNameInArray = (stateName: string, states: string[]) =>
  states.some(state => state === stateName)

export const isNotArrived = (stateName: string) =>
  stateName === SHIPMENT_STATES.EXCEPTION_SHIPMENT_NOT_ARRIVED
export const isPostponed = (stateName: string) =>
  stateName === SHIPMENT_STATES.CHANGE_SHIPMENT_POST_PONED

export const isMissing = (stateName: string) =>
  stateName === SHIPMENT_STATES.EXCEPTION_SHIPMENT_MISSING

export const isLost = (stateName: string) =>
  stateName === SHIPMENT_STATES.EXCEPTION_SHIPMENT_LOST

export const isCancelled = (stateName: string) => {
  const states = [
    SHIPMENT_STATES.EXCEPTION_SHIPMENT_CANCELLED_BY_SENDER,
    SHIPMENT_STATES.EXCEPTION_SHIPMENT_CANCELLED_BY_TRUNKRS,
  ]
  return checkIfStateNameInArray(stateName, states)
}

export const isDataProcessed = (stateName: string) => {
  const states = [SHIPMENT_STATES.DATA_RECEIVED, SHIPMENT_STATES.DATA_PROCESSED]
  return checkIfStateNameInArray(stateName, states)
}

export const isInTransit = (stateName: string) => {
  const states = [
    SHIPMENT_STATES.SHIPMENT_SORTED,
    SHIPMENT_STATES.SHIPMENT_SORTED_AT_SUB_DEPOT,
    SHIPMENT_STATES.SHIPMENT_ACCEPTED_BY_DRIVER,
  ]
  return checkIfStateNameInArray(stateName, states)
}

export const isDelivered = (stateName: string) => {
  const states = [
    SHIPMENT_STATES.SHIPMENT_DELIVERED,
    SHIPMENT_STATES.SHIPMENT_DELIVERED_TO_NEIGHBOR,
  ]
  return checkIfStateNameInArray(stateName, states)
}

export const isNotDelivered = (stateName: string) => {
  const states = [SHIPMENT_STATES.SHIPMENT_NOT_DELIVERED]
  return checkIfStateNameInArray(stateName, states)
}

export const isDeliveredToNeigbour = (stateName: string) => {
  const states = [SHIPMENT_STATES.SHIPMENT_DELIVERED_TO_NEIGHBOR]
  return checkIfStateNameInArray(stateName, states)
}

export const isShipmentToInvestigate = (stateName: string) => {
  const states = [SHIPMENT_STATES.EXCEPTION_SHIPMENT_INVESTIGATE]
  return checkIfStateNameInArray(stateName, states)
}

export const isNonDeliveryState = (stateName: string) => {
  const states = [
    SHIPMENT_STATES.SHIPMENT_NOT_DELIVERED,
    SHIPMENT_STATES.EXCEPTION_SHIPMENT_CANCELLED_BY_TRUNKRS,
    SHIPMENT_STATES.EXCEPTION_SHIPMENT_CANCELLED_BY_SENDER,
    SHIPMENT_STATES.RETURN_SHIPMENT_TO_SENDER,
    SHIPMENT_STATES.EXCEPTION_SHIPMENT_DECLINED_BY_DRIVER,
    SHIPMENT_STATES.EXCEPTION_SHIPMENT_INVESTIGATE,
    SHIPMENT_STATES.ADDRESS_CHANGE_REQUIRED,
  ]
  return checkIfStateNameInArray(stateName, states)
}

export const isMissortedState = (stateName: string) => {
  const states = [
    SHIPMENT_STATES.EXCEPTION_SHIPMENT_MIS_SORTED,
    'EXCEPTION_SHIPMENT_MISS_SORTED',
  ]
  return checkIfStateNameInArray(stateName, states)
}

export const isReturnToSender = (stateName: string) =>
  checkIfStateNameInArray(stateName, [
    SHIPMENT_STATES.RETURN_SHIPMENT_TO_SENDER,
  ])

export const isDeclinedByDriver = (stateName: string) =>
  checkIfStateNameInArray(stateName, [
    SHIPMENT_STATES.EXCEPTION_SHIPMENT_DECLINED_BY_DRIVER,
  ])

export const isForInvestigation = (stateName: string) =>
  checkIfStateNameInArray(stateName, ['EXCEPTION_SHIPMENT_INVESTIGATE'])
