import './styles.scss'

import { ModalForm } from '@/app/base/components'
import { useTranslation } from '@/hooks'
import * as React from 'react'
import { memo } from 'react'

export interface IPassCodeModalProps {
  passCode: string
  show: boolean
  onClose: () => void
}

const PassCodeModal = ({ passCode, show, onClose }: IPassCodeModalProps) => {
  const [t] = useTranslation(['TOUR_PROGRESS'])

  const nextPassCode = passCode.length < 4 ? '0' + passCode : passCode
  return (
    <ModalForm show={show} title={t('NO_PASSCODE')} handleClose={onClose}>
      <div className="passcode-container">
        {nextPassCode.split('').map((char: string) => (
          <div className="digit-box">{char}</div>
        ))}
      </div>
      <div className="button-container">
        <button
          className="btn button action icon bg-transparent sub-text clear-all"
          onClick={onClose}
        >
          {t('CLOSE')}
        </button>
      </div>
    </ModalForm>
  )
}

export default memo(PassCodeModal)
