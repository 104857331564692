import API from '@/api'
import { FETCH_STATUS } from '@/constants'
import { useResponseErrorHandler } from '@/hooks'
import { IExtendedTour, IFetchSubcoResponse, ISubco, ITour } from '@/models'
import { arrayReplace } from '@/utils'
import first from 'lodash/first'
import { useState } from 'react'

import { ISubcoState } from './state'

export const useSubcoActions = (initialState: ISubcoState): ISubcoState => {
  const [subcos, setSubcos] = useState(initialState.subcos)
  const [isLoading, setIsLoading] = useState(initialState.isLoading)
  const [activeSubcoId, setActiveSubcoId] = useState(initialState.activeSubcoId)

  const addSubco = (subco: ISubco) =>
    setSubcos(currentSubcos => [...currentSubcos, subco])

  const updateSubco = (subco: ISubco) => {
    setSubcos(currentSubcos => {
      const currentSubco = currentSubcos.find(({ id }) => id === subco.id)
      return arrayReplace(
        currentSubcos,
        { id: subco.id },
        {
          ...currentSubco,
          ...subco,
        }
      )
    })
  }

  const fetchAllSubco = async () => {
    setIsLoading(true)
    const [err, result] = await useResponseErrorHandler(
      API.Tour().fetchSubCosWithTours()
    )
    setIsLoading(false)
    if (err) {
      return []
    }
    return result.map((subcoId: number) => ({ id: subcoId } as ISubco))
  }

  const fetchSubco = async (
    id: number
  ): Promise<IFetchSubcoResponse | null> => {
    updateSubco({ id, isLoading: true } as ISubco)
    const [err, result] = await useResponseErrorHandler(
      API.Tour().fetchSubCoTourOverviews(id)
    )
    updateSubco({ id, isLoading: false } as ISubco)
    if (err) {
      return null
    }
    const firstResult = first(result)
    const subco =
      firstResult && mapResponseToSubco(firstResult as IExtendedTour)
    const tours = result.map((extendedTour: IExtendedTour) =>
      mapResponseToTour(extendedTour)
    )
    const drivers = result.map((extendedTour: IExtendedTour) =>
      mapResponseToDriver(extendedTour)
    )
    return {
      tours,
      subco,
      drivers,
    }
  }

  const mapResponseToTour = (extendedTour: IExtendedTour): ITour => ({
    id: extendedTour.tourId,
    driverId: extendedTour.driverId,
    subCoId: extendedTour.subCoId,
    status: FETCH_STATUS.NONE,
    delayed: false,
    color: extendedTour.tourColor,
  })

  const mapResponseToSubco = (extendedTour: IExtendedTour): ISubco => ({
    id: extendedTour.subCoId,
    name: extendedTour.subCoName,
    delays: 0,
  })

  const mapResponseToDriver = (extendedTour: IExtendedTour) => ({
    id: extendedTour.driverId,
    name: extendedTour.driverName,
    pictureLocation: extendedTour.driverPicture,
    subCoId: extendedTour.subCoId,
    subCoName: extendedTour.subCoName,
    tourId: extendedTour.tourId,
    delayed: false,
  })

  return {
    subcos,
    isLoading,
    fetchAllSubco,
    activeSubcoId,
    setActiveSubcoId,
    addSubco,
    fetchSubco,
    updateSubco,
  }
}
