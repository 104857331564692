import './style.scss'

import { AppContext, RouterContext, ShipmentsContext } from '@/contexts'
import { useTranslation } from '@/hooks'
import { classList, downloadPDF, eventHandler, isWeekend } from '@/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { defaultTo } from 'lodash'
import moment from 'moment'
import * as React from 'react'
import { useState } from 'react'
import { useContext } from 'react'
import DayPicker from 'react-day-picker/DayPickerInput'

enum ACTIONS {
  POSTPONE_TODAY = 1,
  POSTPONE_TO_SPECIFIC_DATE,
  FOR_SHIPMENT_LABEL_PRINTING,
}

interface IRecentAction {
  postponed: boolean
  printed: boolean
  error: boolean
  data: {
    description?: string
    label?: string
    shipmentId?: number
    barcode?: string
  }
}

export const Warehouse = () => {
  const {
    queuePostponeShipment,
    fetchShipmentByBarcode,
    printLabel,
  } = useContext(ShipmentsContext)
  const { history } = useContext(RouterContext)
  const { setLoading } = useContext(AppContext)

  const [barcode, setBarcode] = useState('')
  const [recentActions, setRecentActions] = useState<Array<IRecentAction>>([])
  const [lastAction, setLastAction] = useState<IRecentAction | null>()

  const [selectedAction, setSelectedAction] = useState<ACTIONS>(
    ACTIONS.POSTPONE_TODAY
  )
  const [postponeDate, setPostponeDate] = useState()
  const [t] = useTranslation(['WAREHOUSE', 'POSTPONE_SHIPMENT', 'COMMON'])

  const handleBarcodeEnter = async (
    ev: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (ev.keyCode !== 13) {
      return false
    }

    if (barcode.trim().length > 0) {
      let nextAction: IRecentAction | null = null

      setLoading(true)
      const shipment = await fetchShipmentByBarcode(barcode)

      if (shipment) {
        if (selectedAction === ACTIONS.FOR_SHIPMENT_LABEL_PRINTING) {
          const pdfData = await printLabel(barcode)
          if (pdfData) {
            const fileName = `label-${shipment.getTrunkrsNumber}.pdf`

            downloadPDF(pdfData, fileName)
            nextAction = {
              postponed: false,
              printed: true,
              error: false,
              data: {
                description: t('LABEL_FOR_SHIPMENT_PRINTED', { barcode }),
                barcode,
              },
            }
          } else {
            nextAction = {
              postponed: false,
              printed: false,
              error: true,
              data: {
                description: t('LABEL_FOR_SHIPMENT_FAILED', { barcode }),
              },
            }
          }
          nextAction.data.label = t('PRINT')
        } else {
          const date =
            selectedAction === ACTIONS.POSTPONE_TO_SPECIFIC_DATE
              ? postponeDate
              : new Date().toISOString()
          const result = await queuePostponeShipment(barcode, date)

          if (result) {
            nextAction = {
              postponed: true,
              printed: false,
              error: false,
              data: {
                description:
                  selectedAction === ACTIONS.POSTPONE_TO_SPECIFIC_DATE
                    ? t('POSTPONED_SHIPMENT_TO_DATE', {
                        barcode,
                        date: moment(date).format('DD-MM-YYYY'),
                      })
                    : t('POSTPONED_SHIPMENT_TO_TODAY', { barcode }),
                shipmentId: shipment.getId,
                barcode,
              },
            }
          } else {
            nextAction = {
              postponed: false,
              printed: false,
              error: true,
              data: {
                description:
                  selectedAction === ACTIONS.POSTPONE_TO_SPECIFIC_DATE
                    ? t('POSTPONE_SHIPMENT_TO_DATE_FAILED', {
                        barcode,
                        date: moment(date).format('DD-MM-YYYY'),
                      })
                    : t('POSTPONE_SHIPMENT_TO_TODAY_FAILED', { barcode }),
              },
            }
          }
          nextAction.data.label = t('POSTPONE')
        }
      } else {
        nextAction = {
          postponed: false,
          printed: false,
          error: true,
          data: {
            label:
              selectedAction === ACTIONS.FOR_SHIPMENT_LABEL_PRINTING
                ? t('PRINT')
                : t('POSTPONE'),
            description: t('ERROR.SHIPMENT_NOT_FOUND'),
          },
        }
      }
      setLoading(false)
      setBarcode('')
      setLastAction(nextAction)
      setRecentActions([...recentActions, nextAction])
    }
  }

  const handlePrint = async (barcode: string) => {
    setLoading(true)
    const pdfData = await printLabel(defaultTo(barcode, ''))
    setLoading(false)
    if (pdfData) {
      downloadPDF(pdfData, `label-${barcode}.pdf`)
    }
  }

  const handleBarcodeChange = async (
    ev: React.ChangeEvent<HTMLInputElement>
  ) => {
    setBarcode(ev.currentTarget.value)
    setLastAction(null)
  }

  const handleDateFormatting = (date: Date) => moment(date).format('DD-MM-YYYY')

  const handleDayChange = (day: Date) => {
    setPostponeDate(day)
  }

  const handleRedirect = (action: IRecentAction) => {
    history.push(`/shipments/${action.data.shipmentId}`)
  }

  return (
    <div id="warehouse-container" className="container-fluid">
      <h1 className="h2 mt-4 text-left title">{t('WAREHOUSE')}</h1>
      <div className="wrapper">
        <div id="barcode-scanner" className="row">
          <div className="col">
            <span className="barcode-icon">
              <FontAwesomeIcon icon="barcode" />
            </span>
            <input
              className="form-control mr-sm-2 barcode search"
              type="text"
              placeholder={t('SCAN_A_BARCODE')}
              value={barcode}
              onChange={handleBarcodeChange}
              onKeyDown={handleBarcodeEnter}
            />
            <span className="actions">
              <button
                className={classList(
                  'btn button',
                  selectedAction === ACTIONS.POSTPONE_TODAY
                    ? 'action'
                    : 'inactive'
                )}
                type="button"
                onClick={eventHandler(
                  setSelectedAction,
                  ACTIONS.POSTPONE_TODAY
                )}
              >
                {t('POSTPONE_SHIPMENT:BUTTON.POSTPONE_TO_TODAY')}
              </button>
              <button
                className={classList(
                  'btn-postpone btn button',
                  selectedAction === ACTIONS.POSTPONE_TO_SPECIFIC_DATE
                    ? 'action'
                    : 'inactive'
                )}
                type="button"
                onClick={eventHandler(
                  setSelectedAction,
                  ACTIONS.POSTPONE_TO_SPECIFIC_DATE
                )}
              >
                {t('POSTPONE_SHIPMENT:BUTTON.POSTPONE_TO_SPECIFIC_DATE')}
                {selectedAction === ACTIONS.POSTPONE_TO_SPECIFIC_DATE && (
                  <DayPicker
                    dayPickerProps={{
                      fromMonth: new Date(),
                      disabledDays: [
                        {
                          before: new Date(),
                        },
                        isWeekend,
                      ],
                      showOutsideDays: true,
                      enableOutsideDaysClick: false,
                      hideWeekEnd: true,
                      selectedDays: [postponeDate],
                    }}
                    format={'DD-MM-YYYY'}
                    keepFocus={false}
                    formatDate={handleDateFormatting}
                    placeholder={t('COMMON:PICK_DATE')}
                    onDayChange={handleDayChange}
                  />
                )}
              </button>
              <button
                className={classList(
                  'btn button',
                  selectedAction === ACTIONS.FOR_SHIPMENT_LABEL_PRINTING
                    ? 'action'
                    : 'inactive'
                )}
                type="button"
                onClick={eventHandler(
                  setSelectedAction,
                  ACTIONS.FOR_SHIPMENT_LABEL_PRINTING
                )}
              >
                {t('BUTTON.PRINT_SHIPMENT_LABEL')}
              </button>
            </span>
          </div>
        </div>
        {lastAction && (
          <div className="scan-notification-container">
            {lastAction.postponed && (
              <div className="notification success">
                <p className="text">
                  <FontAwesomeIcon
                    icon={['far', 'thumbs-up']}
                    className="icon"
                  />{' '}
                  {t('POSTPONED_ITEM', { barcode: lastAction.data.barcode })}
                </p>
              </div>
            )}
            {lastAction.printed && (
              <div className="notification success">
                <p className="text">
                  <FontAwesomeIcon
                    icon={['far', 'sticky-note']}
                    className="icon"
                  />{' '}
                  {t('PRINTED_LABEL_ITEM', {
                    barcode: lastAction.data.barcode,
                  })}
                </p>
              </div>
            )}
            {lastAction.error && (
              <div className="notification error">
                <p className="text">
                  <FontAwesomeIcon
                    icon={['far', 'thumbs-down']}
                    className="icon"
                  />{' '}
                  {t('SHIPMENT_NOT_FOUND')}
                </p>
              </div>
            )}
          </div>
        )}

        <div className="history row">
          <div className="col-6">
            <p className="text-left subtitle">{t('COMMON:RECENT')}</p>
            <div className="list">
              {[...recentActions].reverse().map((action: IRecentAction) => {
                const { description, label, barcode } = action.data
                return (
                  <div className="history-action row">
                    {action.postponed || action.printed ? (
                      <>
                        <div className="col">
                          <FontAwesomeIcon
                            icon={[
                              'far',
                              action.postponed ? 'thumbs-up' : 'sticky-note',
                            ]}
                            className="icon text green"
                          />
                        </div>
                        <div className="col">
                          <p className="text">{label}</p>
                        </div>
                        <div className="col">
                          <p className="text text-left">{description}</p>
                        </div>
                        <div className="col">
                          {action.postponed ? (
                            <button
                              className="btn button default icon"
                              onClick={eventHandler(handleRedirect, action)}
                            >
                              {t('BUTTON.VIEW_SHIPMENT')}
                            </button>
                          ) : (
                            <button
                              className="btn button icon"
                              onClick={eventHandler(handlePrint, barcode)}
                            >
                              {t('BUTTON.PRINT_AGAIN')}
                            </button>
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="col">
                          <FontAwesomeIcon
                            icon={['far', 'thumbs-down']}
                            className="icon text red"
                          />
                        </div>
                        <div className="col">
                          <p className="text">{label}</p>
                        </div>
                        <div className="col">
                          <p className="text text-left red">{description}</p>
                        </div>
                        <div className="col" />
                      </>
                    )}
                  </div>
                )
              })}
            </div>
            {recentActions.length === 0 && (
              <p className="text no-results">{t('COMMON:NO_RESULTS')}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
