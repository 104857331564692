import Icon from '@/app/base/components/.v2/Icon'
import emptyStateIcon from '@/app/base/img/hublogs-empty-state.svg'
import { ShipmentsContext } from '@/contexts'
import { useTranslation } from '@/hooks'
import { HUBLOG_STATE, IHubLog } from '@/models'
import { showInMapWithOriginDestination } from '@/utils/seeInMap'
import clsx from 'clsx'
import defaultTo from 'lodash/defaultTo'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'
import upperFirst from 'lodash/upperFirst'
import moment from 'moment'
import React, { memo, useContext, useMemo } from 'react'

import EmptyLog from '../emptyLog'
import styles from './styles.module.scss'

const HubLogs = () => {
  const [t] = useTranslation('HUB_LOGS')
  const { hubLogs } = useContext(ShipmentsContext)
  const hubLogsGroupedByDate = Object.keys(hubLogs)
  const isEmpty = !hubLogsGroupedByDate.length

  return (
    <div className={clsx(styles.wrapper, isEmpty && styles.empty)}>
      {useMemo(
        () =>
          hubLogsGroupedByDate.map(date => {
            const logs = sortBy(get(hubLogs, date, []), 'createdAt').reverse()
            return (
              <div key={date} className={styles['hub-log-container']}>
                <div className={styles.date}>
                  {moment(date, 'DD/MM/YYYY').format('DD-MM-YYYY')}
                </div>
                {logs.map(
                  ({
                    createdAt,
                    region,
                    owner,
                    state,
                    version,
                    distance,
                  }: IHubLog) =>
                    version > 0 && (
                      <div key={createdAt} className={styles['hub-log']}>
                        <div className={styles.timestamp}>
                          {moment(new Date(createdAt)).format('HH:mm:ss')}
                        </div>
                        <div className={styles.region}>
                          {state === HUBLOG_STATE.HUB_IN_CENTRAL
                            ? 'Central'
                            : defaultTo(region, '')}
                        </div>
                        <div className={styles['pill-container']}>
                          <span
                            className={clsx(
                              styles.circle,
                              styles[
                                state === HUBLOG_STATE.HUB_IN ||
                                state === HUBLOG_STATE.HUB_IN_CENTRAL
                                  ? 'hub-in'
                                  : 'hub-out'
                              ]
                            )}
                          />
                          <span className={styles.text}>
                            {t(
                              state === HUBLOG_STATE.HUB_IN ||
                                state === HUBLOG_STATE.HUB_IN_CENTRAL
                                ? 'IN'
                                : 'OUT'
                            )}
                          </span>
                        </div>
                        <div className={styles.distanceLink}>
                          {distance && (
                            <>
                              <a
                                href={showInMapWithOriginDestination(
                                  {
                                    lat: get(distance, 'subcoLatitude'),
                                    long: get(distance, 'subcoLongitude'),
                                  },
                                  {
                                    lat: get(distance, 'scanLatitude'),
                                    long: get(distance, 'scanLongitude'),
                                  }
                                )}
                                target="_blank"
                              >
                                {`${(
                                  get(distance, 'distanceFromHub', 0) / 1000
                                ).toFixed(1)} KM`}{' '}
                                <Icon name="redirect" />
                                <div
                                  className={styles['static-tooltip-distance']}
                                >
                                  <div>{t('DISTANCE_TO_DEPOT')}</div>
                                </div>
                              </a>
                            </>
                          )}
                        </div>
                        <div className={styles['static-tooltip']}>
                          <div>{t('OWNER')}</div>
                          <div>{upperFirst(get(owner, 'name', 'N/A'))}</div>
                        </div>
                      </div>
                    )
                )}
              </div>
            )
          }),
        [hubLogs]
      )}
      {isEmpty && (
        <EmptyLog
          src={emptyStateIcon}
          title={t('EMPTY_SCREEN_TITLE')}
          description={t('EMPTY_SCREEN_DETAILS')}
        />
      )}
    </div>
  )
}

export default memo(HubLogs)
