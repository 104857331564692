import { CollectionTourContext } from '@/contexts'
import { initialState } from '@/store/collectionTour/state'
import * as React from 'react'

import { useCollectionTourActions } from './actions'

export default ({ children }: { children: React.ReactNode }) => {
  const state = useCollectionTourActions(initialState)

  return (
    <CollectionTourContext.Provider value={state}>
      {children}
    </CollectionTourContext.Provider>
  )
}
