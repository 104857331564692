import { IFetchSubcoResponse, ISubco } from '@/models'

export interface ISubcoState {
  subcos: Array<ISubco>
  isLoading: boolean | null
  fetchAllSubco: () => Promise<Array<ISubco>>
  activeSubcoId: number | null
  setActiveSubcoId: (id: number) => void
  addSubco: (subco: ISubco) => void
  fetchSubco: (id: number) => Promise<IFetchSubcoResponse | null>
  updateSubco: (subco: ISubco) => void
}

export const initialSubcoState: ISubcoState = {
  subcos: [],
  isLoading: null,
  fetchAllSubco: () => Promise.resolve([]),
  activeSubcoId: null,
  setActiveSubcoId: () => null,
  addSubco: () => null,
  fetchSubco: () => Promise.resolve(null),
  updateSubco: () => null,
}
