import './styles.scss'

import { ShipmentSearchBar } from '@/app/features/shipments/components'
import { ROOT_URL } from '@/constants'
import { RouterContext } from '@/contexts'
import { clsx } from '@/utils'
import * as React from 'react'
import { useCallback, useContext, useEffect, useState } from 'react'

export const SearchBar = () => {
  const { history } = useContext(RouterContext)
  const [isVisible, setIsVisible] = useState(false)

  const keyDownFunc = useCallback(
    (ev: KeyboardEvent = window.event as KeyboardEvent) => {
      if (ev.ctrlKey && ev.which === 70) {
        if (location.pathname !== ROOT_URL) {
          setIsVisible(true)
          const elem = document.getElementById('shipment-search-input')
          if (elem) {
            elem.focus()
          }
          ev.preventDefault()
        }
      } else if (ev.which === 27) {
        setIsVisible(false)
      }
      ev.stopPropagation()
    },
    [isVisible]
  )

  useEffect(() => {
    if (document.addEventListener) {
      document.addEventListener('keydown', keyDownFunc)
      return () => document.removeEventListener('keydown', keyDownFunc)
    }
  }, [])

  useEffect(
    () =>
      history.listen(() => {
        setIsVisible(false)
      }),
    []
  )

  return (
    <div className={clsx('search-bar-container', { 'd-none': !isVisible })}>
      <div className="box-shadow">
        <ShipmentSearchBar />
      </div>
    </div>
  )
}
