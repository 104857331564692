import clsx from 'clsx'
import React, { memo } from 'react'

import styles from './styles.module.scss'

export default memo(
  ({
    children,
    className,
    visible,
  }: React.HTMLAttributes<HTMLDivElement> & { visible?: boolean }) => {
    return (
      <div
        className={clsx('modal', styles.modal, visible && 'd-block', className)}
        tabIndex={-1}
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className={clsx('modal-content', styles['modal-content'])}>
            {children}
          </div>
        </div>
      </div>
    )
  }
)
