import { SHIPMENT_STATE } from '@/constants'
import { ReasonCode } from '@/models'

import { IDriver, Row } from './types'

export interface ITourRecoveryState {
  tourId?: number
  isAllRowsSelected: boolean
  selectedRows: Row[]
  modifiedRows: Row[]
  driver?: IDriver
  reasonCodes: ReasonCode[]
  rows: Row[]
  handleToggleRow: (rowIndex: number) => void
  handleResetRow: (rowIndex: number) => void
  handleToggleAllRows: () => void
  handleApplyPreview: (
    state: SHIPMENT_STATE,
    time: string,
    reasonCode?: ReasonCode
  ) => void
  handleModifyRow: (
    rowIndex: number,
    state?: SHIPMENT_STATE,
    time?: string,
    reasonCode?: ReasonCode
  ) => void
  handleApplyToRowsBelow: (rowIndex: number) => void
  clearSelection: () => void
  fetchInitialData: () => Promise<void>
  handleSetTime: (rowIndex: number, time: string) => void
  handleSetShipmentState: (rowIndex: number, state: SHIPMENT_STATE) => void
  handleSetReasonCode: (rowIndex: number, reasonCode: ReasonCode) => void
}

export const initialTourRecoveryState: ITourRecoveryState = {
  isAllRowsSelected: false,
  selectedRows: [],
  modifiedRows: [],
  reasonCodes: [],
  rows: [],
  handleToggleRow: () => null,
  handleResetRow: () => null,
  handleToggleAllRows: () => null,
  handleApplyPreview: () => null,
  handleModifyRow: () => null,
  handleApplyToRowsBelow: () => null,
  clearSelection: () => null,
  fetchInitialData: () => Promise.resolve(),
  handleSetTime: () => null,
  handleSetShipmentState: () => null,
  handleSetReasonCode: () => null,
}
