import { CollectionTrackingContext } from '@/contexts'
import { useCollectionTrackingActions } from '@/store/collectionTracking/actions'
import { initialState } from '@/store/collectionTracking/state'
import * as React from 'react'

export default ({ children }: { children: React.ReactNode }) => {
  const state = useCollectionTrackingActions(initialState)
  return (
    <CollectionTrackingContext.Provider value={state}>
      {children}
    </CollectionTrackingContext.Provider>
  )
}
