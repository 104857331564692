import { SearchBar, Sidebar } from '@/app/base/components'
import { Routes } from '@/app/features/routing'
import { NotificationPane } from '@/app/features/user/components/notificationPane'
import UserMenu from '@/app/features/user/components/userMenu'
import routes from '@/app/routes'
import {
  AppContext,
  AuthContext,
  LocalizationContext,
  RouterContext,
} from '@/contexts'
import i18n from '@/i18n'
import {
  CollationStore,
  DebtorStore,
  DriverStore,
  MerchantStore,
  NotificationsStore,
  ShipmentsStore,
  SmartSearchStore,
  SubcoStore,
  TourStore,
} from '@/store'
import { isCollectionTourUser } from '@/utils'
import React, { memo, useCallback, useContext, useEffect, useMemo } from 'react'
import ScrollContainer from 'react-perfect-scrollbar'

const App = () => {
  const { location } = useContext(RouterContext)
  const {
    Loader: { component: Loader, ref },
    isNotificationPaneVisible,
    setIsNotificationPaneVisible,
    sidebarCollapsed,
  } = useContext(AppContext)

  const {
    authenticated,
    identity: {
      profile: { email },
    },
  } = useContext(AuthContext)

  const toggleNotificationPane = useCallback(
    () => setIsNotificationPaneVisible(!isNotificationPaneVisible),
    [isNotificationPaneVisible]
  )

  useEffect(() => {
    setIsNotificationPaneVisible(false)
  }, [location])

  const privateRoutes = useMemo(
    () =>
      routes.filter(route =>
        route.forCollectionTourUser
          ? isCollectionTourUser(email)
          : !route.public && !route.disabled
      ),
    [routes, email]
  )

  const isIndexPage = /^\/shipments$/i.test(location.pathname)

  return (
    <div className="app">
      <LocalizationContext.Provider value={i18n}>
        <SmartSearchStore>
          <DebtorStore>
            <MerchantStore>
              <SubcoStore>
                <TourStore>
                  <DriverStore>
                    <CollationStore>
                      {authenticated && (
                        <NotificationsStore>
                          <Sidebar routes={privateRoutes}>
                            <UserMenu />
                          </Sidebar>
                          <NotificationPane
                            show={isNotificationPaneVisible}
                            onClose={toggleNotificationPane}
                          />
                        </NotificationsStore>
                      )}
                      {isIndexPage ? (
                        <main role="main" className="px-4">
                          <ShipmentsStore>
                            <SearchBar />
                            <Routes routes={routes} />
                          </ShipmentsStore>
                        </main>
                      ) : (
                        <ScrollContainer
                          role="main"
                          className="px-4"
                          component="main"
                        >
                          <ShipmentsStore>
                            <SearchBar />
                            <Routes routes={routes} />
                          </ShipmentsStore>
                        </ScrollContainer>
                      )}
                    </CollationStore>
                  </DriverStore>
                </TourStore>
              </SubcoStore>
            </MerchantStore>
          </DebtorStore>
        </SmartSearchStore>
      </LocalizationContext.Provider>
      <Loader refElement={ref} />
    </div>
  )
}

export default memo(App)
