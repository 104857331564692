import API from '@/api'
import {
  IMerchantOverviewState,
  MerchantAccount,
} from '@/store/merchantOverview/IMerchantOverviewState'
import get from 'lodash/get'

import { ICreateMerchantParams } from '../client/actions'

export enum MERCHANT_OVERVIEW_ACTIONS {
  INITIALIZE_MERCHANT_LIST,
  FETCH_MERCHANT_COMPLETE,
  FETCH_MERCHANT_PENDING,
  SET_STATE,
  UPDATE_PENDING,
  UPDATE_MERCHANT_INVOICING_COMPLETE,
  UPDATE_MERCHANT_FAILED,
  UPDATE_MERCHANT_DATA_COMPLETE,
  UPDATE_MERCHANT_NAME,
  FETCH_FORECASTS_PENDING,
  FETCH_FORECASTS_COMPLETE,
  UPDATE_FORECASTS_PENDING,
  UPDATE_FORECASTS_COMPLETE,
  UPDATE_FORECASTS_MERGE,
}

export interface IMerchantOverviewAction {
  type: MERCHANT_OVERVIEW_ACTIONS
  payload?: any
}

const Merchant = API.Merchant()

export const createMerchantUserAccount = (
  account: MerchantAccount
): Promise<void> => Merchant.createMerchantUser(account)

export const fetchMerchantInfoById = (
  dispatch: (action: IMerchantOverviewAction) => void
) => (merchantId: number) => {
  dispatch({
    type: MERCHANT_OVERVIEW_ACTIONS.FETCH_MERCHANT_PENDING,
  })
  return Merchant.fetchMerchantOverview(merchantId).then(merchantInfo => {
    dispatch({
      type: MERCHANT_OVERVIEW_ACTIONS.FETCH_MERCHANT_COMPLETE,
      payload: merchantInfo,
    })
  })
}

export const initializeMerchantOverview = (
  dispatch: (action: IMerchantOverviewAction) => void
) => async () =>
  Merchant.getMerchantList().then(list =>
    dispatch({
      type: MERCHANT_OVERVIEW_ACTIONS.INITIALIZE_MERCHANT_LIST,
      payload: list,
    })
  )

export const setState = (
  dispatch: (action: IMerchantOverviewAction) => void
) => (newState: IMerchantOverviewState) => {
  dispatch({
    type: MERCHANT_OVERVIEW_ACTIONS.SET_STATE,
    payload: newState,
  })
}

export const onUpdateMerchantInvoicing = (
  dispatch: (action: IMerchantOverviewAction) => void
) => async (
  merchantId: number,
  payload: Pick<ICreateMerchantParams, 'pricing' | 'invoicing'>
): Promise<void> => {
  dispatch({ type: MERCHANT_OVERVIEW_ACTIONS.UPDATE_PENDING })

  try {
    await Merchant.updateInvoicingSection(merchantId, payload)
    dispatch({
      type: MERCHANT_OVERVIEW_ACTIONS.UPDATE_MERCHANT_INVOICING_COMPLETE,
      payload: {
        ...payload,
        invoicing: {
          ...payload.invoicing,
          address: get(payload, 'invoicing.location.address'),
          city: `${get(payload, 'invoicing.location.postalCode')} ${get(
            payload,
            'invoicing.location.city'
          )}`,
          country: get(payload, 'invoicing.location.country'),
        },
      },
    })
  } catch (error) {
    const message = get(error, 'response.data.message')
    dispatch({
      type: MERCHANT_OVERVIEW_ACTIONS.UPDATE_MERCHANT_FAILED,
      payload: message,
    })
  }
}

export const onUpdateMerchantData = (
  dispatch: (action: IMerchantOverviewAction) => void
) => async (
  merchantId: number,
  payload: ICreateMerchantParams
): Promise<void> => {
  dispatch({ type: MERCHANT_OVERVIEW_ACTIONS.UPDATE_PENDING })
  const keys = Object.keys(payload)
  let reqBody = payload
  try {
    if (keys.includes('operations') && keys.length >= 2) {
      reqBody = {
        ...payload,
        operations: {
          cutOffTime: get(payload, 'operations.cutOffTime'),
        } as any,
      }
    }

    await Merchant.updateMerchantInfo(merchantId, reqBody)

    if (keys.includes('merchant')) {
      dispatch({
        type: MERCHANT_OVERVIEW_ACTIONS.UPDATE_MERCHANT_NAME,
        payload: get(payload, 'merchant.name'),
      })
    }

    const data = keys.reduce((acc: any, key: any) => {
      let addressObject

      if (!!get(payload, `${key}.location`)) {
        addressObject = {
          address: get(payload, `${key}.location.address`),
          city: `${get(payload, `${key}.location.postalCode`)} ${get(
            payload,
            `${key}.location.city`
          )}`,
          country: get(payload, `${key}.location.country`),
        }
      }
      acc[key] = {
        ...payload[key as keyof ICreateMerchantParams],
        ...addressObject,
      }
      return acc
    }, {})

    dispatch({
      type: MERCHANT_OVERVIEW_ACTIONS.UPDATE_MERCHANT_DATA_COMPLETE,
      payload: data,
    })
  } catch (error) {
    const message = get(error, 'response.data.message')
    dispatch({
      type: MERCHANT_OVERVIEW_ACTIONS.UPDATE_MERCHANT_FAILED,
      payload: message,
    })
  }
}

export const fetchForecasts = (
  dispatch: (action: IMerchantOverviewAction) => void
) => async (merchantId: number) => {
  dispatch({
    type: MERCHANT_OVERVIEW_ACTIONS.FETCH_FORECASTS_PENDING,
  })
  return Merchant.fetchMerchantForecasts(merchantId)
    .then(forecasts => {
      dispatch({
        type: MERCHANT_OVERVIEW_ACTIONS.FETCH_FORECASTS_COMPLETE,
        payload: forecasts,
      })
    })
    .catch(() => {
      dispatch({
        type: MERCHANT_OVERVIEW_ACTIONS.FETCH_FORECASTS_COMPLETE,
        payload: {
          merchant: {},
          forecast: [],
        },
      })
    })
}

export const updateForecasts = (
  dispatch: (action: IMerchantOverviewAction) => void
) => async (
  merchantId: number,
  payload: { date: string; forecast: number }[]
) => {
  dispatch({
    type: MERCHANT_OVERVIEW_ACTIONS.UPDATE_FORECASTS_PENDING,
  })
  await Merchant.updateMerchantForecast(merchantId, payload).finally(() => {
    dispatch({
      type: MERCHANT_OVERVIEW_ACTIONS.UPDATE_FORECASTS_MERGE,
      payload,
    })
    dispatch({
      type: MERCHANT_OVERVIEW_ACTIONS.UPDATE_FORECASTS_COMPLETE,
    })
  })
}
