import MuiTextField from '@material-ui/core/TextField'
import get from 'lodash/get'
import omit from 'lodash/omit'
import React, { HTMLProps, memo, useCallback } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import MaskedInput, { Props as IMaskProps } from 'react-input-mask'

export interface IInputMaskedProps extends HTMLProps<HTMLInputElement> {
  name: string
  maskProps: IMaskProps
  disableUnderline?: boolean
  isEdit?: boolean
}

const InputMasked = ({
  name,
  onChange,
  onBlur,
  maskProps,
  children,
  isEdit = false,
  ...props
}: IInputMaskedProps) => {
  const { control, setValue } = useFormContext()
  const { maskPlaceholder = null, ...restProps } = maskProps

  const renderControllerProps = useCallback(
    (props: any) => {
      let injectedProps = {
        ...props,
        value: get(props, 'value', ''),
        onChange: (ev: any) => {
          ev.persist()
          props.onChange(ev)
          onChange && onChange(ev)
        },
        onChangeCapture: (ev: any) => {
          ev.persist()
          setValue(name, ev.target.value)
        },
        onBlur: (ev: any) => {
          ev.persist()
          props.onBlur()
          onBlur && onBlur(ev)
        },
      }

      if (isEdit) {
        injectedProps = omit(injectedProps, ['onChange'])
      }

      return (
        <MaskedInput
          {...injectedProps}
          {...restProps}
          name={name}
          maskPlaceholder={maskPlaceholder}
        >
          <MuiTextField InputProps={{ disableUnderline: isEdit }} />
        </MaskedInput>
      )
    },
    [maskProps, onChange, onBlur]
  )

  return (
    <Controller
      {...props as any[]}
      as={renderControllerProps}
      name={name as never}
      control={control as any}
    />
  )
}

export default memo(InputMasked)
