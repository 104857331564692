import { SHIPMENT_STATES } from 'Trunkrs-SDK/dist/typings/types'

import { SearchableItemKey } from './interfaces'

export const filterList = [
  SearchableItemKey.ORDER_REFERENCE,
  SearchableItemKey.TRUNKRS_NUMBER,
  SearchableItemKey.CURRENT_RECIPIENT,
  SearchableItemKey.RECIPIENT_ADDRESS,
  SearchableItemKey.RECIPIENT_CITY,
  SearchableItemKey.RECIPIENT_POSTALCODE,
  SearchableItemKey.SENDER,
  SearchableItemKey.CREATION_DATE,
  SearchableItemKey.CURRENT_DELIVERY_DATE,
  SearchableItemKey.CURRENT_SHIPMENT_STATE,
]

export const filterArrayEnabled = [SearchableItemKey.CURRENT_SHIPMENT_STATE]

export const normalStates = [
  SHIPMENT_STATES.DATA_PROCESSED,
  SHIPMENT_STATES.SHIPMENT_SORTED,
  SHIPMENT_STATES.SHIPMENT_SORTED_AT_SUB_DEPOT,
  SHIPMENT_STATES.SHIPMENT_ACCEPTED_BY_DRIVER,
  SHIPMENT_STATES.EXCEPTION_SHIPMENT_DECLINED_BY_DRIVER,
  SHIPMENT_STATES.SHIPMENT_DELIVERED,
  SHIPMENT_STATES.SHIPMENT_DELIVERED_TO_NEIGHBOR,
  SHIPMENT_STATES.SHIPMENT_NOT_DELIVERED,
  SHIPMENT_STATES.EXCEPTION_SHIPMENT_NOT_ARRIVED,
  SHIPMENT_STATES.EXCEPTION_SHIPMENT_INVESTIGATE,
  SHIPMENT_STATES.RETURN_SHIPMENT_TO_SENDER,
  SHIPMENT_STATES.EXCEPTION_SHIPMENT_CANCELLED_BY_TRUNKRS,
  SHIPMENT_STATES.EXCEPTION_SHIPMENT_CANCELLED_BY_SENDER,
  SHIPMENT_STATES.ADDRESS_CHANGE_REQUIRED,
  SHIPMENT_STATES.SHIPMENT_DELAYED_AFTER_SORTED,
  SHIPMENT_STATES.SHIPMENT_DELAYED_AFTER_SORTED_AT_SUB_DEPOT,
]
