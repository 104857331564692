import { CollectionTrackingContext } from '@/contexts'
import { useTranslation } from '@/hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

import DrawerLoading from '../drawerLoading'
import Collections from './collections'
import DriverDetails from './driverDetails'
import Header from './header'
import RemarksBox from './remarksBox'
import classes from './style.module.scss'

interface IDrawerContentProps {
  toggleDrawer: (shouldBeOpen: boolean) => void
}

const DrawerContent: React.FC<IDrawerContentProps> = ({ toggleDrawer }) => {
  const {
    activeCollectionTourDetails: details,
    fetchingCollectionTourDetails: loading,
  } = React.useContext(CollectionTrackingContext)
  const [t] = useTranslation('COLLECTION_TRACKING')

  const handleCloseDrawer = () => {
    toggleDrawer(false)
  }

  if (!details || loading) {
    return <DrawerLoading handleCloseDrawer={handleCloseDrawer} />
  }

  const {
    collectionCollations,
    driver,
    state,
    tourId,
    tourRemark,
    tour,
  } = details

  return (
    <div className={classes.drawer_content}>
      <Header closeDrawer={handleCloseDrawer} tourId={tourId} />
      {tourRemark && (
        <RemarksBox
          color="orange"
          content={
            <div>
              <FontAwesomeIcon icon="exclamation-circle" size="2x" />
              <span>{t('ATTENTION')}</span>
              <span>{tourRemark}</span>
            </div>
          }
        />
      )}
      <DriverDetails driver={driver} tourId={tourId} />
      <Collections
        collations={collectionCollations}
        tourState={state}
        tourTimeDetails={tour}
      />
    </div>
  )
}

export default DrawerContent
