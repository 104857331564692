import { Tooltip } from '@/app/base/components'
import { AppContext, NotificationsContext } from '@/contexts'
import { useTranslation } from '@/hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { memo, useCallback, useContext } from 'react'

const NotificationMenuLink = () => {
  const { totalNotifications } = useContext(NotificationsContext)
  const {
    setIsNotificationPaneVisible,
    isNotificationPaneVisible,
    sidebarCollapsed,
  } = useContext(AppContext)
  const toggleNotificationPane = useCallback(
    () => setIsNotificationPaneVisible(!isNotificationPaneVisible),
    [isNotificationPaneVisible]
  )

  const [t] = useTranslation('NOTIFICATIONS')

  return (
    <a className="nav-link menu-link" onClick={toggleNotificationPane}>
      {sidebarCollapsed ? (
        <Tooltip text={t('NOTIFICATIONS')} className="menu-tooltip">
          {totalNotifications > 0 && (
            <span className="badge badge-pill badge-danger collapsed">
              {totalNotifications}
            </span>
          )}
          <FontAwesomeIcon icon="bell" />
        </Tooltip>
      ) : (
        <FontAwesomeIcon icon="bell" />
      )}
      <span className="notification menu-text">
        {t('NOTIFICATIONS')}
        {totalNotifications > 0 && (
          <span className="badge badge-pill badge-danger">
            {totalNotifications}
          </span>
        )}
      </span>
    </a>
  )
}

export default memo(NotificationMenuLink)
