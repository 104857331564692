import { CheckBox } from '@/app/base/components'
import { NotificationsContext, RouterContext } from '@/contexts'
import { useTranslation } from '@/hooks'
import { IShipmentNotification, SHIPMENT_ALERT_CATEGORY } from '@/models'
import { clsx } from '@/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import isEmpty from 'lodash/isEmpty'
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

const {
  NOT_SORTED_AT_SUB_DEPOT,
  NOT_ACCEPTED_BY_DRIVER,
  RUNNING_LATE,
} = SHIPMENT_ALERT_CATEGORY

const NOTIFICATION_TEXT = {
  [NOT_SORTED_AT_SUB_DEPOT]: 'NOT_SORTED_DETAILS',
  [NOT_ACCEPTED_BY_DRIVER]: 'NOT_ACCEPTED_DETAILS',
  [RUNNING_LATE]: 'LATE_SHIPMENT_DETAILS',
}

export const Notification = ({
  notification,
}: {
  notification: IShipmentNotification
}) => {
  const { history } = useContext(RouterContext)
  const { markNotifications } = useContext(NotificationsContext)

  const {
    driverPicture,
    shipmentId,
    alertCategory,
    isDone,
    tourId,
    trunkrsNr,
    shipmentsAffected,
  } = notification

  const [isChecked, setIsChecked] = useState(isDone)

  const handleOnMarkCheck = useCallback(
    async (ev: React.SyntheticEvent<HTMLInputElement>) => {
      const nextNotification = {
        ...notification,
        isDone: ev.currentTarget.checked,
      }
      setIsChecked(ev.currentTarget.checked)
      await markNotifications([nextNotification], alertCategory)
    },
    [notification, markNotifications]
  )

  const handleOnClearMark = useCallback(async () => {
    const nextNotification = { ...notification, isDone: false }
    setIsChecked(false)
    await markNotifications([nextNotification], alertCategory)
  }, [notification, markNotifications])

  const selectShipment = useCallback(
    () => history.push(`/shipments/${shipmentId}`),
    []
  )

  const isMarkAsDone = isDone || isChecked

  const [t] = useTranslation('NOTIFICATIONS')

  return (
    <div className="notification">
      <div className={clsx(isMarkAsDone && 'opacity-grayscale')}>
        {isEmpty(driverPicture) || driverPicture === 'n/a' ? (
          <FontAwesomeIcon className="user-icon text" icon="user-circle" />
        ) : (
          <img className="user-icon" src={driverPicture} />
        )}
        <div className="details-container">
          <p className="text">
            {t('SHIPMENT')}{' '}
            <a
              className="btn button default select-shipment"
              onClick={selectShipment}
            >
              {trunkrsNr}
            </a>{' '}
            {t(NOTIFICATION_TEXT[alertCategory], { trunkrsNr })}
          </p>
          <span className="tour-no sub-text">
            {t('TOUR_ID', { tourId })}
            {shipmentsAffected.length > 1 &&
              ` (${t('SHIPMENTS_AFFECTED', {
                count: shipmentsAffected.length,
              })})`}
          </span>
        </div>
      </div>
      <CheckBox checked={isMarkAsDone} onChange={handleOnMarkCheck} />
      <button
        className={clsx(
          'btn button action icon close',
          !isMarkAsDone && 'invisible'
        )}
        onClick={handleOnClearMark}
      >
        <FontAwesomeIcon className="text" icon="times" />
      </button>
    </div>
  )
}

export default memo(Notification)
