import DateAndTimePicker from '@/app/base/components/dateAndTimePicker'
import { AddComment } from '@/app/features/shipment/components/setFinalState/components'
import { AppContext, CommentsContext, ShipmentsContext } from '@/contexts'
import { useTranslation } from '@/hooks'
import { useFieldValidation } from '@/hooks'
import { Country, INeighbourParams, SHIPMENT_ACTIONS } from '@/models'
import { eventHandler } from '@/utils'
import { defaultTo, first, isEmpty } from 'lodash'
import moment from 'moment'
import React, { memo, useCallback, useContext, useState } from 'react'

interface IForNeighbourDeliveryProps {
  handleClose?: (...args: any[]) => void
}
const ForNeighbourDelivery = ({ handleClose }: IForNeighbourDeliveryProps) => {
  const { setLoading } = useContext(AppContext)
  const { addComment } = useContext(CommentsContext)
  const { setShipmentAsDeliveredToNeighbour } = useContext(ShipmentsContext)
  const [selectedDateTime, setSelectedDateTime] = useState(moment())

  const [comment, setComment] = useState('')

  const [t] = useTranslation(['SET_FINAL_STATE', 'EDIT_RECIPIENT'])

  const [
    address,
    setAddress,
    addressErrors,
    validateAddress,
    pristineAddress,
  ] = useFieldValidation('', {
    presence: {
      allowEmpty: false,
      message: t('EDIT_RECIPIENT:VALIDATION.STREET_HOUSE_REQUIRED'),
    },
    length: {
      minimum: 2,
      message: t('EDIT_RECIPIENT:VALIDATION.STREET_HOUSE_MIN_LENGTH'),
    },
    format: {
      pattern: /.*\d+.*/g,
      message: t('EDIT_RECIPIENT:VALIDATION.STREET_HOUSE_REQUIRED_DIGIT'),
    },
  })

  const [
    postalCode,
    setPostalCode,
    postalCodeErrors,
    validatePostalCode,
    pristinePostalCode,
  ] = useFieldValidation('', {
    presence: {
      allowEmpty: false,
      message: t('EDIT_RECIPIENT:VALIDATION.ZIPCODE_REQUIRED'),
    },
    format: {
      pattern: /\d{4}\s?\w{2}/g,
      message: t('EDIT_RECIPIENT:VALIDATION.ZIPCODE_INVALID'),
    },
  })

  const [
    city,
    setCity,
    cityErrors,
    validateCity,
    pristineCity,
  ] = useFieldValidation('', {
    presence: {
      allowEmpty: false,
      message: t('EDIT_RECIPIENT:VALIDATION.CITY_REQUIRED'),
    },
    length: {
      minimum: 2,
      message: t('EDIT_RECIPIENT:VALIDATION.CITY_MIN_LENGTH'),
    },
  })

  const [
    name,
    setName,
    nameErrors,
    validateName,
    pristineName,
  ] = useFieldValidation('', {
    presence: {
      allowEmpty: false,
      message: t('EDIT_RECIPIENT:VALIDATION.NAME_REQUIRED'),
    },
    length: {
      minimum: 2,
      message: t('EDIT_RECIPIENT:VALIDATION.NAME_MIN_LENGTH'),
    },
    format: {
      pattern: /[^0-9]+/g,
      message: t('EDIT_RECIPIENT:VALIDATION.NAME_REQUIRED_ALPHA'),
    },
  })

  const setStateToDeliveredToNeighbour = useCallback(
    async (ev: React.SyntheticEvent<any>) => {
      const validationResults = await Promise.all([
        validateAddress(),
        validatePostalCode(),
        validateCity(),
        validateName(),
      ])

      if (!validationResults.every(Boolean)) {
        return
      }

      const neighbour: INeighbourParams = {
        name: defaultTo(name, ''),
        address: defaultTo(address, ''),
        postalCode: defaultTo(postalCode, ''),
        city: defaultTo(city, ''),
        country: Country.NL,
      }
      setLoading(true)
      const success = await setShipmentAsDeliveredToNeighbour(
        neighbour,
        comment,
        moment(selectedDateTime).toString()
      )
      if (success && !isEmpty(comment)) {
        await addComment(comment)
      }
      setLoading(false)
      if (success && handleClose) {
        handleClose(ev)
      }
    },
    [comment, validateAddress, validatePostalCode, validateCity, validateName]
  )

  const handleSetAddress = useCallback(eventHandler(setAddress), [])
  const handleSetPostalCode = useCallback(eventHandler(setPostalCode), [])
  const handleSetCity = useCallback(eventHandler(setCity), [])
  const handleSetName = useCallback(eventHandler(setName), [])

  const isPristine =
    pristineAddress && pristinePostalCode && pristineCity && pristineName

  return (
    <div>
      <form
        id="neighbour-form"
        className="form text-left needs-validation"
        noValidate={true}
      >
        <div className="form-group row">
          <div className="col">
            <label className="row col text bold">
              {t('SET_TIME_AND_DATE')}
            </label>
            <DateAndTimePicker
              value={selectedDateTime}
              placeholder={t('PICK_TIME_AND_DATE')}
              handleConfirm={setSelectedDateTime}
            />
          </div>
        </div>
        <div className="address-input-container form-group row">
          <div className="col">
            <label className="text bold" htmlFor="txt-address">
              {t('EDIT_RECIPIENT:STREET_HOUSE_NUMBER')}
            </label>
            <input
              type="text"
              className="form-control"
              id="txt-address"
              placeholder={t('EDIT_RECIPIENT:STREET_HOUSE_NUMBER')}
              value={address}
              onChange={handleSetAddress}
              required
            />
            {addressErrors.length > 0 && (
              <label className="error-label label">
                {first(addressErrors)}
              </label>
            )}
          </div>
        </div>
        <div className="form-group row">
          <div className="col-3">
            <label className="text bold" htmlFor="txt-zipcode">
              {t('EDIT_RECIPIENT:ZIPCODE')}
            </label>
            <input
              type="text"
              className="form-control"
              id="txt-zipcode"
              placeholder={t('EDIT_RECIPIENT:ZIPCODE')}
              value={postalCode}
              onChange={handleSetPostalCode}
              required
            />
            {postalCodeErrors.length > 0 && (
              <label className="error-label label">
                {first(postalCodeErrors)}
              </label>
            )}
          </div>
          <div id="input-city-container" className="col">
            <label className="text bold" htmlFor="txt-city">
              {t('EDIT_RECIPIENT:CITY')}
            </label>
            <input
              type="text"
              className="form-control"
              id="txt-city"
              placeholder={t('EDIT_RECIPIENT:CITY')}
              value={city}
              onChange={handleSetCity}
              required
            />
            {cityErrors.length > 0 && (
              <label className="error-label label">{first(cityErrors)}</label>
            )}
          </div>
        </div>
        <div id="input-name" className="form-group row">
          <div className="col">
            <label className="text bold" htmlFor="txt-name">
              {t('EDIT_RECIPIENT:NAME')}
            </label>
            <input
              type="text"
              className="form-control"
              id="txt-name"
              placeholder={t('EDIT_RECIPIENT:NAME')}
              value={name}
              onChange={handleSetName}
              required
            />
            {nameErrors.length > 0 && (
              <label className="error-label label">{first(nameErrors)}</label>
            )}
          </div>
        </div>
      </form>
      <AddComment action={SHIPMENT_ACTIONS.DELIVERY} callback={setComment} />
      <div className="actions">
        <button
          className="btn button inactive icon"
          type="button"
          onClick={handleClose}
        >
          {t('COMMON:BUTTON.NEVERMIND')}
        </button>
        <button
          className={'btn button box-shadow success'}
          type="button"
          onClick={setStateToDeliveredToNeighbour}
          disabled={isPristine}
        >
          {t('BUTTON.MARK_AS_DELIVERED_TO_NEIGHBOUR')}
        </button>
      </div>
    </div>
  )
}
export default memo(ForNeighbourDelivery)
