import './style.scss'

import Icon from '@/app/base/components/.v2/Icon'
import NavigationBack from '@/app/base/components/navigationBack'
import {
  ExpectedArrival,
  QuickTour,
  Recipient,
  Sender,
  ShipmentComments,
} from '@/app/features/shipment/components'
import { ENVIRONMENT, PRODUCT_NAME } from '@/constants'
import {
  AppContext,
  DriverContext,
  RouterContext,
  ShipmentsContext,
} from '@/contexts'
import { useTranslation } from '@/hooks'
import { ShipmentFeatures } from '@/models'
import { CommentsStore } from '@/store'
import { copyToClipboard } from '@/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react'
import { IMeasurement } from 'Trunkrs-SDK/dist/utils/spliMeasurement'

import ToolTip from '../../base/components/.v2/Tooltip'
import RightPanel from './components/rightPanel'

const supportedFeatures = ['REQUIREPOD', 'NONEIGHBOURDELIVERY']

export const Shipment = () => {
  const { setLoading } = useContext(AppContext)
  const { getParams } = useContext(RouterContext)
  const [initializing, setInitializing] = useState(true)
  const [showTooltip, setTooltipShow] = useState(false)
  const [merchantConfigInitializing, setMerchantConfigInitializing] = useState(
    false
  )
  const [
    merchantAddressChangeDisabled,
    setMerchantAddressChangeDisabled,
  ] = useState(true)

  const { id } = getParams<{ id: number }>()

  const {
    fetchShipment,
    activeShipment,
    setActiveShipment,
    setActiveOverviewShipment,
    getActiveOverviewShipment,
    activeShipmentDetails: shipmentDetails,
    activeOverviewShipment,
    frozenFoodDeliveryAttempts,
    accumulatedOutOfFreezerTime,
    maxTimeOutOfFreezer,
    fetchHubLogs,
    fetchAuditLogs,
    fetchMerchantConfig,
  } = useContext(ShipmentsContext)
  const { allDriversWithSubcoId } = useContext(DriverContext)

  useLayoutEffect(() => {
    setInitializing(false)
  }, [])

  useEffect(() => {
    setLoading(true)
    setInitializing(true)
    setActiveShipment(null)
    setActiveOverviewShipment(null)
    fetchShipment(id)
      .then(async shipment => {
        if (shipment) {
          setActiveShipment(shipment)
          await getActiveOverviewShipment(shipment.getId)
          return shipment
        }
        return null
      })
      .then(shipment => {
        if (shipment) {
          const merchantId = shipment.getMerchantId
          setMerchantConfigInitializing(true)
          fetchMerchantConfig(merchantId)
            .then(response => {
              const merchantConfig = get(response, 'result', []).find(
                config => config.configuration === 'DISABLE_ADDRESS_CHANGE'
              )
              const addressChangeDisabled = get(
                merchantConfig,
                'enabled',
                false
              )
              setMerchantAddressChangeDisabled(addressChangeDisabled)
            })
            .finally(() => setMerchantConfigInitializing(false))
        }
      })
      .then(() => {
        const doFetchHubLogs = async () => {
          if (!isEmpty(allDriversWithSubcoId)) {
            await fetchHubLogs(id)
            await fetchAuditLogs(id)
          }
        }
        return doFetchHubLogs()
      })
      .finally(() => {
        setLoading(false)
        setInitializing(false)
      })
  }, [id, allDriversWithSubcoId])

  const [t] = useTranslation(['SHIPMENT', 'TOUR_PROGRESS', 'SHIPMENT_DETAILS'])

  const isSameDayFrozenFood =
    get(activeOverviewShipment, 'productName') ===
    PRODUCT_NAME.SAME_DAY_FROZEN_FOOD
  const isRequirePOD = get(
    shipmentDetails,
    'shipmentFeatures.requirePOD',
    false
  )
  const isNoNeighbourDelivery = get(
    shipmentDetails,
    'shipmentFeatures.noNeighbourDelivery',
    false
  )
  const isAddressChangeDisabled = merchantConfigInitializing
    ? undefined
    : isRequirePOD || isNoNeighbourDelivery || merchantAddressChangeDisabled
  const lbdRemark = get(shipmentDetails, 'leaveBehindPermission.remark', null)

  const reasonLabel = useCallback(() => {
    let label = []
    if (isRequirePOD) {
      label.push(t('REQUIREPOD'))
    }
    if (isNoNeighbourDelivery) {
      label.push(t('NONEIGHBOURDELIVERY'))
    }
    if (merchantAddressChangeDisabled) {
      label.push(t('MERCHANT_NO_ADDRESS_CHANGE'))
    }
    return label.join(' | ')
  }, [isRequirePOD, isNoNeighbourDelivery, merchantAddressChangeDisabled])

  const addressChangeFeatures = merchantConfigInitializing
    ? undefined
    : {
        isAddressChangeDisabled,
        reasonText: isRequirePOD
          ? t('REQUIREPOD_OTP')
          : merchantAddressChangeDisabled
          ? t('MERCHANT_NO_ADDRESS_CHANGE')
          : '',
      }

  if (initializing || !shipmentDetails) return null

  const tntLink =
    ENVIRONMENT === 'production'
      ? `https://parcel.trunkrs.nl/${shipmentDetails.trunkrsNr}/${
          shipmentDetails.recipient.postalCode
        }`
      : `https://parcel-v2-staging.trunkrs.app/${shipmentDetails.trunkrsNr}/${
          shipmentDetails.recipient.postalCode
        }`

  const copyShipmentNumber = (text: string) => {
    copyToClipboard(text)
    setTooltipShow(true)
    setTimeout(() => {
      setTooltipShow(false)
    }, 400)
  }

  const parseWeight = (weight: IMeasurement) => {
    if (weight.unit === 'g') {
      return Math.round(weight.value / 10) / 100
    }
    return Math.round(weight.value * 100) / 100
  }

  return (
    <div id="shipment-container" className="container-fluid">
      <div className="header">
        <NavigationBack
          label={t('NAVIGATION.BACK_TO_SHIPMENTS')}
          path="/shipments"
        />
      </div>
      <h1 className="h2 mt-4 text-left title">
        <div>
          <span className="shipment-number">
            {t('SHIPMENT_NUMBER', {
              number: activeShipment && activeShipment.getTrunkrsNumber,
            })}
            <span className="span-tooltip">
              <span
                className={
                  showTooltip
                    ? 'copy-tooltip-success visible'
                    : 'copy-tooltip-success'
                }
              >
                {t('TEXT_COPIED')}
              </span>
              <button
                className="btn button action icon bg-transparent copy"
                onClick={() =>
                  copyShipmentNumber(
                    `${activeShipment && activeShipment.getTrunkrsNumber}`
                  )
                }
              >
                <Icon name="copy" />
              </button>
            </span>
          </span>
          <a
            className="tnt-link"
            target="_blank"
            rel="noopener noreferrer"
            href={tntLink}
          >
            <div>
              Open Track &amp; Trace
              <Icon name="outgoing" />
            </div>
          </a>
          <div className="weight">
            {!!activeShipment &&
              !!activeShipment.getWeight &&
              `Shipment weight: ${parseWeight(activeShipment!.getWeight)}kg`}
          </div>
        </div>
        {isSameDayFrozenFood && (
          <span className="product-name-identifier">
            <FontAwesomeIcon icon="snowflake" /> - Food
          </span>
        )}
        {isAddressChangeDisabled && (
          <span className="product-name-identifier pod">{reasonLabel()}</span>
        )}
        <p className="desc">
          {t(
            `${
              lbdRemark
                ? 'LEAVE_BEHIND_PERMISION_ALLOWED'
                : 'LEAVE_BEHIND_PERMISSION_NOT_ALLOWED'
            }`
          )}
        </p>
        <p className="sub">{shipmentDetails.barcode}</p>
      </h1>
      <div className="wrapper">
        <div className="row h-100">
          <CommentsStore>
            <div className="col-9 h-100">
              <div className="row">
                <div className="col">
                  {activeOverviewShipment && (
                    <Recipient
                      shipment={activeOverviewShipment}
                      addressChangeFeatures={addressChangeFeatures}
                    />
                  )}
                </div>
                <div className="col">
                  <Sender sender={shipmentDetails.sender} />
                </div>
                <div className="col">
                  <ExpectedArrival />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="tour-progress-label">
                    <QuickTour shipment={activeShipment} />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <ShipmentComments />
                </div>
              </div>
            </div>
            <div className="col-3 h-100 mt-24">
              <RightPanel
                data={shipmentDetails.shipmentHistory}
                isSameDayFrozenFood={isSameDayFrozenFood}
                freezerData={frozenFoodDeliveryAttempts}
                accumulatedOutOfFreezerTime={accumulatedOutOfFreezerTime}
                maxTimeOutOfFreezer={maxTimeOutOfFreezer}
                proofOfDelivery={shipmentDetails.proofOfDelivery}
              />
            </div>
          </CommentsStore>
        </div>
      </div>
    </div>
  )
}
