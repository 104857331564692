import * as Sentry from '@sentry/browser'

export const useResponseErrorHandler = (asyncMethod: Promise<any>) => {
  return asyncMethod
    .then(response => [null, response])
    .catch(err => {
      Sentry.captureException(err)
      return [err]
    })
}
