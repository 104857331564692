import LoadingBars from '@/app/base/components/loadingBars'
import LoadingTile from '@/app/base/components/loadingTile'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import * as React from 'react'

import classes from './style.module.scss'

interface IDrawerLoadingProps {
  handleCloseDrawer: () => void
}

const DrawerLoading: React.FC<IDrawerLoadingProps> = ({
  handleCloseDrawer,
}) => {
  return (
    <div className={classes.drawer_content_loading}>
      <div className={classes.header}>
        <LoadingTile width={200} height={25} />
        <button
          className={clsx('icon close', classes.no_outline)}
          onClick={handleCloseDrawer}
        >
          <FontAwesomeIcon icon="times" />
        </button>
      </div>
      <LoadingTile width={250} height={35} />
      <div className={classes.middle}>
        <LoadingTile width={70} height={70} circular={true} />
        <div className={classes.middle_1}>
          <LoadingTile width={100} height={20} />
          <LoadingTile width={80} height={20} />
        </div>
        <div className={classes.middle_2}>
          <LoadingTile width={200} height={50} />
          <LoadingTile width={250} height={20} />
        </div>
      </div>
      <LoadingBars numberOfBar={5} barHeight={150} />
    </div>
  )
}

export default DrawerLoading
