import { flatten } from '@/utils'

export const classList = (...args: any[]) =>
  flatten(
    args.map(o =>
      typeof o === 'object'
        ? Object.keys(o).filter(key => o[key])
        : o.toString()
    )
  ).join(' ')
