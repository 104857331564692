import './styles.scss'

import NavigationBack from '@/app/base/components/navigationBack'
import { Form } from '@/app/base/components/reactHookComponents'
import MerchantFormNotification from '@/app/features/merchantCreation/components/merchantFormNotififcation'
import { AppContext, ClientContext, RouterContext } from '@/contexts'
import { useTranslation } from '@/hooks'
import { ICreateMerchantParams } from '@/store/client/actions'
import {
  convertFileToBase64,
  getMimeTypeFromBase64String,
  scrollMainPageToTop,
} from '@/utils'
import { yupResolver } from '@hookform/resolvers'
import get from 'lodash/get'
import moment from 'moment'
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { UseFormMethods } from 'react-hook-form'

import {
  MerchantCollectionForm,
  MerchantITForm,
  MerchantInvoicingForm,
  MerchantShipmentForm,
  MerchantWebshopForm,
} from './components'
import validationSchema, { defaultValues } from './schema'

const MerchantCreation = () => {
  const { setLoading } = useContext(AppContext)
  const { history } = useContext(RouterContext)
  const { createMerchant, fetchMerchantFormResource } = useContext(
    ClientContext
  )
  const [notificationState, setNotificationState] = useState<{
    isOpen: boolean
    isError?: boolean
  }>({ isOpen: false })
  const formMethods = useRef<
    UseFormMethods<ICreateMerchantParams> & {
      formRef?: React.Ref<HTMLFormElement>
    }
  >()

  const timer = useRef<any>()

  useEffect(() => {
    fetchMerchantFormResource()
  }, [])

  const clearTimer = useCallback(() => {
    clearTimeout(timer.current)
  }, [timer.current])

  useEffect(() => {
    clearTimer()
    if (notificationState.isOpen) {
      timer.current = setTimeout(() => {
        setNotificationState({ isOpen: false })
      }, 5000)
    }
    return clearTimer
  }, [notificationState])

  const handleSubmit = async (formValues: ICreateMerchantParams) => {
    setLoading(true)

    const cutOffTime = moment(
      get(formValues, 'timeSlotConfig.dataWindowClosesAt', '09:00'),
      'HH:mm'
    )
    const parcelImage = get(formValues, 'collection.parcelImage[0]')
    const encodedParcelImage =
      parcelImage && (await convertFileToBase64(parcelImage))

    const collection = {
      ...get(formValues, 'collection'),
      importantInfo: get(formValues, 'collection.importantInfo', ''),
      ...(encodedParcelImage && {
        parcelImage: {
          data: encodedParcelImage,
          fileType: await getMimeTypeFromBase64String(encodedParcelImage),
        },
      }),
    }

    const requestParams: ICreateMerchantParams = {
      ...formValues,
      timeSlotConfig: {
        dataWindowClosesAt: cutOffTime.format(),
      },
      collection,
      operations: {
        ...get(formValues, 'operations'),
        cutOffTime: cutOffTime.format('HH:mm'),
      },
      pricing: {
        ...get(formValues, 'pricing'),
        deliveryTier7: 0,
        deliveryHeavySurcharge: get(formValues, 'pricing.deliveryXlSurcharge'),
      },
    }

    const merchantId = await createMerchant(requestParams)
    const isSuccess = !!merchantId
    setLoading(false)

    scrollMainPageToTop()
    setNotificationState({ isOpen: true, isError: !isSuccess })

    if (isSuccess) {
      if (formMethods.current) {
        const { reset } = formMethods.current
        reset(defaultValues as any)
      }
      history.push(`/profile/merchant/${merchantId}`)
    }
  }

  const handleOnLoad = useCallback(
    (methods: UseFormMethods<ICreateMerchantParams>) => {
      formMethods.current = methods
    },
    []
  )

  const handleSubmitError = useCallback(() => {
    setNotificationState({ isOpen: true, isError: true })
  }, [])

  const [t] = useTranslation('MERCHANT_CREATION')

  return (
    <div id="merchant-creation" className="container-fluid">
      <div className="title">
        <h1 className="h2 mt-4 text-left">{t('CREATE_MERCHANT')}</h1>
        <NavigationBack
          label={t('BACK')}
          path="/profile"
          className="nav-wrapper"
          navClass="nav-link"
        />
      </div>
      <div className="wrapper">
        <Form<ICreateMerchantParams>
          onLoad={handleOnLoad}
          onSubmit={handleSubmit}
          onSubmitError={handleSubmitError}
          resolver={yupResolver(validationSchema)}
          reValidateMode="onBlur"
          defaultValues={defaultValues as any}
        >
          <MerchantFormNotification {...notificationState} />
          <MerchantWebshopForm />
          <MerchantShipmentForm />
          <MerchantCollectionForm />
          <MerchantInvoicingForm />
          <MerchantITForm />
          <div className="submit-button-container">
            <button
              type="submit"
              className="btn btn-primary pull-right button action box-shadow"
            >
              {t('BUTTON.CREATE_MERCHANT')}
            </button>
          </div>
        </Form>
      </div>
    </div>
  )
}

export default memo(MerchantCreation)
