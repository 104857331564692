import './styles.scss'

import {
  InputGroup,
  InputRangeField,
  SelectField,
  TextField,
} from '@/app/base/components/reactHookComponents'
import MerchantPhotoUpload from '@/app/features/merchantCreation/components/merchantPhotoUpload'
import { useTranslation } from '@/hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Grid from '@material-ui/core/Grid/Grid'
import MenuItem from '@material-ui/core/MenuItem/MenuItem'
import get from 'lodash/get'
import toNumber from 'lodash/toNumber'
import React, {
  SyntheticEvent,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useFormContext } from 'react-hook-form'

import resource from '../../resource.json'

const MerchantShipmentForm = () => {
  const [showForCastedVolumeWarning, setShowForCastedVolumeWarning] = useState(
    false
  )
  const [showShipmentSizeWarning, setShowShipmentSizeWarning] = useState(false)
  const [showExceededWeightWarning, setShowExceededWeightWarning] = useState(
    false
  )

  const {
    formState: { isSubmitted },
  } = useFormContext()
  const weightClasses = get(resource, 'WeightClasses', []) as Array<{
    value: string
    description: string
  }>

  const [t] = useTranslation('MERCHANT_CREATION')

  useEffect(() => {
    setShowForCastedVolumeWarning(false)
    setShowShipmentSizeWarning(false)
    setShowExceededWeightWarning(false)
  }, [isSubmitted])

  const handleForCastedVolumeOnBlur = useCallback(
    (ev: SyntheticEvent<HTMLInputElement>) => {
      const targetField = 'operations.forecastedVolumeFrom'
      const fieldName = ev.currentTarget.getAttribute('name')
      if (fieldName === targetField) {
        setShowForCastedVolumeWarning(toNumber(ev.currentTarget.value) < 55)
      }
    },
    []
  )

  const handleShipmentSizeOnBlur = useCallback(
    (ev: SyntheticEvent<HTMLInputElement>) => {
      setShowShipmentSizeWarning(toNumber(ev.currentTarget.value) > 60)
    },
    []
  )

  const handleShipmentWeightOnChange = useCallback(
    (ev: SyntheticEvent<HTMLSelectElement>) => {
      setShowExceededWeightWarning(ev.currentTarget.value === 'HEAVY')
    },
    []
  )

  return (
    <div id="merchant-shipment">
      <div className="header-wrapper">
        <div>
          <span className="header-text">{t('SHIPMENTS_HEADER')}</span>
          <span className="header-subtext">{t('SHIPMENTS_SUB_HEADER')}</span>
        </div>
        <div className="icon" />
      </div>
      <div className="content">
        <Grid container>
          <Grid container item xs={12} spacing={1}>
            <Grid item xs={4}>
              <InputRangeField
                label={t('STARTING_VOLUME_WEEKLY')}
                names={[
                  'operations.startingVolumeFrom',
                  'operations.startingVolumeTo',
                ]}
                placeholders={['0', '55']}
              />
            </Grid>
            <Grid item xs={4}>
              <InputRangeField
                onBlur={handleForCastedVolumeOnBlur}
                label={t('FORCASTED_VOLUME_WEEKLY')}
                names={[
                  'operations.forecastedVolumeFrom',
                  'operations.forecastedVolumeTo',
                ]}
                placeholders={['55', '999']}
              />
              {showForCastedVolumeWarning && (
                <div className="warning-notification invalid-tooltip">
                  {t('FORCASTED_VOLUME_WARNING')}
                </div>
              )}
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="weeks-until-forcasted"
                label={t('WEEKS_UNTIL_FORCASTED_VOLUME')}
                name="operations.weeksUntilForecastedVolume"
                placeholder="4"
                maskProps={{ mask: '99' }}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={1}>
            <Grid item xs={6}>
              <InputGroup
                numeric
                onBlur={handleShipmentSizeOnBlur}
                label={t('MAXIMUM_SHIPMENT_SIZE_CM')}
                names={[
                  'operations.shipmentLengthCm',
                  'operations.shipmentWidthCm',
                  'operations.shipmentHeightCm',
                ]}
                placeholders={['L', 'W', 'H']}
                icons={[
                  <FontAwesomeIcon icon="times" />,
                  <FontAwesomeIcon icon="times" />,
                ]}
              />
              {showShipmentSizeWarning && (
                <div className="warning-notification invalid-tooltip">
                  {t('SHIPMENT_SIZE_WARNING')}
                </div>
              )}
            </Grid>
            <Grid item xs={6}>
              <SelectField
                required
                onChange={handleShipmentWeightOnChange}
                label={t('SHIPMENT_WEIGHT_CLASS')}
                id="shipment-weight-class"
                name="operations.expectedWeight"
              >
                <MenuItem value="" hidden>
                  {t('WEIGHT_CLASS_PLACEHOLDER')}
                </MenuItem>
                {useMemo(
                  () =>
                    weightClasses.map(({ value, description }) => (
                      <MenuItem value={value}>{description}</MenuItem>
                    )),
                  []
                )}
              </SelectField>
              {showExceededWeightWarning && (
                <div className="warning-notification invalid-tooltip shipment-weight-warning">
                  {t('EXCEEDED_WEIGHT_WARNING')}
                </div>
              )}
            </Grid>
          </Grid>
          <MerchantPhotoUpload />
        </Grid>
      </div>
    </div>
  )
}

export default memo(MerchantShipmentForm)
