import get from 'lodash/get'
import React, { HTMLProps, memo, useCallback } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Props as IMaskProps } from 'react-input-mask'
import { NumberFormatValues } from 'react-number-format'

export interface IControlledProps extends HTMLProps<HTMLElement> {
  name: string
  decimalSeparator?: string | boolean
  allowNegative?: boolean
  maskProps?: IMaskProps
  onValueChange?: (values: NumberFormatValues) => void
}

const WithController = (props: IControlledProps) => {
  const { control } = useFormContext()

  const { children, onChange, onBlur } = props
  const renderControllerProps = useCallback(
    (props: any) => {
      const injectedProps = {
        ...props,
        value: get(props, 'value', ''),
        onChange: (ev: any) => {
          ev.persist()
          props.onChange(ev)
          onChange && onChange(ev)
        },
        onBlur: (ev: any) => {
          ev.persist()
          props.onBlur()
          onBlur && onBlur(ev)
        },
        onValueChange: ({ value }: any) => {
          props.onChange(value)
        },
      }

      return children && (children as any)(injectedProps)
    },
    [props]
  )

  return (
    <Controller
      {...props as any}
      as={renderControllerProps}
      name={name as never}
      control={control as any}
    />
  )
}

export default memo(WithController)
