import ButtonBase from '@material-ui/core/ButtonBase'
import { useTheme } from '@material-ui/core/styles'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import clsx from 'clsx'
import addMonths from 'date-fns/addMonths'
import subMonths from 'date-fns/subMonths'
import React, { useCallback, useMemo, useRef, useState } from 'react'

import Calendar from '../Calendar'
import Weekdays from '../Weekdays'
import {
  getNextSetOfCalendarMonthsToView,
  getPreviousSetOfCalendarMonthToView,
} from './helper'
import styles from './styles.module.scss'

export interface PickerProps {
  initialDate: Date
  showWeekends?: boolean
  showOutsideDays?: boolean
  onDateClick?: (date: Date) => void
}

export const Picker: React.FC<PickerProps> = ({
  initialDate,
  showWeekends = true,
  showOutsideDays = true,
  onDateClick,
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const [calendars, setCalendars] = useState([
    { date: subMonths(initialDate, 1), key: -1 },
    { date: initialDate, key: 0 },
    { date: addMonths(initialDate, 1), key: 1 },
    { date: addMonths(initialDate, 2), key: 2 },
  ])

  const theme = useTheme()

  const width = useMemo(() => {
    return showWeekends ? theme.spacing(39) : theme.spacing(29)
  }, [theme, showWeekends])

  const handlePrev = useCallback(
    (_e?: React.SyntheticEvent, count = 1) => {
      const newCalendars = getNextSetOfCalendarMonthsToView(calendars, count)
      setCalendars(newCalendars)
    },
    [calendars]
  )

  const handleNext = useCallback(
    (_e?: React.SyntheticEvent, count = 1) => {
      const newCalendars = getPreviousSetOfCalendarMonthToView(calendars, count)
      setCalendars(newCalendars)
    },
    [calendars]
  )

  const handleWheel = useCallback(
    (event: React.WheelEvent) => {
      if (event.deltaY > 0) {
        handleNext()
        return
      }

      handlePrev()
    },
    [handleNext, handlePrev]
  )

  return (
    <div
      className={styles.pickerRoot}
      style={{ width: width * 2 }}
      onWheel={handleWheel}
    >
      <div className={styles.calendars}>
        {calendars.map(calendar => (
          <Calendar
            month={calendar.date.getMonth()}
            year={calendar.date.getFullYear()}
            showWeekends={showWeekends}
            hideWeekdayNames
            showOutsideDays={showOutsideDays}
            onDateClick={onDateClick}
          />
        ))}
      </div>

      <div className={clsx(styles.controls, styles.fixedElement)}>
        <ButtonBase onClick={handlePrev}>
          <ChevronLeftIcon className={styles.chevron} />
        </ButtonBase>
        <ButtonBase onClick={handleNext}>
          <ChevronRightIcon className={styles.chevron} />
        </ButtonBase>
      </div>

      <div className={clsx(styles.weekdays, styles.fixedElement)}>
        <Weekdays showWeekends={showWeekends} />
        <Weekdays showWeekends={showWeekends} />
      </div>
    </div>
  )
}

export default Picker
