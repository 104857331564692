import InputBase, {
  InputBaseProps,
} from '@material-ui/core/InputBase/InputBase'
import React, { memo } from 'react'
import { useFormContext } from 'react-hook-form'

interface IInputProps extends InputBaseProps {
  name: string
}

const Input = ({ name, ...props }: IInputProps) => {
  const { register } = useFormContext()
  return <InputBase name={name} inputRef={register} {...props} />
}

export default memo(Input)
