import * as React from 'react'
import { groupBy, sortBy, uniqBy, filter, values, defaults } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { useEffect } from 'react'
import { useContext } from 'react'
import { AppContext, ShipmentsContext } from '@/contexts'
import { eventHandler } from '@/utils'
import { useTranslation } from '@/hooks'

export const SearchSender = (props: {
  onSelect?: (id: number | undefined) => void
}) => {
  const { fetchMerchants } = useContext(ShipmentsContext)
  const { setLoading } = useContext(AppContext)
  const [query, setQuery] = useState('')
  const [merchants, setMerchants] = useState<
    Array<{ id: number; name: string }>
  >([])
  const [filteredResults, setFilteredResults] = useState<{
    [key: string]: Array<{ id: number; name: string }>
  }>()
  const [showAutoComplete, setShowAutoComplete] = useState(false)
  const [selecting, setSelecting] = useState(false)

  const groupByLetter = (merchants: Array<{ id: number; name: string }>) =>
    groupBy(merchants, merchant => merchant.name.slice(0, 1).toLowerCase())

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      const result = await fetchMerchants()
      if (result) {
        const sortedByName = sortBy(uniqBy(result, 'name'), merchant =>
          merchant.name.toLowerCase()
        )
        setMerchants(sortedByName)
        setFilteredResults(groupByLetter(sortedByName))
      }
      setLoading(false)
    })()
  }, [])

  const handleSearch = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const searchString = ev.currentTarget.value
    setQuery(searchString)

    const result = defaults(
      filter(
        merchants,
        merchant =>
          merchant.name.toLowerCase().indexOf(searchString.toLowerCase()) > -1
      ),
      []
    )
    setFilteredResults(groupByLetter(result))
    setShowAutoComplete(true)

    const { onSelect } = props
    if (typeof onSelect === 'function') {
      onSelect(undefined)
    }
  }

  const handleSelect = (merchant: { id: number; name: string }) => {
    const { onSelect } = props
    const { id, name } = merchant

    setQuery(name)
    setShowAutoComplete(false)
    setSelecting(false)
    if (typeof onSelect === 'function') {
      onSelect(id)
    }
  }

  const handleBlur = () => {
    if (!selecting) {
      setShowAutoComplete(false)
    }
  }

  const [t] = useTranslation(['UPLOAD_CSV'])

  return (
    <div id="search-sender">
      <div className="row">
        <div className="col-4">
          <span className="search-icon search">
            <FontAwesomeIcon icon="search" />
          </span>
          <input
            className="form-control mr-sm-2 search"
            type="text"
            placeholder={t('SEARCH_CLIENT')}
            value={query}
            onChange={handleSearch}
            onFocus={eventHandler(setShowAutoComplete, true)}
            onBlur={handleBlur}
          />
          <span className="down-icon search">
            <FontAwesomeIcon icon="caret-down" />
          </span>
        </div>
      </div>
      {showAutoComplete && (
        <div className="row autocomplete">
          <div className="col-4">
            <div id="sender-autocomplete" className="list-group">
              {values(filteredResults).map(group => {
                return group.map((merchant, index) => {
                  const { name, id } = merchant
                  return (
                    <button
                      onClick={eventHandler(handleSelect, merchant)}
                      onMouseEnter={eventHandler(setSelecting, true)}
                      onMouseLeave={eventHandler(setSelecting, false)}
                      type="button"
                      key={index}
                      className="list-group-item list-group-item-action d-flex align-items-center"
                    >
                      <span className="text letter">
                        {index === 0 ? name.slice(0, 1).toUpperCase() : ''}
                      </span>
                      <span className="label name">{name}</span>
                    </button>
                  )
                })
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
