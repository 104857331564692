import ValidationsContainer from '@/app/features/collectionTour/components/publishingScreen/components/ValidationsContainer'
import { CollectionTourContext } from '@/contexts'
import { useTranslation } from '@/hooks'
import { PublishType } from '@/store/collectionTour/state'
import Collapse from '@material-ui/core/Collapse'
import React, { useContext, useEffect, useState } from 'react'
import Lottie from 'react-lottie'

import animationData from '../../../../base/img/truck-loading.json'
import classes from './style.module.scss'

interface IPublishingScreenProps {}

const getTimeString = (timeInSec: number) => {
  const delim = ':'
  const minutes = Math.floor((timeInSec % 3600) / 60)
  const seconds = Math.floor(timeInSec % 60)

  const minutesText = minutes < 10 ? '0' + minutes : minutes
  const secondsText = seconds < 10 ? '0' + seconds : seconds
  return minutesText + delim + secondsText
}

const PublishingScreen: React.FC<IPublishingScreenProps> = () => {
  const {
    setPublishingNewTours,
    publishType,
    getOptimizationRemainingTime,
    publishValidations,
  } = useContext(CollectionTourContext)
  const [t] = useTranslation('COLLECTION_TOUR')

  const [counter, setCounter] = useState(
    publishType === PublishType.Publish ? 25 : getOptimizationRemainingTime()
  )

  const timerHandle = React.useRef<NodeJS.Timeout | undefined>()
  const [isCounterDone, setCounterDone] = React.useState(false)
  const [isValidationsUnderstood, setValidationsUnderstood] = React.useState(
    false
  )

  const handleValidationsUnderstood = React.useCallback(
    () => setValidationsUnderstood(true),
    []
  )

  useEffect(() => {
    const isCompletelyDone =
      isValidationsUnderstood ||
      (isCounterDone && !publishValidations) ||
      (publishValidations && publishValidations.status === 'ok')

    if (isCompletelyDone) {
      setPublishingNewTours(false)
    }
  }, [isValidationsUnderstood, isCounterDone, publishValidations])

  useEffect(() => {
    if (counter < 1) {
      setCounterDone(true)
      clearInterval(timerHandle.current!)
    }
  }, [counter])

  useEffect(() => {
    let timeLeft = counter

    timerHandle.current = setInterval(() => {
      timeLeft -= 1
      setCounter(timeLeft)
    }, 1000)

    return () => clearInterval(timerHandle.current!)
  }, [])

  const isValidationsVisible =
    !isValidationsUnderstood &&
    !!publishValidations &&
    publishValidations.status === 'error'

  const validationMessages = React.useMemo(
    () =>
      publishValidations
        ? publishValidations.messages.map(message => message.message)
        : [],
    [publishValidations]
  )

  return (
    <div className={classes.container}>
      <Lottie
        height={300}
        width={300}
        options={{
          loop: true,
          autoplay: true,
          animationData,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        }}
      />
      <h1>
        {publishType === PublishType.Publish
          ? t('PUBLISHING.PUBLISHING_COLLECTION_TOUR')
          : t('PUBLISHING.OPTIMISING_COLLECTION_TOUR')}
      </h1>

      <Collapse in={!publishValidations}>
        <span>
          {publishType === PublishType.Publish
            ? t('PUBLISHING.HOLD_ON_A_MOMENT')
            : t('PUBLISHING.CREATING_BEST_ROUTE')}
        </span>

        <div className={classes.time}>{getTimeString(counter)}</div>
        <div className={classes.time_remaining}>
          {t('PUBLISHING.TIME_REMAINING')}
        </div>
      </Collapse>

      <ValidationsContainer
        className={classes.validations}
        open={isValidationsVisible}
        messages={validationMessages}
        onClose={handleValidationsUnderstood}
      />
    </div>
  )
}

export default PublishingScreen
