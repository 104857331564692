import {
  AutoComplete,
  Button,
  Modal,
  Select,
  TextArea,
} from '@/app/base/components/.lib'
import { MESSAGE_TYPE } from '@/constants'
import { DriverContext } from '@/contexts'
import { useTranslation } from '@/hooks'
import { IDriverMessage } from '@/store/driver/state'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import defaultTo from 'lodash/defaultTo'
import React, { memo, useCallback, useContext, useRef, useState } from 'react'

import styles from './styles.module.scss'

const Messenger = () => {
  const { storeDriverMessage, allDrivers } = useContext(DriverContext)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [sending, setSending] = useState(false)
  const [typeOfMessage, setTypeOfMessage] = useState<MESSAGE_TYPE | undefined>()
  const [driverId, setDriverId] = useState<number | undefined>()
  const [message, setMessage] = useState('')
  const timeout = useRef<any>()

  const sendingDisabled = !message.trim().length || !driverId || !typeOfMessage

  const handleDriverNameSelect = useCallback((value: any) => {
    setDriverId(value)
  }, [])

  const handleWriteMessage = useCallback(
    (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
      setMessage(ev.currentTarget.value)
    },
    []
  )

  const sendMessage = useCallback(() => {
    if (message && driverId && typeOfMessage) {
      const payload: IDriverMessage = {
        driverId,
        message,
        type: defaultTo(typeOfMessage, MESSAGE_TYPE.INFORMATION),
      }

      storeDriverMessage(payload).finally(() => {
        setSending(false)
        setTimeout(() => {
          setIsModalVisible(false)
          setMessage('')
          setDriverId(undefined)
          setTypeOfMessage(undefined)
        }, 3000)
      })
    }
  }, [message, driverId, typeOfMessage])

  const handleTypeOfMessageSelect = useCallback(typeOfMessage => {
    setTypeOfMessage(typeOfMessage)
  }, [])

  const collection = allDrivers.map(({ driverId, driverName, subcoName }) => ({
    value: driverId,
    text: driverName,
    content: (
      <span className={styles['driver-suggestion-label']}>
        {driverName}
        <span>{subcoName}</span>
      </span>
    ),
  }))

  const beginRequest = useCallback(() => {
    if (!timeout.current) {
      setIsModalVisible(true)
      setSending(true)
      timeout.current = setTimeout(() => {
        sendMessage()
        timeout.current = undefined
      }, 3000)
    }
  }, [sendMessage, timeout, sendingDisabled])

  const cancelRequest = useCallback(() => {
    clearTimeout(timeout.current)
    timeout.current = undefined
    setIsModalVisible(false)
    setSending(false)
  }, [timeout])

  const [t] = useTranslation(['DRIVER_MESSAGES'])

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Select
          value={typeOfMessage}
          placeholder={t('TYPE_OF_MESSAGE_PLACEHOLDER')}
          className={clsx(styles['type-of-message-select'])}
          onValueChange={handleTypeOfMessageSelect}
        >
          <button
            className={styles['option-item']}
            value={MESSAGE_TYPE.INFORMATION}
          >
            <FontAwesomeIcon icon="info-circle" className={styles.blue} />
            {t('INFORMATIONAL')}
          </button>
          <button
            className={styles['option-item']}
            value={MESSAGE_TYPE.IMPORTANT}
          >
            <FontAwesomeIcon
              icon="exclamation-triangle"
              className={styles.orange}
            />
            {t('WARNING')}
          </button>
        </Select>
        <div className={styles['auto-complete-container']}>
          {t('TO')}{' '}
          <AutoComplete
            value={driverId}
            variant="chip"
            className={styles['auto-complete']}
            collection={collection}
            callback={handleDriverNameSelect}
          />
        </div>
      </div>
      <div className={styles.body}>
        <TextArea
          value={message}
          placeholder={t('WRITE_MESSAGE_PLACEHOLDER')}
          onChange={handleWriteMessage}
          maxLength={280}
        />
      </div>
      <div className={styles.footer}>
        <div>{t('REMAINING_CHARS', { number: 280 - message.length })}</div>
        <Button
          variant="info"
          onClick={beginRequest}
          disabled={sendingDisabled}
        >
          {t('BUTTON_SEND')}
        </Button>
      </div>
      <Modal visible={isModalVisible}>
        <div
          className={clsx(styles['response-modal'], sending && styles.sending)}
        >
          {!sending && <FontAwesomeIcon icon="check-circle" />}
          {t(sending ? 'SENDING_MESSAGE' : 'MESSAGE_SENT')}
          {sending && (
            <span onClick={cancelRequest} className={styles['close-icon']}>
              <FontAwesomeIcon icon="times" />
            </span>
          )}
        </div>
      </Modal>
    </div>
  )
}

export default memo(Messenger)
