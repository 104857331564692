import { SHIPMENT_STATE } from '@/constants'

export const getClassForShipmentState = (state: SHIPMENT_STATE): string => {
  switch (state) {
    case SHIPMENT_STATE.DATA_PROCESSED:
      return 'not-scanned'
    case SHIPMENT_STATE.SHIPMENT_DELIVERED:
      return 'delivered'
    case SHIPMENT_STATE.SHIPMENT_DELIVERED_TO_NEIGHBOR:
      return 'delivered-to-neighbour'
    case SHIPMENT_STATE.SHIPMENT_NOT_DELIVERED:
      return 'not-delivered'
    case SHIPMENT_STATE.EXCEPTION_SHIPMENT_DECLINED_BY_DRIVER:
      return 'declined-by-driver'
    default:
      return state.toLowerCase().replace('_', '-')
  }
}
