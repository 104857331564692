import * as Sentry from '@sentry/browser'

import API from '@/api'
import { AUTH_URL } from '@/constants'
import { useContext, useState } from 'react'
import { IAuthState } from '@/store/auth/state'
import { useResponseErrorHandler } from '@/hooks'
import { AppContext } from '@/contexts'
import { IAdminProfileDetails, IIdentity } from '@/models'

export const useAuthActions = (initialState: IAuthState) => {
  const { setError } = useContext(AppContext)
  const [authenticated, setAuthenticated] = useState(initialState.authenticated)
  const [identity, setIdentity] = useState<IIdentity>(initialState.identity)
  const auth = API.Auth()
  const communication = API.Communication()

  const checkAccess = async () => {
    const [err, hasAccess] = await useResponseErrorHandler(auth.checkAccess())
    if (!err) {
      if (hasAccess) {
        const identity = await getIdentity()
        setIdentity(identity)
        setAuthenticated(true)
      }
    } else {
      setError({
        value: err,
        description: err.toString(),
      })
    }
  }

  const requestLogin = async (baseUri: string = window.location.origin) => {
    const redirectUri = `${baseUri}${AUTH_URL}`
    auth.initiateGoogleLogin(redirectUri)
  }

  const completeLogin = async (
    state: string,
    code: string,
    baseUri: string = window.location.origin
  ) => {
    const redirectUri = `${baseUri}${AUTH_URL}`
    const [err] = await useResponseErrorHandler(
      auth.completeGoogleLogin(state, redirectUri, code)
    )
    if (err) {
      setError({
        value: err,
        description: err.toString(),
      })
      return false
    } else {
      const identity = await getIdentity()
      setIdentity(identity)
      setAuthenticated(true)
    }
    setError(null)
    return true
  }

  const getIdentity = async () => {
    const group = await getUserGroup()
    const sub = await getUserSub()
    let userProfile: IAdminProfileDetails = {
      name: '',
      picture: '',
      email: '',
    }
    const [, result] = await useResponseErrorHandler(
      communication.fetchAdminProfile(sub)
    )
    if (result) {
      userProfile = result

      Sentry.setUser({
        id: sub,
        username: userProfile.name,
        email: userProfile.email,
      })
    }
    return {
      group,
      profile: userProfile,
    } as IIdentity
  }

  const getUserGroup = async () => {
    const [err, result] = await useResponseErrorHandler(auth.getGroups())
    if (err) {
      setError({
        value: err,
        description: err.toString(),
      })
      return null
    } else {
      return result
    }
  }

  const getUserSub = async () => {
    const [err, result] = await useResponseErrorHandler(auth.getUserSub())
    if (err) {
      setError({
        value: err,
        description: err.toString(),
      })
      return ''
    } else {
      return result
    }
  }

  const requestLogout = async () => {
    const [err] = await useResponseErrorHandler(auth.invalidate())
    if (err) {
      setError({
        value: err,
        description: err.toString(),
      })
      return false
    }
    setAuthenticated(false)
    localStorage.removeItem('accessToken')
    setError(null)
    return true
  }

  return {
    authenticated,
    identity,
    checkAccess,
    requestLogin,
    completeLogin,
    requestLogout,
  }
}
