import clsx from 'clsx'
import get from 'lodash/get'
import React, { memo, useCallback, useEffect, useRef, useState } from 'react'

import Icon from '../Icon'
import styles from './styles.module.scss'

interface IToastProps extends React.DetailedHTMLProps<any, any> {
  icon?: React.ReactNode
  position?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right'
  headerText?: string | React.ReactNode
  subText?: string | React.ReactNode
  onExit?: () => void
}

const Toast = ({
  icon = <Icon name="check-circle" />,
  position = 'bottom-left',
  className,
  children,
  headerText,
  subText,
  onExit,
  ...props
}: IToastProps) => {
  const [isVisible, setIsVisible] = useState(false)
  const timer = useRef<any>()

  const clearTimer = useCallback(() => {
    clearTimeout(timer.current)
  }, [timer.current])

  useEffect(() => {
    clearTimer()
    if (isVisible) {
      timer.current = setTimeout(() => {
        setIsVisible(false)
        if (typeof onExit === 'function') {
          onExit()
        }
      }, 5000)
    }
    return clearTimer
  }, [isVisible])

  useEffect(() => {
    setIsVisible(true)
  }, [])

  return isVisible ? (
    <div
      className={clsx(
        styles.notificationContainer,
        get(styles, position),
        className
      )}
      {...props}
    >
      <div className={styles.icon}>{icon}</div>
      <div className={styles.messageContainer}>
        <span className={styles.closeButton} onClick={onExit}>
          <Icon name="close" />
        </span>
        {children ? (
          children
        ) : (
          <>
            <span className={styles['header-text']}>{headerText}</span>
            <span className={styles['sub-text']}>{subText}</span>
          </>
        )}
      </div>
    </div>
  ) : null
}

export default memo(Toast)
