import React, { memo, useMemo, useContext } from 'react'
import { IShipmentNotification } from '@/models'
import { NotificationsContext } from '@/contexts'
import {
  NoNotification,
  Notification,
} from '@/app/features/user/components/notificationPane/components/'

export const LateShipmentsNotifications = () => {
  const { lateShipmentsNotifs } = useContext(NotificationsContext)
  return (
    <div className="content late-shipments">
      {useMemo(
        () =>
          lateShipmentsNotifs.map((notification: IShipmentNotification) => (
            <Notification
              key={notification.alertId}
              notification={notification}
            />
          )),
        [lateShipmentsNotifs]
      )}
      {lateShipmentsNotifs.length === 0 && <NoNotification />}
    </div>
  )
}

export default memo(LateShipmentsNotifications)
