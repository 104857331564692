import * as React from 'react'

import classes from './style.module.scss'

interface ILoadingTileProps {
  style?: React.CSSProperties
  width?: number
  height?: number
  circular?: boolean
}

const LoadingTile: React.FC<ILoadingTileProps> = ({
  width,
  height,
  circular,
}) => {
  return (
    <div
      className={classes.gradient}
      style={{
        width,
        height,
        borderRadius: circular ? '50%' : '0px',
      }}
    />
  )
}

export default LoadingTile
