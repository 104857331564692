import { useCallback, useEffect, useRef, useState } from 'react'

export const useWithLongClick = (
  callback: () => void,
  timeout: number = 3000
) => {
  const [starting, setStarting] = useState(false)
  const timer = useRef<any>()

  useEffect(() => {
    clearTimeout(timer.current)
    if (starting) {
      timer.current = setTimeout(callback, timeout)
    }
    return () => clearTimeout(timer.current)
  }, [starting])

  const start = useCallback(() => setStarting(true), [])
  const stop = useCallback(() => setStarting(false), [])

  return {
    onMouseDown: start,
    onMouseUp: stop,
    onMouseLeave: stop,
    starting,
  }
}
