import './style.scss'

import Icon from '@/app/base/components/.v2/Icon'
import { clsx } from '@/utils'
import * as React from 'react'
import { NavLink } from 'react-router-dom'

export interface INavigationBackProps {
  label: string
  path: string
  exact?: boolean
  disabled?: boolean
  className?: string
  navClass?: string
}

const NavigationBack: React.SFC<INavigationBackProps> = ({
  label,
  path,
  exact,
  disabled,
  className,
  navClass,
}) => {
  return (
    <div id="navigation-back-container" className={className}>
      <NavLink
        className={clsx('nav-link', { disabled }, navClass)}
        exact={exact}
        to={path}
      >
        <Icon name="arrow-left" />
        <span className="link-label">{label}</span>
      </NavLink>
    </div>
  )
}

export default NavigationBack
