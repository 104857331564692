import { useMemo, useState } from 'react'
import { get, unionBy, sortBy, groupBy, first, flatMap, sum, map } from 'lodash'
import { useResponseErrorHandler } from '@/hooks'

import { INotificationsState } from '@/store/notifications/state'
import { IShipmentNotification, SHIPMENT_ALERT_CATEGORY } from '@/models'
import API from '@/api'

const {
  NOT_SORTED_AT_SUB_DEPOT,
  NOT_ACCEPTED_BY_DRIVER,
  RUNNING_LATE,
} = SHIPMENT_ALERT_CATEGORY

export const useNotificationActions = (initialState: INotificationsState) => {
  const shipmentAlert = API.ShipmentAlert()

  const [notSortedNotifs, setNotSortedNotifs] = useState<
    Array<IShipmentNotification>
  >(initialState.notSortedNotifs)
  const [notAcceptedNotifs, setNotAcceptedNotifs] = useState<
    Array<IShipmentNotification>
  >(initialState.notAcceptedNotifs)
  const [lateShipmentsNotifs, setLateShipmentsNotifs] = useState<
    Array<IShipmentNotification>
  >(initialState.lateShipmentsNotifs)

  const notificationCount: {
    notAcceptedByDriver: number
    notSortedAtSubDepot: number
    runningLate: number
  } = {
    notAcceptedByDriver: notAcceptedNotifs.filter(
      notification => !notification.isDone
    ).length,
    notSortedAtSubDepot: notSortedNotifs.filter(
      notification => !notification.isDone
    ).length,
    runningLate: lateShipmentsNotifs.filter(
      notification => !notification.isDone
    ).length,
  }

  const totalNotifications = sum(map(notificationCount))

  const getUpdatedNotifications = (
    nextNotifications: Array<IShipmentNotification>,
    currentNotifications: Array<IShipmentNotification>
  ) =>
    sortBy(
      unionBy(nextNotifications, currentNotifications, 'alertId'),
      'alertId'
    )

  const groupByTour = (notifications: Array<IShipmentNotification>) =>
    sortBy(
      flatMap(
        groupBy(notifications, 'tourId'),
        (tours: Array<IShipmentNotification>) => {
          const tour = first(sortBy(tours, 'trunkrsNr'))
          return {
            ...tour,
            shipmentsAffected: tours.map(tour => tour.shipmentId),
          } as IShipmentNotification
        }
      ),
      'alertId'
    )

  const fetchNotifications = async () => {
    const [, response] = await useResponseErrorHandler(
      shipmentAlert.fetchAlerts()
    )

    if (!response) return null

    const nextNotSortedNotifs = groupByTour(
      get(response, 'notSortedAtSubDepot', [])
    )
    const nextNotAcceptedNotifs = groupByTour(
      get(response, 'notAcceptedByDriver', [])
    )
    const nextLateShipmentsNotifs = groupByTour(
      get(response, 'runningLate', [])
    )

    setNotSortedNotifs(nextNotSortedNotifs)
    setNotAcceptedNotifs(nextNotAcceptedNotifs)
    setLateShipmentsNotifs(nextLateShipmentsNotifs)

    return response
  }

  const clearNotifications = async (
    shipmentIds: number[],
    category: SHIPMENT_ALERT_CATEGORY
  ) => {
    const [err] = await useResponseErrorHandler(
      shipmentAlert.deleteShipmentAlerts(shipmentIds)
    )
    if (!err) {
      switch (category) {
        case NOT_SORTED_AT_SUB_DEPOT:
          setNotSortedNotifs([])
          break
        case NOT_ACCEPTED_BY_DRIVER:
          setNotAcceptedNotifs([])
          break
        case RUNNING_LATE:
          setLateShipmentsNotifs([])
          break
      }
    }
  }

  const markNotifications = async (
    notifications: Array<IShipmentNotification>,
    category: SHIPMENT_ALERT_CATEGORY
  ) => {
    const nextMarkedNotifs = notifications.filter(
      notification => notification.isDone
    )
    const nextClearedNotifs = notifications.filter(
      notification => !notification.isDone
    )

    switch (category) {
      case NOT_SORTED_AT_SUB_DEPOT:
        setNotSortedNotifs(
          getUpdatedNotifications(notifications, notSortedNotifs)
        )
        break
      case NOT_ACCEPTED_BY_DRIVER:
        setNotAcceptedNotifs(
          getUpdatedNotifications(notifications, notAcceptedNotifs)
        )
        break
      case RUNNING_LATE:
        setLateShipmentsNotifs(
          getUpdatedNotifications(notifications, lateShipmentsNotifs)
        )
        break
    }

    if (nextMarkedNotifs.length) {
      await useResponseErrorHandler(
        shipmentAlert.toggleShipmentAlertStatus(
          nextMarkedNotifs
            .map(notification => notification.shipmentsAffected)
            .flat(),
          true
        )
      )
    }
    if (nextClearedNotifs.length) {
      await useResponseErrorHandler(
        shipmentAlert.toggleShipmentAlertStatus(
          nextClearedNotifs
            .map(notification => notification.shipmentsAffected)
            .flat(),
          false
        )
      )
    }
  }

  return useMemo(
    () => ({
      notSortedNotifs,
      notAcceptedNotifs,
      lateShipmentsNotifs,
      totalNotifications,
      notificationCount,
      fetchNotifications,
      clearNotifications,
      markNotifications,
    }),
    [notSortedNotifs, notAcceptedNotifs, lateShipmentsNotifs]
  )
}
