import './styles.scss'

import { clsx } from '@/utils'
import React, {
  HTMLAttributes,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

const NotificationPopup = ({
  children,
  className,
}: HTMLAttributes<HTMLElement>) => {
  const [visibility, setVisibility] = useState(true)
  const timer = useRef<any>()

  const clearTimer = useCallback(() => {
    clearTimeout(timer.current)
  }, [timer.current])

  useEffect(() => {
    clearTimer()
    setVisibility(true)
    timer.current = setTimeout(() => {
      setVisibility(false)
    }, 3000)
    return clearTimer
  }, [children])

  return (
    <div
      className={clsx('notification-popup', className, {
        fadeout: !visibility,
      })}
    >
      {children}
    </div>
  )
}

export default memo(NotificationPopup)
