import getColor from '@/utils/getColor'
import orderBy from 'lodash/orderBy'

import { ICollectionTourState } from './state'

interface IAction {
  type: string
  payload?: any
}

const assignTourColor = (tour: any) => ({
  ...tour,
  stops: orderBy(tour.stops, 'position'),
  color: getColor(),
})

const reducer = (
  state: ICollectionTourState,
  action: IAction
): ICollectionTourState => {
  const { type, payload } = action

  switch (type) {
    case 'activeTagTours': {
      return { ...state, [type]: payload.map(assignTourColor) }
    }
  }

  return { ...state, [type]: payload }
}

export default reducer
