import './styles.scss'

import { clsx } from '@/utils'
import React, { memo, useCallback } from 'react'
import TooltipTrigger from 'react-popper-tooltip'
import { ChildrenArg, TooltipArg } from 'react-popper-tooltip/dist/types'

interface ITooltipProps extends React.HTMLAttributes<HTMLElement> {
  placement?: 'top' | 'right' | 'bottom' | 'left'
  text?: string | React.ReactNode
  eventTriggers?: string[]
}

const Tooltip = ({
  text,
  placement = 'right',
  eventTriggers = ['click', 'hover'],
  children,
  className,
}: ITooltipProps) => {
  const tooltip = useCallback(
    ({
      arrowRef,
      tooltipRef,
      getArrowProps,
      getTooltipProps,
      placement,
    }: TooltipArg) => (
      <div
        {...getTooltipProps({
          ref: tooltipRef,
          className: clsx('tooltip-container', className),
        })}
      >
        <div
          {...getArrowProps({
            ref: arrowRef,
            className: 'tooltip-arrow text',
            'data-placement': placement,
          })}
        />
        {text}
      </div>
    ),
    [text, className, placement]
  )

  const trigger = useCallback(
    ({ getTriggerProps, triggerRef }: ChildrenArg) => (
      <span
        {...getTriggerProps({
          ref: triggerRef,
          className: 'tooltip-trigger',
        })}
      >
        {children}
      </span>
    ),
    []
  )

  return (
    <TooltipTrigger
      placement={placement}
      trigger={eventTriggers as any}
      tooltip={tooltip}
    >
      {trigger}
    </TooltipTrigger>
  )
}

export default memo(Tooltip)
