import CustomChip from '@/app/base/components/customChip'
import IconWithLabel from '@/app/base/components/iconWithLabel'
import { useTranslation } from '@/hooks'
import clsx from 'clsx'
import * as React from 'react'
import { ICollectionTourTrackingResponseRow } from 'Trunkrs-SDK/dist/models/spatial/Collections/CollectionTour'

import classes from './style.module.scss'

interface ITourItemProps {
  tour: ICollectionTourTrackingResponseRow
  index: number
  activeTag: string
  onItemClick: () => void
  active: boolean
}

const TourItem: React.FC<ITourItemProps> = ({
  tour,
  index,
  activeTag,
  onItemClick,
  active,
}) => {
  const [t] = useTranslation('COLLECTION_TRACKING')

  return (
    <tr>
      <td>
        <div
          className={clsx(classes.tour_item, active && classes.active)}
          onClick={onItemClick}
        >
          <div className={classes.tour_id}>{`${activeTag}-${index + 1}`}</div>
          <div className={classes.tour_progress_overview}>
            <CustomChip
              label={`${tour.driver.name} - ${tour.driver.subcoName}`}
              avatarUrl={tour.driver.picture}
            />
            <div className={classes.status}>
              <IconWithLabel
                label={`${tour.stopsMade}/${tour.totalStops} stops made`}
                labelColor={`#888888`}
                icon="truck"
              />
              <IconWithLabel
                label={t(tour.delayStatus)}
                labelColor={
                  tour.delayStatus === 'ON_SCHEDULE' ? `#00D763` : `#FF5351`
                }
                icon={
                  tour.delayStatus === 'ON_SCHEDULE'
                    ? 'heart'
                    : 'exclamation-triangle'
                }
              />
              <IconWithLabel label={t(tour.stateName)} labelColor={`#13D2FC`} />
            </div>
          </div>
        </div>
      </td>
    </tr>
  )
}

export default TourItem
