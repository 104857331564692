import './style.scss'

import { ModalForm } from '@/app/base/components'
import {
  HorizontalTabMenu,
  TabMenuItem,
} from '@/app/base/components/.v2/TabMenu'
import { AppContext, ShipmentsContext } from '@/contexts'
import { useFieldValidation, useTranslation } from '@/hooks'
import { Location, Recipient } from '@/models'
import { eventHandler } from '@/utils'
import { defaultTo, first, get, toArray } from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { Country } from 'Trunkrs-SDK/dist/constants'

enum RECIPIENT_FORM {
  ADDRESS,
  RECIPIENT_INFO,
}
export const EditRecipient = ({
  handleClose,
}: {
  handleClose?: (ev?: React.SyntheticEvent<any>) => void
}) => {
  const {
    overviewShipments: { length: hasOverviewShipments },
    activeOverviewShipment: currentShipment,
    setActiveOverviewShipment,
    changeAddress,
    updateOverviewShipments,
    activeShipment,
    fetchShipmentRecipient,
  } = useContext(ShipmentsContext)
  const { setLoading } = useContext(AppContext)
  const [recipientInstance, setRecipientInstance] = useState<Recipient>()
  const [changeAddResponse, setChangeAddResponse] = useState<boolean>(true)
  const [selectedRecipientForm, setSelectedRecipientForm] = useState<
    RECIPIENT_FORM
  >(RECIPIENT_FORM.ADDRESS)

  useEffect(() => {
    setLoading(true)
    fetchShipmentRecipient()
      .then(response => setRecipientInstance(response as Recipient))
      .catch(err => console.error(err))
      .finally(() => setLoading(false))
  }, [activeShipment])

  const [t] = useTranslation(['EDIT_RECIPIENT', 'COMMON'])

  const recipientAddress = get(currentShipment, 'recipient.address', '')

  const handleSelectRecipientForm = (form: RECIPIENT_FORM) => () => {
    setSelectedRecipientForm(form)
  }

  const [
    streetAddress,
    setStreetAddress,
    streetAddressErrors,
    validateStreetAddress,
    pristineStreetAddress,
  ] = useFieldValidation(undefined, {
    presence: {
      allowEmpty: false,
      message: t('VALIDATION.STREET_HOUSE_REQUIRED'),
    },
  })

  const [
    houseNo,
    setHouseNo,
    houseNoErrors,
    validateHouseNo,
    pristineHouseNo,
  ] = useFieldValidation(undefined, {
    length: {
      minimum: 1,
      message: t('VALIDATION.STREET_HOUSE_MIN_LENGTH'),
    },
    format: {
      pattern: /.*\d+.*/g,
      message: t('VALIDATION.STREET_HOUSE_REQUIRED_DIGIT'),
    },
  })

  const [
    postalCode,
    setPostalCode,
    postalCodeErrors,
    validatePostalCode,
    pristinePostalCode,
  ] = useFieldValidation(get(currentShipment, 'recipient.postalCode', ''), {
    presence: {
      allowEmpty: false,
      message: t('VALIDATION.ZIPCODE_REQUIRED'),
    },
    format: {
      pattern: /\d{4}(\s?\w{2})?/g,
      message: t('VALIDATION.ZIPCODE_INVALID'),
    },
  })

  const [
    city,
    setCity,
    cityErrors,
    validateCity,
    pristineCity,
  ] = useFieldValidation(get(currentShipment, 'recipient.city', ''), {
    presence: {
      allowEmpty: false,
      message: t('VALIDATION.CITY_REQUIRED'),
    },
    length: {
      minimum: 2,
      message: t('VALIDATION.CITY_MIN_LENGTH'),
    },
  })

  const [
    name,
    setName,
    nameErrors,
    validateName,
    pristineName,
  ] = useFieldValidation(get(currentShipment, 'recipient.name', ''), {
    presence: {
      allowEmpty: false,
      message: t('VALIDATION.NAME_REQUIRED'),
    },
    length: {
      minimum: 2,
      message: t('VALIDATION.NAME_MIN_LENGTH'),
    },
    format: {
      pattern: /[^0-9]+/g,
      message: t('VALIDATION.NAME_REQUIRED_ALPHA'),
    },
  })

  const [
    phoneNo,
    setPhoneNo,
    phoneNoErrors,
    validatePhoneNo,
    pristinePhoneNo,
  ] = useFieldValidation(get(currentShipment, 'recipient.phoneNumber', ''), {
    format: {
      pattern: '^$|[^a-zA-Z]+',
      flags: 'g',
      message: t('VALIDATION.PHONENO_REQUIRED_NUMERIC'),
    },
  })

  const [
    emailAddress,
    setEmailAddress,
    emailAddressErrors,
    validateEmailAddress,
    pristineEmailAddress,
  ] = useFieldValidation(get(currentShipment, 'recipient.emailAddress', ''), {
    presence: {
      allowEmpty: false,
      message: t('VALIDATION.EMAIL_REQUIRED'),
    },
    email: {
      message: t('VALIDATION.EMAIL_INVALID'),
    },
  })

  const handleSave = async (ev: React.SyntheticEvent<any>) => {
    let isAllValid = true
    let apiResponse: any = {}

    const isAddressChange = selectedRecipientForm === RECIPIENT_FORM.ADDRESS

    const selectedValidators = isAddressChange
      ? toArray({
          validateStreetAddress,
          validateHouseNo,
          validatePostalCode,
          validateCity,
        }).map(validator => {
          return (async () => {
            if (!(await validator())) {
              isAllValid = false
            }
          })()
        })
      : toArray({
          validateName,
          validatePhoneNo,
          validateEmailAddress,
        }).map(validator => {
          return (async () => {
            if (!(await validator())) {
              isAllValid = false
            }
          })()
        })

    await Promise.all(selectedValidators)

    if (isAllValid && recipientInstance) {
      const isNotPristineAddressDetails = [
        pristineStreetAddress,
        pristineHouseNo,
        pristinePostalCode,
        pristineCity,
      ].includes(false)
      const isNotPristineRecipientInfo = [
        pristineName,
        pristinePhoneNo,
        pristineEmailAddress,
      ].includes(false)

      if (isNotPristineAddressDetails && isAddressChange) {
        setLoading(true)
        apiResponse = await changeAddress({
          streetName: streetAddress,
          houseNumber: houseNo,
          postalCode,
          city,
          country: defaultTo(
            ((await recipientInstance.getLocation) as Location).getCountry,
            Country.NL
          ),
        }).catch(err => console.error(err))
        setChangeAddResponse(Boolean(apiResponse))
      }

      if (
        isNotPristineRecipientInfo &&
        selectedRecipientForm === RECIPIENT_FORM.RECIPIENT_INFO
      ) {
        setLoading(true)
        recipientInstance.setName = defaultTo(name, '')
        recipientInstance.setEmailAddress = defaultTo(emailAddress, '')
        recipientInstance.setPhoneNumber = defaultTo(phoneNo, '')
        await recipientInstance.save().catch(err => console.error(err))
      }

      if (currentShipment && !!apiResponse) {
        const { recipient } = currentShipment
        const nextActiveOverviewShipment = {
          ...currentShipment,
          recipient: {
            name,
            emailAddress,
            postalCode,
            address: isAddressChange
              ? `${streetAddress} ${houseNo}`
              : recipientAddress,
            city,
            phoneNumber: phoneNo,
          },
        }

        /**
         * This is a temporary patch to fix the issue in address change
         * KNOWN ISSUE: Update on address for changeAddress API call returns 200 but there wasn't a change at all.
         */
        if (
          isNotPristineAddressDetails &&
          isAddressChange &&
          recipient.address === get(apiResponse, 'newLocation.address')
        ) {
          setLoading(false)
          return setChangeAddResponse(false)
        }
        setActiveOverviewShipment(nextActiveOverviewShipment)
        setChangeAddResponse(Boolean(apiResponse))
        if (hasOverviewShipments) {
          updateOverviewShipments(nextActiveOverviewShipment)
        }
      }
      setLoading(false)
      if (typeof handleClose === 'function' && apiResponse) {
        handleClose(ev)
      }
    }
  }

  const isPristine =
    selectedRecipientForm === RECIPIENT_FORM.ADDRESS
      ? ![
          pristineStreetAddress,
          pristineHouseNo,
          pristinePostalCode,
          pristineCity,
        ].includes(false)
      : ![pristineName, pristinePhoneNo, pristineEmailAddress].includes(false)

  return (
    <ModalForm
      show={true}
      title={t('EDIT_RECIPIENT_INFORMATION')}
      handleClose={handleClose}
    >
      <form
        id="edit-recipient-form"
        className="form text-left needs-validation"
        noValidate={true}
      >
        <div className="row h-100">
          <HorizontalTabMenu className="vertical-tab-menu">
            <TabMenuItem
              onClick={handleSelectRecipientForm(RECIPIENT_FORM.ADDRESS)}
            >
              Address
            </TabMenuItem>
            <TabMenuItem
              onClick={handleSelectRecipientForm(RECIPIENT_FORM.RECIPIENT_INFO)}
            >
              Recipient Details
            </TabMenuItem>
          </HorizontalTabMenu>
        </div>
        {selectedRecipientForm === RECIPIENT_FORM.ADDRESS ? (
          <>
            <div id="address-input-container" className="form-group row">
              <div className="col-8">
                <label className="text" htmlFor="txt-street">
                  {t('STREET')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="txt-street"
                  value={streetAddress}
                  key="txt-street"
                  placeholder={t('STREET')}
                  onChange={eventHandler(setStreetAddress)}
                  required
                />
                {streetAddressErrors.length > 0 && (
                  <label className="error-label label">
                    {first(streetAddressErrors)}
                  </label>
                )}
              </div>
              <div id="input-houseno-container" className="col">
                <label className="text" htmlFor="txt-houseno">
                  {t('HOUSE_NUMBER')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={t('HOUSE_NUMBER')}
                  id="txt-houseno"
                  value={houseNo}
                  onChange={eventHandler(setHouseNo)}
                  required
                />
                {houseNoErrors.length > 0 && (
                  <label className="error-label label">
                    {first(houseNoErrors)}
                  </label>
                )}
              </div>
            </div>
            <div className="form-group row">
              <div className="col-3">
                <label className="text" htmlFor="txt-zipcode">
                  {t('ZIPCODE')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="txt-zipcode"
                  placeholder={t('ZIPCODE')}
                  value={postalCode}
                  onChange={eventHandler(setPostalCode)}
                  required
                />
                {postalCodeErrors.length > 0 && (
                  <label className="error-label label">
                    {first(postalCodeErrors)}
                  </label>
                )}
              </div>
              <div id="input-city-container" className="col">
                <label className="text" htmlFor="txt-city">
                  {t('CITY')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="txt-city"
                  placeholder={t('CITY')}
                  value={city}
                  onChange={eventHandler(setCity)}
                  required
                />
                {cityErrors.length > 0 && (
                  <label className="error-label label">
                    {first(cityErrors)}
                  </label>
                )}
              </div>
            </div>
          </>
        ) : (
          <>
            <div id="input-name" className="form-group row">
              <div className="col">
                <label className="text" htmlFor="txt-name">
                  {t('NAME')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="txt-name"
                  placeholder={t('NAME')}
                  value={name}
                  onChange={eventHandler(setName)}
                  required
                />
                {nameErrors.length > 0 && (
                  <label className="error-label label">
                    {first(nameErrors)}
                  </label>
                )}
              </div>
            </div>
            <div id="input-phoneno" className="form-group row">
              <div className="col">
                <label className="text" htmlFor="txt-phoneno">
                  {t('PHONE_NUMBER')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="txt-phoneno"
                  placeholder={t('PHONE_NUMBER')}
                  value={phoneNo}
                  onChange={eventHandler(setPhoneNo)}
                  required
                />
                {phoneNoErrors.length > 0 && (
                  <label className="error-label label">
                    {first(phoneNoErrors)}
                  </label>
                )}
              </div>
            </div>
            <div id="input-email" className="form-group row">
              <div className="col">
                <label className="text" htmlFor="txt-email">
                  {t('EMAIL_ADDRESS')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="txt-email"
                  placeholder={t('EMAIL_ADDRESS')}
                  value={emailAddress}
                  onChange={eventHandler(setEmailAddress)}
                  required
                />
                {emailAddressErrors.length > 0 && (
                  <label className="error-label label">
                    {first(emailAddressErrors)}
                  </label>
                )}
              </div>
            </div>
          </>
        )}
        {!changeAddResponse && (
          <div className="form-group row">
            <div className="col">
              <label className="error-prompt">
                {t('ADDRESS_CHANGED_FAILED')}
              </label>
            </div>
          </div>
        )}
        <div className="actions">
          <button
            className="btn button inactive icon"
            type="button"
            onClick={handleClose}
          >
            {t('COMMON:BUTTON.CLOSE')}
          </button>
          <button
            className="btn button success box-shadow"
            type="button"
            onClick={handleSave}
            disabled={isPristine}
          >
            {t('COMMON:BUTTON.SAVE')}
          </button>
        </div>
      </form>
    </ModalForm>
  )
}
