import clsx from 'clsx'
import React, { memo } from 'react'

export interface IFieldBlockProps {
  label?: string
  fieldError?: string
  className?: string
  isMulti?: boolean
  children: React.ReactNode
}

const FieldBlock = ({
  label,
  className,
  fieldError,
  isMulti,
  children,
}: IFieldBlockProps) => (
  <div
    className={clsx(
      isMulti ? 'multiField' : 'field-block',
      fieldError && 'has-error',
      className
    )}
  >
    {label && <span className="label">{label}</span>}
    <div className={clsx('input-group')}>{children}</div>
  </div>
)

export default memo(FieldBlock)
