import moment from 'moment'
import React from 'react'

interface IProps {
  recorded_at: string
}

export const DriverActiveWarning: React.FC<IProps> = props => {
  const lastActive = moment().diff(props.recorded_at, 'minute')
  if (lastActive > 0 && lastActive <= 10) {
    return (
      <div className="col-12">
        <div className="alert alert-warning text-left">
          This driver made connection with us
          <strong>{Math.round(lastActive)} minutes</strong> ago. Are you sure
          (s)he can't update?
        </div>
      </div>
    )
  }
  return null
}

export default DriverActiveWarning
