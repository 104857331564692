import { ISearchFilters } from '@/models'
import { IMerchantState } from '@/store/merchants/state'
import { useState } from 'react'
import { Merchant } from 'Trunkrs-SDK/dist/models'

export const useMerchantActions = (initialState: IMerchantState) => {
  const [merchants, setMerchants] = useState(initialState.merchants)
  const [filters, setFilter] = useState(initialState.filters)

  const fetchMerchants = () =>
    Merchant.getMerchants().then(results => {
      setMerchants(results)
      return merchants
    })

  const setFilterAction = (filtersProps?: ISearchFilters) => {
    setFilter(filtersProps)
    return filters
  }

  return {
    merchants,
    filters,
    fetchMerchants,
    setFilterAction,
  }
}
