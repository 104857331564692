import React, { useCallback, useContext } from 'react'

import { SmartSearchContext } from '../../../../../../../store/smartSearch'
import Picker from './Picker'

export interface DateRangePickerProps {
  handleDateClick: (date: Date) => void
}

export const DateRangePicker: React.FC<DateRangePickerProps> = ({
  handleDateClick,
}) => {
  // const { setDateStart, setDateEnd, dateStart } = useContext(SmartSearchContext)

  // const handleDateClick = useCallback(
  //   (date: Date) => {
  //     if(!dateStart) {
  //       return setDateStart(date)
  //     }

  //     return setDateEnd(date)

  //   }, [dateStart, setDateStart, setDateEnd])

  return (
    <Picker
      initialDate={new Date()}
      showOutsideDays={false}
      showWeekends={false}
      onDateClick={handleDateClick}
    />
  )
}

export default DateRangePicker
