import { BUTTON_VARIANTS } from '@/app/base/components/.lib/@types'
import clsx from 'clsx'
import React, { memo } from 'react'

import styles from './styles.module.scss'

type variant =
  | BUTTON_VARIANTS
  | 'default'
  | 'info'
  | 'warning'
  | 'danger'
  | 'success'

export default memo(
  ({
    type = 'button',
    children,
    className,
    variant = BUTTON_VARIANTS.DEFAULT,
    ...props
  }: React.ButtonHTMLAttributes<HTMLButtonElement> & { variant?: variant }) => {
    return (
      <button
        className={clsx(styles.button, styles[variant], className)}
        type={type}
        {...props}
      >
        {children}
      </button>
    )
  }
)
