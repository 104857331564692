import clsx from 'clsx'
import get from 'lodash/get'
import omit from 'lodash/omit'
import React, { HTMLProps, memo, useCallback } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import NumberFormat, { NumberFormatValues } from 'react-number-format'

export interface IInputNumberProps extends HTMLProps<HTMLInputElement> {
  name: string
  decimalSeparator?: string | boolean
  decimalScale?: number
  fixedDecimalScale?: boolean
  allowNegative?: boolean
  onValueChange?: (values: NumberFormatValues) => void
  isEdit?: boolean
}

const InputNumber = ({
  name,
  decimalSeparator = ',',
  decimalScale,
  fixedDecimalScale,
  allowNegative = false,
  onChange,
  onBlur,
  className,
  isEdit = false,
  ...rest
}: IInputNumberProps) => {
  const { control, setValue } = useFormContext()

  const renderControllerProps = useCallback(
    ({ ...props }: any) => {
      let injectedProps = {
        ...props,
        value:
          props.value === undefined ? rest.defaultValue || '' : props.value,
        onValueChange: ({ value }: any) => setValue(name, value),
        onChange: (ev: any) => {
          ev.persist()
          props.onChange(ev)
          onChange && onChange(ev)
        },
        onBlur: (ev: any) => {
          ev.persist()
          props.onBlur()
          onBlur && onBlur(ev)
        },
      }

      if (isEdit) {
        injectedProps.value = get(props, 'value', props.defaultValue)
        injectedProps = omit(injectedProps, ['onChange'])
      }

      return (
        <NumberFormat
          {...injectedProps as any[]}
          allowNegative={allowNegative}
          decimalSeparator={decimalSeparator}
          isNumericString={true}
          name={name}
          decimalScale={decimalScale}
          fixedDecimalScale={fixedDecimalScale}
        />
      )
    },
    [onChange, onBlur, isEdit, setValue]
  )

  return (
    <Controller
      {...rest as any[]}
      as={renderControllerProps}
      name={name as never}
      className={clsx('form-control', className)}
      control={control as any}
    />
  )
}

export default memo(InputNumber)
