import './style.scss'

import StatusBadge, {
  STATUS_BADGE_TYPES,
} from '@/app/base/components/.v2/StatusBadge'
import { useTranslation } from '@/hooks'
import {
  isCancelled,
  isDeclinedByDriver,
  isDelivered,
  isInTransit,
  isMissortedState,
  isNonDeliveryState,
  isNotArrived,
  isNotDelivered,
  isPostponed,
  isShipmentToInvestigate,
} from '@/utils/checkStatus'
import React, { useCallback } from 'react'

interface IProps {
  statusText: string
}

export const StatusLabel: React.FC<IProps> = ({ statusText }) => {
  const [t] = useTranslation('SHIPMENT_STATES')

  const getBadgeType = useCallback(statusText => {
    switch (true) {
      case isCancelled(statusText):
      case isNotArrived(statusText):
      case isPostponed(statusText):
      case isMissortedState(statusText):
      case isNotDelivered(statusText):
      case isShipmentToInvestigate(statusText):
      case isDeclinedByDriver(statusText):
      case isNonDeliveryState(statusText):
        return {
          variant: STATUS_BADGE_TYPES.DANGER,
        }
      case isInTransit(statusText):
        return {
          variant: STATUS_BADGE_TYPES.PRIMARY,
        }
      case isDelivered(statusText):
        return {
          variant: STATUS_BADGE_TYPES.SUCCESS,
        }
      default:
        return {
          variant: STATUS_BADGE_TYPES.DEFAULT,
        }
    }
  }, [])

  const { variant } = getBadgeType(statusText)

  return <StatusBadge variant={variant}>{t(statusText)}</StatusBadge>
}

export default StatusLabel
