import './styles.scss'

import {
  InputHidden,
  SelectField,
  TextField,
  TextFieldWithIcon,
} from '@/app/base/components/reactHookComponents'
import { INPUT_MASK_NL_POSTAL_CODE } from '@/constants'
import { useTranslation } from '@/hooks'
import Fab from '@material-ui/core/Fab/Fab'
import Grid from '@material-ui/core/Grid/Grid'
import MenuItem from '@material-ui/core/MenuItem/MenuItem'
import get from 'lodash/get'
import React, { memo, useCallback, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'

import resource from '../../resource.json'

const MerchantInvoicingForm = () => {
  const countries = get(resource, 'Countries', []) as Array<{
    value: string
    description: string
  }>

  const [t] = useTranslation('MERCHANT_CREATION')

  const renderValidationTextKVKNumber = useCallback(
    () => (
      <div className="invalid-tooltip warning-notification">
        {t('INVALID_KVK_NUMBER')}
      </div>
    ),
    []
  )
  const renderValidationTextBTWNumber = useCallback(
    () => (
      <div className="invalid-tooltip warning-notification">
        {t('INVALID_BTW_NUMBER')}
      </div>
    ),
    []
  )

  return (
    <div id="merchant-invoicing">
      <div className="header-wrapper">
        <div>
          <span className="header-text">{t('INVOICING_HEADER')}</span>
          <span className="header-subtext">{t('INVOICING_SUB_HEADER')}</span>
        </div>
        <div className="icon" />
      </div>
      <div className="content">
        <Grid container>
          <Grid item xs={12}>
            <TextField
              id="invoicing-contact-person"
              className="custom-input-group"
              label={t('CONTACT_PERSON')}
              name="invoicing.contactPerson"
              placeholder={t('CONTACT_PERSON_PLACEHOLDER')}
              InputProps={{
                endAdornment: <SameContactButton />,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="invoicing-email-address"
              label={t('EMAIL_ADDRESS_FOR_INVOICING')}
              name="invoicing.emailAddressInvoice"
              placeholder={t('EMAIL_PLACEHOLDER')}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="invoicing-phone-number"
              label={t('PHONE_NUMBER')}
              name="invoicing.phoneNumber"
              placeholder={t('PHONE_NUMBER_PLACEHOLDER')}
            />
          </Grid>
          <Grid item xs={12}>
            <InputHidden name="invoicing.sameAsWebshopAddress" />
            <TextField
              id="invoicing-address"
              className="custom-input-group"
              label={t('ADDRESS')}
              name="invoicing.location.address"
              placeholder={t('ADDRESS_PLACEHOLDER')}
              InputProps={{
                endAdornment: <SameAddressButton />,
              }}
            />
          </Grid>
          <Grid container item xs={12} spacing={1}>
            <Grid item xs={3}>
              <TextField
                id="invoicing-zipcode"
                label={t('ZIPCODE')}
                name="invoicing.location.postalCode"
                placeholder={t('ZIPCODE_PLACEHOLDER')}
                maskProps={{ mask: INPUT_MASK_NL_POSTAL_CODE }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="invoicing-city"
                label={t('CITY')}
                name="invoicing.location.city"
                placeholder={t('CITY_PLACEHOLDER')}
              />
            </Grid>
            <Grid item xs={5}>
              <SelectField
                label={t('COUNTRY')}
                id="invoicing-country"
                name="invoicing.location.country"
              >
                {useMemo(
                  () =>
                    countries.map(({ value, description }) => (
                      <MenuItem value={value}>{description}</MenuItem>
                    )),
                  []
                )}
              </SelectField>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TextField
              renderValidationText={renderValidationTextKVKNumber}
              id="invoicing-kvk-number"
              label={t('KVK_NUMBER')}
              name="invoicing.kvkNumber"
              placeholder="12345678"
              maskProps={{ mask: '99999999' }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              renderValidationText={renderValidationTextBTWNumber}
              id="invoicing-btw-number"
              label={t('BTW_NUMBER')}
              name="invoicing.btwNumber"
              placeholder="NL123456789B01"
              maskProps={{ mask: 'aa999999999a99' }}
            />
          </Grid>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={6}>
              <TextFieldWithIcon
                numeric
                prependIcon
                id="invoicing-price-tier-1"
                label={t('PRICE_TIER_1')}
                name="pricing.deliveryTier1"
                placeholder="7,50"
                defaultValue={7.5}
                decimalScale={2}
                fixedDecimalScale
                icon={<span className="shipment-count-label">11 - 20</span>}
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldWithIcon
                numeric
                prependIcon
                id="invoicing-price-tier-4"
                label={t('PRICE_TIER_4')}
                name="pricing.deliveryTier4"
                placeholder="6,20"
                defaultValue={6.2}
                decimalScale={2}
                fixedDecimalScale
                icon={<span className="shipment-count-label">151 - 250</span>}
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldWithIcon
                numeric
                prependIcon
                id="invoicing-price-tier-2"
                label={t('PRICE_TIER_2')}
                name="pricing.deliveryTier2"
                placeholder="6,95"
                defaultValue={6.95}
                decimalScale={2}
                fixedDecimalScale
                icon={<span className="shipment-count-label">21 - 50</span>}
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldWithIcon
                numeric
                prependIcon
                id="invoicing-price-tier-5"
                label={t('PRICE_TIER_5')}
                name="pricing.deliveryTier5"
                placeholder="5,90"
                defaultValue={5.9}
                decimalScale={2}
                fixedDecimalScale
                icon={<span className="shipment-count-label">251 - 500</span>}
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldWithIcon
                numeric
                prependIcon
                id="invoicing-price-tier-3"
                label={t('PRICE_TIER_3')}
                name="pricing.deliveryTier3"
                placeholder="6,55"
                defaultValue={6.55}
                decimalScale={2}
                fixedDecimalScale
                icon={<span className="shipment-count-label">51 - 150</span>}
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldWithIcon
                numeric
                prependIcon
                id="invoicing-price-tier-6"
                label={t('PRICE_TIER_6')}
                name="pricing.deliveryTier6"
                placeholder="5,45"
                defaultValue={5.45}
                decimalScale={2}
                fixedDecimalScale
                icon={<span className="shipment-count-label">501 - 1000</span>}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={2} className="mt-2">
            <Grid item xs={6}>
              <TextFieldWithIcon
                numeric
                prependIcon
                id="invoicing-price-collection"
                label={t('PRICE_COLLECTION')}
                name="pricing.collection"
                placeholder="25,00"
                defaultValue={25}
                decimalScale={2}
                fixedDecimalScale
                icon={<span className="shipment-count-label">€</span>}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={2} className="mt-2">
            <Grid item xs={6}>
              <TextFieldWithIcon
                numeric
                prependIcon
                id="invoicing-price-xl"
                label={t('PRICE_XL_PARCEL')}
                name="pricing.deliveryXlSurcharge"
                placeholder="4,50"
                defaultValue={4.5}
                decimalScale={2}
                fixedDecimalScale
                icon={<span className="shipment-count-label">€</span>}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

const SameAddressButton = memo(() => {
  const { getValues, setValue } = useFormContext()

  const setAddress = useCallback(() => {
    const formValues = getValues()
    setValue('invoicing', {
      ...get(formValues, 'invoicing'),
      location: get(formValues, 'merchant.location', {}),
      sameAsWebshopAddress: true,
    })
  }, [getValues, setValue])

  const [t] = useTranslation('MERCHANT_CREATION')
  return (
    <Fab variant="extended" size="small" onClick={setAddress}>
      {t('BUTTON.SAME_AS_WEBSHOP_ADDRESS')}
    </Fab>
  )
})

const SameContactButton = memo(() => {
  const { getValues, setValue } = useFormContext()

  const setContact = useCallback(() => {
    const formValues = getValues()
    setValue('invoicing', {
      ...get(formValues, 'invoicing'),
      contactPerson: get(formValues, 'collection.contactPerson', ''),
      phoneNumber: get(formValues, 'collection.phoneNumber', ''),
    })
  }, [getValues, setValue])

  const [t] = useTranslation('MERCHANT_CREATION')
  return (
    <Fab variant="extended" size="small" onClick={setContact}>
      {t('BUTTON.SAME_AS_COLLECTION_CONTACT')}
    </Fab>
  )
})

export default memo(MerchantInvoicingForm)
