import { useTranslation } from '@/hooks/useTranslation'
import {
  isDataProcessed,
  isDelivered,
  isDeliveredToNeigbour,
  isInTransit,
  isNonDeliveryState,
} from '@/utils/checkStatus'
import clsx from 'clsx'
import React, { useMemo } from 'react'
import { SHIPMENT_STATES } from 'Trunkrs-SDK/dist/models/delivery/ShipmentLog'

import styles from './styles.module.scss'

export interface StateLabelProps {
  state: SHIPMENT_STATES
}

export const StateLabel: React.FC<StateLabelProps> = ({ state }) => {
  const [t] = useTranslation('SHIPMENT_STATES')

  const activeClass = useMemo(() => {
    if (isDeliveredToNeigbour(state)) {
      return 'toNeigbour'
    } else if (isDelivered(state)) {
      return 'delivered'
    } else if (isDataProcessed(state)) {
      return 'acceptedByDriver'
    } else if (isNonDeliveryState(state)) {
      return 'notDelivered'
    } else if (isInTransit(state)) {
      return 'inTransit'
    } else {
      return 'notDelivered'
    }
  }, [state])

  return (
    <div className={clsx(styles['shipment-state-label'])}>
      <span className={styles[String(activeClass)]} />
      <span>{t(state || '')}</span>
    </div>
  )
}

export default StateLabel
