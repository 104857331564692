import { Repick } from '@/@types/repick'
import Button from '@/app/base/components/.v2/Button'
import Calendar from '@/app/base/components/.v2/Calendar'
import Icon from '@/app/base/components/.v2/Icon'
import Tooltip from '@/app/base/components/.v2/Tooltip'
import { formatToTimeString } from '@/utils'
import clsx from 'clsx'
import { isNaN } from 'formik'
import clamp from 'lodash/clamp'
import isDate from 'lodash/isDate'
import moment from 'moment'
import React, {
  HTMLAttributes,
  LiHTMLAttributes,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import ClickAway from '../../../../../utils/ClickAway'
import styles from './styles.module.scss'

const SelectCalendar = ({
  onChange,
  value,
  className,
  label,
  placeholder,
  disabled,
  ...props
}: Repick<React.DetailedHTMLProps<HTMLAttributes<any>, any>, 'onChange'> & {
  onChange: (date?: any) => void
  label?: string
  disabled?: boolean
}) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false)
  const [selectedHour, setSelectedHour] = useState()
  const [selectedMinutes, setSelectedMinutes] = useState()

  const showDropdown = useCallback(() => setIsDropdownVisible(true), [])
  const hideDropdown = useCallback(() => {
    setSelectedHour('')
    setSelectedMinutes('')
    setIsDropdownVisible(false)
  }, [])

  const handleChange = useCallback(
    date => {
      onChange(date)
      hideDropdown()
    },
    [onChange]
  )

  const handleHourChange = (ev: any) => {
    const value = ev.target.value.replace(/^0/, '')
    setSelectedHour(clamp(isNaN(value) ? 0 : value, 0, 23).toString())
    if (selectedMinutes == undefined) {
      setSelectedMinutes('00')
    }
  }

  const handleMinuteChange = (ev: any) => {
    const value = ev.target.value.replace(/^0/, '')
    setSelectedMinutes(clamp(isNaN(value) ? 0 : value, 0, 59).toString())
    if (selectedHour == undefined) {
      setSelectedHour('00')
    }
  }

  const formatHourValue = (ev: any) => {
    const value = ev.target.value.replace(/^0/, '')
    const numValue = clamp(isNaN(value) ? 0 : value, 0, 23)
    setSelectedHour(formatToTimeString(numValue))
  }

  const formatMinuteValue = (ev: any) => {
    const value = ev.target.value.replace(/^0/, '')
    const numValue = clamp(isNaN(value) ? 0 : value, 0, 59)
    setSelectedMinutes(formatToTimeString(numValue))
  }

  const handleSelectedTime = () => {
    if (selectedHour !== undefined && selectedMinutes !== undefined) {
      const dateTime = moment(isDate(value) ? value : new Date())
        .set({
          hour: selectedHour.replace(/^0/, ''),
          minute: selectedMinutes.replace(/^0/, ''),
        } as any)
        .toDate()
      hideDropdown()
      onChange(dateTime)
    }
  }

  const setTimeButtonDisabled =
    selectedHour === undefined && selectedMinutes === undefined

  return (
    <div
      className={clsx(
        className,
        styles.container,
        isDropdownVisible && styles.focused,
        disabled && styles.disabled
      )}
      {...props}
    >
      <div className={clsx(styles.label, isDropdownVisible && styles.active)}>
        <div onClick={showDropdown}>
          <div>
            {label && <span>{`${label}: `}</span>}
            {value ? (
              moment(value).format('DD-MM-YYYY HH:mm')
            ) : (
              <span>{placeholder}</span>
            )}
          </div>
          <Icon name="calendar" />
        </div>
      </div>
      <ClickAway callback={hideDropdown}>
        <div
          className={clsx(
            styles.dropdownContainer,
            isDropdownVisible && styles.visible
          )}
        >
          <Calendar onChange={handleChange} selectedDate={value} />
          <div className={styles.timePickerContainer}>
            <Icon name="clock" />
            <div>
              <input
                type="text"
                value={selectedHour}
                onBlur={formatHourValue}
                onChange={handleHourChange}
                placeholder="hh"
              />
              :
              <input
                type="text"
                onBlur={formatMinuteValue}
                value={selectedMinutes}
                onChange={handleMinuteChange}
                placeholder="mm"
              />
              <Button
                disabled={setTimeButtonDisabled}
                variant="success"
                onClick={handleSelectedTime}
              >
                Set time
              </Button>
            </div>
          </div>
        </div>
      </ClickAway>
    </div>
  )
}

export default memo(SelectCalendar)
