import defaultTo from 'lodash/defaultTo'
import filter from 'lodash/filter'
import get from 'lodash/get'
import React, {
  FormEvent,
  ReactNode,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

export const useSelect = (
  children: React.ReactNode,
  value?: string,
  callback?: (value: string) => void
) => {
  const [currentValue, setCurrentValue] = useState(value)
  const [selectedOption, setSelectedOption] = useState()
  const [options, setOptions] = useState<any>([])
  const collection = useRef<{ [key: string]: ReactNode }>({})

  useEffect(() => {
    if (value !== currentValue) {
      setCurrentValue(value)
      setSelectedOption(value && get(collection.current, value))
      setOptions(filter(collection.current, (v, key) => value !== key))
    }
  }, [value])

  const handleOptionClick = useCallback(
    (ev: React.MouseEvent) => {
      const keyValue = get(ev, 'currentTarget.value', '')
      const option = get(collection.current, keyValue)

      setCurrentValue(keyValue)
      setSelectedOption(option)
      setOptions(filter(collection.current, (v, key) => keyValue !== key))

      if (typeof callback === 'function') {
        callback(keyValue)
      }
    },
    [collection]
  )

  useEffect(() => {
    React.Children.forEach(children, (child: any) => {
      const el = React.cloneElement(child, {
        ...child.props,
        onClick: handleOptionClick,
      })
      const keyValue = get(el, 'props.value')
      collection.current = {
        ...collection.current,
        [keyValue]: el,
      }
    })
    setOptions(filter(collection.current, (v, key) => currentValue !== key))
    const option = currentValue && get(collection.current, currentValue)
    setSelectedOption(option)
  }, [])

  return useMemo(
    () => ({
      currentValue,
      Options: memo(() => options),
      SelectedOption: selectedOption && memo(() => selectedOption),
    }),
    [currentValue, options, selectedOption]
  )
}

export const useAutoComplete = (
  collection: Array<{ value: any; text: string; content?: any }>,
  callback: (value: any) => void,
  value?: any
) => {
  const [currentValue, setCurrentValue] = useState()
  const [searchString, setSearchString] = useState('')
  const [suggestions, setSuggestions] = useState<
    Array<{ value: any; text: string }>
  >([])

  useEffect(() => {
    const selectedValue = collection.find(o => o.value === value)
    setCurrentValue(selectedValue)
  }, [])

  useEffect(() => {
    if (value !== get(currentValue, 'value')) {
      const selectedValue = collection.find(o => o.value === value)
      setCurrentValue(selectedValue)
    }
  }, [value])

  const handleInputChange = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      const value = get(ev, 'currentTarget.value', '')
      setCurrentValue(undefined)
      setSearchString(value)
      const filteredSuggestion = value.trim().length
        ? filter(collection, ({ text }) =>
            defaultTo(text, '')
              .toLowerCase()
              .includes(value.toLowerCase())
          )
        : []
      setSuggestions(filteredSuggestion)
    },
    [collection]
  )

  const handleSuggestionSelect = useCallback(
    (suggestion: { value: any; text: string }) => () => {
      setCurrentValue(suggestion)
      setSearchString('')
      setSuggestions([])

      if (typeof callback === 'function') {
        callback(suggestion.value)
      }
    },
    []
  )

  const clearValue = useCallback(() => {
    setCurrentValue(undefined)
  }, [])

  const clearSuggestions = useCallback(() => {
    setSuggestions([])
  }, [])

  return useMemo(
    () => ({
      searchString,
      currentValue,
      suggestions,
      clearValue,
      clearSuggestions,
      handleInputChange,
      handleSuggestionSelect,
    }),
    [currentValue, suggestions, searchString]
  )
}

export const useTextArea = (
  value?: string,
  onChange: ((ev: any) => void) | undefined = undefined,
  maxLength: number | undefined = undefined
) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (ref.current) {
      if (value !== undefined && value !== ref.current.innerText) {
        ref.current.innerText = value
        const range = document.createRange()
        range.selectNodeContents(ref.current)
        range.collapse(false)
        const selection = window.getSelection()
        selection.removeAllRanges()
        selection.addRange(range)
      }
    }
  }, [ref, value])

  const handleInput = useCallback(
    (ev: FormEvent<HTMLDivElement>) => {
      const value = get(ev, 'currentTarget.innerText', '').substr(0, maxLength)

      if (typeof onChange === 'function') {
        onChange({
          ...ev,
          currentTarget: {
            ...ev.currentTarget,
            value,
          },
        })
      }
    },
    [onChange]
  )

  const handleKeyDown = useCallback(
    (ev: React.KeyboardEvent<HTMLDivElement>) => {
      const value = get(ev, 'currentTarget.innerText', '')
      const charLength = value.length

      if (maxLength && (ev.keyCode !== 8 && charLength >= maxLength)) {
        ev.preventDefault()
        return
      }
    },
    [maxLength]
  )

  return useMemo(
    () => ({
      ref,
      handleInput,
      handleKeyDown,
    }),
    [ref]
  )
}
