import { CollationContext } from '@/contexts'
import * as React from 'react'

import { useCollationActions } from './actions'
import { initialCollationState } from './state'

export default ({ children }: { children: React.ReactNode }) => {
  const state = useCollationActions(initialCollationState)
  return (
    <CollationContext.Provider value={state}>
      {children}
    </CollationContext.Provider>
  )
}
