export * from './ROUTES'
export * from './SHIPMENT_STATE'
export * from './REASON_CODE'
export * from './FREEZER_STATE'
export * from './FETCH_STATUS'

export const SAME_DAY_RETURN = 'SAME_DAY_RETURN'
export const AUTH_KEY = '__CSP_AUTH_KEY'

export enum PRODUCT_NAME {
  SAME_DAY = 'SAME_DAY',
  SAME_DAY_FOOD = 'SAME_DAY_FOOD',
  SAME_DAY_FROZEN_FOOD = 'SAME_DAY_FROZEN_FOOD',
}

export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN
export const ENVIRONMENT = process.env.REACT_APP_ENV
export const EMAIL_COLLECTION_TOUR_CLIENT =
  process.env.REACT_APP_EMAIL_COLLECTION_TOUR_CLIENT
export const IS_DEV_ENVIRONMENT = process.env.NODE_ENV === 'development'

export enum FILE_INPUT_STATE {
  INITIAL,
  FILE_UPLOADED,
  FILE_REJECTED,
  FILE_UPLOAD_ERROR,
}

export const REGEX_DIGIT = /[0-9]/
export const REGEX_LETTER = /[a-zA-Z]/
export const REGEX_LETTER_OR_SPACE = /[a-zA-Z]|\s/
export const REGEX_ZERO_OR_ONE_LETTER = /[a-zA-Z]?/

export const INPUT_MASK_NL_POSTAL_CODE = [
  REGEX_DIGIT,
  REGEX_DIGIT,
  REGEX_DIGIT,
  REGEX_DIGIT,
  REGEX_LETTER_OR_SPACE,
  REGEX_LETTER,
  REGEX_ZERO_OR_ONE_LETTER,
]

export enum MESSAGE_TYPE {
  INFORMATION = 'INFORMATION',
  IMPORTANT = 'IMPORTANT',
  CONVERSATION = 'CONVERSATION',
}
