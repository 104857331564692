import { get } from 'lodash'

export const seeInMap = (data: any) => {
  const latitude = get(data, 'latitude', 0)
  const longitude = get(data, 'longitude', 0)
  return `https://maps.google.com/?q=${latitude},${longitude}`
}

export const seeInMapWithOriginDestination = (data: any) => {
  const driverLat = get(data, 'driver.latitude', 0)
  const driverLong = get(data, 'driver.longitude', 0)

  const destinationLat = get(data, 'destination.latitude', 0)
  const destinationLong = get(data, 'destination.longitude', 0)

  const origin = `${driverLat},${driverLong}`
  const destination = `${destinationLat},${destinationLong}`
  const googleMapUrl = 'https://www.google.com/maps/dir/?api=1'
  const driverLocation = `${googleMapUrl}&origin=${origin}&destination=${destination}&travelmode=driving`

  return driverLocation
}

export const showInMapWithOriginDestination = (
  origin: { lat: number; long: number },
  destination: { lat: number; long: number }
) => {
  const originMarker = `${origin.lat},${origin.long}`
  const destinationMarker = `${destination.lat},${destination.long}`
  const googleMapUrl = 'https://www.google.com/maps/dir/?api=1'

  return `${googleMapUrl}&origin=${originMarker}&destination=${destinationMarker}&travelmode=driving`
}
