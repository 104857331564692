export const tourColors = [
  '#b71c1c',
  '#3A3485',
  '#017FCA',
  '#009587',
  '#30322c',
  '#48687f',
  '#785447',
  '#EE6E01',
  '#EC407A',
  '#4CAF50',
  '#b71c1c',
  '#3A3485',
  '#017FCA',
  '#009587',
  '#30322c',
  '#48687f',
  '#785447',
  '#ed2789',
  '#4CAF50',
  '#EE6E01',
]
