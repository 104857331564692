import { useTranslation } from '@/hooks'
import * as React from 'react'

const Legend = () => {
  const [t] = useTranslation('TOUR_OVERVIEW')
  return (
    <div className="legend">
      <div className="stop-container">
        <div className="stop not-scanned" />
        {t('LEGEND_NOT_SCANNED_AT_HUB')}
      </div>

      <div className="stop-container">
        <div className="stop declined-by-driver" />
        {t('LEGEND_DECLINED_BY_DRIVER')}
      </div>

      <div className="stop-container">
        <div className="stop" />
        {t('LEGEND_ON_ITS_WAY')}
      </div>

      <div className="stop-container">
        <div className="stop delivered" />
        {t('LEGEND_DELIVERED')}
      </div>

      <div className="stop-container">
        <div className="stop delivered-to-neighbour" />
        {t('LEGEND_DELIVERED_TO_NEIGHBOUR')}
      </div>

      <div className="stop-container">
        <div className="stop not-delivered" />
        {t('LEGEND_NOT_DELIVERED')}
      </div>

      <div className="stop-container">
        <div className="stop delayed" />
        {t('LEGEND_DELAYED')}
      </div>
    </div>
  )
}

export default Legend
