import StopMarker from '@/app/features/collectionTour/components/collectionTourMap/map/StopMarker'
import mapStyles from '@/constants/mapStyles.json'
import { IColoredCollectionTour } from '@/store/collectionTour/state'
import { decode } from 'google-polyline'
import flatMap from 'lodash/flatMap'
import React, { useMemo } from 'react'
import {
  GoogleMap,
  Polyline,
  WithGoogleMapProps,
  withGoogleMap,
  withScriptjs,
} from 'react-google-maps'

interface IMapProps {
  tours: IColoredCollectionTour[] | null
}

interface IMapState {
  center: { lat: number; lng: number }
  zoom: number
}

const Map: React.FC<IMapProps & WithGoogleMapProps> = ({ tours }) => {
  const mapRef = React.createRef<GoogleMap>()

  React.useEffect(() => {
    const {
      context: { __SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED: map },
    } = mapRef.current as any
    const bounds = new google.maps.LatLngBounds()
    const hasBounds = tours && tours.length

    if (hasBounds) {
      flatMap(tours, 'stops').forEach((stop: any) =>
        bounds.extend(new google.maps.LatLng(stop.lat, stop.lng))
      )
    } else {
      bounds.extend(new google.maps.LatLng(52.1326, 5.2913))
    }

    if (map) {
      map.fitBounds(bounds)
      if (!hasBounds) {
        map.setZoom(8)
      }
    }
  }, [tours])

  const generatePolylines = useMemo(
    () =>
      tours &&
      tours.map(tour =>
        tour.stops.map(
          (stop: any) =>
            stop.polyline && (
              <Polyline
                key={`${tour.tourId}-${stop.position}`}
                path={decode(stop.polyline).map(
                  (item: any) =>
                    item && {
                      lat: item[0],
                      lng: item[1],
                    }
                )}
                options={{
                  strokeColor: tour.color,
                  strokeOpacity: 0.5,
                }}
              />
            )
        )
      ),
    [tours]
  )

  const generateMarkers = useMemo(
    () =>
      tours &&
      tours.map(tour =>
        tour.stops.map((stop, index, stops) => {
          const { shopName, lat, lng, position } = stop as any
          if (!lat || !lng) return

          return (
            <StopMarker
              key={`${tour.tourId}-${position}`}
              label={shopName}
              color={tour.color}
              position={index}
              start={index === 0}
              end={index === stops.length - 1}
              lat={lat}
              lng={lng}
            />
          )
        })
      ),
    [tours]
  )

  return (
    <GoogleMap
      ref={mapRef}
      options={{
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        scrollwheel: true,
        styles: mapStyles as any,
      }}
    >
      {tours && generatePolylines}
      {tours && generateMarkers}
    </GoogleMap>
  )
}

export default withScriptjs(withGoogleMap(Map))
