import clsx from 'clsx'
import React, { useMemo } from 'react'

import classes from './style.module.scss'

interface IitemsProps {
  items: string[]
  activeItem: string
  onSelectItem: (item: string) => void
}

const ItemsNavigation: React.FC<IitemsProps> = ({
  items,
  activeItem,
  onSelectItem,
}) => {
  const list = useMemo(
    () =>
      items.map(item => (
        <span
          key={item}
          className={clsx(activeItem === item && classes.active)}
          onClick={() => onSelectItem(item)}
        >
          {item}
        </span>
      )),
    [items, activeItem]
  )

  return <div className={classes.item_navigation}>{list}</div>
}

export default ItemsNavigation
