import LayoutWithRightDrawer from '@/app/base/components/layoutWithRightDrawer'
import { CollectionTrackingContext } from '@/contexts'
import React, { useContext, useEffect, useLayoutEffect, useState } from 'react'

import DrawerContent from './components/drawerContent'
import MainContent from './components/mainContent'

const CollectionTracking: React.FC = () => {
  const [initializing, setInitializing] = useState(true)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const { fetchAvailableTags, setActiveTag } = useContext(
    CollectionTrackingContext
  )

  useEffect(() => {
    setInitializing(true)
    fetchAvailableTags()
      .then(tags => {
        setActiveTag(tags[0])
      })
      .finally(() => {
        setInitializing(false)
      })
  }, [])

  const toggleDrawer = (shouldBeOpen: boolean) => {
    setDrawerOpen(shouldBeOpen)
  }

  if (initializing) return null

  return (
    <LayoutWithRightDrawer
      drawerOpen={drawerOpen}
      drawerContent={<DrawerContent toggleDrawer={toggleDrawer} />}
    >
      <MainContent toggleDrawer={toggleDrawer} isDrawerOpen={drawerOpen} />
    </LayoutWithRightDrawer>
  )
}

export default CollectionTracking
