import './styles.scss'

import {
  InputHidden,
  SelectField,
  TextAreaField,
  TextField,
} from '@/app/base/components/reactHookComponents'
import { INPUT_MASK_NL_POSTAL_CODE } from '@/constants'
import { useTranslation } from '@/hooks'
import Fab from '@material-ui/core/Fab/Fab'
import Grid from '@material-ui/core/Grid/Grid'
import MenuItem from '@material-ui/core/MenuItem/MenuItem'
import get from 'lodash/get'
import React, { memo, useCallback, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'

import resource from '../../resource.json'

const MerchantCollectionForm = () => {
  const countries = get(resource, 'Countries', []) as Array<{
    value: string
    description: string
  }>

  const cutOffSchedules = get(resource, 'CutOffSchedules', []) as string[]

  const [t] = useTranslation('MERCHANT_CREATION')

  return (
    <div id="merchant-collection">
      <div className="header-wrapper">
        <div>
          <span className="header-text">{t('COLLECTION_HEADER')}</span>
          <span className="header-subtext">{t('COLLECTION_SUB_HEADER')}</span>
        </div>
        <div className="icon" />
      </div>
      <div className="content">
        <Grid container>
          <Grid item xs={12}>
            <SelectField
              required
              label={t('CUT_OFF_TIME')}
              id="cut-off-time"
              name="timeSlotConfig.dataWindowClosesAt"
              menuListMaxHeight={150}
            >
              <MenuItem value="" hidden>
                {t('CUTOFF_TIME_PLACEHOLDER')}
              </MenuItem>
              {useMemo(
                () =>
                  cutOffSchedules.map(value => (
                    <MenuItem value={value}>{value}</MenuItem>
                  )),
                []
              )}
            </SelectField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="collection-contact-person"
              label={t('CONTACT_PERSON')}
              name="collection.contactPerson"
              placeholder={t('CONTACT_PERSON_PLACEHOLDER')}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="collection-phone-number"
              label={t('PHONE_NUMBER')}
              name="collection.phoneNumber"
              placeholder={t('PHONE_NUMBER_PLACEHOLDER')}
            />
          </Grid>
          <Grid item xs={12}>
            <InputHidden name="collection.sameAsWebshopAddress" />
            <TextField
              id="collection-address"
              className="custom-input-group"
              label={t('ADDRESS')}
              name="collection.location.address"
              placeholder={t('ADDRESS_PLACEHOLDER')}
              InputProps={{
                endAdornment: <SameAddressButton />,
              }}
            />
          </Grid>
          <Grid container item xs={12} spacing={1}>
            <Grid item xs={3}>
              <TextField
                id="collection-zipcode"
                label={t('ZIPCODE')}
                placeholder={t('ZIPCODE_PLACEHOLDER')}
                name="collection.location.postalCode"
                maskProps={{ mask: INPUT_MASK_NL_POSTAL_CODE }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="collection-city"
                label={t('CITY')}
                placeholder={t('CITY_PLACEHOLDER')}
                name="collection.location.city"
              />
            </Grid>
            <Grid item xs={5}>
              <SelectField
                label={t('COUNTRY')}
                id="collection-country"
                name="collection.location.country"
              >
                {useMemo(
                  () =>
                    countries.map(({ value, description }) => (
                      <MenuItem value={value}>{description}</MenuItem>
                    )),
                  []
                )}
              </SelectField>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TextAreaField
              id="collection-comment"
              label={t('COMMENT')}
              name="collection.importantInfo"
              placeholder={t('COMMENT_PLACEHOLDER')}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

const SameAddressButton = () => {
  const { getValues, setValue } = useFormContext()

  const setAddress = useCallback(() => {
    const formValues = getValues()
    setValue('collection', {
      ...get(formValues, 'collection'),
      location: get(formValues, 'merchant.location', {}),
      sameAsWebshopAddress: true,
    })
  }, [])

  const [t] = useTranslation('MERCHANT_CREATION')
  return (
    <Fab variant="extended" size="small" onClick={setAddress}>
      {t('BUTTON.SAME_AS_WEBSHOP_ADDRESS')}
    </Fab>
  )
}

export default memo(MerchantCollectionForm)
