import * as React from 'react'
import { IShipmentFileSchema } from '@/app/features/uploadcsv'
import { useContext } from 'react'
import { AppContext, RouterContext, ShipmentsContext } from '@/contexts'
import { useTranslation } from '@/hooks'

export const ShipmentsTable = (props: {
  shipments: Array<IShipmentFileSchema>
}) => {
  const { shipments } = props

  const [t] = useTranslation(['ALL_SHIPMENTS'])

  return (
    <div className="shipments-table table mt-4 mb-4">
      <div className="row table-heading">
        <div className="col">{t('TRUNKRSNR')}</div>
        <div className="col">{t('RECIPIENT')}</div>
        <div className="col">{t('ADDRESS')}</div>
        <div className="col">{t('REGION')}</div>
        <div className="col">{t('SENDER')}</div>
        <div className="col">{t('LAST_DRIVER')}</div>
        <div className="col">{t('STATUS')}</div>
        <div className="col">{t('DELIVERY_DATE')}</div>
      </div>
      {shipments &&
        shipments.map((shipment, index) => (
          <ShipmentDataRow key={index} shipment={shipment} />
        ))}
    </div>
  )
}

const ShipmentDataRow = (props: { shipment: IShipmentFileSchema }) => {
  const {
    shipment: {
      parcel_barcode,
      receiver_name,
      receiver_address,
      sender_name,
      date,
    },
  } = props

  const { fetchShipmentByBarcode } = useContext(ShipmentsContext)
  const { history } = useContext(RouterContext)
  const { setLoading } = useContext(AppContext)

  const setActiveShipment = () => {
    ;(async () => {
      setLoading(true)
      const shipment = await fetchShipmentByBarcode(parcel_barcode)
      if (shipment) {
        history.push(`/shipments/${shipment.getId}`)
      }
      setLoading(false)
    })()
  }

  const [t] = useTranslation(['UPLOAD_CSV'])

  return (
    <div className="row" onClick={setActiveShipment}>
      <div className="col">{parcel_barcode}</div>
      <div className="col">{receiver_name}</div>
      <div className="col">{receiver_address}</div>
      <div className="col"> </div>
      <div className="col">{sender_name}</div>
      <div className="col"> - </div>
      <div className="col">{t('SHIPMENT_REGISTERED')}</div>
      <div className="col">{date}</div>
    </div>
  )
}
