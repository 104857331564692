import './style.scss'

import { ModalForm } from '@/app/base/components'
import {
  ForDelivery,
  ForLost,
  ForMissing,
  ForNeighbourDelivery,
  ForNonDelivery,
} from '@/app/features/shipment/components/setFinalState/components'
import { useTranslation } from '@/hooks'
import { SHIPMENT_ACTIONS } from '@/models'
import { clsx } from '@/utils'
import React, { memo, useCallback, useState } from 'react'

const {
  DELIVERY,
  NEIGHBOUR_DELIVERY,
  NON_DELIVERY,
  MISSING,
  LOST,
} = SHIPMENT_ACTIONS

const SetFinalState = ({
  handleClose,
}: {
  handleClose?: (ev?: React.SyntheticEvent<any>) => void
}) => {
  const [selectedAction, setSelectedAction] = useState<SHIPMENT_ACTIONS>(
    DELIVERY
  )

  const handleSetSelectedActionDelivery = useCallback(
    () => setSelectedAction(DELIVERY),
    []
  )
  const handleSetSelectedActionNeighbourDelivery = useCallback(
    () => setSelectedAction(NEIGHBOUR_DELIVERY),
    []
  )
  const handleSetSelectedActionNonDelivery = useCallback(
    () => setSelectedAction(NON_DELIVERY),
    []
  )
  const handleSetSelectedActionMissing = useCallback(
    () => setSelectedAction(MISSING),
    []
  )
  const handleSetSelectedActionLost = useCallback(
    () => setSelectedAction(LOST),
    []
  )

  const [t] = useTranslation(['SET_FINAL_STATE', 'COMMON'])

  return (
    <ModalForm
      show={true}
      title={t('SET_FINAL_STATE')}
      handleClose={handleClose}
    >
      <div id="set-final-state">
        <div className="wrapper">
          <button
            className={clsx(
              'btn button',
              selectedAction === DELIVERY ? 'success' : 'inactive'
            )}
            type="button"
            onClick={handleSetSelectedActionDelivery}
          >
            {t('BUTTON.DELIVERY')}
          </button>
          <button
            className={clsx(
              'btn-deliver-to-neighbour btn button',
              selectedAction === NEIGHBOUR_DELIVERY ? 'success' : 'inactive'
            )}
            type="button"
            onClick={handleSetSelectedActionNeighbourDelivery}
          >
            {t('BUTTON.NEIGHBOUR_DELIVERY')}
          </button>
          <button
            className={clsx(
              'btn-no-delivery btn button',
              selectedAction === NON_DELIVERY ? 'danger' : 'inactive'
            )}
            type="button"
            onClick={handleSetSelectedActionNonDelivery}
          >
            {t('BUTTON.NO_DELIVERY')}
          </button>
          <button
            className={clsx(
              'btn-no-delivery btn button',
              selectedAction === MISSING ? 'danger' : 'inactive'
            )}
            type="button"
            onClick={handleSetSelectedActionMissing}
          >
            {t('BUTTON.MISSING')}
          </button>
          <button
            className={clsx(
              'btn-no-delivery btn button',
              selectedAction === LOST ? 'danger' : 'inactive'
            )}
            type="button"
            onClick={handleSetSelectedActionLost}
          >
            {t('BUTTON.LOST')}
          </button>
        </div>
        {selectedAction === DELIVERY && (
          <ForDelivery handleClose={handleClose} />
        )}
        {selectedAction === NEIGHBOUR_DELIVERY && (
          <ForNeighbourDelivery handleClose={handleClose} />
        )}
        {selectedAction === NON_DELIVERY && (
          <ForNonDelivery handleClose={handleClose} />
        )}
        {selectedAction === MISSING && <ForMissing handleClose={handleClose} />}
        {selectedAction === LOST && <ForLost handleClose={handleClose} />}
      </div>
    </ModalForm>
  )
}

export default memo(SetFinalState)
