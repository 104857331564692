import { useTranslation } from '@/hooks'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { memo } from 'react'

export interface IErrorBlockProps {
  fieldError?: string
}

const ErrorBlock = ({ fieldError }: IErrorBlockProps) => {
  const [t] = useTranslation('MERCHANT_OVERVIEW')
  return (
    <div className="error-container">
      {fieldError && (
        <>
          <FontAwesomeIcon icon={faExclamationCircle} color="#FF5351" />
          <span>{t(fieldError)}</span>
        </>
      )}
    </div>
  )
}

export default memo(ErrorBlock)
