import { FETCH_STATUS, FREEZER_STATE, SHIPMENT_STATE } from '@/constants'
import { TFunction } from 'i18next'
import moment from 'moment'
import * as React from 'react'
import { HTMLAttributes } from 'react'
import { Control } from 'react-hook-form'
import { FieldErrors, FieldValues } from 'react-hook-form/dist/types/form'
import {
  Driver,
  Estimation,
  Location,
  Recipient,
  Sender,
  Shipment,
  ShipmentComment,
  ShipmentLog,
  State,
  Subco,
  TimeSlot,
  Tour,
} from 'Trunkrs-SDK/dist/models'
import {
  IFreezerLog,
  IFreezerLogsHistory,
  IProof,
} from 'Trunkrs-SDK/dist/models/delivery/Shipment'
import { IShipmentAlert } from 'Trunkrs-SDK/dist/models/delivery/ShipmentAlert'

export * from './auth'
export * from './route'
export * from 'Trunkrs-SDK/dist/models'
export * from 'Trunkrs-SDK/dist/models/delivery/Shipment'
export { SHIPMENT_STATES } from 'Trunkrs-SDK/dist/models/delivery/ShipmentLog'
export * from 'Trunkrs-SDK/dist/models/delivery/ShipmentAlert'
export * from 'Trunkrs-SDK/dist/constants'

export interface IShipmentData {
  shipment?: Shipment
  recipient?: Recipient
  sender?: Sender
  location?: Location
  senderLocation?: Location
  subco?: Subco
  driver?: Driver
  status?: State
  timeSlot?: TimeSlot
  tour?: Tour
  logs?: Array<ShipmentLog>
  estimation?: Estimation
  logZero?: ShipmentLog
}

export interface IAdminProfileDetails {
  name: string
  email: string
  picture: string
}

export interface IShipmentComment extends ShipmentComment {
  userProfile: IAdminProfileDetails
}

export interface IAddressHistory {
  location: Location
  timeStamp: Date
}

export interface IPersonalInfo {
  name: string
  address: string
  postalCode: string
  city: string
  emailAddress: string
  phoneNumber: string
}

export interface IShipment {
  shipmentId: number
  trunkrsNr: string
  recipient: IPersonalInfo
  region: string
  sender: IPersonalInfo
  lastDriverName: string
  statusText: string
  deliveryDate?: Date | moment.Moment
  productName?: string
}

export interface IDeliveryDetails {
  shipmentId: number
  logDate: string
  signature: string
  picture: string
  comments: string
  reason: {
    code: string
    description: string
    nl: string
    en: string
  }
  state: {
    name: string
    description: string
  }
  neighbour: {
    name: string
    email: string
    phoneNr: string
    companyName: string
    instructions: string
    address: string
    postalCode: string
    country: string
    city: string
  }
}

export interface withT {
  t: TFunction
}

export interface IShipmentTourEstimation {
  tour: Tour
  estimation: Estimation
}

export interface ISearchFilters {
  date?: Date
  driver_id?: string | number
  subco_tag?: string
  merchant_name?: string
  state_name?: string
}

export interface IOverviewShipmentsResultFilter extends ISearchFilters {
  currentPage: number
  resultsPerPage: number
  endOfResults: boolean
  query?: string
  fetchAllShipmentIds?: boolean
}

export interface ILoadingProps extends React.HTMLAttributes<HTMLDivElement> {
  loading?: boolean
  modal?: boolean
  caption?: string
  [key: string]: any
}

export interface ILoaderMethods {
  setLoading: (isLoading: boolean) => void
  destroy: () => void
}

export interface IDOMWidgetBase<T> {
  component: (props: T) => JSX.Element | null
  ref?: React.RefObject<HTMLElement>
}

export interface IDOMWidget<T, U> extends IDOMWidgetBase<T> {
  methods: U
}

export interface ISubCo {
  id: number
  name: string
  delays: {
    [key: number]: number
  }
}

export interface ISubco {
  id: number
  name: string
  delays: number
  isLoading?: boolean
}

export interface ITour {
  id: number
  startTime?: string
  endTime?: string
  driverId: number
  subCoId: number
  status: FETCH_STATUS
  delayed: boolean
  color: string
}

export interface IRecipient {
  name: string
  phoneNumber: string
  address: string
  postalCode: string
  country: string
  city: string
  latitude: number
  longitude: number
}

export interface IMerchant {
  name: string
}

export interface IRecipient {
  name: string
  phoneNumber: string
  address: string
  postalCode: string
  country: string
  city: string
  latitude: number
  longitude: number
}

export interface IMerchant {
  id?: number
  name: string
}

export interface ICollation {
  id: number
  eta: string
  position: number
  state?: SHIPMENT_STATE
  driverId: number
  shipmentId: number
  tourDate: string
  tourId: number
  delayed?: boolean
  polyline: string
  recipient: IRecipient
  merchant: IMerchant
}

export interface IDriver {
  recorded_at: string
  id: number
  name: string
  pictureLocation: string
  picture?: string
  subCoId: number
  subCoName: string
  tourId: number
  active?: boolean
  emailAddress?: string
  latestPosition?: string
  latitude?: number
  longitude?: number
  phoneNumber?: string
  speed?: number
  delayed: boolean
}

export interface IFetchCollationData {
  address: string
  city: string
  collationId: number
  country: string
  driverId: number
  eta: string
  merchantName: string
  polyline: string
  position: number
  postalCode: string
  recipientName: string
  recipientPhoneNumber: string
  shipmentId: number
  tourDate: string
  tourId: number
  latitude: number
  longitude: number
}

export interface IFetchTourOverviewData {
  collations: Array<IFetchCollationData>
  driverEmail: string
  driverLatitude: number
  driverLongitude: number
  driverName: string
  driverPhoneNr: string
  driverSpeed: number
  endTime: string
  startTime: string
  tourId: number
}

export interface IExtendedTour {
  tourId: number
  subCoId: number
  subCoName: string
  driverId: number
  driverName: string
  driverPicture: string
  tourColor: string
}

export interface ISubCoState {
  [key: number]: ISubCo
}

export interface ITourState {
  [key: number]: ITour
}

export interface IDriverState {
  [key: number]: IDriver
}

export interface ICollationState {
  [key: number]: ICollation
}

export interface INeighbourParams {
  name: string
  address: string
  postalCode: string
  city: string
  country: string
}

export enum SHIPMENT_ACTIONS {
  CANCEL,
  POSTPONE,
  SET_FINAL_STATE,
  DELIVERY,
  NEIGHBOUR_DELIVERY,
  NON_DELIVERY,
  MISSING,
  LOST,
}

export interface IShipmentNotification extends IShipmentAlert {
  shipmentsAffected: Array<number>
}

export interface IShipmentState {
  shipmentId: number
  shipmentStateName: SHIPMENT_STATE
}

export interface IRegionShipmentReturn {
  name: string
  subcos: ISubcoShipmentReturn[]
}

export interface ISubcoShipmentReturn {
  id: number
  name: string
  shipments: IShipmentReturn[]
}

export interface IShipmentReturn {
  returnedToTrunkrs: boolean
  shipmentId: number
  trunkrsNr: number
  driver: {
    id: number
  }
  merchant: {
    id: number
    name: string
  }
  recipient: {
    address: string
    city: string
    postalCode: string
    country: string
  }
  subco: {
    name: string
  }
}

export interface IFetchSubcoResponse {
  tours: Array<ITour>
  subco: ISubco | undefined
  drivers: Array<IDriver>
}

export interface IFrozenFoodDeliveryAttempt {
  date: string
  logs: IFreezerLog[]
  attemptNum: number
}

export interface IReactHookFormBase<T = any>
  extends HTMLAttributes<HTMLElement> {
  register?: FieldValues['register']
  control?: Control<FieldValues>
  errors?: FieldErrors<FieldValues>
  setValue?: (name: string, value: any) => void
  getValues?: (payload?: { nest: boolean }) => T
  watch?: (name: string) => any
}

export interface IMerchantInfo {
  merchant: {
    name: string
    address: string
    city: string
    country: string
    accountManager: string
    productCategory: string
  }
  operations: {
    startingVolumeFrom: number
    startingVolumeTo: number
    forecastedVolumeFrom: number
    forecastedVolumeTo: number
    weeksUntilForecastedVolume: number
    shipmentLengthCm: number
    shipmentWidthCm: number
    shipmentHeightCm: number
    expectedWeight: string
    cutOffTime: string
  }
  collection: {
    contactPerson: string
    phoneNumber: string
    address: string
    city: string
    country: COUNTRY_CODES
    comment: string
    parcel_image_link: string
  }
  invoicing: {
    contactPerson: string
    emailAddressInvoice: string
    kvkNumber: string
    btwNumber: string
    phoneNumber: string
    address: string
    city: string
    country: COUNTRY_CODES
  }
  pricing: {
    deliveryTier1: number
    deliveryTier2: number
    deliveryTier3: number
    deliveryTier4: number
    deliveryTier5: number
    deliveryTier6: number
    deliveryTier7: number
    deliveryXlSurcharge: number
    deliveryHeavySurcharge: number
    collection: number
  }
  itDetails: {
    contactPerson: string
    contactPersonEmail: string
    webshopType: WEBSHOP_TYPE
    integrationType: INTEGRATION_TYPE
    recipientCommunication: string
  }
}

export interface ICallLog {
  recordingId: number
  recordingUrl?: string
  createdAt: string
}

export enum INTEGRATION_TYPE {
  CCVSHOP = 'CCVShop',
  LIGHTSPEED = 'Lightspeed',
  MAGENTO_1 = 'Magento 1',
  MAGENTO_2 = 'Magento 2',
  PRESTASHOP = 'Prestashop',
  SHOPIFY = 'Shopify',
  WOO_COMMERCE = 'WooCommerce',
  OTHER = 'Other',
}

export enum WEBSHOP_TYPE {
  API = 'API',
  CSV = 'CSV',
  DELIVERYMATCH = 'Deliverymatch',
  LOGIC4 = 'Logic4',
  LOGXSTART = 'Logxstar',
  PAAZL = 'Paazl',
  SENDCLOUND = 'Sendcloud',
  SFTP = 'SFTP',
  SHERPAN = 'Sherpaan',
  SHIPPING_PORTAL = 'Shipping portal',
  TRANSSMART = 'Transsmart',
  OTHER = 'Other',
}

export enum COUNTRY_CODES {
  BELGIUM = 'be',
  NETHERLANDS = 'nl',
  GERMANY = 'de',
}

export enum HUBLOG_STATE {
  HUB_IN = 'HUB_IN',
  HUB_OUT = 'HUB_OUT',
  HUB_IN_CENTRAL = 'HUB_IN_CENTRAL',
}

export interface IHubLog {
  createdAt: string
  driverId: number | null
  owner: {
    name: string
    id: string
  }
  region: string
  shipmentId: number
  state: 'HUB_IN' | 'HUB_OUT' | 'HUB_IN_CENTRAL'
  subcoId: number
  userSub: string
  version: number
  distance?: {
    distanceFromHub: number
    scanLatitude: number
    scanLongitude: number
    subcoLatitude: number
    subcoLongitude: number
  }
}

export interface IReturnScan {
  hublogId: number
  subcoId: number
  scanLatitude: number
  scanLongitude: number
  hublogDate: string
  returnScanLogId: number
  shipmentId: number
  distanceFromHub: number
  driverId: number
  subcoLatitude: number
  subcoLongitude: number
}

export interface IInventory {
  page: number
  items: Array<{
    shipmentId: number
    inventoryDate: string
    inventoryStatus: string
    scannedAt: string
    notScannableReasonId: string
    notScannableComment: string
    trunkrsNr: string
    shipmentState: string
    merchantName: string
    merchantId: number
    region: number
    subcoId: number
    notScannableReason: string
  }>
}

export interface IAuditLogs {
  shipmentId: number
  createdAt: Date
  userSub: string
  source: string
  owner?: string
}
export interface ShipmentFeatures {
  requirePOD: boolean
  noSignature: boolean
  noNeighbourDelivery: boolean
  deliverInMailbox: boolean
}
export interface IProofOfDeliveryResult {
  enabled: boolean
  proof?: IProof
}

export interface IInventory {
  page: number
  items: Array<{
    shipmentId: number
    inventoryDate: string
    inventoryStatus: string
    scannedAt: string
    notScannableReasonId: string
    notScannableComment: string
    trunkrsNr: string
    shipmentState: string
    merchantName: string
    merchantId: number
    region: number
    subcoId: number
    notScannableReason: string
  }>
}

export interface ShipmentFeatures {
  requirePOD: boolean
  noSignature: boolean
  noNeighbourDelivery: boolean
  deliverInMailbox: boolean
}
export interface IProofOfDeliveryResult {
  enabled: boolean
  proof?: IProof
}
