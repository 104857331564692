import {
  InputMasked,
  InputNumber,
} from '@/app/base/components/reactHookComponents'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import toNumber from 'lodash/toNumber'
import React, { memo, useCallback } from 'react'
import { useFormContext } from 'react-hook-form'
import { Props as IMaskProps } from 'react-input-mask'
import { NumberFormatValues } from 'react-number-format'

interface IInputRangeFieldProps extends React.HTMLAttributes<HTMLInputElement> {
  label?: string
  names: [string, string]
  placeholders?: [string, string]
  numeric?: boolean
  maskProps?: IMaskProps
  onValueChange?: (values: NumberFormatValues) => void
}

const InputRangeField = ({
  label,
  names,
  placeholders,
  numeric,
  maskProps,
  className,
  onBlur,
  onChange,
  onValueChange,
  ...props
}: IInputRangeFieldProps) => {
  const { errors, setValue, getValues } = useFormContext()
  const fieldName1 = get(names, '[0]')
  const fieldName2 = get(names, '[1]')

  const fieldErrors = names
    .map(name => get(errors, `${name}.message`))
    .filter(message => message)

  const handleRangeValidation = useCallback(
    ev => {
      const min = getValues(fieldName1)
      const max = getValues(fieldName2)

      if (!isEmpty(max) && toNumber(max) < toNumber(min)) {
        setValue(fieldName1, max)
      }
      onBlur && onBlur(ev)
    },
    [fieldName1, fieldName2, onBlur]
  )

  return (
    <div
      className={clsx(
        'input-group input-range-field',
        className,
        !!fieldErrors.length && 'has-error'
      )}
    >
      {label && <label>{label}</label>}
      <div className="input-group">
        {fieldName1 && (
          <>
            {maskProps ? (
              <InputMasked
                {...props as any[]}
                name={fieldName1}
                placeholder={get(placeholders, '[0]')}
                maskProps={maskProps}
              />
            ) : (
              <InputNumber
                {...props as any[]}
                name={fieldName1}
                placeholder={get(placeholders, '[0]')}
                onBlur={handleRangeValidation}
                onChange={onChange}
                decimalSeparator={false}
              />
            )}
          </>
        )}
        <div className="input-group-prepend input-group-append">
          <div className="input-group-text">
            <FontAwesomeIcon icon="arrows-alt-h" />
          </div>
        </div>
        {fieldName2 && (
          <>
            {maskProps ? (
              <InputMasked
                {...props as any[]}
                name={fieldName2}
                placeholder={get(placeholders, '[1]')}
                maskProps={maskProps}
              />
            ) : (
              <InputNumber
                {...props as any[]}
                name={fieldName2}
                placeholder={get(placeholders, '[1]')}
                onBlur={handleRangeValidation}
                onChange={onChange}
                decimalSeparator={false}
              />
            )}
          </>
        )}
      </div>
      {!!fieldErrors.length && (
        <div className="invalid-tooltip">{get(fieldErrors, '[0]')}</div>
      )}
    </div>
  )
}

export default memo(InputRangeField)
