import * as React from 'react'

interface IProps {
  handleClick: (subcoId: number) => void
  isActive: boolean
  title: string
  badge: number
  subcoId: number
}

const SubcoButton: React.FunctionComponent<IProps> = ({
  handleClick,
  isActive,
  title,
  badge,
  subcoId,
}) => {
  const handleSelectSubco = React.useCallback(() => handleClick(subcoId), [
    subcoId,
  ])

  return (
    <button onClick={handleSelectSubco} className={isActive ? 'active' : ''}>
      {title}
      {badge > 0 && <div className="delays">{badge}</div>}
    </button>
  )
}

export default SubcoButton
