import { saveAs } from 'file-saver'

export const printPDF = (data: string) => {
  const printWin = window.open('')
  if (printWin) {
    printWin.document.write(
      `<iframe width='100%' height='100%' style='position:fixed;top:0;left:0' type="application/json" src='${data}'/>`
    )
  }
}

export const downloadPDF = (
  pdfArrayBuffer: Uint8Array,
  fileName: string = 'download.pdf'
) => console.log(saveAs(new File([pdfArrayBuffer], fileName), fileName))
