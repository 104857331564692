import './style.scss'

import NotificationPopup from '@/app/base/components/notificationPopup'
import barcodeIcon from '@/app/base/img/barcode.svg'
import {
  NoShipmentReturns,
  ShipmentReturnsTable,
  ShipmentReturnsTabs,
} from '@/app/features/shipmentReturns/components'
import { AppContext, DriverContext, ShipmentReturnsContext } from '@/contexts'
import { useTranslation } from '@/hooks'
import { printPDF } from '@/utils'
import { isLost, isMissing } from '@/utils/checkStatus'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { get, uniqBy } from 'lodash'
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import IconLoader from 'react-inlinesvg'

enum SCAN_RESULTS {
  ERROR = 'SCAN_ERROR',
  SUCCEED = 'SCAN_SUCCEED',
  SHIPMENT_NOT_IN_TODO_LIST = 'SHIPMENT_NOT_IN_TODO_LIST',
  SHIPMENT_DATE_MISSMATCH = 'SHIPMENT_DATE_MISSMATCH',
  BARCODE_NO_SHIPMENT_FOUND = 'BARCODE_NO_SHIPMENT_FOUND',
  SHIPMENT_ALREADY_AT_TRUNKRS = 'SHIPMENT_ALREADY_AT_TRUNKRS',
  SHIPMENT_MISSING = 'SHIPMENT_MISSING',
  SHIPMENT_LOST = 'SHIPMENT_LOST',
}

interface IScanResult {
  state: SCAN_RESULTS
  data: {
    trunkrsNr: string
    region: string
  }
}

const ShipmentReturns = () => {
  const { setLoading } = useContext(AppContext)
  const {
    shipmentReturns,
    getShipmentReturns,
    submitReturnedShipment,
    printShipmentReturns,
  } = useContext(ShipmentReturnsContext)

  const { fetchAllDrivers, allDrivers } = useContext(DriverContext)

  const [selectedRegion, setSelectedRegion] = useState()

  const [barcode, setBarcode] = useState('')
  const [scanResult, setScanResult] = useState<IScanResult | null>(null)

  const [t] = useTranslation(['SHIPMENT_RETURNS'])

  useEffect(() => {
    setLoading(true)
    fetchAllDrivers()
      .then(() => {
        getShipmentReturns()
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  const handleBarcodeEnter = useCallback(
    async (ev: React.KeyboardEvent<HTMLInputElement>) => {
      if (ev.keyCode !== 13) {
        return false
      }

      if (barcode.trim().length > 0) {
        setLoading(true)
        const result = await submitReturnedShipment(barcode)
        if (result) {
          setScanResult({
            state: result.error
              ? get(
                  SCAN_RESULTS,
                  result.error.message,
                  SCAN_RESULTS.BARCODE_NO_SHIPMENT_FOUND
                )
              : isMissing(get(result, 'data.latestState'))
              ? SCAN_RESULTS.SHIPMENT_MISSING
              : isLost(get(result, 'data.latestState'))
              ? SCAN_RESULTS.SHIPMENT_LOST
              : SCAN_RESULTS.SUCCEED,
            data: {
              region: get(result, 'data.region'),
              trunkrsNr: get(result, 'data.trunkrsNr'),
            },
          })

          getShipmentReturns()
        }

        setLoading(false)
        setBarcode('')
      }
    },
    [barcode]
  )

  const handlePrint = async () => {
    setLoading(true)
    const pdfData = await printShipmentReturns(selectedRegion)
    setLoading(false)
    if (pdfData) {
      printPDF(`data:application/pdf;base64,${pdfData}`)
    }
  }

  const handleBarcodeChange = useCallback(
    async (ev: React.ChangeEvent<HTMLInputElement>) => {
      setBarcode(ev.currentTarget.value)
      setScanResult(null)
    },
    [barcode]
  )

  const setSelectedRegionHandler = (regionName?: string) => () => {
    setSelectedRegion(regionName)
    setScanResult(null)
  }

  const handleTabClick = useCallback(
    (regionName?: string) => setSelectedRegionHandler(regionName),
    []
  )

  const selectedShipmentReturns = selectedRegion
    ? shipmentReturns.filter(
        ({ name: regionName }) => regionName === selectedRegion
      )
    : shipmentReturns
  const combinedShipmentReturns = selectedShipmentReturns.flatMap(
    ({ subcos }) => subcos.flatMap(({ shipments }) => shipments)
  )
  const subcos = uniqBy(combinedShipmentReturns, 'subco.name')
  const subcoName = get(subcos, '[0].subco.name', '')

  return (
    <div id="shipment-returns-container" className="container-fluid">
      <h1 className="h2 mt-4 text-left title">{t('RETURNS')}</h1>
      <div className="wrapper">
        <div id="barcode-scanner" className="row">
          <div className="col">
            <span className="barcode-icon">
              <IconLoader src={barcodeIcon} />
            </span>
            <input
              className="form-control mr-sm-2 barcode search"
              type="text"
              placeholder={t('SCAN_BARCODE')}
              value={barcode}
              onChange={handleBarcodeChange}
              onKeyDown={handleBarcodeEnter}
            />
          </div>
        </div>
        <NotificationPopup className="scan-notification-container">
          {!!scanResult &&
            ([
              SCAN_RESULTS.SUCCEED,
              SCAN_RESULTS.SHIPMENT_LOST,
              SCAN_RESULTS.SHIPMENT_MISSING,
            ].includes(scanResult.state) ? (
              <div className="notification success">
                <p className="text">
                  <FontAwesomeIcon icon="check-circle" className="icon" />
                  {t(scanResult.state, {
                    trunkrsNr: get(scanResult, 'data.trunkrsNr'),
                    region: get(scanResult, 'data.region'),
                  })}
                </p>
              </div>
            ) : (
              <div className="notification error">
                <p className="text">
                  <FontAwesomeIcon icon="exclamation-circle" className="icon" />
                  {t(scanResult.state)}
                </p>
              </div>
            ))}
        </NotificationPopup>
        <div className="shipment-returns row">
          <ShipmentReturnsTabs
            regionShipmentReturns={shipmentReturns}
            handleTabClick={handleTabClick}
            activeTab={selectedRegion}
          />
          {combinedShipmentReturns.length > 0 ? (
            <>
              <div className="heading col-12">
                <h2 className="subtitle bold text-left">
                  {selectedRegion &&
                    `${selectedRegion}${
                      subcos.length === 1 ? ` - ${subcoName}` : ''
                    }`}
                </h2>
                <button
                  className="btn btn-primary my-2 my-sm-0 button action box-shadow"
                  onClick={handlePrint}
                >
                  <FontAwesomeIcon icon="print" className="icon" />{' '}
                  {t('PRINT_RETURNS')}
                </button>
              </div>
              <ShipmentReturnsTable shipmentReturns={combinedShipmentReturns} />
            </>
          ) : (
            <NoShipmentReturns noSelectedRegion={!selectedRegion} />
          )}
        </div>
      </div>
    </div>
  )
}

export default memo(ShipmentReturns)
