import StatusLabel from '@/app/base/components/statusLabel'
import { SHIPMENT_STATE } from '@/constants'
import { useTranslation } from '@/hooks'
import { IShipmentHistoryDetails, IShipmentLog } from '@/models'
import { isMissortedState, isNonDeliveryState } from '@/utils/checkStatus'
import React, { useMemo } from 'react'
import './style.scss'


export interface IShipmentLogsProps {
  attempts: IShipmentHistoryDetails[]
  initialLogs?: IShipmentLog[]
}

const ShipmentLogs: React.SFC<IShipmentLogsProps> = ({ attempts }) => {
  const dailyRecordCards = useMemo(
    () =>
      [
        ...attempts.map((attempt, index) => ({
          ...attempt,
          attemptNum: index + 1,
        })),
      ]
        .reverse()
        .map(attempt => {
          return (
            <DailyRecordCard
              key={attempt.attemptNum}
              date={attempt.date}
              logs={attempt.shipmentLogs}
              attemptNum={attempt.attemptNum}
            />
          )
        }),
    [attempts]
  )

  return <>{dailyRecordCards}</>
}

export interface IDailyRecordCardProps {
  logs: IShipmentLog[]
  date: string
  attemptNum: number
}

const getClassName = (state: string) => {
  switch (state) {
    case SHIPMENT_STATE.SHIPMENT_DELIVERED:
    case SHIPMENT_STATE.SHIPMENT_DELIVERED_TO_NEIGHBOR:
      return 'delivered'
    default:
      return 'default'
  }
}

const DailyRecordCard: React.SFC<IDailyRecordCardProps> = ({
  logs,
  date,
  attemptNum,
}) => {
  const [t] = useTranslation(['SHIPMENT_STATES', 'SHIPMENT', 'REASON_CODES'])

  const logsComponent = useMemo(
    () =>
      logs.map(log => (
        <div className="log" key={log.logId}>
          <div className="data time">{log.time}</div>
          <div className="data">
            <div className="status-badge">
              <StatusLabel statusText={log.state} />
            </div>
            {log.reason && isNonDeliveryState(log.state) && (
              <div className="reason">{t(`REASON_CODES:${log.reason}`)}</div>
            )}
            {isMissortedState(log.state) ? (
              <div className="reason">{log.location}</div>
            ) : (
              ''
            )}
          </div>
          <span className="box-shadow">
            <div>{`${t('SHIPMENT:SET_BY')}:`}</div>
            <div>{log.setBy}</div>
          </span>
        </div>
      )),
    [logs]
  )

  return (
    <div id="daily-card" className="box-shadow">
      <div className="wrapper">
        <div className="header">
          <div className="date">{date}</div>
          <div className="label">
            {t(`SHIPMENT:ATTEMPT_NUM`, { number: attemptNum })}
          </div>
        </div>
      </div>
      <div className="wrapper content-list">{logsComponent}</div>
    </div>
  )
}

export default ShipmentLogs
