import React from 'react'

import styles from './styles.module.scss'

export interface EmptyLogProps {
  title: string
  description: string
  src?: string
}

export const EmptyLog: React.FC<EmptyLogProps> = ({
  title,
  description,
  src,
}) => (
  <div className={styles['empty-screen']}>
    <img alt="empty" src={src} />
    <div>{title}</div>
    <div>{description}</div>
  </div>
)

export default EmptyLog
