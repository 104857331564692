import './styles.scss'

import { clsx } from '@/utils'
import { uniqueId } from 'lodash'
import React, { memo, useMemo } from 'react'

interface ICheckBoxProps extends React.HTMLAttributes<HTMLInputElement> {
  onChange?: (...args: any[]) => void
  label?: string
  checked?: boolean
}

export const CheckBox = ({
  onChange,
  checked = false,
  label = '',
  className,
}: ICheckBoxProps) => {
  const id = useMemo(() => uniqueId('checkbox_'), [])
  return (
    <div className={clsx(['custom-control custom-checkbox'], className)}>
      <input
        type="checkbox"
        className={'custom-control-input'}
        id={id}
        onChange={onChange}
        checked={checked}
      />
      <label className={'custom-control-label'} htmlFor={id}>
        {label}
      </label>
    </div>
  )
}

export default memo(CheckBox)
