import clsx from 'clsx'
import format from 'date-fns/format'
import React from 'react'

import Weekdays from '../Weekdays'
import Days from './Days'
import NextDays from './NextDays'
import PreviousDays from './PreviousDays'
import styles from './styles.module.scss'

export interface CalendarProps {
  month: number
  year: number
  className?: string
  showWeekends?: boolean
  showOutsideDays?: boolean
  hideWeekdayNames?: boolean
  onDateClick?: (date: Date) => void
}

export const Calendar: React.FC<CalendarProps> = ({
  month,
  year,
  className,
  showOutsideDays = false,
  showWeekends = false,
  hideWeekdayNames = false,
  onDateClick,
}) => {
  return (
    <div className={clsx(styles.container, className)}>
      <div className={styles.calendar}>
        <div className={styles.month}>
          <span>{format(new Date(year, month), 'MMMM yyyy')}</span>
        </div>

        {hideWeekdayNames ? (
          <div className={styles.spacer} />
        ) : (
          <Weekdays showWeekends={showWeekends} />
        )}

        <div
          className={clsx(styles.dateGrid, showWeekends && styles.showWeekends)}
        >
          <PreviousDays
            month={month}
            year={year}
            showOutsideDays={showOutsideDays}
          />
          <Days month={month} year={year} onDateClick={onDateClick} />
          <NextDays
            month={month}
            year={year}
            showOutsideDays={showOutsideDays}
          />
        </div>
      </div>
    </div>
  )
}

export default React.memo(Calendar)
