import { SmartSearchContext } from '@/store/smartSearch'
import React, { useContext } from 'react'

import { SearchableItemKey } from '../../helpers/interfaces'
import DateSelect from '../DateSelect/'
import StatusSelect from '../StatusSelect'
import TextSearch from '../TextSearch'

export interface FilterProps {}

export const Filter: React.FC<FilterProps> = ({}) => {
  const { selectedFilter } = useContext(SmartSearchContext)

  switch (selectedFilter) {
    case SearchableItemKey.CREATION_DATE:
    case SearchableItemKey.CURRENT_DELIVERY_DATE:
      return <DateSelect />
    case SearchableItemKey.CURRENT_SHIPMENT_STATE:
      return <StatusSelect />
    default:
      return <TextSearch />
  }
}

export default Filter
