import './styles.scss'

import no_returns_logo from '@/app/base/img/no-returns-icon.svg'
import { useTranslation } from '@/hooks'
import React, { memo } from 'react'

export const NoShipmentReturns = memo(
  ({ noSelectedRegion }: { noSelectedRegion?: boolean }) => {
    const [t] = useTranslation('SHIPMENT_RETURNS')
    return (
      <div className="no-shipments-returns d-flex w-100 flex-column align-items-center justify-content-center">
        <img className="icon" src={no_returns_logo} />
        <label className="title bold">{t('NO_SHIPMENT_RETURNS_HEADER')}</label>
        <label className="subtitle">
          {t(
            noSelectedRegion
              ? 'NO_SHIPMENT_RETURNS_SUBTEXT'
              : 'NO_SHIPMENT_RETURNS_REGION_SUBTEXT'
          )}
        </label>
      </div>
    )
  }
)
