import { ICollation } from '@/models'
import { getCleanCollations } from '@/utils/getCleanCollations'
import * as React from 'react'
import { useMemo } from 'react'

interface IProps {
  collations: Array<ICollation>
}

const StopNumbers: React.FunctionComponent<IProps> = props => {
  const { collations } = props
  const cleanCollations = useMemo(() => getCleanCollations(collations), [
    collations,
  ])
  return (
    <div className="position-row">
      {cleanCollations.map((collation, index) => (
        <div className="position" key={collation.shipmentId}>
          {index + 1}
        </div>
      ))}
    </div>
  )
}

export default StopNumbers
