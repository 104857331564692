import { ShipmentsContext } from '@/contexts'
import { useShipmentActions } from '@/store/shipments/actions'
import { initialState } from '@/store/shipments/state'
import * as React from 'react'

export default ({ children }: { children: React.ReactNode }) => {
  const state = useShipmentActions(initialState)

  return (
    <ShipmentsContext.Provider value={state}>
      {children}
    </ShipmentsContext.Provider>
  )
}
