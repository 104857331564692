import no_results_logo from '@/app/base/img/no-results-icon.png'
import React from 'react'

export const NoTourYet: React.FC = () => {
  return (
    <div
      id="no-shipments-found"
      className="d-flex w-100 flex-column align-items-center justify-content-center"
    >
      <img className="icon" src={no_results_logo} />
      <label className="text">Searching for a tour ...</label>
    </div>
  )
}

export default NoTourYet
