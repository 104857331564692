import 'react-day-picker/lib/style.css'

import './style.scss'

import { useTranslation } from '@/hooks'
import moment, { Moment } from 'moment'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import DayPickerInput from 'react-day-picker/DayPickerInput'

import { OverlayComponent } from './OverlayComponent'

export const dateFormatForView = 'dddd DD-MM-YYYY HH:mm'

const OPTIONS = {
  ns: 'DATE_PICKER',
  returnObjects: true,
}

export interface IDateAndTimePickerProps {
  value: Moment
  placeholder: string
  handleConfirm: (value: Moment) => void
}

const DateAndTimePicker: React.FC<IDateAndTimePickerProps> = ({
  value,
  placeholder,
  handleConfirm,
}) => {
  const [selectedDay, setSelectedDay] = useState(value.format('YYYY-MM-DD'))
  const [datePickerKey, setDatePickerKey] = useState(0)
  const [, translate] = useTranslation('DATE_PICKER')
  const weekDays: string[] = translate.t('WEEKDAYS_SHORT', OPTIONS)
  const monthNames: string[] = translate.t('MONTHS', OPTIONS)

  const daypicker = useRef<DayPickerInput>(null)

  useEffect(() => {
    setSelectedDay(value.format('YYYY-MM-DD'))
  }, [value])

  const handleDateFormat = useCallback((day: Date) => {
    const timeInInput = getCurrentValueFromInput()
    const selectedValue = moment(day)

    if (timeInInput) {
      selectedValue.hour(Number(timeInInput.format('HH')))
      selectedValue.minute(Number(timeInInput.format('mm')))
    }
    setSelectedDay(selectedValue.toString())
    return selectedValue.format(dateFormatForView)
  }, [])

  const handleClose = () => {
    setDatePickerKey(prevState => prevState + 1)
    setSelectedDay(value.format('YYYY-MM-DD'))
    if (daypicker && daypicker.current) {
      daypicker.current.hideDayPicker()
    }
  }

  const getCurrentValueFromInput = () => {
    if (daypicker && daypicker.current) {
      return moment(daypicker.current.getInput().value, dateFormatForView)
    }
    return null
  }

  const overlayComponent = useCallback(
    (props: any) => (
      <OverlayComponent
        {...props}
        confirm={handleConfirm}
        closeOverlay={handleClose}
        selectedDay={new Date(selectedDay)}
      />
    ),
    [handleClose, selectedDay, handleConfirm]
  )

  return (
    <DayPickerInput
      key={datePickerKey}
      ref={daypicker}
      inputProps={{
        className: 'form-control',
        readOnly: true,
      }}
      dayPickerProps={{
        months: monthNames,
        weekdaysShort: weekDays,
        selectedDays: new Date(selectedDay),
        showOutsideDays: true,
        modifiers: {
          today: new Date(),
          selected: new Date(selectedDay),
        },
        modifiersStyles: {
          today: { color: '#00d763' },
          selected: { color: 'white' },
        },
      }}
      value={value.format(dateFormatForView)}
      keepFocus={false}
      hideOnDayClick={false}
      placeholder={placeholder}
      formatDate={handleDateFormat}
      format="LL"
      overlayComponent={overlayComponent}
    />
  )
}

export default DateAndTimePicker
