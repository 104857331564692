import * as React from 'react'
import { useEffect, useState, useContext } from 'react'
import moment from 'moment'
import { first, last } from 'lodash'

import { ModalForm } from '@/app/base/components'

import './style.scss'

import { ShipmentsContext } from '@/contexts'
import { IAddressHistory } from '@/models'
import { useTranslation } from '@/hooks'

export const AddressHistory = (props: {
  handleClose?: (ev?: React.SyntheticEvent<any>) => void
}) => {
  const { addressHistory } = useContext(ShipmentsContext)
  const [originalAddress, setOriginalAddress] = useState<IAddressHistory>()
  const [currentAddress, setCurrentAddress] = useState<IAddressHistory>()

  useEffect(() => {
    if (addressHistory.length > 1) {
      setOriginalAddress(first(addressHistory))
      setCurrentAddress(last(addressHistory))
    }
  }, [addressHistory])

  const [t] = useTranslation(['ADDRESS_HISTORY'])

  return (
    <ModalForm
      show={true}
      title={t('ADDRESS_HISTORY')}
      handleClose={props.handleClose}
    >
      <div id="address-history">
        {originalAddress && (
          <div id="original-address">
            <p className="text font-weight-bold">{t('ORIGINAL_ADDRESS')}</p>
            <div className="address-details">
              <p className="street text">
                {originalAddress.location.getAddress} <br />
                {`${originalAddress.location.getPostalCode} ${
                  originalAddress.location.getCity
                } ${originalAddress.location.getCountry}`}
              </p>
              {/*<p className="region text">Region: [region]</p>*/}
            </div>
          </div>
        )}
        {currentAddress && (
          <div id="new-address">
            <div className="d-flex justify-content-between align-items-center">
              <p className="text font-weight-bold">{t('CURRENT_ADDRESS')}</p>
              <p className="timestamp text">
                {t('CHANGE_MADE_ON_TIMESTAMP', {
                  timestamp: moment(currentAddress.timeStamp).format(
                    'DD-MM-YYYY HH:mm'
                  ),
                })}
              </p>
            </div>
            <div className="address-details">
              <p className="street text">
                {currentAddress.location.getAddress} <br />
                {`${currentAddress.location.getPostalCode} ${
                  currentAddress.location.getCity
                } ${currentAddress.location.getCountry}`}
              </p>
              {/*<p className="region text">Region: [region]</p>*/}
            </div>
          </div>
        )}
      </div>
    </ModalForm>
  )
}
