import './style.scss'

import { Tooltip } from '@/app/base/components'
import SidebarMenuIcon from '@/app/base/components/icons/sidebar-menu-icon'
import routes from '@/app/routes'
import { AppContext, AuthContext } from '@/contexts'
import { useTranslation } from '@/hooks'
import React, { memo, useCallback, useContext, useEffect } from 'react'

import NotificationMenuLink from './notificationMenuLink'

const UserMenu = () => {
  const {
    checkAccess,
    requestLogout,
    identity: {
      profile: { name, picture },
    },
  } = useContext(AuthContext)

  const { setLoading, sidebarCollapsed } = useContext(AppContext)

  useEffect(() => {
    checkAccess()
  }, [])

  const handleLogout = useCallback(
    async (ev: React.MouseEvent<HTMLAnchorElement>) => {
      ev.preventDefault()
      setLoading(true)
      await requestLogout()
      setLoading(false)
    },
    []
  )

  const isNotificationMenuEnabled = !!routes.find(
    ({ label, disabled }) => label === 'NOTIFICATIONS' && !disabled
  )

  const userInitials = name
    .split(' ')
    .map(str => str.substr(0, 1))
    .join('')
    .substr(0, 2)

  const [t] = useTranslation('USER_MENU')

  return (
    <div className="user-menu">
      {isNotificationMenuEnabled && <NotificationMenuLink />}
      <div className="d-flex align-items-center user-profile-container">
        <span>
          <div className="user-initials">{userInitials}</div>
          <div className="user">
            <p className="text">{name}</p>
            <p className="sub-text">{t('CUSTOMER_SERVICE')}</p>
          </div>
        </span>
      </div>
      <a className="nav-link menu-link sign-out" onClick={handleLogout}>
        {sidebarCollapsed ? (
          <Tooltip text={t('SIGN_OUT')} className="menu-tooltip">
            <SidebarMenuIcon icon="log-off" />
          </Tooltip>
        ) : (
          <SidebarMenuIcon icon="log-off" />
        )}
        <span className="menu-text">{t('SIGN_OUT')}</span>
      </a>
    </div>
  )
}

export default memo(UserMenu)
