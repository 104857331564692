import React, {
  FormEvent,
  ReactElement,
  memo,
  useCallback,
  useEffect,
} from 'react'
import {
  FormProvider,
  UseFormMethods,
  UseFormOptions,
  useForm,
} from 'react-hook-form'
import { FieldErrors, UnpackNestedValue } from 'react-hook-form/dist/types/form'

interface IFormProps<T> extends UseFormOptions<T> {
  onSubmit: (formValues: UnpackNestedValue<T>) => void
  onSubmitError?: (errors: FieldErrors<T>) => void
  onLoad?: (formMethods: UseFormMethods<T>) => void
  children: ReactElement | ReactElement[]
  className?: string
}

function Form<T>({
  children,
  onSubmit,
  onSubmitError,
  onLoad,
  className,
  ...formOptions
}: IFormProps<T>) {
  const methods = useForm<T>(formOptions)

  useEffect(() => {
    onLoad && onLoad(methods)
  }, [onLoad, methods])

  return (
    <FormProvider {...methods}>
      <form
        className={className}
        noValidate
        onSubmit={methods.handleSubmit(onSubmit, onSubmitError)}
      >
        {children}
      </form>
    </FormProvider>
  )
}

export default memo(Form) as typeof Form
