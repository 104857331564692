import mapStyles from '@/constants/mapStyles.json'
import { ICollation } from '@/models'
import { getCleanCollations } from '@/utils/getCleanCollations'
import { getIconForShipmentState } from '@/utils/getIconForShipmentState'
import { decode } from 'google-polyline'
import get from 'lodash/get'
import last from 'lodash/last'
import * as React from 'react'
import {
  GoogleMap,
  Polyline,
  WithGoogleMapProps,
  withGoogleMap,
  withScriptjs,
} from 'react-google-maps'
import { MarkerWithLabel } from 'react-google-maps/lib/components/addons/MarkerWithLabel'

import home from '../../../base/img/logo-dark.svg'

interface IProps {
  lat: number
  lng: number
  zoom: number
  isMarkerShown: boolean
  collations: Array<ICollation>
  activeCollation: number
  setActiveCollation: (id: number) => void
  color: string
}

declare const google: any

const Map: React.FunctionComponent<IProps & WithGoogleMapProps> = ({
  lat = 51.990276,
  lng = 5.103033,
  zoom = 8,
  collations,
  setActiveCollation,
  activeCollation,
  color,
}) => {
  const collation = collations.find(
    (collation: ICollation) => collation.id === activeCollation
  )
  const activeLat = get(collation, 'recipient.latitude', lat)
  const activeLng = get(collation, 'recipient.longitude', lng)
  const cleanCollations = getCleanCollations(collations)
  const handleMarkerClick = (id: number) => () => setActiveCollation(id)
  const generatePolylines = React.useMemo(
    () =>
      collations.map((collation: ICollation) => (
        <Polyline
          key={collation.id}
          path={decode(collation.polyline).map(
            (item: any) =>
              item && {
                lat: item[0],
                lng: item[1],
              }
          )}
          options={{
            strokeColor: color,
            strokeOpacity: 1,
          }}
        />
      )),
    [collations]
  )
  const getCoordinates = React.useCallback(
    (collation: ICollation) => {
      const lastPolyline: Array<number> | undefined = last(
        decode(collation.polyline)
      )
      const lat = get(
        collation,
        'recipient.latitude',
        lastPolyline && lastPolyline[0]
      )
      const lng = get(
        collation,
        'recipient.longitude',
        lastPolyline && lastPolyline[1]
      )
      return {
        lat,
        lng,
      }
    },
    [collations]
  )
  const generateMarkers = React.useMemo(
    () =>
      collations.map(
        (collation: ICollation, index: number) =>
          index !== 0 && (
            <MarkerWithLabel
              key={collation.id}
              position={{
                lat: get(
                  collation,
                  'recipient.latitude',
                  getCoordinates(collation).lat
                ),
                lng: get(
                  collation,
                  'recipient.longitude',
                  getCoordinates(collation).lng
                ),
              }}
              defaultAnimation={0}
              title={
                index < collations.length - 1
                  ? `${collation.recipient.address} ${
                      collation.recipient.postalCode
                    } ${collation.recipient.city}`
                  : 'Trunkrs'
              }
              onClick={handleMarkerClick(collation.id)}
              icon={{
                url: getIconForShipmentState(collation.state),
                scaledSize: {
                  width: 40,
                  height: 40,
                },
              }}
              labelAnchor={{
                lat:
                  collation.recipient.latitude ||
                  decode(collation.polyline)[0][0],
                lng:
                  collation.recipient.longitude ||
                  decode(collation.polyline)[0][1],
              }}
            >
              <div className="position">
                {index < collations.length - 1 ? (
                  collation.position
                ) : (
                  <img src={home} alt="Trunkrs" />
                )}
              </div>
            </MarkerWithLabel>
          )
      ),
    [collations]
  )
  const handleMapMounted = React.useCallback(
    (map: GoogleMap) => {
      if (!map) return
      if (collation) {
        map.panTo({
          lat: collation.recipient.latitude,
          lng: collation.recipient.longitude,
        })
      } else {
        fitMap(map)
      }
    },
    [collation]
  )
  const fitMap = React.useMemo(
    () => (map: GoogleMap) => {
      const bounds = new google.maps.LatLngBounds()
      collations.forEach(({ polyline }: ICollation) => {
        const cleanPolyline = decode(polyline).filter((item: any) => !!item)
        const latitudes = cleanPolyline.map((item: any) => item[0])
        const longitudes = cleanPolyline.map((item: any) => item[1])
        if (latitudes.length && longitudes.length) {
          const maxLatitude = Math.max(...latitudes)
          const maxLongitude = Math.max(...longitudes)
          const minLatitude = Math.min(...latitudes)
          const minLongitude = Math.min(...longitudes)

          const maxCollationPosition = new google.maps.LatLng(
            maxLatitude,
            maxLongitude
          )
          bounds.extend(maxCollationPosition)
          const minCollationPosition = new google.maps.LatLng(
            minLatitude,
            minLongitude
          )
          bounds.extend(minCollationPosition)
        }
      })
      map.fitBounds(bounds)
    },
    [collations]
  )
  return (
    <GoogleMap
      defaultZoom={zoom}
      defaultCenter={{ lat: activeLat, lng: activeLng }}
      options={{
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        scrollwheel: false,
        styles: mapStyles as any,
      }}
      ref={handleMapMounted}
    >
      {generatePolylines}
      {generateMarkers}
    </GoogleMap>
  )
}

export default withScriptjs(withGoogleMap(Map))
