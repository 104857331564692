import {
  IMerchantOverviewState,
  MerchantAccount,
} from '@/store/merchantOverview/IMerchantOverviewState'
import React from 'react'

import { ICreateMerchantParams } from '../client/actions'

const MerchantOverviewContext = React.createContext(({
  state: {
    isInitialized: false,
    fetchingMerchantInfo: false,
    isEditActive: false,
    forecasts: [],
    fetchingForecasts: false,
  },
  actions: {
    initialize: () => {},
    addMerchantUserAccount: (account: MerchantAccount) => {},
    fetchMerchantInfoById: (merchantId: number) => {},
    setState: (newState: any) => {},
    onUpdateMerchantInvoicing: (
      merchantId: number,
      pricingTiers: Pick<ICreateMerchantParams, 'pricing'>
    ) => {},
    onUpdateMerchantData: (
      merchantId: number,
      data: ICreateMerchantParams
    ) => {},
    fetchForecasts: (merchaintId: number) => {},
    updateForecasts: (
      merchantId: number,
      payload: { date: string; forecast: number }[]
    ) => {},
  },
} as unknown) as IMerchantOverviewState)

export default MerchantOverviewContext
