import { Repick } from '@/@types/repick'
import Icon from '@/app/base/components/.v2/Icon'
import clsx from 'clsx'
import defaultTo from 'lodash/defaultTo'
import get from 'lodash/get'
import React, {
  HTMLAttributes,
  LiHTMLAttributes,
  memo,
  useCallback,
  useMemo,
  useState,
} from 'react'

import ClickAway from '../../../../../utils/ClickAway'
import Tooltip from '../Tooltip'
import styles from './styles.module.scss'

export const DropdownMenuItem = memo(
  (
    props: React.DetailedHTMLProps<
      LiHTMLAttributes<HTMLLIElement>,
      HTMLLIElement
    > & { value: string | number }
  ) => <li {...props} />
)

const DropdownMenu = ({
  onChange,
  value,
  className,
  label,
  placeholder,
  disabled,
  children,
  ...props
}: Repick<React.DetailedHTMLProps<HTMLAttributes<any>, any>, 'onChange'> & {
  onChange: (value?: any) => void
  value?: string | number
  label?: string
  disabled?: boolean
}) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false)

  const contents = useMemo(() => React.Children.toArray(children), [children])
  const selectedNode = useMemo(
    () =>
      get(
        contents.find(child => get(child, 'props.value') === value),
        'props.children'
      ),
    [value, contents]
  )

  const showDropdown = useCallback(() => setIsDropdownVisible(true), [])
  const hideDropdown = useCallback(() => setIsDropdownVisible(false), [])

  const handleChange = useCallback(
    keyValue => () => {
      hideDropdown()
      onChange(keyValue)
    },
    [onChange]
  )

  const handleClear = useCallback(() => {
    hideDropdown()
    onChange()
  }, [onChange])

  return (
    <div
      className={clsx(className, styles.container, disabled && styles.disabled)}
      {...props}
    >
      <div className={clsx(styles.label, isDropdownVisible && styles.active)}>
        {defaultTo(value, '').toString().length > 0 && (
          <div className={styles.clearButton} onClick={handleClear}>
            <Tooltip text="Clear" placement="top" eventTriggers={['hover']}>
              <Icon name="close" />
            </Tooltip>
          </div>
        )}
        <div onClick={showDropdown}>
          {label && <span>{`${label}: `}</span>}
          {selectedNode ? selectedNode : <span>{placeholder}</span>}
          <Icon name="caret-down" />
        </div>
      </div>
      <ClickAway callback={hideDropdown}>
        <ul
          className={clsx(
            styles.dropdownContainer,
            isDropdownVisible && styles.visible
          )}
        >
          {useMemo(
            () =>
              contents.map((child: any, i) => {
                const nodeValue = get(child, 'props.value')
                return React.cloneElement(child, {
                  ...get(child, 'props'),
                  key: `dropdown_menu_${nodeValue}_${i}`,
                  className: clsx(
                    get(child, 'props.className'),
                    nodeValue == value && styles.selected
                  ),
                  onClick: handleChange(nodeValue),
                })
              }),
            [value, contents]
          )}
        </ul>
      </ClickAway>
    </div>
  )
}

export default memo(DropdownMenu)
