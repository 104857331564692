import './styles.scss'

import {
  SelectField,
  TextField,
} from '@/app/base/components/reactHookComponents'
import { INPUT_MASK_NL_POSTAL_CODE } from '@/constants'
import { ClientContext } from '@/contexts'
import { useTranslation } from '@/hooks'
import Grid from '@material-ui/core/Grid/Grid'
import MenuItem from '@material-ui/core/MenuItem/MenuItem'
import get from 'lodash/get'
import React, { memo, useContext, useMemo } from 'react'

import resource from '../../resource.json'

const MerchantWebshopForm = () => {
  const { accountManagers, productCategories } = useContext(ClientContext)
  const countries = get(resource, 'Countries', []) as Array<{
    value: string
    description: string
  }>

  const [t] = useTranslation('MERCHANT_CREATION')

  return (
    <div id="merchant-webshop-info">
      <div className="header-wrapper">
        <div>
          <span className="header-text">{t('WEBSHOP_INFORMATION_HEADER')}</span>
          <span className="header-subtext">
            {t('WEBSHOP_INFORMATION_SUB_HEADER')}
          </span>
        </div>
        <div className="icon" />
      </div>
      <div className="content">
        <Grid container>
          <Grid item xs={12}>
            <TextField
              id="webshop-name"
              placeholder={t('WEBSHOP_NAME_PLACEHOLDER')}
              label={t('WEBSHOP_NAME')}
              name="merchant.name"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="webshop-address"
              placeholder={t('ADDRESS_PLACEHOLDER')}
              label={t('ADDRESS')}
              name="merchant.location.address"
            />
          </Grid>
          <Grid container item xs={12} spacing={1}>
            <Grid item xs={3}>
              <TextField
                id="webshop-zipcode"
                label={t('ZIPCODE')}
                placeholder={t('ZIPCODE_PLACEHOLDER')}
                name="merchant.location.postalCode"
                maskProps={{ mask: INPUT_MASK_NL_POSTAL_CODE }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="webshop-city"
                label={t('CITY')}
                placeholder={t('CITY_PLACEHOLDER')}
                name="merchant.location.city"
              />
            </Grid>
            <Grid item xs={5}>
              <SelectField
                label={t('COUNTRY')}
                id="webshop-country"
                name="merchant.location.country"
              >
                {useMemo(
                  () =>
                    countries.map(({ value, description }) => (
                      <MenuItem value={value}>{description}</MenuItem>
                    )),
                  []
                )}
              </SelectField>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <SelectField
              label={t('PRODUCT_CATEGORY')}
              id="product-category"
              name="merchant.productCategoryId"
            >
              {useMemo(
                () =>
                  productCategories &&
                  productCategories.map(({ name: productCategory, id }) => (
                    <MenuItem value={id}>{productCategory}</MenuItem>
                  )),
                [productCategories]
              )}
            </SelectField>
          </Grid>
          <Grid item xs={12}>
            <SelectField
              required
              label={t('ACCOUNT_MANAGER')}
              id="account-manager"
              name="merchant.accountManagerId"
            >
              <MenuItem value="" hidden>
                {t('ACCOUNT_MANAGER_PLACEHOLDER')}
              </MenuItem>
              {useMemo(
                () =>
                  accountManagers &&
                  accountManagers.map(({ name: accountManager, id }) => (
                    <MenuItem value={id}>{accountManager}</MenuItem>
                  )),
                [accountManagers]
              )}
            </SelectField>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default memo(MerchantWebshopForm)
