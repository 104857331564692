import './components/MerchantList/styles.scss'

import { MERCHANT_INFO_SECTIONS } from '@/app/features/clientProfiles/MerchantOverview/components/MerchantInfomationModal/helper'
import MerchantList from '@/app/features/clientProfiles/MerchantOverview/components/MerchantList'
import { ClientContext, DebtorContext, RouterContext } from '@/contexts'
import { useTranslation } from '@/hooks'
import MerchantOverviewContext from '@/store/merchantOverview'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '@material-ui/core/Button'
import React, { useCallback, useContext, useEffect, useState } from 'react'

import MerchantInformationModal from './components/MerchantInfomationModal'

interface MerchantAccountFields {
  emailAddress: string
  phoneNumber: string
  merchantId: number
  countryCode?: string
}

const MerchantOverview: React.FC = () => {
  const {
    state: {
      isInitialized,
      merchantList,
      fetchingMerchantInfo,
      fetchingForecasts,
    },
    actions: {
      initialize,
      addMerchantUserAccount,
      fetchMerchantInfoById,
      fetchForecasts,
      setState,
    },
  } = useContext(MerchantOverviewContext)

  const {
    isLoading,
    isSaving,
    isLoadingDetails,
    debtors,
    debtorNumber,
    loadDebtors,
    loadDebtorDetails,
    setDebtorNumber,
    saveDebtorNumber,
  } = useContext(DebtorContext)

  const { fetchMerchantFormResource } = useContext(ClientContext)

  const [accountCreationResponse, setAccountCreationResponse] = useState<
    { statusCode: number; message: string } | undefined
  >(undefined)

  const [t] = useTranslation('MERCHANT_OVERVIEW')
  const [selectedMerchantId, setSelectedMerchantId] = useState<number | null>(
    null
  )
  const { history, getParams } = useContext(RouterContext)
  const htmlElement = React.useMemo(() => document.querySelector('body'), [])

  const { id: merchantId } = getParams<{ id: number }>()

  const updateState = (newState: any) => {
    setState({
      state: {
        ...newState,
      },
    } as any)
  }

  useEffect(() => {
    if (/^\d+$/.test(merchantId) && isInitialized) {
      handleSelectMerchant(merchantId)
    }
  }, [merchantId, isInitialized])

  useEffect(() => {
    if (!isInitialized) {
      initialize()
      fetchMerchantFormResource()
    }

    if (!debtors.length) {
      loadDebtors()
    }
  }, [isInitialized, initialize])

  const handleSelectMerchant = useCallback(
    (merchantId: number) => {
      if (htmlElement) htmlElement.classList.add('overflow-hidden')
      setSelectedMerchantId(merchantId)
      updateState({ selectedMerchantId: merchantId })

      fetchMerchantInfoById(merchantId)
      fetchForecasts(merchantId)
      loadDebtorDetails(merchantId)
    },
    [updateState]
  )

  const handleCloseModal = useCallback(() => {
    if (htmlElement) htmlElement.classList.remove('overflow-hidden')
    updateState({
      isEditActive: false,
      sectionActive: MERCHANT_INFO_SECTIONS.WEBSHOP,
      updateMerchantErr: undefined,
    })
    setAccountCreationResponse(undefined)
    setSelectedMerchantId(null)
  }, [updateState])

  const handleCreateMerchantUser = useCallback(
    (account: MerchantAccountFields) => {
      setAccountCreationResponse(undefined)
      account.merchantId = Number(selectedMerchantId)
      addMerchantUserAccount({ ...account })
        .then(() =>
          setAccountCreationResponse({
            statusCode: 200,
            message: t('CREATE_ACCOUNT_SUCCESS_MSG'),
          })
        )
        .catch(error => {
          const {
            response: { data },
          } = error
          setAccountCreationResponse(data)
        })
    },
    [selectedMerchantId, setAccountCreationResponse]
  )

  const handleSaveDebtor = React.useCallback(() => {
    if (!selectedMerchantId) return
    saveDebtorNumber(selectedMerchantId)
  }, [saveDebtorNumber, selectedMerchantId])

  if (!isInitialized || isLoading) {
    return null
  }

  return (
    <>
      <div className="header-wrapper">
        <h1 className="h2 text-left pageTitle">{t('MERCHANT_LIST')}</h1>
        <div>
          <Button
            startIcon={<FontAwesomeIcon icon="plus" />}
            onClick={() => history.push(`/profile/add`)}
            className="btn button action create-user box-shadow add-client"
            classes={{
              startIcon: 'create-user-startIcon',
            }}
          >
            {t('ADD_CLIENT')}
          </Button>
        </div>
      </div>

      <MerchantList
        merchants={merchantList}
        onSelectMerchant={handleSelectMerchant}
      />

      <MerchantInformationModal
        isLoading={
          fetchingMerchantInfo || fetchingForecasts || isLoadingDetails
        }
        isSaving={isSaving}
        open={!!selectedMerchantId}
        debtorNumber={debtorNumber}
        debtors={debtors}
        accountCreationResponse={accountCreationResponse}
        onClose={handleCloseModal}
        onDebtorSelected={setDebtorNumber}
        onSaveDebtor={handleSaveDebtor}
        handleCreateMerchantUser={handleCreateMerchantUser}
      />
    </>
  )
}

export default MerchantOverview
