import useDateHighlight from '@/hooks/useDateHighlight'
import ButtonBase from '@material-ui/core/ButtonBase'
import clsx from 'clsx'
import React, { useCallback } from 'react'

import styles from './styles.module.scss'

const d = new Date()

export interface DayProps {
  children: number
  date: Date
  show?: boolean
  calendarMonth?: Date
  onClick?: (value: Date) => void
  outsideDayType?: 'prev' | 'next'
}

export const Day: React.FC<DayProps> = ({
  outsideDayType = false,
  calendarMonth,
  show = true,
  children,
  onClick,
  date,
}) => {
  const { isHighlighted, isStart, isEnd } = useDateHighlight(
    date,
    outsideDayType,
    calendarMonth
  )

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick(date)
    }
  }, [onClick, date])

  const theDate = React.useMemo(
    () => (
      <span
        className={clsx(
          (isStart || isEnd) && !outsideDayType && styles.selectedDay
        )}
        color={outsideDayType ? 'grey' : 'indigo'}
      >
        {children}
      </span>
    ),
    [styles, children, outsideDayType, isStart, isEnd]
  )

  return (
    <ButtonBase
      disabled={!!outsideDayType}
      className={clsx(
        styles.root,
        !isHighlighted &&
          !outsideDayType &&
          !isStart &&
          !isEnd &&
          styles.hovering,
        outsideDayType && styles.outsideDay,
        isHighlighted && styles.selected,
        isStart && styles.start,
        isEnd && styles.end,
        !show && styles.hidden
      )}
      onClick={handleClick}
    >
      {theDate}
    </ButtonBase>
  )
}

export default React.memo(Day)
