import { useTranslation } from '@/hooks'
import { IDriver } from '@/models'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'

interface IProps {
  tourId: number
  driver: IDriver
  delayed: boolean
  showModal: () => void
}

const DriverInfo: React.FunctionComponent<IProps> = props => {
  const { tourId, driver, delayed, showModal } = props
  const [t] = useTranslation('TOUR_OVERVIEW')
  return (
    <div className="info-row">
      <div className="driver-info">
        <h1 className={delayed ? 'delayed' : ''}>
          Tour - {tourId} {delayed ? `(${t('DELAYED')})` : ''}
        </h1>
        <h2>{driver.name}</h2>
        {driver.phoneNumber && (
          <a className="driver-phone-number" href={`tel:${driver.phoneNumber}`}>
            <FontAwesomeIcon icon="phone" />
            {driver.phoneNumber}
          </a>
        )}
      </div>
      <div className="driver-position">
        <span>&nbsp;</span>
        <button className="box advanced-view-btn" onClick={showModal}>
          {t('ADVANCED_VIEW')}
        </button>
      </div>
      {!!driver.latitude && (
        <div className="driver-position">
          <span>{t('LOCATION')}</span>
          <div className="box">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={driver.latestPosition}
            >
              {t('SHOW_ON_MAPS')}
            </a>
          </div>
        </div>
      )}
      <div className="driver-position">
        <span>{t('SPEED')}</span>
        <div className="box">{driver.speed}km/h</div>
      </div>
    </div>
  )
}

export default DriverInfo
