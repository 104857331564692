import { ShipmentReturnsContext } from '@/contexts'
import * as React from 'react'

import { useShipmentReturnsActions } from './actions'
import { initialState } from './state'

export default ({ children }: { children: React.ReactNode }) => {
  const state = useShipmentReturnsActions(initialState)
  return (
    <ShipmentReturnsContext.Provider value={state}>
      {children}
    </ShipmentReturnsContext.Provider>
  )
}
