import './styles.scss'

import { Loader } from '@/app/base/components'
import no_results_logo from '@/app/base/img/no-results-icon.png'
import {
  CollationContext,
  DriverContext,
  SubcoContext,
  TourContext,
} from '@/contexts'
import { useTranslation } from '@/hooks'
import {
  ICollation,
  IDriver,
  IFetchSubcoResponse,
  ISubco,
  ITour,
} from '@/models'
import { clsx } from '@/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import first from 'lodash/first'
import * as React from 'react'
import { useContext, useEffect, useLayoutEffect, useState } from 'react'

import DriverButton from './components/DriverButton'
import QuickTourContainer from './components/QuickTourContainer'
import SubcoButton from './components/SubcoButton'

const TourOverview: React.FunctionComponent = () => {
  const [initializing, setInitializing] = useState<boolean>(true)
  const [loadingShipments, setLoadingShipments] = useState<boolean | null>(null)
  const [query, setQuery] = useState<string>('')
  const [searchMode, setSearchMode] = useState<boolean>(false)
  const [queriedDrivers, setQueriedDrivers] = useState<IDriver[]>([])
  const [t] = useTranslation('TOUR_OVERVIEW')
  const {
    subcos,
    fetchAllSubco,
    addSubco,
    fetchSubco,
    updateSubco,
    isLoading,
    activeSubcoId,
    setActiveSubcoId,
  } = useContext(SubcoContext)
  const { addTour, fetchTour, updateTour } = useContext(TourContext)
  const {
    addDriver,
    updateDriver,
    fetchImage,
    drivers,
    updateActiveDriverId,
    activeDriverId,
  } = useContext(DriverContext)
  const {
    mapCollations,
    addCollation,
    collations,
    lastFetchedShipmentStates,
    fetchShipmentStates,
  } = useContext(CollationContext)

  const handleSelectDriver = React.useCallback(
    (driver: IDriver) => () => {
      updateActiveDriverId(driver.id)
      setActiveSubcoId(driver.subCoId)
      setQuery('')
      setQueriedDrivers([])
    },
    []
  )
  const handleQueryChange = React.useCallback((e: any) => {
    const currentQuery = e.target.value
    setQuery(currentQuery)
    if (currentQuery.length > 0) {
      setSearchMode(true)
    } else {
      setSearchMode(true)
    }
    const filteredDrivers = drivers.filter((driver: IDriver) => {
      const lower = driver.name.toLowerCase()
      const hit = lower.indexOf(currentQuery.toLowerCase())
      return hit > -1
    })
    setQueriedDrivers(filteredDrivers)
  }, [])
  const resetSearch = React.useCallback(() => {
    setQuery('')
    setQueriedDrivers([])
  }, [])

  const init = React.useCallback(async () => {
    setLoadingShipments(true)

    const subcos = await fetchAllSubco()
    subcos.map((subco: ISubco) => addSubco(subco))

    const firstSubco = first(subcos)
    firstSubco && setActiveSubcoId(firstSubco.id)

    const allSubcos = await Promise.all(
      subcos.map(async ({ id }) => fetchSubco(id))
    )
    allSubcos.map(
      (item: IFetchSubcoResponse | null) =>
        item && item.subco && updateSubco(item.subco)
    )

    const allTours = allSubcos.reduce(
      (acc: Array<ITour>, item) => (item ? [...acc, ...item.tours] : acc),
      []
    )
    allTours.map(tour => addTour(tour))

    const allDrivers = allSubcos.reduce(
      (acc: Array<IDriver>, item) => (item ? [...acc, ...item.drivers] : acc),
      []
    )
    allDrivers.map((driver: IDriver) => addDriver(driver))

    const allTourCollations = await Promise.all(
      allTours.map(async tour => {
        const driver = allDrivers.find(({ tourId }) => tourId === tour.id)
        const getTourResult = driver && (await getTour(tour.id, driver))
        if (driver && getTourResult) {
          updateTour({
            ...tour,
            ...getTourResult.tour,
          })
          updateDriver(getTourResult.driver)
          await fetchImage(getTourResult.driver)
          return getTourResult.collations
        }
      })
    )

    const allCollations = allTourCollations.reduce(
      (acc: Array<ICollation>, item) => (item ? [...acc, ...item] : acc),
      []
    )
    allCollations.map(collation => addCollation(collation))

    setLoadingShipments(false)
  }, [])

  const getTour = React.useCallback(async (tourId: number, driver: IDriver) => {
    const getTourResult = await fetchTour(tourId)
    if (getTourResult) {
      const updatedDriver = {
        ...driver,
        emailAddress: getTourResult.driverEmail,
        latitude: getTourResult.driverLatitude,
        longitude: getTourResult.driverLongitude,
        name: getTourResult.driverName,
        phoneNumber: getTourResult.driverPhoneNr,
        speed: getTourResult.driverSpeed,
        tourId: getTourResult.tourId,
      }
      const collations = mapCollations(getTourResult.collations)
      const tour = {
        id: tourId,
        startTime: getTourResult.startTime,
        endTime: getTourResult.endTime,
      }

      return {
        driver: updatedDriver,
        collations,
        tour,
      }
    }
  }, [])

  const handleSubcoClick = React.useCallback((subcoId: number) => {
    updateActiveDriverId(null)
    setActiveSubcoId(subcoId)
  }, [])

  useLayoutEffect(() => setInitializing(false), [setInitializing])

  useEffect(() => {
    init().finally(() => console.log('Done.'))
  }, [])

  useEffect(() => {
    if (!loadingShipments && collations.length) {
      const interval = setInterval(async () => {
        await fetchShipmentStates()
        console.log('Fetching shipment states.', interval)
      }, 300000)
      return () => clearInterval(interval)
    }
  }, [loadingShipments])

  const subcoButtons = React.useMemo(
    () =>
      subcos.map(subco => (
        <SubcoButton
          key={subco.id}
          subcoId={subco.id}
          title={subco.name}
          badge={subco.delays}
          handleClick={handleSubcoClick}
          isActive={activeSubcoId == subco.id}
        />
      )),
    [subcos]
  )

  const driverButtons = React.useMemo(
    () =>
      drivers
        .filter((driver: IDriver) => driver.subCoId === activeSubcoId)
        .map((driver: IDriver) => (
          <DriverButton
            key={driver.id}
            driver={driver}
            selectedDriverId={activeDriverId}
            setSelectedDriverId={updateActiveDriverId}
            setSelectedSubCoId={setActiveSubcoId}
            setQuery={setQuery}
            setQueriedDrivers={setQueriedDrivers}
          />
        )),
    [drivers]
  )

  return (
    <div id="tour-overview" className="container-fluid">
      <div className="header">
        <div className="title-bar">
          <h1 className="h2 mt-4 text-left title">{t('TOUR_OVERVIEW')}</h1>
          {lastFetchedShipmentStates && (
            <div>
              <button onClick={fetchShipmentStates} className="refresh-button">
                <FontAwesomeIcon
                  className="refresh-list-icon"
                  icon="redo-alt"
                />
                {t('REFRESH_LIST')}
              </button>
              {t('LAST_UPDATED')}: {lastFetchedShipmentStates.format('hh:mm')}
            </div>
          )}
        </div>
        <p className="title-description">{t('TOUR_OVERVIEW_DESCRIPTION')}</p>
      </div>
      <div className="wrapper">
        {isLoading ? (
          <Loader loading={isLoading} caption={t('FETCHING_TOURS')} />
        ) : subcos.length > 0 ? (
          <div>
            <div id="search-bar" className="row">
              <div className="col-12 container">
                <span
                  className={clsx('search-icon search', {
                    searching: searchMode,
                  })}
                >
                  <FontAwesomeIcon icon="search" />
                </span>
                <input
                  id="shipment-search-input"
                  className={clsx(
                    'form-control mr-sm-2 search',
                    query.length && 'with-results'
                  )}
                  type="text"
                  placeholder={t('SEARCH_DRIVERS')}
                  aria-label="Search"
                  value={query}
                  autoComplete="off"
                  onChange={handleQueryChange}
                />
                {query.length > 0 && (
                  <button
                    className="btn bg-transparent clear-btn text"
                    onClick={resetSearch}
                  >
                    <FontAwesomeIcon icon="backspace" />
                    {t('COMMON:BUTTON.CLEAR')}
                  </button>
                )}
                {queriedDrivers.length > 0 && query.length > 0 && (
                  <div className="driver-query">
                    {queriedDrivers.map((driver: IDriver) => (
                      <button
                        key={driver.id}
                        onClick={handleSelectDriver(driver)}
                      >
                        <span>
                          {driver.name} - {driver.tourId}
                        </span>
                        <span>{driver.subCoName}</span>
                      </button>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className="subCos">{subcoButtons}</div>
            <div className="drivers">{driverButtons}</div>
            <QuickTourContainer />
          </div>
        ) : (
          <div className="no-results no-shipments-found d-flex w-100 flex-column align-items-center justify-content-center">
            <img className="icon" src={no_results_logo} alt="Trunkrs" />
            <h1 className="header">
              <span>{t('NO_TOURS_FOUND')}</span>
            </h1>
          </div>
        )}
      </div>
    </div>
  )
}

export default TourOverview
