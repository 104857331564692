import './style.scss'

import LayoutWithRightDrawer from '@/app/base/components/layoutWithRightDrawer'
import { CollectionTourContext } from '@/contexts'
import React, { useContext, useEffect, useState } from 'react'
import { CSSTransition } from 'react-transition-group'

import DrawerContent from './components/drawerContent'
import MainContent from './components/mainContent'
import PublishingScreen from './components/publishingScreen'

interface ICollectionTourProps {}

const CollectionTour: React.FC<ICollectionTourProps> = () => {
  const [initializing, setInitializing] = useState(true)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [showPublishingScreen, setShowPublishingScreen] = useState(false)
  const {
    fetchAvailableTags,
    setActiveTag,
    publishingNewTours,
    getOptimizationRemainingTime,
  } = useContext(CollectionTourContext)

  useEffect(() => {
    setInitializing(true)
    getOptimizationRemainingTime()
    fetchAvailableTags()
      .then(tags => {
        setActiveTag(tags[0])
      })
      .finally(() => {
        setInitializing(false)
      })
  }, [])

  useEffect(() => {
    if (publishingNewTours) {
      setTimeout(() => {
        setShowPublishingScreen(true)
      }, 500)
    }
    setShowPublishingScreen(false)
  }, [publishingNewTours])

  const toggleDrawer = (shouldBeOpen: boolean) => {
    setDrawerOpen(shouldBeOpen)
  }

  if (initializing) return null

  return (
    <>
      <LayoutWithRightDrawer
        drawerOpen={drawerOpen}
        drawerContent={<DrawerContent toggleDrawer={toggleDrawer} />}
      >
        <MainContent toggleDrawer={toggleDrawer} isDrawerOpen={drawerOpen} />
      </LayoutWithRightDrawer>

      <CSSTransition
        in={showPublishingScreen}
        timeout={500}
        classNames="publishing"
        unmountOnExit
      >
        <PublishingScreen />
      </CSSTransition>
    </>
  )
}

export default CollectionTour
