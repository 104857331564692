import { Modal } from '@/app/base/components'
import RemarksBox from '@/app/features/collectionTracking/components/drawerContent/remarksBox'
import { CollectionTourContext } from '@/contexts'
import { useTranslation } from '@/hooks'
import { useWithLongClick } from '@/hooks/useWithLongClick'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import React, { useContext, useEffect, useRef, useState } from 'react'

import classes from './style.module.scss'

interface IPublishModalProps {
  openModal: boolean
  handleModalClose: () => void
}

const PublishModal: React.FC<IPublishModalProps> = ({
  openModal,
  handleModalClose,
}) => {
  const { publishNewTours, publishingNewTours, optimiseTours } = useContext(
    CollectionTourContext
  )
  const [t] = useTranslation('COLLECTION_TOUR')
  const [isOptimizeActive, setIsOptimizeActive] = useState(false)
  const [height, setHeight] = useState('auto')
  const { starting, ...eventHandlersProps } = useWithLongClick(() =>
    isOptimizeActive ? optimiseTours() : publishNewTours()
  )

  const inner = useRef<HTMLDivElement>(null)

  // const enableOptimization = () => {
  //   setIsOptimizeActive(true)
  // }

  const disableOptimization = () => {
    setIsOptimizeActive(false)
  }

  const closeModal = () => {
    setHeight('0px')

    setTimeout(() => {
      handleModalClose()
    }, 200)
  }

  useEffect(() => {
    setHeight(inner.current!.clientHeight + 'px')
  }, [isOptimizeActive, openModal])

  useEffect(() => {
    if (publishingNewTours) {
      closeModal()
    }
  }, [publishingNewTours])

  return (
    <Modal show={openModal}>
      <div className={clsx(classes.container)}>
        <div className={classes.tabs_control}>
          <div
            onClick={disableOptimization}
            className={clsx(!isOptimizeActive && classes.active)}
          >
            <FontAwesomeIcon icon="check-circle" />
            {t('NO_OPTIMISATION')}
          </div>
          <div
            className={clsx(
              classes.optimizeDisabled,
              isOptimizeActive && classes.active
            )}
          >
            <FontAwesomeIcon icon="check-circle" />
            {t('OPTIMISE_TOURS')}
          </div>
        </div>
        <div className={classes.content} style={{ height }}>
          <div ref={inner}>
            <RemarksBox
              color="lighter-blue"
              content={
                isOptimizeActive ? (
                  <span>{t('PUBLISH_OPTIMISE_TEXT')}</span>
                ) : (
                  <span>{t('PUBLISH_NO_OPTIMISE')}</span>
                )
              }
              className={classes.remark}
            />

            {isOptimizeActive && (
              <RemarksBox
                color="light-orange"
                content={
                  <span>
                    <span>{t('ATTENTION')} </span>
                    {t('PUBLISH_OPTIMISE_ATTN')}
                  </span>
                }
                className={clsx(classes.remark, classes.optimize_remark)}
              />
            )}
          </div>
        </div>
        <div className={classes.actions}>
          <button
            className="btn button inactive icon"
            type="button"
            onClick={closeModal}
          >
            {t('CANCEL')}
          </button>
          <button
            className={clsx(
              'btn',
              classes.buttonPT,
              starting && classes.btn_started
            )}
            {...eventHandlersProps}
          >
            {t('PRESS_3_SECONDS')}
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default PublishModal
