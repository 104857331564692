import {
  InputMasked,
  InputNumber,
} from '@/app/base/components/reactHookComponents'
import FormControl from '@material-ui/core/FormControl/FormControl'
import MuiTextField, {
  StandardTextFieldProps,
} from '@material-ui/core/TextField'
import clsx from 'clsx'
import get from 'lodash/get'
import omit from 'lodash/omit'
import React, { ReactNode, memo, useCallback, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Props as IMaskProps } from 'react-input-mask'

import ErrorContainer from './EditMerchant/ErrorContainer'
import FieldBlock from './EditMerchant/FieldBlock'

export interface ITextFieldProps extends Omit<StandardTextFieldProps, 'error'> {
  name: string
  label?: string
  numeric?: boolean
  maskProps?: IMaskProps
  isEdit?: boolean
  error?: string
  renderValidationText?: (fieldError: string) => ReactNode
}

const TextField = ({
  id,
  label,
  name,
  numeric,
  maskProps,
  renderValidationText,
  placeholder,
  className,
  onChange,
  onBlur,
  error,
  isEdit = false,
  ...props
}: ITextFieldProps) => {
  const { control, errors, setValue } = useFormContext()
  const fieldError = get(errors, `${name}.message`, error)

  const renderControllerProps = useCallback(
    (props: any) => {
      let injectedProps = {
        ...props,
        value: get(props, 'value', ''),
        onChange: (ev: any) => {
          ev.persist()
          props.onChange(ev)
          onChange && onChange(ev)
        },
        onChangeCapture: (ev: any) => {
          ev.persist()
          setValue(name, ev.target.value)
        },
        onBlur: (ev: any) => {
          ev.persist()
          props.onBlur()
          onBlur && onBlur(ev)
        },
      }

      if (isEdit) {
        injectedProps = omit(injectedProps, ['onChange'])
      }

      return (
        <MuiTextField
          InputProps={{ disableUnderline: isEdit }}
          {...injectedProps}
        />
      )
    },
    [maskProps, onChange, onBlur]
  )

  const RenderChildren = useMemo(
    () =>
      maskProps ? (
        <InputMasked
          {...props as any[]}
          isEdit={isEdit}
          id={id}
          name={name}
          placeholder={placeholder}
          maskProps={maskProps}
        />
      ) : (
        <>
          {numeric ? (
            <InputNumber
              {...props as any[]}
              id={id}
              name={name}
              placeholder={placeholder}
            />
          ) : (
            <Controller
              {...props as any[]}
              as={renderControllerProps}
              id={id}
              name={name as never}
              placeholder={placeholder}
              control={control as any}
            />
          )}
        </>
      ),
    [props, id, name, placeholder, isEdit, maskProps]
  )

  if (isEdit) {
    return (
      <FieldBlock fieldError={fieldError} className={className} label={label}>
        {RenderChildren}
        <ErrorContainer fieldError={fieldError} />
      </FieldBlock>
    )
  } else {
    return (
      <FormControl
        className={clsx('text-field', className, fieldError && 'has-error')}
        fullWidth={true}
      >
        {label && <label htmlFor={id}>{label}</label>}
        {RenderChildren}
        {fieldError && (
          <>
            {renderValidationText ? (
              renderValidationText(fieldError)
            ) : (
              <div className="invalid-tooltip">{fieldError}</div>
            )}
          </>
        )}
      </FormControl>
    )
  }
}

export default memo(TextField)
