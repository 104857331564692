import './style.scss'

import { FlexText } from '@/app/base/components'
import {
  AppContext,
  AuthContext,
  CommentsContext,
  ShipmentsContext,
} from '@/contexts'
import { useTranslation } from '@/hooks'
import { IShipmentComment } from '@/models'
import { eventHandler } from '@/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isEmpty } from 'lodash'
import moment from 'moment'
import * as React from 'react'
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'

export const ShipmentComments = () => {
  const { activeShipment, activeShipmentLogs } = useContext(ShipmentsContext)
  const { shipmentComments, fetchShipmentComments } = useContext(
    CommentsContext
  )

  const [t] = useTranslation(['COMMENTS'])

  useEffect(() => {
    fetchShipmentComments(activeShipment)
  }, [activeShipment && activeShipment.getId, activeShipmentLogs])

  return (
    <div id="comments-panel">
      <h2 className="text">{t('COMMENTS')}</h2>
      <div className="wrapper">
        <PostComment />
        {shipmentComments
          .filter(comment => !comment.getParentId)
          .map(comment => {
            const replies = shipmentComments.filter(
              reply => reply.getParentId === comment.getId
            )
            return <Comment comment={comment} replies={replies} />
          })}
      </div>
    </div>
  )
}

const PostComment = () => {
  const { addComment } = useContext(CommentsContext)
  const { setLoading } = useContext(AppContext)
  const {
    identity: {
      profile: { picture: userPicture },
    },
  } = useContext(AuthContext)
  const [comment, setComment] = useState('')
  const [showPostButton, setShowPostButton] = useState(false)

  const handlePostComment = async () => {
    if (!isEmpty(comment)) {
      setLoading(true)
      await addComment(comment)
      setComment('')
      setLoading(false)
    }
  }

  const handleCancelPostComment = () => {
    if (comment.trim().length === 0) {
      setShowPostButton(false)
    }
  }

  const [t] = useTranslation(['COMMENTS', 'COMMON'])

  return (
    <div className="comment box-shadow">
      <div className="d-flex align-items-center">
        {isEmpty(userPicture) || userPicture === 'n/a' ? (
          <FontAwesomeIcon className="user-icon text" icon="user-circle" />
        ) : (
          <img className="user-icon" src={userPicture} />
        )}
        <FlexText
          className="form-control text"
          rows={1}
          placeholder={t('WRITE_A_NEW_COMMENT')}
          value={comment}
          onChange={eventHandler(setComment)}
          onFocus={eventHandler(setShowPostButton, true)}
          onBlur={handleCancelPostComment}
        />
      </div>
      {showPostButton && (
        <div className="d-flex justify-content-end">
          <button
            className="btn button action"
            type="button"
            onClick={handlePostComment}
            disabled={!comment.trim().length}
          >
            {t('COMMON:BUTTON.POST')}
          </button>
        </div>
      )}
    </div>
  )
}

const PostReply = (props: {
  setShowReply: Dispatch<SetStateAction<boolean>>
  parentId?: number
  name: string
}) => {
  const { addComment } = useContext(CommentsContext)
  const { setLoading } = useContext(AppContext)
  const {
    identity: {
      profile: { picture: userPicture },
    },
  } = useContext(AuthContext)
  const [comment, setComment] = useState('')
  const replyTextbox = useRef<HTMLTextAreaElement>(null)
  const { setShowReply, parentId, name } = props

  useEffect(() => {
    if (replyTextbox) {
      replyTextbox.current!.focus()
    }
  }, [])

  const handlePostReply = async () => {
    if (!isEmpty(comment)) {
      setLoading(true)
      await addComment(comment, parentId)
      setLoading(false)
      setShowReply(false)
    }
  }

  const handleCancelReply = () => {
    if (comment.trim().length === 0) {
      setShowReply(false)
    }
  }

  const [t] = useTranslation(['COMMENTS', 'COMMON'])

  return (
    <div className="reply-comment">
      <div className="d-flex align-items-center">
        {isEmpty(userPicture) || userPicture === 'n/a' ? (
          <FontAwesomeIcon className="user-icon text" icon="user-circle" />
        ) : (
          <img className="user-icon" src={userPicture} />
        )}
        <small className="label">{t('REPLYING_TO_USER', { name })}</small>
      </div>
      <textarea
        ref={replyTextbox}
        className="form-control text"
        rows={1}
        value={comment}
        onChange={eventHandler(setComment)}
        onBlur={handleCancelReply}
      />
      <div className="d-flex justify-content-end">
        <button
          className="btn button action"
          type="button"
          onClick={handlePostReply}
          disabled={!comment.trim().length}
        >
          {t('POST_REPLY')}
        </button>
      </div>
    </div>
  )
}

const Comment = ({
  comment,
  replies,
}: {
  comment: IShipmentComment
  replies: Array<IShipmentComment>
}) => {
  const {
    getId: parentId,
    getMessage: message,
    getTimestamp: timestamp,
    userProfile: { name, picture },
  } = comment

  const { removeComment } = useContext(CommentsContext)
  const { setLoading } = useContext(AppContext)
  const [showReply, setShowReply] = useState(false)

  const handleRemoveComment = async () => {
    setLoading(true)
    await removeComment(comment)
    setShowReply(false)
    setLoading(false)
  }

  const handleRemoveCommentReply = (
    commentReply: IShipmentComment
  ) => async () => {
    setLoading(true)
    await removeComment(commentReply)
    setLoading(false)
  }

  const [t] = useTranslation(['COMMON'])

  return (
    <div className="comment box-shadow">
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          {isEmpty(picture) || picture === 'n/a' ? (
            <FontAwesomeIcon className="user-icon text" icon="user-circle" />
          ) : (
            <img className="user-icon" src={picture} />
          )}
          <div className="user">
            <p className="name text">{name}</p>
            <p className="timestamp label">
              {moment(timestamp as any).format('HH:mm on DD-MM-YYYY')}
            </p>
          </div>
        </div>
        {!showReply && (
          <div className="d-flex justify-content-between">
            <button
              className="btn button action icon bg-transparent reply"
              onClick={eventHandler(setShowReply, true)}
            >
              {t('BUTTON.REPLY')}
              <FontAwesomeIcon icon="reply" />
            </button>
            <button
              className="btn button danger icon bg-transparent remove-reply"
              onClick={handleRemoveComment}
            >
              {t('BUTTON.REMOVE')}
              <FontAwesomeIcon icon="trash-alt" />
            </button>
          </div>
        )}
      </div>
      <p className="comment-text text">{message}</p>
      {replies.map(commentReply => {
        const {
          getMessage: message,
          getTimestamp: timestamp,
          userProfile: { name, picture },
        } = commentReply

        return (
          <div className="reply-comment">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                {isEmpty(picture) || picture === 'n/a' ? (
                  <FontAwesomeIcon
                    className="user-icon text"
                    icon="user-circle"
                  />
                ) : (
                  <img className="user-icon" src={picture} />
                )}
                <div className="user">
                  <p className="name text">{name}</p>
                  <p className="timestamp label">
                    {moment(timestamp as any).format('HH:mm on DD-MM-YYYY')}
                  </p>
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <button
                  className="btn button danger icon bg-transparent remove-reply"
                  onClick={handleRemoveCommentReply(commentReply)}
                >
                  {t('BUTTON.REMOVE')}
                  <FontAwesomeIcon icon="trash-alt" />
                </button>
              </div>
            </div>
            <p className="comment-text text">{message}</p>
          </div>
        )
      })}
      {showReply && (
        <PostReply
          setShowReply={setShowReply}
          parentId={parentId}
          name={name}
        />
      )}
    </div>
  )
}
