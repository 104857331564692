import clsx from 'clsx'
import React, { useMemo } from 'react'
import { ISingleCollectionTourEstimation } from 'Trunkrs-SDK/dist/models/spatial/Collections/CollectionTour'

import CollectionItem from '../collectionItem'
import classes from './style.module.scss'

interface ICollectionListProps {
  activeTour: ISingleCollectionTourEstimation
}

const CollectionList: React.FC<ICollectionListProps> = ({
  activeTour: { shops },
}) => {
  const collectionList = useMemo(() => {
    return shops.map(shop => {
      let position: number | string = shop.position
      if (shop.position === 0) {
        position = 'Start'
      } else if (shop.position === shops.length - 1) {
        position = 'End'
      }

      return (
        <tr key={shop.position}>
          <td>
            <CollectionItem collection={shop} position={position} />
          </td>
        </tr>
      )
    })
  }, [shops])

  return (
    <table className={clsx('table', classes.collection_list)}>
      <tbody>{collectionList}</tbody>
    </table>
  )
}

export default CollectionList
