import * as React from 'react'
import { useState, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { last } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { parse, ParseResult } from 'papaparse'
import { classList } from '@/utils'
import './style.scss'
import { ShipmentsTable } from '@/app/features/uploadcsv/components/shipmentsTable'
import { SearchSender } from '@/app/features/uploadcsv/components/searchSender'
import { useTranslation } from '@/hooks'
import { useContext } from 'react'
import { AppContext } from '@/contexts'

export interface IShipmentFileSchema {
  date: string
  parcel_barcode: string
  receiver_address: string
  receiver_city: string
  receiver_company_name: string
  receiver_country: string
  receiver_email_address: string
  receiver_name: string
  receiver_phone_number: string
  receiver_postal_code: string
  sender_address: string
  sender_city: string
  sender_company_name: string
  sender_country: string
  sender_email_address: string
  sender_name: string
  sender_phone_number: string
  sender_postal_code: string
}

export const UploadCSV = () => {
  const { setLoading } = useContext(AppContext)
  const [shipments, setShipments] = useState<Array<IShipmentFileSchema>>([])
  const [uploadedFile, setUploadedFile] = useState<File | undefined | null>()
  const [selectedMerchantId, setSelectedMerchantId] = useState()

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open: openFile,
  } = useDropzone({
    noClick: true,
    accept: '.csv',
    onDrop: (acceptedFiles: File[]) => setUploadedFile(last(acceptedFiles)),
  })

  const handleUpload = useCallback(
    (file: File | null) => {
      return () => {
        setUploadedFile(file)
      }
    },
    [uploadedFile]
  )

  const createShipmentsFromFile = () => {
    if (uploadedFile) {
      parse(uploadedFile, {
        worker: true,
        header: true,
        complete: (results: ParseResult) => {
          if (results.errors.length === 0) {
            setShipments(results.data)
          }
          setUploadedFile(null)
        },
      })
    }
  }

  const handleMerchantSelect = (id: number | undefined) => {
    setSelectedMerchantId(id)
    setUploadedFile(null)
  }

  const [t] = useTranslation(['UPLOAD_CSV'])

  return (
    <div id="uploadcsv-container" className="container-fluid">
      <h1 className="h2 mt-4 text-left title">{t('UPLOAD_CSV')}</h1>
      <p className="mt-1 text-left subtitle">{t('UPLOAD_CSV_DESCRIPTION')}</p>
      <div className="wrapper">
        <SearchSender onSelect={handleMerchantSelect} />
        <div
          id="csv-drop-box"
          className={classList('d-flex flex-column', {
            'for-uploading': uploadedFile,
            active: isDragActive,
          })}
          {...(selectedMerchantId !== undefined ? { ...getRootProps() } : [])}
        >
          {!uploadedFile ? (
            <>
              <span className="icon">
                <FontAwesomeIcon icon="hand-point-down" />
              </span>
              <input {...getInputProps()} />
              <p className="text">{t('DRAG_OR_DROP_CSV_FILE')}</p>
              <button
                className="btn button action"
                onClick={openFile}
                disabled={selectedMerchantId === undefined}
              >
                {t('BUTTON.SELECT_CSV_FILE')}
              </button>
            </>
          ) : (
            <>
              <span className="icon file">
                <FontAwesomeIcon icon="file-csv" />
              </span>
              <p className="text">{uploadedFile && uploadedFile['name']}</p>
              <button
                className="btn button success"
                onClick={createShipmentsFromFile}
              >
                {t('BUTTON.CREATE_SHIPMENTS')}
              </button>
              <button
                className="btn button icon danger"
                onClick={handleUpload(null)}
              >
                {t('BUTTON.CANCEL_UPLOAD')}
              </button>
            </>
          )}
        </div>
        <div className="shipments-table row">
          <div className="col">
            <p className="text-left subtitle">{t('CREATED_SHIPMENTS')}</p>
            {shipments.length === 0 ? (
              <p className="text no-results">{t('NO_CREATED_SHIPMENTS')}</p>
            ) : (
              <ShipmentsTable shipments={shipments} />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
