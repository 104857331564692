import * as React from 'react'

import { useSmartSearchActions } from './actions'
import { ISmartSearchState } from './state'

export const SmartSearchContext = React.createContext({} as ISmartSearchState)

export default ({ children }: { children: React.ReactNode }) => {
  const state = useSmartSearchActions()
  return (
    <SmartSearchContext.Provider value={state}>
      {children}
    </SmartSearchContext.Provider>
  )
}
