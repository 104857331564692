import './styles.scss'

import { useTranslation } from '@/hooks/useTranslation'
import chunk from 'lodash/chunk'
import get from 'lodash/get'
import groupBy from 'lodash/groupBy'
import sortBy from 'lodash/sortBy'
import uniqBy from 'lodash/uniqBy'
import values from 'lodash/values'
import React from 'react'
import { IMerchantOverview } from 'Trunkrs-SDK/dist/models/delivery/Merchant'

interface IMerchantListProps {
  merchants: IMerchantOverview[]
  onSelectMerchant: (merchantId: number) => void | Promise<void>
}

const MerchantList: React.FC<IMerchantListProps> = ({
  merchants,
  onSelectMerchant,
}) => {
  const [t] = useTranslation('MERCHANT_OVERVIEW')
  const groupByLetter = (merchants: Array<{ id: number; name: string }>) =>
    groupBy(merchants, merchant =>
      get(merchant, 'name', '')
        .slice(0, 1)
        .toLowerCase()
    )

  const sortedMerchantListItems = groupByLetter(
    sortBy(uniqBy(merchants, 'name'), merchant =>
      get(merchant, 'name', '').toLocaleLowerCase()
    )
  ) //items.sort((a, b) => a.id - b.id)

  const intoThree = chunk(
    values(sortedMerchantListItems),
    Math.ceil(values(sortedMerchantListItems).length / 3)
  )

  return (
    <div className="merchant-list-container">
      <div className="listContainer">
        {intoThree.map((column, index) => (
          <div className="merchant-overview-list" key={index}>
            {column.map(group => {
              const groupLetter = get(group, '[0].name[0]', '').toUpperCase()
              return (
                <div key={groupLetter} className="list-item-container">
                  <span className="text letter">{groupLetter}</span>
                  {group.map((merchant, index) => {
                    const { id, name } = merchant
                    return (
                      <div
                        onClick={() => onSelectMerchant(id)}
                        className="list-item"
                        key={`merchant-list-item-${index}`}
                      >
                        <span className="label name">{name}</span>
                        <span className="label id">{merchant.id}</span>
                      </div>
                    )
                  })}
                </div>
              )
            })}
          </div>
        ))}
      </div>
    </div>
  )
}

export default MerchantList
