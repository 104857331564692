import Button from '@/app/base/components/.v2/Button'
import { useTranslation } from '@/hooks/useTranslation'
import { SmartSearchContext } from '@/store/smartSearch'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { SHIPMENT_STATES } from 'Trunkrs-SDK/dist/typings/types'

import Checkbox from '../../../Checkbox'
import { normalStates } from '../../helpers/constants'
import { SearchableItemKey } from '../../helpers/interfaces'
import styles from './styles.module.scss'

export const StatusSelect: React.FC = () => {
  const [t] = useTranslation(['SHIPMENT_STATES', 'SMART_SEARCH'])
  const { handleAddBulkChip, activeChips, handleRemoveChip } = useContext(
    SmartSearchContext
  )

  const [checkboxes, setCheckboxes] = useState<
    {
      label: string
      key: SHIPMENT_STATES
      checked: boolean
    }[]
  >([])

  useEffect(() => {
    const tickedCheckboxes = normalStates.map(state => ({
      label: t(state),
      key: state,
      checked: activeChips.some(a => a.value === state),
    }))
    setCheckboxes(tickedCheckboxes)
  }, [activeChips, setCheckboxes])

  const handleChange = useCallback(
    (checked: boolean, key: string) => {
      const index = checkboxes.findIndex(cb => cb.key === key)
      let copy = [...checkboxes]
      copy[index] = {
        ...copy[index],
        checked,
      }
      setCheckboxes(copy)
    },
    [checkboxes, setCheckboxes]
  )

  const handleSearch = useCallback(() => {
    const tickedBoxes = checkboxes.filter(cb => cb.checked)

    const chipsToAdd = tickedBoxes.map((box, index) => ({
      id: activeChips.length + (index + 1),
      key: SearchableItemKey.CURRENT_SHIPMENT_STATE,
      value: box.key,
    }))

    handleAddBulkChip(chipsToAdd as any)
  }, [handleAddBulkChip, checkboxes, activeChips])

  return (
    <div className={styles.root}>
      <div className={styles.body}>
        {checkboxes.map(cb => (
          <div className={styles.checkbox}>
            <Checkbox
              checked={cb.checked}
              onChange={checked => handleChange(checked, cb.key)}
              variant="default"
            />
            <span className={styles.label}>{cb.label}</span>
          </div>
        ))}
      </div>
      <div className={styles.footer}>
        <Button onClick={handleSearch} variant="primary">
          {t('SEARCH')}
        </Button>
      </div>
    </div>
  )
}

export default StatusSelect
