import './style.scss'

import { FREEZER_STATE } from '@/constants'
import { IFreezerLogsHistory, IFrozenFoodDeliveryAttempt } from '@/models'
import first from 'lodash/first'
import groupBy from 'lodash/groupBy'
import last from 'lodash/last'
import moment from 'moment'
import React, { useCallback, useMemo, useState } from 'react'
import { IFreezerLog } from 'Trunkrs-SDK/dist/models/delivery/Shipment'

import AccumulatedOutOfFreezer from './components/AccumulatedOutOfFreezer'
import DailyRecordCard from './components/DailyRecordCard'
import NoFreezerLogs from './components/NoFreezerLogs'

interface IFreezerLogsProps {
  attempts?: IFrozenFoodDeliveryAttempt[]
  accumulatedOutOfFreezerTime: number
  maxTimeOutOfFreezer: number
}

const FreezerLogs: React.FunctionComponent<IFreezerLogsProps> = ({
  attempts = [],
  accumulatedOutOfFreezerTime,
  maxTimeOutOfFreezer,
}) => {
  const dailyRecordCards = useMemo(
    () =>
      [
        ...attempts
          .filter(attempt => !!attempt.logs.length)
          .map((attempt, index) => ({
            ...attempt,
            attemptNum: index + 1,
          })),
      ]
        .reverse()
        .map(attempt => {
          return (
            <DailyRecordCard
              key={attempt.attemptNum}
              date={attempt.date}
              logs={attempt.logs}
              attemptNum={attempt.attemptNum}
            />
          )
        }),
    [attempts]
  )

  const lastLogStatus = useMemo(() => {
    const lastAttempt = last(attempts)
    return lastAttempt && first(lastAttempt.logs)
  }, [attempts])

  if (!dailyRecordCards.length) {
    return <NoFreezerLogs />
  }

  return (
    <div>
      <AccumulatedOutOfFreezer
        time={accumulatedOutOfFreezerTime}
        lastLogStatus={lastLogStatus}
        maxTime={maxTimeOutOfFreezer}
      />
      {dailyRecordCards}
    </div>
  )
}

export default FreezerLogs
