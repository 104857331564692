import {
  InputMasked,
  InputNumber,
} from '@/app/base/components/reactHookComponents'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import get from 'lodash/get'
import React, { ReactNode, memo, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { Props as IMaskProps } from 'react-input-mask'

interface IInputGroupProps extends React.HTMLAttributes<HTMLInputElement> {
  label?: string
  names: string[]
  icons?: Array<ReactNode | string>
  placeholders?: string[]
  defaultValues?: string[] | number[]
  numeric?: boolean
  maskProps?: IMaskProps
  isEdit?: boolean
}

const InputGroup = ({
  label,
  names,
  placeholders,
  defaultValues,
  icons,
  numeric,
  maskProps,
  className,
  isEdit = false,
  ...props
}: IInputGroupProps) => {
  const { register, errors, getValues } = useFormContext()
  const fieldErrors = names
    .map(name => get(errors, `${name}.message`))
    .filter(message => message)

  return (
    <div
      className={clsx(
        'input-group',
        className,
        !!fieldErrors.length && 'has-error'
      )}
    >
      {label && <label>{label}</label>}
      <div className="input-group">
        {useMemo(
          () =>
            names.map((name, index) => {
              return (
                <>
                  {maskProps ? (
                    <InputMasked
                      {...props as any[]}
                      name={name}
                      placeholder={get(placeholders, `[${index}]`)}
                      maskProps={maskProps}
                    />
                  ) : (
                    <>
                      {numeric ? (
                        <InputNumber
                          {...props as any[]}
                          isEdit={isEdit}
                          defaultValue={get(defaultValues, index)}
                          name={name}
                          placeholder={get(placeholders, `[${index}]`)}
                        />
                      ) : (
                        <input
                          {...props}
                          className="form-control"
                          name={name}
                          placeholder={get(placeholders, `[${index}]`)}
                          ref={register}
                        />
                      )}
                    </>
                  )}
                  {icons && icons[index] && (
                    <div className="input-group-prepend input-group-append">
                      <div className="input-group-text">{icons[index]}</div>
                    </div>
                  )}
                </>
              )
            }),
          [names, icons, maskProps, numeric, getValues]
        )}
      </div>
      {!!fieldErrors.length && !isEdit && (
        <div className="invalid-tooltip">{get(fieldErrors, '[0]')}</div>
      )}
    </div>
  )
}

export default memo(InputGroup)
