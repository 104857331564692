import React from 'react'
import { Marker } from 'react-google-maps'

interface IStopMarkerProps {
  label: string
  color: string
  position: number
  lat: number
  lng: number
  start?: boolean
  end?: boolean
}

const StopMarker = ({
  label,
  color,
  position,
  lat,
  lng,
  start,
  end,
}: IStopMarkerProps) => {
  const icon = React.useMemo(() => {
    if (start) {
      return 'https://chart.apis.google.com/chart?chst=d_map_pin_icon&chld=home|BDBDBD|ffffff'
    }
    if (end) {
      return 'https://chart.apis.google.com/chart?chst=d_map_pin_icon&chld=truck|BDBDBD|ffffff'
    }

    return `https://chart.apis.google.com/chart?chst=d_map_xpin_letter&chld=pin|${position}|${color.substring(
      1
    )}|ffffff|ffffff`
  }, [color, start, end, position])

  return <Marker title={label} icon={icon} position={{ lat, lng }} />
}

export default StopMarker
