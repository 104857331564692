import * as React from 'react'
import './style.scss'
import logo from '../../img/logo.svg'
import { ILoadingProps } from '@/models'
import icon from '../../img/spinner.svg'
import { classList } from '@/utils'
import { defaultTo } from 'lodash'

export const Loader = ({
  caption,
  refElement,
  loading,
  ...props
}: ILoadingProps) => {
  return (
    <div
      className={classList('modal', { 'd-block': defaultTo(loading, false) })}
      tabIndex={-1}
      role="dialog"
      ref={refElement}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div {...props} className="loader">
            <div className="content">
              <img alt="Trunkrs Logo" src={logo} className="logo" />
              <div className="text">
                <img src={icon} className="spinner" />
                {caption || 'loading ...'}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
