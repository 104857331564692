import get from 'lodash/get'
import * as yup from 'yup'

import { MERCHANT_INFO_SECTIONS, getCountryCode } from './helper'

export const invoicingSchema = yup.object().shape({
  pricing: yup.object().shape({
    deliveryTier1: yup.number().required('FIELD_REQUIRED'),
    deliveryTier2: yup.number().required('FIELD_REQUIRED'),
    deliveryTier3: yup.number().required('FIELD_REQUIRED'),
    deliveryTier4: yup.number().required('FIELD_REQUIRED'),
    deliveryTier5: yup.number().required('FIELD_REQUIRED'),
    deliveryTier6: yup.number().required('FIELD_REQUIRED'),
    deliveryXlSurcharge: yup.number().required('INVALID_XL_PRICING'),
    collection: yup.number().required('INVALID_PRICE_COLLECTION'),
  }),
  invoicing: yup.object().shape({
    phoneNumber: yup.string().required('FIELD_REQUIRED'),
    contactPerson: yup.string().required('FIELD_REQUIRED'),
    emailAddressInvoice: yup
      .string()
      .required('FIELD_REQUIRED')
      .matches(/\S+@\S+\.\S+/g, 'INVALID_EMAIL'),
    address: yup.string().test({
      test: function(value) {
        if (!!value) {
          const valueArr = value.split(',')
          if (valueArr.length <= 1) {
            return this.createError({
              message: `INVALID_ADDRESS_FORMAT`,
              path: 'invoicing.address',
            })
          }
          const address = get(valueArr, '[0]')
          const cityWithPostalCode = get(valueArr, '[1]')
            .trim()
            .split(' ')
          const country = get(valueArr, '[2]')
          if (!getCountryCode(country)) {
            return this.createError({
              message: `INVALID_COUNTRY`,
              path: 'invoicing.address',
            })
          }
          if (
            !!address &&
            !!cityWithPostalCode[0] &&
            !!cityWithPostalCode[1] &&
            !!country
          ) {
            return true
          }
          return this.createError({
            message: `INVALID_ADDRESS_FORMAT`,
            path: 'invoicing.address',
          })
        } else {
          return this.createError({
            message: `FIELD_REQUIRED`,
            path: 'invoicing.address',
          })
        }
      },
    }),
    kvkNumber: yup
      .string()
      .required('FIELD_REQUIRED')
      .matches(/^\d{8}$/g, 'INVALID_KVK_NUMBER'),
    btwNumber: yup
      .string()
      .required('FIELD_REQUIRED')
      .matches(/^[a-z]{2}\d{9}[a-z]\d{2}$/gi, 'INVALID_BTW_NUMBER'),
  }),
})

export const shipmentSchema = yup.object().shape({
  operations: yup.object().shape({
    forecastedVolumeFrom: yup
      .number()
      .typeError('FIELD_REQUIRED')
      .required('FIELD_REQUIRED'),
    forecastedVolumeTo: yup
      .number()
      .typeError('FIELD_REQUIRED')
      .required('FIELD_REQUIRED'),
    shipmentLengthCm: yup
      .number()
      .typeError('FIELD_REQUIRED')
      .required('FIELD_REQUIRED'),
    shipmentWidthCm: yup
      .number()
      .typeError('FIELD_REQUIRED')
      .required('FIELD_REQUIRED'),
    shipmentHeightCm: yup
      .number()
      .typeError('FIELD_REQUIRED')
      .required('FIELD_REQUIRED'),
    expectedWeight: yup
      .string()
      .typeError('FIELD_REQUIRED')
      .required('FIELD_REQUIRED'),
  }),
})

export const webshopSchema = yup.object().shape({
  merchant: yup.object().shape({
    name: yup.string().required('FIELD_REQUIRED'),
    productCategoryId: yup.number().required('FIELD_REQUIRED'),
    accountManagerId: yup.number().required('FIELD_REQUIRED'),
    address: yup.string().test({
      test: function(value) {
        if (!!value) {
          const valueArr = value.split(',')
          if (valueArr.length <= 1) {
            return this.createError({
              message: `INVALID_ADDRESS_FORMAT`,
              path: 'merchant.address',
            })
          }

          const address = get(valueArr, '[0]')
          const cityWithPostalCode = get(valueArr, '[1]')
            .trim()
            .split(' ')
          const country = get(valueArr, '[2]')
          if (!getCountryCode(country)) {
            return this.createError({
              message: `INVALID_COUNTRY`,
              path: 'merchant.address',
            })
          }
          if (
            !!address &&
            !!cityWithPostalCode[0] &&
            !!cityWithPostalCode[1] &&
            !!country
          ) {
            return true
          }

          return this.createError({
            message: `INVALID_ADDRESS_FORMAT`,
            path: 'merchant.address',
          })
        } else {
          return this.createError({
            message: `FIELD_REQUIRED`,
            path: 'merchant.address',
          })
        }
      },
    }),
  }),
})

export const collectionSchema = yup.object().shape({
  collection: yup.object().shape({
    phoneNumber: yup.string().required('FIELD_REQUIRED'),
    contactPerson: yup.string().required('FIELD_REQUIRED'),
    address: yup
      .string()
      .required('FIELD_REQUIRED')
      .test({
        test: function(value) {
          if (!!value) {
            const valueArr = value.split(',')
            if (valueArr.length <= 1) {
              return this.createError({
                message: `INVALID_ADDRESS_FORMAT`,
                path: 'collection.address',
              })
            }
            const address = get(valueArr, '[0]')
            const cityWithPostalCode = get(valueArr, '[1]')
              .trim()
              .split(' ')
            const country = get(valueArr, '[2]')

            if (!getCountryCode(country)) {
              return this.createError({
                message: `INVALID_COUNTRY`,
                path: 'collection.address',
              })
            }
            if (
              !!address &&
              !!cityWithPostalCode[0] &&
              !!cityWithPostalCode[1] &&
              !!country
            ) {
              return true
            }
            return this.createError({
              message: `INVALID_ADDRESS_FORMAT`,
              path: 'collection.address',
            })
          } else {
            return this.createError({
              message: `FIELD_REQUIRED`,
              path: 'collection.address',
            })
          }
        },
      }),
  }),
  operations: yup.object().shape({
    cutOffTime: yup.string().required('FIELD_REQUIRED'),
  }),
})

export const itDetailsSchema = yup.object().shape({
  itDetails: yup.object().shape({
    recipientCommunication: yup.string().required('FIELD_REQUIRED'),
    contactPersonEmail: yup
      .string()
      .required('FIELD_REQUIRED')
      .matches(/\S+@\S+\.\S+/g, 'INVALID_EMAIL'),
    contactPerson: yup.string().required('FIELD_REQUIRED'),
  }),
})

export const tabs = (setState: any) => [
  {
    sectionName: MERCHANT_INFO_SECTIONS.WEBSHOP,
    disabled: false,
    child: MERCHANT_INFO_SECTIONS.WEBSHOP,
    onClick: () =>
      setState({
        state: {
          sectionActive: MERCHANT_INFO_SECTIONS.WEBSHOP,
          updateMerchantErr: undefined,
        },
      }),
  },
  {
    sectionName: MERCHANT_INFO_SECTIONS.SHIPMENTS,
    disabled: false,
    child: MERCHANT_INFO_SECTIONS.SHIPMENTS,
    onClick: () =>
      setState({
        state: {
          sectionActive: MERCHANT_INFO_SECTIONS.SHIPMENTS,
          updateMerchantErr: undefined,
        },
      }),
  },
  {
    sectionName: MERCHANT_INFO_SECTIONS.COLLECTION,
    disabled: false,
    child: MERCHANT_INFO_SECTIONS.COLLECTION,
    onClick: () =>
      setState({
        state: {
          sectionActive: MERCHANT_INFO_SECTIONS.COLLECTION,
          updateMerchantErr: undefined,
        },
      }),
  },
  {
    sectionName: MERCHANT_INFO_SECTIONS.INVOICING,
    disabled: false,
    child: MERCHANT_INFO_SECTIONS.INVOICING,
    onClick: () =>
      setState({
        state: {
          sectionActive: MERCHANT_INFO_SECTIONS.INVOICING,
          updateMerchantErr: undefined,
        },
      }),
  },
  {
    sectionName: MERCHANT_INFO_SECTIONS.IT,
    disabled: false,
    child: MERCHANT_INFO_SECTIONS.IT,
    onClick: () =>
      setState({
        state: {
          sectionActive: MERCHANT_INFO_SECTIONS.IT,
          updateMerchantErr: undefined,
        },
      }),
  },
]
