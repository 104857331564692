import localForage from 'localforage'
import React, { useEffect, useMemo, useState } from 'react'

export function usePersistedState<T>(key: string, defaultValue: T) {
  const [state, setState] = useState<T>(defaultValue)

  useEffect(() => {
    localForage.getItem<T>(key).then(nextState => setState(nextState))
  }, [])

  useEffect(() => {
    localForage.setItem(key, state)
  }, [key, state])

  return useMemo(
    () => [state, setState] as [T, React.Dispatch<React.SetStateAction<T>>],
    [key, state]
  )
}
