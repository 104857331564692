import { AlertModal } from '@/app/base/components/AlertModal'
import { ROOT_URL } from '@/constants'
import { AppContext, ShipmentsContext, TourRecoveryContext } from '@/contexts'
import { useTranslation } from '@/hooks'
import isEmpty from 'lodash/isEmpty'
import React, { useCallback, useContext, useState } from 'react'
import { Redirect } from 'react-router'

import DriverActiveWarning from './components/driverActiveWarning'
import MassUpdateForm from './components/massUpdateForm'
import NoTourYet from './components/noTourYet'
import ShipmentsTable from './components/ShipmentsTable'
import {
  handleCleanPayload,
  setCollationFinalValue,
  setModifiedCollation,
} from './helpers'

export const TourRecovery: React.FC = () => {
  const [t] = useTranslation('TOUR_RECOVERY')

  const {
    tourId,
    driver,
    rows,
    modifiedRows,
    fetchInitialData,
    selectedRows,
  } = useContext(TourRecoveryContext)
  const { massApplyState } = useContext(ShipmentsContext)
  const { setLoading } = useContext(AppContext)
  const [open, setOpen] = useState(false)

  const handleApplyModifiedStates = useCallback(async () => {
    const modifiedCollations = setModifiedCollation(modifiedRows)

    if (isEmpty(modifiedCollations)) return

    const finalPayload = setCollationFinalValue(modifiedCollations)

    setLoading(true)
    await handleSubmit(finalPayload)
    setLoading(false)
  }, [modifiedRows])

  const handleSubmit = useCallback(
    async data => {
      try {
        await handleCleanPayload(data, massApplyState)
      } catch (error) {
        console.log(error)
        setOpen(true)
      }

      await fetchInitialData()
    },
    [fetchInitialData]
  )

  if (!tourId) {
    return <Redirect to={ROOT_URL} />
  }

  if (!driver || !rows || !rows.length) {
    return <NoTourYet />
  }

  return (
    <div className="container-fluid">
      <div className="col-12 text-left">
        <h1 className="h2 mt-4 text-left title">Tour {tourId}</h1>
        <p className="sub"> {`${t('DRIVER')} ${driver.name}`}</p>
      </div>

      <DriverActiveWarning recorded_at={driver.recorded_at} />

      <MassUpdateForm />

      <ShipmentsTable t={t} />

      <div className="row text-left">
        <div className="col-12 mt-4 mb-4">
          <button
            className="btn button success box-shadow"
            onClick={handleApplyModifiedStates}
            disabled={isEmpty(selectedRows)}
          >
            {t('SET_STATE_COUNT', { stateCount: selectedRows.length })}
          </button>
        </div>
      </div>
      <AlertModal show={open} title={t('FAILED')} type="error">
        <p>{t('SOMETHING_WENT_WRONG')}</p>
      </AlertModal>
    </div>
  )
}

export default TourRecovery
