import { DriverContext } from '@/contexts'
import { IShipmentReturn } from '@/models'
import get from 'lodash/get'
import React, { memo, useContext } from 'react'

import { StatusLabel } from '../statusLabel'

export const ShipmentReturnDataRow = memo(
  ({
    shipmentReturn: {
      returnedToTrunkrs: isReturnedToTrunkrs,
      trunkrsNr,
      driver: { id: driverId },
      merchant: { name: merchantName },
      recipient: { address, city, postalCode },
      subco: { name: subcoName },
    },
  }: {
    shipmentReturn: IShipmentReturn
  }) => {
    const { allDrivers } = useContext(DriverContext)

    const driverName = get(
      allDrivers.find(driver => get(driver, 'driverId') === driverId),
      'driverName',
      ''
    )

    return (
      <tr>
        <td className="text-pre">{`${address}\n${postalCode} ${city}`}</td>
        <td>{trunkrsNr}</td>
        <td>{merchantName}</td>
        <td>{subcoName}</td>
        <td>{driverName}</td>
        <td className="status">
          <StatusLabel isReturned={isReturnedToTrunkrs} />
        </td>
      </tr>
    )
  }
)
