import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FormControl from '@material-ui/core/FormControl/FormControl'
import Select from '@material-ui/core/Select/Select'
import clsx from 'clsx'
import React, { HTMLProps, memo } from 'react'

interface ISelectFieldProps extends HTMLProps<HTMLSelectElement> {
  id: string
  name: string
  label?: string
  popOverClassName?: string
  menuListMaxHeight?: number
  isEdit?: boolean
}

const CustomSelect = ({
  name,
  id,
  label,
  children,
  className,
  popOverClassName,
  placeholder,
  menuListMaxHeight,
  isEdit,
  ...rest
}: ISelectFieldProps) => {
  const menuPopoverId = `${id}-select-menu-popover`

  const paperClass = 'select-menu-popover-container'

  return (
    <FormControl className={clsx('select-field', className)} fullWidth={true}>
      {label && <label htmlFor={id}>{label}</label>}
      <Select
        {...rest as any}
        variant="outlined"
        displayEmpty={true}
        className="form-control"
        placeholder={placeholder}
        MenuProps={{
          placeholder,
          PopoverClasses: {
            paper: clsx(paperClass, popOverClassName),
          },
          PaperProps: {
            style: {
              maxHeight: menuListMaxHeight,
            },
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 10,
            horizontal: 1,
          },
          getContentAnchorEl: null,
          marginThreshold: -999,
          id: menuPopoverId,
        }}
        IconComponent={({ className }) => (
          <FontAwesomeIcon
            icon={
              className.includes('MuiSelect-iconOpen')
                ? 'chevron-up'
                : 'chevron-down'
            }
          />
        )}
      >
        {children}
      </Select>
    </FormControl>
  )
}

export default memo(CustomSelect)
