import ButtonBase from '@material-ui/core/ButtonBase'
import React, { useMemo } from 'react'

import OnTimeSpan from '../onTimeSpan'
import classes from './style.module.scss'

interface IitemsProps {
  items: any[]
  activeItem: string
  onSelectItem: (item: string) => void
}

const ItemsNavigationWithCount: React.FC<IitemsProps> = ({
  items,
  activeItem,
  onSelectItem,
}) => {
  const list = useMemo(
    () =>
      items.map((item, index) => (
        <ButtonBase
          disableRipple={true}
          onClick={() => onSelectItem(item.tag)}
          key={`item_nav_button_${index}`}
          className={classes.navButton}
        >
          <OnTimeSpan
            status={item.status}
            extraClassNames={activeItem === item.tag ? [classes.active] : []}
          >
            {item.tag} ({item.linehauls || 0})
          </OnTimeSpan>
        </ButtonBase>
      )),
    [items, activeItem]
  )

  return <div className={classes.item_navigation}>{list}</div>
}

export default ItemsNavigationWithCount
