import emptyStateIcon from '@/app/base/img/hublogs-empty-state.svg'
import { ShipmentsContext } from '@/contexts'
import { useTranslation } from '@/hooks/useTranslation'
import clsx from 'clsx'
import get from 'lodash/get'
import moment from 'moment'
import React, { useContext, useMemo } from 'react'

import EmptyLog from '../emptyLog'
import styles from './styles.module.scss'

export interface AuditLogsProps {}

export const AuditLogs: React.FC<AuditLogsProps> = () => {
  const [t] = useTranslation('SCAN_LOGS')
  const { auditLogs } = useContext(ShipmentsContext)

  const auditLogsGroupedByDate = Object.keys(auditLogs)

  const hasLogs = useMemo(() => auditLogsGroupedByDate.length, [
    auditLogsGroupedByDate,
  ])

  if (hasLogs) {
    return (
      <>
        {auditLogsGroupedByDate.map((date, index) => (
          <div key={index} className={clsx(styles.wrapper, styles.separator)}>
            <div className={styles['hub-log-container']}>
              <div className={styles.date}>
                {moment(date, 'DD/MM/YYYY').format('DD-MM-YYYY')}
              </div>
              {auditLogs[date].map((log, index) => (
                <div key={index} className={styles['hub-log']}>
                  <div className={styles.timestamp}>
                    {moment(log.createdAt).format('HH:mm:ss')}
                  </div>
                  <div className={styles.source}>
                    {get(log, 'source')
                      ? log.source.replace(/[[\]]/g, '')
                      : t('NO_SOURCE')}
                  </div>
                  <div className={styles['static-tooltip']}>
                    <div>{t('OWNER')}</div>
                    <div>{log.owner}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </>
    )
  }

  return (
    <div className={clsx(styles.wrapper, styles.empty)}>
      <EmptyLog
        src={emptyStateIcon}
        title={t('EMPTY_SCREEN_TITLE')}
        description={t('EMPTY_SCREEN_DETAILS')}
      />
    </div>
  )
}

export default AuditLogs
