import React, { memo } from 'react'

import { ReactComponent as LogOffIcon } from '../../../img/sidebar-menu/log-off-icon.svg'
import { ReactComponent as MessagesIcon } from '../../../img/sidebar-menu/messages-icon.svg'
import { ReactComponent as ReportIcon } from '../../../img/sidebar-menu/report-icon.svg'
import { ReactComponent as ReturnsIcon } from '../../../img/sidebar-menu/returns-icon.svg'
import { ReactComponent as ShipmentIcon } from '../../../img/sidebar-menu/shipments-icon.svg'
import { ReactComponent as TourOverviewIcon } from '../../../img/sidebar-menu/tour-overview-icon.svg'
import { ReactComponent as UserIcon } from '../../../img/sidebar-menu/user-icon.svg'
import { ReactComponent as WarehouseIcon } from '../../../img/sidebar-menu/warehouse-icon.svg'

const iconDictionary = {
  shipments: <ShipmentIcon />,
  warehouse: <WarehouseIcon />,
  user: <UserIcon />,
  touroverview: <TourOverviewIcon />,
  returns: <ReturnsIcon />,
  messages: <MessagesIcon />,
  'log-off': <LogOffIcon />,
  report: <ReportIcon />,
} as { [key: string]: React.ReactNode }

const SidebarMenuIcon = ({ icon }: { icon: string }) => {
  const svg = iconDictionary[icon]

  return <>{svg}</>
}

export default memo(SidebarMenuIcon)
