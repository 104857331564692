import * as actions from './actions'

export interface State {
  isLoading: boolean
  isLoadingDetails: boolean
  isSaving: boolean
  debtorNumber: null | string
  debtor: actions.DebtorResponse[0] | null
  debtors: actions.DebtorResponse
}

export const initialState: State = {
  isLoading: false,
  isLoadingDetails: false,
  isSaving: false,
  debtorNumber: null,
  debtor: null,
  debtors: [],
}

export const reducer = (state: State, action: actions.Action): State => {
  const { type } = action

  switch (type) {
    case 'set-loading': {
      const { isLoading } = action as actions.SetLoadingAction
      return { ...state, isLoading }
    }

    case 'set-saving': {
      const { isSaving } = action as actions.SetSavingAction
      return { ...state, isSaving }
    }

    case 'set-debtors': {
      const { debtorNumber } = state
      const { debtors } = action as actions.SetDebtorAction

      const debtor =
        debtorNumber && debtors.length
          ? debtors.find(d => d.debtorNumber === debtorNumber) || null
          : null

      return { ...state, debtors, debtor }
    }

    case 'set-current-merchant': {
      const { debtors } = state
      const { debtorNumber } = action as actions.SetCurrentMerchantAction

      const debtor =
        debtorNumber && debtors.length
          ? debtors.find(d => d.debtorNumber === debtorNumber) || null
          : null

      return { ...state, debtorNumber, debtor }
    }

    default: {
      return state
    }
  }
}
