import { CURRENCY_EURO } from '@/constants/MERCHANT_OVERVIEW'
import { COUNTRY_CODES } from '@/models'
import capitalize from 'lodash/capitalize'

export enum MERCHANT_INFO_SECTIONS {
  COLLECTION = 'COLLECTION',
  INVOICING = 'INVOICING',
  IT = 'IT',
  SHIPMENTS = 'SHIPMENTS',
  WEBSHOP = 'WEBSHOP INFORMATION',
  NOTIFICATION = 'NOTIFICATION',
}

export const getPrice = (price: number) => {
  if (price) return `${CURRENCY_EURO} ${price}`
  return '--'
}

export const getVolumes = (from: number, to: number) => {
  if (from && to) return `${from} - ${to}`
  return '--'
}

export const getSizeCm = (w: number, h: number, l: number) => {
  if (w && h && l) return `${l}x${w}x${h}`
  return '--'
}

export const getCountry = (countryCode: string) => {
  for (const [key, value] of Object.entries(COUNTRY_CODES)) {
    if (countryCode && countryCode.trim().toLowerCase() === value) {
      return capitalize(key)
    }
  }
  return undefined
}

export const getCountryCode = (countryName: string) => {
  for (const [key, value] of Object.entries(COUNTRY_CODES)) {
    if (countryName && countryName.trim().toUpperCase() === key) {
      return value.toUpperCase()
    }
  }
  return undefined
}
